import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'ioh-imgTooltip',
  styleUrls: ['./img-tooltip.component.scss'],
  templateUrl: './img-tooltip.component.html',
})
export class ImgTooltipComponent implements OnInit {
  IsShowFlag = false;
  @Input() imgSrc = '';
  @Input() left = '';

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit() {
    const iconTrigger = document.getElementById('icon-trigger');
    iconTrigger.style.left = this.left;
  }

  mouseIn() {
    this.IsShowFlag = true;
  }

  mouseOut() {
    this.IsShowFlag = false;
  }
}
