import * as EngagementActions from './engagement-details.actions';
import * as EngagementDetailsSelectors from './engagement-details.selectors';
import * as fromEngagementDetails from './engagement-details.reducer';
import { Action, Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable()
export class EngagementDetailsFacade {
  allEngagementDetails$ = this.store.pipe(
    select(EngagementDetailsSelectors.getEngagementDetailsState)
  );

  allInternalPurposes$ = this.store.pipe(
    select(EngagementDetailsSelectors.getAllInternalPurposes),
    filter((result) => Boolean(result))
  );

  allOpportunityIds$ = this.store.pipe(
    select(EngagementDetailsSelectors.getAllOpportunityIds),
    filter((result) => Boolean(result))
  );

  constructor(
    private readonly store: Store<
      fromEngagementDetails.EngagementDetailsPartialState
    >
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  loadInternalPurposeResults(programId: string) {
    this.dispatch(EngagementActions.loadInternalPurposeResults({ programId }));
  }

  loadOpportunityIdResults(value) {
    this.dispatch(
      EngagementActions.loadOpportunityIdsSearchResults({ payload: value })
    );
  }

  selectInternalPurpose(value) {
    this.dispatch(
      EngagementActions.selectInternalPurpose({
        selectedInternalPurpose: value,
      })
    );
  }

  updateobjectivesAndExpectations(value) {
    this.dispatch(
      EngagementActions.updateObjectivesAndExpectations({
        objectivesAndExpectations: value,
      })
    );
  }

  updateBackgroundText(value) {
    this.dispatch(
      EngagementActions.updateBackgroundText({
        backgroundText: value,
      })
    );
  }

  updateNoMmsOpportunityId(value) {
    this.dispatch(
      EngagementActions.updateNoMmsOpportunityId({
        noMmsOpportunityId: value,
      })
    );
  }

  updateSelectedOpportunityIds(value) {
    this.dispatch(
      EngagementActions.updateSelectedOpportunityIds({
        selectedOpportunityIds: value,
      })
    );
  }

  clearStateObject() {
    this.dispatch(EngagementActions.clearStateObject());
  }
}
