<div [ngClass]="{'margin-top-7':tooltipObj}"
  fxLayout="{{direction}}"
  class="dropdown"
  [formGroup]="parentForm">
  <label
    fxFlex
    attr.for={{dropdownId}}
    class="dropdown__label"
    *ngIf="label"
    tabindex="0"
    [class.dropdown__label__alignment]="direction === 'row'"
    >
      <span *ngIf="required === true" class="dropdown__asterisk">*</span>
        {{label}}<ioh-tooltip [detail]="tooltipObj"></ioh-tooltip>
        <ioh-imgTooltip left='180px' imgSrc="../../../../../assets/images/purpose.PNG" *ngIf="label === 'Engagement Purpose'"></ioh-imgTooltip>
      </label>
    <mat-form-field appearance="outline" class="dropdown__field">
      <mat-select
        [formControlName]="field"
        panelClass="{{panelName}}"
        multiple="{{isMultiple}}"
        placeholder="{{placeholder}}"
        id="{{dropdownId}}"
        [attr.aria-label]="field"
        [compareWith]="compareFn"
        [disabled]="isDisabled"
        (click)="expandDropdown();$event.stopPropagation()"
        >
        <mat-option title="{{value.tooltip}}"
          *ngFor="let value of values"
          [value]="value"
          [ngClass]="{'hideOption': field=='category'&&hideOptions.includes(value.value)}"
          [disabled]="isDisabledOp(value)"
          (click)="collapseDropdown();$event.stopPropagation()"
          >
          {{value.value}}
          <button
            *ngIf="pipelineView && value.value!='No Saved Views' && expanded"
            mat-icon-button
            aria-label="Clear"
            class="deleteButton"
            (click)="onViewDeleteClick(value);$event.stopPropagation()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ioh-error
    class="dropdown__error"
    *ngIf="(parentForm.touched || parentForm.dirty) &&
      (parentForm.hasError('unique'))"
    errorMsg="This range was already selected!">
  </ioh-error>
    <ioh-error
      class="dropdown__error"
      *ngIf="(parentForm.get(field).touched || parentForm.get(field).dirty) && parentForm.get(field).hasError('required')"
      errorMsg="{{label || 'Period'}} is required"></ioh-error>
</div>
