import { ACTIVE_ENG_STATUS } from '@utils/index';
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AddBookingService } from '@services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClientComponent } from './client/client.component';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ExternalComponent } from './external/external.component';
import { GeneralFacade } from '@ioh/core-data';
import { IOrgType, IOrganizationCategory } from '@ioh/types';
import { InternalComponent } from './internal/internal.component';
import { ModalService } from '@services';
import { OrganizationEntryService } from './organization-entry.service';
import { OrganizationTableElement } from '@utils/index';
import { TableService, WorkshopService } from '@services';
import { browser } from '@utils/index';
import {
  historyColNames,
  orgColNames,
} from '@assets/data/index';
import { tooltipConst } from '@assets/data/index';
import { transformDates } from '@utils/index';
import _, { isEqual } from 'lodash';

@Component({
  selector: 'ioh-organization',
  styleUrls: ['./organization.component.scss'],
  templateUrl: './organization.component.html',
})
export class OrganizationComponent implements OnInit, OnDestroy {
  @Input() frontOffice: any;
  @Input() isDisabled: boolean;
  @Input() organizationId: any;
  @Input() externalOrganizationsCategories$: IOrganizationCategory[];
  @Input() accentureEntitiesResults$: any[];
  @Input() organizationTypes$: IOrgType[];
  @ViewChild('OrgsTemplate', { static: false }) modalTemplate: TemplateRef<
    ClientComponent | InternalComponent | ExternalComponent
  >;

  organizationClientType: any = [];
  orgTooltipObj: any = {};
  template: any = {};
  clinetDetails: any = {};
  templateIndex = 0;
  closePopup: boolean;
  saveOrg: boolean;
  orgHistoryTable: boolean = false;
  isNewWorkshop: boolean = false;
  alreadyShowHistory: boolean = false;
  width = '50rem';
  orgSelected = { id: null, value: null };
  formattedTableData$ = new BehaviorSubject([]);
  colNames = orgColNames;
  workshopid: string | number;
  programList = [];
  orgFullData = [];
  historyList = [];
  workshopStatus: any = '';
  orgHistoryListview$ = new BehaviorSubject([]);
  browser = browser;
  historyColNames = historyColNames;
  organizationTypeElemName: any = {};
  dropDownSelectedTypeOfOrganization;
  parentForm: AbstractControl;
  parentOrganizationsFormArray: FormArray;
  formGroupForTemplate: FormGroup;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    public readonly generalFacade: GeneralFacade,
    private readonly fb: FormBuilder,
    private readonly controlContainer: ControlContainer,
    private readonly modalService: ModalService,
    private readonly workshopService: WorkshopService,
    private readonly tableService: TableService,
    private readonly addBookingService: AddBookingService,
    public organizationEntryService: OrganizationEntryService
  ) {}

  ngOnInit(): void {
    this.programList = this.addBookingService.programList;
    this.orgTooltipObj = tooltipConst.organizaitonTooltipObj;
    this.dropDownSelectedTypeOfOrganization = this.fb.group({
      value: this.fb.control('', Validators.required),
    });

    this.parentForm = this.controlContainer.control;
    this.disableInput();
    this.parentOrganizationsFormArray = this.controlContainer.control.get(
      'organisations'
    ) as FormArray;

    this.organizationTypeElemName = {
      direction: 'column',
      dropdownId: this.organizationId,
      field: 'value',
      isMultiple: false,
      label: 'Organization type',
      name: `Organization_Type_${this.organizationId}`,
      placeholder: 'Select organization type',
      required: true,
      value: 'value',
    };

    this.registerSubscriptions();
  }

  registerSubscriptions() {
    this.subscriptions.add(
      this.workshopService.loadOrgList$.subscribe((workshops) => {
        this.orgFullData = workshops;
      })
    );
    this.workshopService.selectedWorkshopId$.subscribe((workshopid) => {
      this.workshopid = workshopid;
    });
    this.subscriptions.add(
      this.dropDownSelectedTypeOfOrganization.valueChanges.subscribe(
        ({ value }) => {
          this.setSelectedOrganization(value);
        }
      )
    );
    this.subscriptions.add(
      this.generalFacade.clientType$.subscribe((type) => {
        this.organizationClientType = type;
      })
    );
    this.subscriptions.add(
      this.parentOrganizationsFormArray.valueChanges.subscribe((changes) => {
        this.handleOrgListviewData(this.orgFullData);
        this.formatTableData();
      })
    );
    this.subscriptions.add(
      this.addBookingService.workshopStatus$.subscribe((status) => {
        this.workshopStatus = status ? status.value : '';
        this.orgHistoryAlreadyHas();
      })
    );
    this.subscriptions.add(
      this.addBookingService.isNewWorkshop$.subscribe((isNew) => {
        this.isNewWorkshop = isNew;
      })
    );
    this.subscriptions.add(
      this.addBookingService.hasOrgHistory$.subscribe((has) => {
        this.alreadyShowHistory = has;
        this.orgHistoryAlreadyHas();
      })
    );
    this.subscriptions.add(
      this.orgHistoryListview$.subscribe((orgHistory) => {
        this.orgHistoryAlreadyHas();
      })
    );
  }

  disableInput() {
    if (this.isDisabled) {
      this.parentForm.disable();
    }
  }

  orgHistoryAlreadyHas() {
    if (
      this.alreadyShowHistory &&
      (this.isNewWorkshop || ACTIVE_ENG_STATUS.includes(this.workshopStatus))
    ) {
      this.orgHistoryTable = true;
    } else {
      this.orgHistoryTable = false;
    }
  }

  showOrgHistoryTable() {
    let historyLists = [];
    this.orgHistoryTable = false;
    this.orgHistoryListview$.subscribe((historyList) => {
      historyLists = historyList || [];
    });
    if (this.isNewWorkshop && historyLists.length) {
      this.orgHistoryTable = true;
      this.addBookingService.hasOrgHistory$.next(true);
    } else if (
      !this.isNewWorkshop &&
      historyLists.length &&
      ACTIVE_ENG_STATUS.includes(this.workshopStatus)
    ) {
      this.orgHistoryTable = true;
      this.addBookingService.hasOrgHistory$.next(true);
    } else {
      this.addBookingService.hasOrgHistory$.next(false);
    }
  }

  handleOrgListviewData(workshops): void {
    if (workshops && workshops.length > 0) {
      workshops = workshops.filter((workshop) => {
        return !['TST', 'DEMO'].includes(workshop.workshop.program.code);
      });
      const unorderedData = this.tableService.flattenAndFormatOrgList(
        workshops
      );
      this.orgHistoryListview$.next(
        this.filterOrgListview(this.setupClientOrg(unorderedData)).map(
          transformDates.bind(this)
        )
      );
    }
  }

  filterOrgListview(workshops) {
    let listviewData = [];
    const orgList = this.parentOrganizationsFormArray
      ? this.parentOrganizationsFormArray.getRawValue()
      : [];
    const filterContent = orgList
      .filter((org) => this.orgCondition(org))
      .map((org) => {
        return org.organizationName;
      });
    if (filterContent.length > 0 && workshops.length > 0) {
      workshops = workshops.map((workshop) => {
        const filterOrgNames = workshop.organizations
          .filter(
            (org) =>
              filterContent.includes(org.organizationName) &&
              this.workshopid != workshop.id
          )
          .map((org) => {
            return org.organizationName;
          });
        workshop.endDateOrder = new Date(workshop.engagementEndDate).getTime();
        workshop.orgName = filterOrgNames.join();
        return workshop;
      });
      listviewData = this.sortByEnddate(this.hasAccessToNavigate(workshops));
    }
    return listviewData;
  }

  sortByEnddate(workshops) {
    return workshops.sort(function (o1, o2) {
      return o2.endDateOrder - o1.endDateOrder;
    });
  }

  hasAccessToNavigate(workshops) {
    const userAccess = this.programList.map((program) => {
      return program.value;
    });
    workshops = workshops
      .filter((workshop) => workshop.orgName)
      .map((workshop) => {
        workshop.accessDeny = !userAccess.includes(workshop.program);
        return workshop;
      });
    return workshops;
  }

  setupClientOrg(workshops: any[]) {
    return workshops
      .filter(
        (item) =>
          item.status != 'Cancelled' &&
          item.organizations &&
          item.organizations.length > 0
      )
      .map((item) => {
        item.organizations = item.organizations.filter((org) =>
          this.orgCondition(org)
        );
        return item;
      });
  }

  orgCondition(org) {
    return (
      org.type &&
      (org.type.id == '3' || org.type.id == '4') &&
      org.organizationName &&
      !org.organizationName.toLowerCase().includes('accenture')
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setSelectedOrganization(value): void {
    this.orgSelected = value;
  }

  formatTableData() {
    const array = this.parentOrganizationsFormArray.value;
    const formattedAray: OrganizationTableElement[] = array.map(
      (element, index: number) => ({
        action: 'edit delete',
        group: element.typeString
          ? element.typeString === 'Internal'
            ? 'Internal'
            : element.category && element.category.value
          : element.type.value == 'Other (Including Accenture Federal Clients)'
          ? 'Client'
          : element.operatingUnit,
        index: index,
        org: element.organizationName || element.accentureEntity?.value,
        type: element.typeString ? element.typeString : 'Client',
        typeId: element.typeString ? element.type : element.type.id,
      })
    );
    this.formattedTableData$.next(formattedAray);
  }

  openDialog(organization, action?: String): void {
    // this makes sure we open the right modal
    this.closePopup = !!action;
    this.saveOrg = false;
    this.formGroupForTemplate = organization;
    this.clinetDetails = this.template;
    const dataModal = { organization, template: this.modalTemplate };
    this.modalService.openDialog(dataModal, this.width);

    this.subscriptions.add(
      this.modalService.dialogInstance.beforeClosed().subscribe(() => {
        const modalOrganization = this.modalService.dialogInstance
          .componentInstance.data.organization;
        this.removeEmptyOrgEntryOnPrematureClose(modalOrganization);
        if (action === 'edit' && !this.saveOrg) {
          this.parentOrganizationsFormArray.setControl(
            this.templateIndex,
            this.clinetDetails
          );
        }
      })
    );
  }

  findIndexOfOrganization(organization) {
    return this.parentOrganizationsFormArray
      .getRawValue()
      .findIndex((value) => isEqual(value, organization));
  }

  removeEmptyOrgEntryOnPrematureClose(modalOrganization) {
    if (modalOrganization.invalid || !this.closePopup) {
      const indexOfOrg = this.findIndexOfOrganization(modalOrganization.value);
      this.parentOrganizationsFormArray.removeAt(indexOfOrg);
    }
  }

  isInternalOrg(): boolean {
    // These are the id's from the DB response
    return this.orgSelected.id == 1;
  }

  isExternalOrg(): boolean {
    return this.orgSelected.id == 2;
  }

  isClientOrg(): boolean {
    return this.orgSelected.id == 3 || this.orgSelected.id == 4;
  }

  addOrganization(): void {
    if (this.isInternalOrg()) {
      this.parentOrganizationsFormArray.push(
        this.organizationEntryService.createInternalOrganization(
          this.orgSelected.id,
          this.orgSelected.value
        )
      );
    } else if (this.isExternalOrg()) {
      this.parentOrganizationsFormArray.push(
        this.organizationEntryService.createExternalOrganization(
          this.orgSelected.id,
          this.orgSelected.value
        )
      );
    } else if (this.isClientOrg()) {
      const form = this.organizationEntryService.createClientOrganization(
        3,
        this.organizationClientType
          .filter((t) => t.id == 3)
          .map((type) => type.value)[0]
      );
      this.parentOrganizationsFormArray.push(form);
    }
    const lastOrganizationAdded = this.parentOrganizationsFormArray.at(
      this.parentOrganizationsFormArray.length - 1
    );

    if (lastOrganizationAdded) {
      this.templateIndex = this.parentOrganizationsFormArray.length - 1;
      this.template = lastOrganizationAdded;
      this.openDialog(lastOrganizationAdded);
    }
  }

  saveOrUpdate(): void {
    this.closePopup = true;
    this.saveOrg = true;
    if (!this.formGroupForTemplate.invalid) {
      this.parentOrganizationsFormArray.setControl(
        this.templateIndex,
        this.template
      );
      this.modalService.closeDialog();
    }
    this.handleOrgListviewData(this.orgFullData);
    this.showOrgHistoryTable();
  }

  editEntry(value: OrganizationTableElement): void {
    const organization = this.parentOrganizationsFormArray.at(value.index);
    this.setSelectedOrganization({ id: value.typeId, value: value.type });
    this.template = organization;
    this.templateIndex = value.index;
    this.openDialog(this.template, 'edit');
  }

  deleteEntry(element: OrganizationTableElement): void {
    this.parentOrganizationsFormArray.removeAt(element.index);
    this.handleOrgListviewData(this.orgFullData);
  }
}
