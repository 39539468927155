<div fxLayout="column" class="input" [formGroup]="parentForm" [attr.disabled]="isDisabled ? '' : null">
  <label *ngIf="label" fxFlex attr.for="{{ inputId }}" class="input__label" tabindex="0">
    <span *ngIf="required" class="input__asterisk">*</span>
    {{label}}<ioh-tooltip [detail]="tooltipObj"></ioh-tooltip>
  </label>
  <mat-form-field
    [style.width]="width"
    appearance="outline"
    color="primary"
  >
    <input
      matInput
      placeholder="{{ placeholder }}"
      formControlName="{{ value }}"
      name="{{ name }}"
      id="{{ inputId }}"
      [pattern]="regex"
      [maxlength]="maxLength"
      attr.aria-invalid="{{ value }} === '' ? true : false"
      attr.aria-label="{{value}}"
      #input
      (blur)="onBlur(value)"
      (keypress)="omit_special_char($event)"
      (cut)="$event.preventDefault()" 
      (copy)="$event.preventDefault()"
      (paste)="$event.preventDefault()"
    />
    <mat-hint *ngIf="maxLength && !inputId.includes('Attendees')" align="end">{{input.value?.length || 0}}/{{maxLength}}</mat-hint>
  </mat-form-field>
    <ioh-error
      class="input__error"
      *ngIf="
        (parentForm.get(value).touched || parentForm.get(value).dirty) &&
        parentForm.get(value).hasError('required')
      "
      errorMsg="{{ label }} is required"
    ></ioh-error>
  <ioh-error
    class="input__error"
    [class.input__error__mt]="maxLength"
    *ngIf="
      (parentForm.get(value).touched || parentForm.get(value).dirty) &&
      parentForm.get(value).hasError('pattern')
    "
    errorMsg="Numbers only. No letters or special characters."
  ></ioh-error>
  <ioh-error
  class="input__error"
  [class.input__error__mt]="maxLength"
  *ngIf="
    (parentForm.get(value).touched || parentForm.get(value).dirty) &&
    parentForm.get(value).hasError('threeDecimalPoints') || parentForm.get(value).hasError('twoDecimalPoints')
  "
  errorMsg="Enter a valid number with up to {{decimalLimit()}} decimal points only please"
></ioh-error>
  <ioh-error
  class="input__error"
  [class.input__error__mt]="maxLength"
  *ngIf="
    (parentForm.get(value).touched || parentForm.get(value).dirty) && parentForm.get(value).hasError('eightDigitsOnly')
  "
  [errorMsg]="parentForm.get(value).getError('eightDigitsOnly').message"
></ioh-error>
  <ioh-error
  class="input__error input__error__hour"
  [class.input__error__mt]="maxLength"
  *ngIf="
    (parentForm.get(value).touched || parentForm.get(value).dirty) && parentForm.get(value).hasError('valid12HourTime')
  "
  [errorMsg]="parentForm.get(value).getError('valid12HourTime').message"
></ioh-error>
  <ioh-error
  class="input__error"
  [class.input__error__mt]="maxLength"
  *ngIf="
    (parentForm.get(value).touched || parentForm.get(value).dirty) && parentForm.get(value).hasError('minStartTime')
  "
  [errorMsg]="parentForm.get(value).getError('minStartTime').message"
></ioh-error>
  <ioh-error
  class="input__error"
  [class.input__error__mt]="maxLength"
  *ngIf="
    parentForm.get(value).hasError('lessThan')
  "
  [errorMsg]="parentForm.get(value).getError('lessThan').message"
></ioh-error>
  <ioh-error
  class="input__error"
  [class.input__error__mt]="maxLength"
  *ngIf="
    parentForm.get(value).hasError('invalidClientArrival')
  "
  errorMsg="The session is starting before the client has arrived!"
></ioh-error>
  <ioh-error
  class="input__error"
  [class.input__error__mt]="maxLength"
  *ngIf="
    parentForm.get(value).hasError('invalidStartTime')
  "
  errorMsg="The session must start before the end time!"
></ioh-error>  
</div>
