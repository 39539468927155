<div id="sidebarmenu">
  <div class="menu"
    *ngIf=!isbackToMainSite
    ngClass.md="menu__medium__screen"
    ngClass.sm="menu__small__screen"
    fxLayout="column">
    <div
      class="menu__add"
      fxLayoutAlign="center center"
      (click)="navigateTo('/admin/add-booking')"
      (keydown.enter)="navigateTo('/admin/add-booking')"
      tabIndex="0"
    >
    <span class="material-icons menu__add__icon">
      add_circle
    </span>
    <span class="menu__add__label">
      Add Booking
    </span>
  </div>
  <div
    class="menu__item"
    fxLayout="row"
    *ngFor="let menuItem of menuItems"
    routerLink="{{menuItem.route}}"
    routerLinkActive="active"
    (click)="clearCloneWorkshop()"
    (keydown.enter)="navigateTo(menuItem.route)"
  >
    <span class="material-icons menu__item__icon">
      {{menuItem.icon}}
    </span>
    <span class="menu__item__label">
      {{menuItem.name}}
    </span>
  </div>
  <!-- <div
    class="menu__item"
    fxLayout="row"
    routerLink="{{myEngagementsRoute}}"
    routerLinkActive="active"
    (click)="navigateTo(myEngagementsRoute)"
    (keydown.enter)="navigateTo(myEngagementsRoute)">
  </div> -->
  <div fxLayoutAlign="center center" class="menu__download">
      <button color="primary" mat-raised-button tabIndex="0"
      (click)="downloadAction()">
        <div class="download-report" fxLayout="row" fxLayoutAlign="center center">
          <span class="material-icons menu__download__icon">
            save_alt
          </span>
          <span tabindex="0">Download Report</span>
        </div>
      </button>
  </div>
  <div 
  class="menu__item help-row" 
  fxLayout="row" (click)="toggleHellBox()"
  (keydown.enter)="toggleHellBox()"
  tabindex="0"
  >
    <span class="material-icons menu-Help">
      help_outline
    </span>
    <span class="menu-Help-text">
      Help
    </span>
  </div>
  <ng-template #DownloadTemplate>
    <h2 class="modal__title downloadTitle" role="banner" mat-dialog-title tabindex="0">Download Report</h2>
    <mat-dialog-content role="contentinfo" class="mat-typography downloadForm">
      <div [formGroup]="downloadGroup" class="downloadWrap">
        <div class="download-row">
          <ioh-dropdown
          *ngIf="downloadGroup.get('hubArchive').value !== 'Archived Engagements'"
          label="Fiscal Year"
          dropdownId="fiscalYearId"
          [formGroup]="downloadGroup"
          isMultiple="true"
          [values]="dropDownValues['fiscalYear']" 
          field = "fiscalYear"
          [required] = "true"
          placeholder = "Select Fiscal Year">
          </ioh-dropdown>
          <ioh-dropdown
          *ngIf="downloadGroup.get('hubArchive').value === 'Archived Engagements'"
          label="Fiscal Year"
          dropdownId="archivefiscayyearId"
          [formGroup]="downloadGroup"
          isMultiple="true"
          [values]="dropDownValues['archivefiscalYear']" 
          field = "archivefiscalYear"
          [required] = "true"
          placeholder = "Select Fiscal Year">
          </ioh-dropdown>
          <ioh-dropdown
          label="Engagement Status"
          dropdownId="engagementStatusId"
          [formGroup]="downloadGroup"
          isMultiple="true"
          [values]="dropDownValues['engagementStatus']" 
          field = "engagementStatus"
          [required] = "true"
          placeholder = "Select Engagement Status">
          </ioh-dropdown>
        </div>
      <div fxLayout="row" *ngIf="showAdminMenu" class="radio-row">
        <div *ngFor="let value of hubArchive" class="hub-archive" fxFlex="100%">
          <ioh-radio-button
          radioId="{{ value.radioId }}"
            field="{{ value.field }}"
            [values]="value.values"
            [formGroup]="downloadGroup"
            ></ioh-radio-button>
        </div>
      </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="center" role="navigation" class="rowButton">
      <button mat-stroked-button color="primary" class="button-cancel admin-btn" (click)="cancel()">Cancel</button>
      <button class="button-save admin-btn" mat-flat-button color="primary" 
      [ngClass]="this.exportService.isDownloadReport ? 'menu__download-disabled' : 'menu__download'" 
      [disabled]="this.exportService.isDownloadReport" (click)="downloadReport()">
      <mat-spinner *ngIf="this.exportService.isDownloadReport" diameter="20" class="download-icon"></mat-spinner>
      Download Report
    </button>
    </mat-dialog-actions>
  </ng-template>
  <div id="menu-admin" *ngIf="showAdminMenu" class="menu__item admin-row">
    <span id="menu-admin-icon" class="material-icons menu-settings">
      settings
    </span>
    <span id="menu-admin-text" class="menu-Help-text" routerLink="{{adminMenuRoute}}" (click)="navigateTo(adminMenuRoute)" >
          Administration
         </span>
  </div>
  <div id="menu-admin" *ngIf="showAdminMenu"
  class="menu__item admin-row" routerLink="{{archivedEngagementRoute}}"
  routerLinkActive="active" (keydown.enter)="navigateTo(archivedEngagementRoute)"
  fxLayout="row" (click)="navigateTo(archivedEngagementRoute)"
  >
    <span id="archived-engagement-icon" class="material-icons menu-Help">
      archive
    </span>
    <span id="archived-engagement-text" class="menu-Help-text">
      Archived Engagements
    </span>
  </div>
</div>
<div class="help-overlay" *ngIf="isShowHelpBox"></div>
<div class="help-box" *ngIf="isShowHelpBox">
  <h2 tabindex="0">
    Help
  </h2>
  <p tabindex="0">
    1. For new or existing user access requests or changes, contact your EngageInnovation Hub Lead or NA Ops Lead.
  </p>
  <p tabindex="0">
    2. For applications errors or enhancement requests, please visit <a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__nam11.safelinks.protection.outlook.com_-3Furl-3Dhttps-253A-252F-252Fsupport.accenture.com-252Ftechnologysupport-26data-3D04-257C01-257Candrew.fiester-2540avanade.com-257C3aebfafcbda2459746ea08d92f4ee45b-257Ccf36141cddd745a7b073111f66d0b30c-257C0-257C1-257C637592838173318285-257CUnknown-257CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0-253D-257C1000-26sdata-3DXeCuIymX9kgaVYwomWLpXiWzKdmOsVenDEuBpY296bs-253D-26reserved-3D0&d=DwMFAw&c=eIGjsITfXP_y-DLLX0uEHXJvU8nOHrUK8IrwNKOtkVU&r=3KJ6V6BqfMN54iUTQReWqnA-2QL-CGg_PoZvMTvHg0U&m=uRIoE7aUcSeyVbybrGzqCSkEvhKqfmVLpc8g9HKQyVo&s=qT_P0PbeZyRBMKcpuF8sbMHe_RlSDEU6OlIcSpk3cis&e=" title="Accenture Technology Support" aria-label="opens accenture technology support in new window" target="_blank">Accenture Technology Support</a> to open a ticket:
  </p>
  <ul>
    <li tabindex="0">1. Click “Create an Incident” </li>
    <li tabindex="0">2. Select Classification “Application Software”</li>
    <li tabindex="0">3. Type “EngageInnovation” in the Application field.</li>
    <li tabindex="0">4. Select the issue from the drop down menu you are experiencing from the Type field.</li>
    <li tabindex="0">5. Enter “Your Summary Comments” in the Summary field describing the issue.</li>
    <li tabindex="0">6. Fill in  “Details” describing the issue in the Description field.</li>
    <li tabindex="0">7. Upload “Any screenshots and/or other evidence” to Add Attachments.</li>
    <li tabindex="0">8. Remember to click “Submit” to send the request.</li>
  </ul>
  <p tabindex="0">
    3. For all the latest FAQs, News, and Training videos please visit the <a href="https://in.accenture.com/innovation/engageinnovation/" title="EngageInnovation Support Page" aria-label="opens engageinnovation support page in a new window" target="_blank">EngageInnovation Support Page</a>
  </p>
  <div class="button-row">
    <button (click)="toggleHellBox()">OK</button>
  </div>
</div>
</div>

<div class="sidebarback" *ngIf=isbackToMainSite>
  <span class="navigate-back" routerLink="{{myEngagementsRoute}}" (click)="navigateTo(myEngagementsRoute)">
   > Back To Main Site
  </span>
  <mat-nav-list class="list-item">
    <div>
      <mat-list-item class="menu-admin-list-sidebar" routerLink="{{adminMenuRoute}}" (click)="navigateTo(adminMenuRoute)">
        <span>User List</span>
      </mat-list-item>
    </div>
    <!-- <div> -->
    <!-- <mat-list-item class="menu-admin-list-sidebar">
      <span>Dropdown Maintenance</span>
    </mat-list-item>
      <div mat-subheader class="dropdown-general">General</div>
          <mat-list-item class="menu-admin-list">
              <div class="maintenance-option">
                <span routerLink="{{adminEngTypeRoute}}" (click)="navigateTo(adminEngTypeRoute)">Eng Type</span>
                <span routerLink="{{adminGroupOwnerRoute}}" (click)="navigateTo(adminGroupOwnerRoute)">Group Owner</span>
                <span routerLink="{{adminInnovationRoute}}" (click)="navigateTo(adminInnovationRoute)">Innovation</span>
                <span routerLink="{{adminEcosystemRoute}}" (click)="navigateTo(adminEcosystemRoute)">Ecosystem</span>
                <span routerLink="{{adminEngPurposeRoute}}" (click)="navigateTo(adminEngPurposeRoute)">Eng Purpose</span>
              </div>
          </mat-list-item>
      </div> -->
  </mat-nav-list>
</div>
