import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { INVISIBLE_OPTIONS } from '@utils/index';

@Component({
  selector: 'ioh-dropdown',
  styleUrls: ['./dropdown.component.scss'],
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() dropdownId: string;
  @Input() panelName: string;
  @Input() isMultiple: boolean;
  @Input() isDisabled: boolean;
  @Input() field: any;
  @Input() values: any[];
  @Input() required: boolean;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() direction: string;
  @Input() tooltipObj: any;
  @Input() pipelineView: boolean = false;
  @Output() viewDeleteClick = new EventEmitter();
  hideOptions = INVISIBLE_OPTIONS;
  parentForm: AbstractControl;
  selectOption = true;
  expanded: boolean = true;

  constructor(
    public readonly elementRef: ElementRef,
    public controlContainer: ControlContainer) { }

    @HostListener('document:click', ['$event'])
    public onPageClick($event) {
      const clickedInside = this.elementRef.nativeElement.contains($event.target);
      this.clickedInside(clickedInside);
      $event.stopPropagation();
    }

    clickedInside(clicked){
      if (!clicked) {
          this.collapseDropdown();
      }else{
        this.expandDropdown();
      }
    }
    
  ngOnInit() {
    this.parentForm = this.controlContainer.control;
  }

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  public compareByValue(f1: any, f2: any) {
    if (f1 && f2 && f1.value && f2.value) {
      return f1 && f2 && (f1.value === f2.value);
    } else if (f1 && f2 && f1.value && !f2.value) {
      // if db response is just a string value or just an id
      return f1 && f2 && (f1.value === f2 || f1.id === f2);
    }
  }


  onViewDeleteClick(data: any) {
    this.viewDeleteClick.emit(data);
  }

  expandDropdown() {
    this.expanded = true;
  }

  collapseDropdown() {
    this.expanded = false;
  }

  isDisabledOp(value) {
    if (value.id==='-1') {
      return true;
    }else{
      return false;
    }
  }
}
