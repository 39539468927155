import * as AttendeeDetailsActions from './attendee-details.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { IOperatingGroup, IOrgType, IOrganizationCategory } from '@ioh/types';

export const ATTENDEEDETAILS_FEATURE_KEY = 'attendeeDetails';

export interface AttendeeDetailsState extends EntityState<any> {
  categoriesResults: IOrganizationCategory[] | null;
  operatingGroupResults: IOperatingGroup[] | null;
  accentureEntitiesResults: any | null;
  organizationTypesResults: IOrgType[] | null;
  organizations: any | null;
  clientOrganizationSearchResults: any | null;
  leadershipLevel: string | null;
  accentureAttendees: number | null;
  clientOtherAttendees: number | null;
  otherAttendees: number | null;
  totalAttendees: number | null;
  selectedId?: string | number; // which AttendeeDetails record has been selected
  loaded: boolean; // has the AttendeeDetails list been loaded
  error?: string | null; // last none error (if any)
}

export interface AttendeeDetailsPartialState {
  readonly [ATTENDEEDETAILS_FEATURE_KEY]: AttendeeDetailsState;
}

export const attendeeDetailsAdapter: EntityAdapter<any> = createEntityAdapter<
  any
>({
  selectId: (attendeeDetails) =>
    attendeeDetails.id ? attendeeDetails.id : attendeeDetails.CustomerNumber,
});

export const initialState: AttendeeDetailsState = attendeeDetailsAdapter.getInitialState(
  {
    accentureEntitiesResults: [],
    categoriesResults: [],
    clientOrganizationSearchResults: [],
    leadershipLevel: '',
    loaded: false,
    accentureAttendees: null,
    clientOtherAttendees: null,
    otherAttendees: null,
    totalAttendees: null,
    operatingGroupResults: [],
    organizationTypesResults: [],
    organizations: [],
  }
);

const attendeeDetailsReducer = createReducer(
  initialState,
  on(AttendeeDetailsActions.getOrganizationCategoriesResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    AttendeeDetailsActions.getOrganizationCategoriesResultsSuccess,
    (state, { categoriesResults }) =>
      attendeeDetailsAdapter.addAll(categoriesResults, {
        ...state,
        categoriesResults,
        loaded: true,
      })
  ),
  on(
    AttendeeDetailsActions.getOrganizationCategoriesResultsFailure,
    (state, { error }) => ({
      ...state,
      categoriesResults: null,
      error,
    })
  ),
  on(AttendeeDetailsActions.getOperatingGroupResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    AttendeeDetailsActions.getOperatingGroupResultsSuccess,
    (state, { operatingGroupResults }) =>
      attendeeDetailsAdapter.addAll(operatingGroupResults, {
        ...state,
        loaded: true,
        operatingGroupResults,
      })
  ),
  on(
    AttendeeDetailsActions.getOperatingGroupResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
      operatingGroupResults: null,
    })
  ),
  on(AttendeeDetailsActions.getAccentureEntities, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    AttendeeDetailsActions.getAccentureEntitiesSuccess,
    (state, { accentureEntitiesResults }) =>
      attendeeDetailsAdapter.addAll(accentureEntitiesResults, {
        ...state,
        accentureEntitiesResults,
        loaded: true,
      })
  ),
  on(
    AttendeeDetailsActions.getAccentureEntitiesFailure,
    (state, { error }) => ({
      ...state,
      accentureEntitiesResults: null,
      error,
    })
  ),
  on(AttendeeDetailsActions.getOrganizationTypes, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    AttendeeDetailsActions.getOrganizationTypesSuccess,
    (state, { organizationTypesResults }) => {
      return Object.assign({}, state, {
        loaded: true,
        organizationTypesResults,
      });
    }
  ),
  on(
    AttendeeDetailsActions.getOrganizationTypesFailure,
    (state, { error }) => ({
      ...state,
      error,
      organizationTypesResults: null,
    })
  ),
  on(AttendeeDetailsActions.updateOrganizations, (state, { organizations }) => {
    return Object.assign({}, state, { organizations });
  }),
  on(AttendeeDetailsActions.getClientOrganizationSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    AttendeeDetailsActions.getClientOrganizationSearchResultsSuccess,
    (state, { clientOrganizationSearchResults }) =>
      attendeeDetailsAdapter.addAll(clientOrganizationSearchResults, {
        ...state,
        clientOrganizationSearchResults,
        loaded: true,
      })
  ),
  on(
    AttendeeDetailsActions.getClientOrganizationSearchResultsFailure,
    (state, { error }) => ({
      ...state,
      accentureEntitiesResults: null,
      error,
    })
  ),
  on(
    AttendeeDetailsActions.updateAccentureAttendees,
    (state, { accentureAttendees }) => {
      return Object.assign({}, state, { accentureAttendees });
    }
  ),
  on(
    AttendeeDetailsActions.updateClientOtherAttendees,
    (state, { clientOtherAttendees }) => {
      return Object.assign({}, state, { clientOtherAttendees });
    }
  ),
  on(
    AttendeeDetailsActions.updateOtherAttendees,
    (state, { otherAttendees }) => {
      return Object.assign({}, state, { otherAttendees });
    }
  ),
  on(
    AttendeeDetailsActions.updateTotalAttendees,
    (state, { totalAttendees }) => {
      return Object.assign({}, state, { totalAttendees });
    }
  ),
  on(
    AttendeeDetailsActions.updateSelectedLeadershipLevel,
    (state, { leadershipLevel }) => {
      return Object.assign({}, state, { leadershipLevel });
    }
  ),
  on(AttendeeDetailsActions.clearStateObject, () => {
    return Object.assign({});
  })
);

export function reducer(
  state: AttendeeDetailsState | undefined,
  action: Action
) {
  return attendeeDetailsReducer(state, action);
}
