import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { AdminFacade } from '@ioh/core-data';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { LookUpEntryService } from './look-up-entry.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'ioh-lookup',
  styleUrls: ['./lookup.component.scss'],
  templateUrl: './lookup.component.html',
})
export class LookUpComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() lookUpId: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() lookupGroupFieldName: string;
  @Input() lookupArrayFieldName: string;
  @Input() lookupInputFieldName: string;
  @Input() limit: number;
  @Input() isDisabled: boolean;
  @Input() required: boolean;
  @Input() addEnable: boolean = false;
  @Input() teamName: boolean = false;
  @Input() searchResults$: Observable<any>;
  @Input() isLoading$: Observable<boolean>;
  @Input() clearResponseList$: Observable<boolean>;
  @Input() tooltipObj: any;
  @Input() noMMS: any;
  private readonly subscriptions: Subscription = new Subscription();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  values: string[] = [];
  isCompletedStatus: boolean;

  @ViewChild('lookUpInput') valueInput: ElementRef<HTMLInputElement>;

  parentForm: AbstractControl;
  chipsError: boolean = false;

  selectable = true;
  removable = true;
  addOnBlur = true;

  constructor(
    private readonly fb: FormBuilder,
    public controlContainer: ControlContainer,
    private readonly cdref: ChangeDetectorRef,
    public lookupEntryService: LookUpEntryService,
    private readonly adminFacade: AdminFacade,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
    this.subscriptions.add(
      this.getResponseArray.valueChanges.subscribe((changes) => {
        setTimeout(() => {
          this.chipsError =
            !this.getResponseArray.hasError('required') &&
            !this.getResponseArray.hasError('maxLimitReached') &&
            this.getResponseArray.status === 'INVALID';
        }, 0);
      })
    );
    this.adminFacade.getEngStatus$.subscribe((engStatus) => {
      this.isCompletedStatus = engStatus === 'Completed';
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  get getLookUpGroup(): FormGroup {
    return this.parentForm.get(this.lookupGroupFieldName) as FormGroup;
  }

  get getResponseArray(): FormArray {
    return this.getLookUpGroup.get(this.lookupArrayFieldName) as FormArray;
  }

  get getLookUpInput(): FormControl {
    return this.getLookUpGroup.get(this.lookupInputFieldName) as FormControl;
  }

  added(event: MatChipInputEvent): void {
    if (this.addEnable) {
      const inputValue = event.value;
      this.add(inputValue);
      this.valueInput.nativeElement.value = '';
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    this.add(value);
    this.valueInput.nativeElement.value = '';
  }

  remove(index: number): void {
    if (this.isDisabled) return;
    this.getResponseArray.at(index).get('chip').setValue(' ');
    this.getResponseArray.removeAt(index);
  }

  private add(inputValue: string): void {
    const value = (inputValue || '').trim();
    if (value) {
      this.getResponseArray.push(
        this.lookupEntryService.createLookUpEntry(value)
      );
      this.getLookUpInput.setValue('');
    }
  }
}
