import * as GeneralActions from './general.actions';
import * as GeneralSelectors from './general.selectors';
import * as fromGeneral from './general.reducer';
import { Action, Store, select } from '@ngrx/store';
import { ITag, PeopleRequest, PeopleResponse } from '@ioh/types';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class GeneralFacade {
  loaded$ = this.store.pipe(select(GeneralSelectors.getGeneralLoaded));
  prepopulated$ = this.store.pipe(
    select(GeneralSelectors.getGeneralPrepopulated)
  );

  allGeneralTab$ = this.store.pipe(select(GeneralSelectors.getGeneralState));

  allEngagementSponsorResults$ = this.store.pipe(
    select(GeneralSelectors.getAllEngagementSponsorResults)
  );

  EIDropdownResults$ = this.store.pipe(
    select(GeneralSelectors.getEIDropdownResults)
  );

  allPrimaryContactResults$ = this.store.pipe(
    select(GeneralSelectors.getAllPrimaryContactResults)
  );

  allAdditionalContactResults$ = this.store.pipe(
    select(GeneralSelectors.getAllAdditionalContactResults)
  );

  engagementTypeResults$ = this.store.pipe(
    select(GeneralSelectors.getEngagementTypeResults)
  );

  clientType$ = this.store.pipe(select(GeneralSelectors.getClientType));

  engagementTitleResults$ = this.store.pipe(
    select(GeneralSelectors.getEngagementTitle)
  );

  engagementGroupResults$ = this.store.pipe(
    select(GeneralSelectors.getEngagementGroupResults)
  );

  engagementGroupEcosystemResults$ = this.store.pipe(
    select(GeneralSelectors.getEngagementGroupEcosystemResults)
  );

  engagementIndustryRD$ = this.store.pipe(
    select(GeneralSelectors.getEngagementIndustryRDResults)
  );

  engagementLabsRD$ = this.store.pipe(
    select(GeneralSelectors.getEngagementLabsRDResults)
  );

  engagementCountry$ = this.store.pipe(
    select(GeneralSelectors.getEngagementCountryResults)
  );

  allEngagementLeadResults$ = this.store.pipe(
    select(GeneralSelectors.getAllEngagementLeadResults)
  );

  allEngagementTeamMemberResults$ = this.store.pipe(
    select(GeneralSelectors.getAllEngagementTeamMemberResults)
  );

  allExternalOrganizationCategories$ = this.store.pipe(
    select(GeneralSelectors.getAllExternalOrganizationCategories)
  );

  allAccentureEntitiesResults$ = this.store.pipe(
    select(GeneralSelectors.getAllAccentureEntities)
  );

  allTagsResults$ = this.store.pipe(select(GeneralSelectors.getAllTagsResults));

  allClientOrganizationSearchResults$ = this.store.pipe(
    select(GeneralSelectors.getAllClientOrganizationSearchResults)
  );

  allOrganizationTypes$ = this.store.pipe(
    select(GeneralSelectors.getAllOrganizationTypes)
  );

  engagementTeamsRoles$ = this.store.pipe(
    select(GeneralSelectors.getEngagementTeamRoles)
  );

  datesAndLocationsRooms$ = this.store.pipe(
    select(GeneralSelectors.getDatesAndLocationsRooms)
  );

  constructor(private readonly store: Store<fromGeneral.GeneralPartialState>) {}

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }

  updatePrepopulated(value: boolean) {
    this.dispatch(GeneralActions.markAsPrepopulated({ value }));
  }

  updateTitle(engagementTitle: string): void {
    this.dispatch(GeneralActions.updateTitle({ engagementTitle }));
  }

  updateClientTypeDropdown(clientType): void {
    this.dispatch(GeneralActions.updateClientTypeDropdown({ clientType }));
  }

  updateEngagementType(engagementType): void {
    this.dispatch(GeneralActions.updateEngagementType({ engagementType }));
  }

  getEngagementGroupResults(programId: string): void {
    this.dispatch(GeneralActions.loadEngagementGroupResults({ programId }));
  }

  getEngagementGroupEcosystemResults(programId: string): void {
    this.dispatch(
      GeneralActions.loadEngagementGroupEcosystemResults({ programId })
    );
  }

  getEngagementIndustryRDResults(programId: string): void {
    this.dispatch(
      GeneralActions.loadEngagementIndustryRDResults({ programId })
    );
  }

  getEngagementLabsRDResults(programId: string): void {
    this.dispatch(GeneralActions.loadEngagementLabsRDResults({ programId }));
  }

  getEngagementCountryResults(programId: string): void {
    this.dispatch(GeneralActions.loadEngagementCountryResults({ programId }));
  }

  getEngagementTypeResults(): void {
    this.dispatch(GeneralActions.getEngagementTypeResults());
  }

  getEIDropdownEntityResults(programId: string): void {
    this.dispatch(GeneralActions.getEIDropdownEntityResults());
  }

  updateTags(tags: ITag[]) {
    this.dispatch(GeneralActions.updateTags({ tags }));
  }

  loadTagsResults(searchQuery: string) {
    this.dispatch(GeneralActions.loadTagsResults({ searchQuery }));
  }

  updateSummary(engagementSummary: string) {
    this.dispatch(GeneralActions.updateSummary({ engagementSummary }));
  }

  updateAdminComments(adminComments: string): void {
    this.dispatch(GeneralActions.updateAdminComments({ adminComments }));
  }

  loadEngagementSponsorSearchResults(searchQuery: PeopleRequest): void {
    this.dispatch(
      GeneralActions.getEngagementSponsorSearchResults({ searchQuery })
    );
  }

  updateEngagementSponsors(engagementSponsors: PeopleResponse[]): void {
    this.dispatch(
      GeneralActions.updateEngagementSponsors({ engagementSponsors })
    );
  }

  loadPrimaryContactSearchResults(searchQuery: PeopleRequest): void {
    this.dispatch(
      GeneralActions.getPrimaryContactSearchResults({ searchQuery })
    );
  }

  updatePrimaryContacts(primaryContacts: PeopleResponse[]): void {
    this.dispatch(GeneralActions.updatePrimaryContacts({ primaryContacts }));
  }

  loadAdditionalContactSearchResults(searchQuery: PeopleRequest): void {
    this.dispatch(
      GeneralActions.getAdditionalContactsSearchResults({ searchQuery })
    );
  }

  updateAdditionalContacts(additionalContacts: PeopleResponse[]): void {
    this.dispatch(
      GeneralActions.updateAdditionalContacts({ additionalContacts })
    );
  }

  updateOrganizations(organizations: any): void {
    this.dispatch(GeneralActions.updateOrganizations({ organizations }));
  }

  updateDatesAndlocations(datesAndLocations: any): void {
    this.dispatch(
      GeneralActions.updateDatesAndlocations({ datesAndLocations })
    );
  }

  updateLogistics(logistics: any): void {
    this.dispatch(GeneralActions.updateLogistics({ logistics }));
  }

  updateNoFeedback(noFeedback: any) {
    this.dispatch(GeneralActions.updateNoFeedback({ noFeedback }));
  }

  getClientOrganizationSearchResults(searchQuery: string) {
    this.dispatch(
      GeneralActions.getClientOrganizationSearchResults({ searchQuery })
    );
  }

  getExternalOrganizationCategoriesResults() {
    this.dispatch(GeneralActions.getExternalOrganizationCategoriesResults());
  }

  getAccentureEntities(): void {
    this.dispatch(GeneralActions.getAccentureEntities());
  }

  getOrganizationTypes(): void {
    this.dispatch(GeneralActions.getOrganizationTypes());
  }

  updateEngagementGroup(engagementGroup): void {
    this.dispatch(GeneralActions.updateEngagementGroup({ engagementGroup }));
  }

  loadEngagementLeadResults(searchQuery: PeopleRequest): void {
    this.dispatch(GeneralActions.loadEngagementLeadResults({ searchQuery }));
  }

  updateEngagementLead(engagementLead): void {
    this.dispatch(GeneralActions.updateEngagementLead({ engagementLead }));
  }

  updateEngagementTeam(engagementTeam): void {
    this.dispatch(GeneralActions.updateEngagementTeam({ engagementTeam }));
  }

  updateEngagementStartDate(value) {
    this.dispatch(
      GeneralActions.updateEngagementStartDate({
        engagementStartDate: value,
      })
    );
  }

  updateEngagementEndDate(value) {
    this.dispatch(
      GeneralActions.updateEngagementEndDate({
        engagementEndDate: value,
      })
    );
  }

  loadEngagementTeamMemberResults(
    searchQuery: PeopleRequest,
    index: number
  ): void {
    this.dispatch(
      GeneralActions.loadEngagementTeamMemberResults({
        index,
        searchQuery,
      })
    );
  }

  updateGroupParticipation(groupParticipation): void {
    this.dispatch(
      GeneralActions.updateGroupParticipation({ groupParticipation })
    );
  }

  updateGroupEcosystem(groupEcosystem): void {
    this.dispatch(GeneralActions.updateGroupEcosystem({ groupEcosystem }));
  }

  updateCountryEur(eurCountry): void {
    this.dispatch(GeneralActions.updateCountryEur({ eurCountry }));
  }

  updateIndustryRD(industryRDTopics): void {
    this.dispatch(GeneralActions.updateIndustryRD({ industryRDTopics }));
  }

  updateLabsRD(labsRDTopics): void {
    this.dispatch(GeneralActions.updateLabsRD({ labsRDTopics }));
  }

  updateCancellationReason(cancellationReason: any): void {
    this.dispatch(
      GeneralActions.updateCancellationReason({ cancellationReason })
    );
  }

  updateCancellationDate(cancellationDate: string): void {
    this.dispatch(GeneralActions.updateCancellationDate({ cancellationDate }));
  }

  getEngagementTeamRoles(programId: string): void {
    this.dispatch(GeneralActions.getEngagementTeamRoles({ programId }));
  }

  loadDatesAndLocationsRooms(programId: string): void {
    this.dispatch(GeneralActions.loadDatesAndLocationsRooms({ programId }));
  }

  clearStateObject(): void {
    this.dispatch(GeneralActions.clearStateObject());
  }
}
