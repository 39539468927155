import * as fromAdmin from './admin/admin.reducer';
import * as fromArchive from './archive/archive.reducer';
import * as fromConsultation from './engagement/consultation/consultation.reducer';
import * as fromGeneral from './engagement/general/general.reducer';
import * as fromRecovery from './engagement/recovery/recovery.reducer';
import { AdminEffects } from './admin/admin.effects';
import { AdminFacade } from './admin/admin.facade';
import { ArchiveEffects } from './archive/archive.effects';
import { ArchiveFacade } from './archive/archive.facade';
import { CommonModule } from '@angular/common';
import { ConsultationEffects } from './engagement/consultation/consultation.effects';
import { ConsultationFacade } from './engagement/consultation/consultation.facade';
import { EffectsModule } from '@ngrx/effects';
import { GeneralEffects } from './engagement/general/general.effects';
import { GeneralFacade } from './engagement/general/general.facade';
import { NgModule } from '@angular/core';
import { RecoveryEffects } from './engagement/recovery/recovery.effects';
import { RecoveryFacade } from './engagement/recovery/recovery.facade';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      GeneralEffects,
      ConsultationEffects,
      AdminEffects,
      ArchiveEffects,
      RecoveryEffects,
    ]),
    StoreModule.forFeature(
      fromGeneral.GENERAL_FEATURE_KEY,
      fromGeneral.reducer
    ),
    StoreModule.forFeature(
      fromConsultation.CONSULTATION_FEATURE_KEY,
      fromConsultation.reducer
    ),
    StoreModule.forFeature(fromAdmin.ADMIN_FEATURE_KEY, fromAdmin.reducer),
    StoreModule.forFeature(
      fromArchive.ARCHIVE_FEATURE_KEY,
      fromArchive.reducer
    ),
    StoreModule.forFeature(
      fromRecovery.RECOVERY_FEATURE_KEY,
      fromRecovery.reducer
    ),
    StoreDevtoolsModule.instrument({ maxAge: 10 }),
  ],
  providers: [
    GeneralFacade,
    ConsultationFacade,
    AdminFacade,
    ArchiveFacade,
    RecoveryFacade,
  ],
})
export class BackOfficeStateModule {}
