import { AddBookingComponent } from './add-booking/add-booking.component';
import { AdminComponent } from './admin/admin.component';
import { ArchivedEngagementComponent } from './archived-engagement/archived-engagement.component';
import { BackOfficeModule } from '../back-office.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
// import { CalendarComponent } from './calendar/calendar.component';
import { ClientHistoryComponent } from './client-history/client-history.component';
import { ClosedComponent } from './closed/closed.component';
import { CommonModule } from '@angular/common';
import { ConsultationTabComponent } from './add-booking/consultation-tab/consultation-tab.component';
import { DocumentsTabComponent } from './add-booking/documents-tab/documents-tab.component';
import { EventDatesComponent } from './event-dates/event-dates.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FormsModule } from '@angular/forms';
import { GeneralTabComponent } from './add-booking/general-tab/general-tab.component';
import { HomeComponent } from './home/home.component';
import { InProgressComponent } from './in-progress/in-progress.component';
import { MetricsComponent } from './metrics/metrics.component';
import { PendingComponent } from './pending/pending.component';
import { RecoveryTabComponent } from './add-booking/recovery-tab/recovery-tab.component';
import { RequestedComponent } from './requested/requested.component';
import { RouterModule } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { SharedModule } from '../../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import { DialogComponent } from './dialog/dialog.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatGridListModule}from '@angular/material/grid-list';


@NgModule({
  declarations: [
    AddBookingComponent,
    MetricsComponent,
    ClosedComponent,
    // CalendarComponent,
    ClientHistoryComponent,
    FeedbackComponent,
    AdminComponent,
    ArchivedEngagementComponent,
    HomeComponent,
    InProgressComponent,
    EventDatesComponent,
    PendingComponent,
    RequestedComponent,
    SettingsComponent,
    ConsultationTabComponent,
    DocumentsTabComponent,
    GeneralTabComponent,
    RecoveryTabComponent,
    DialogComponent

  ],
  exports: [
    AddBookingComponent,
    MetricsComponent,
    ClosedComponent,
    ClientHistoryComponent,
    FeedbackComponent,
    AdminComponent,
    HomeComponent,
    InProgressComponent,
    EventDatesComponent,
    PendingComponent,
    RequestedComponent,
    SettingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    BackOfficeModule,
    SharedModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    MatGridListModule
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SectionsModule {}
