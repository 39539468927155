import { createAction, props } from '@ngrx/store';
/**
 * InternalPurposeSearchResults START
 */
export const loadInternalPurposeResults = createAction(
  '[EngagementDetails] Load InternalPurpose',
  props<{ programId: string }>()
);

export const loadInternalPurposeResultsSuccess = createAction(
  '[EngagementDetails] Load InternalPurposeResults Success',
  props<{ internalPurposeSearchResults: any }>()
);

export const loadInternalPurposeResultsFailure = createAction(
  '[EngagementDetails] Load InternalPurposeResults Failure',
  props<{ error: any }>()
);
/**
 * InternalPurposeSearchResults END
 */

export const selectInternalPurpose = createAction(
  '[EngagementDetails] Select InternalPurpose',
  props<{ selectedInternalPurpose: any }>()
);

export const updateBackgroundText = createAction(
  '[EngagementDetails] Update BackgroundInformation',
  props<{ backgroundText: string }>()
);

export const updateNoMmsOpportunityId = createAction(
  '[EngagementDetails] Update NoMmsOpportunityId',
  props<{ noMmsOpportunityId: any }>()
);

export const updateObjectivesAndExpectations = createAction(
  '[EngagementDetails] Update ObjectivesAndExpectations',
  props<{ objectivesAndExpectations: string }>()
);

export const updateSelectedOpportunityIds = createAction(
  '[EngagementDetails] Update SelectedOpportunityIds',
  props<{ selectedOpportunityIds: string }>()
);

// opportunity id search results
export const loadOpportunityIdsSearchResults = createAction(
  '[EngagementDetails] Load OpportunityIdsSearchResults',
  props<{ payload: string }>()
);

export const loadOpportunityIdsSearchResultsSuccess = createAction(
  '[EngagementDetails] Load OpportunityIdsSearchResults Success',
  props<{ opportunityIdsSearchResults: any }>()
);

export const loadOpportunityIdsSearchResultsFailure = createAction(
  '[EngagementDetails] Load OpportunityIdsSearchResults Failure',
  props<{ error: any }>()
);

export const clearStateObject = createAction(
  '[EngagementDetails] Clear State Object'
);
