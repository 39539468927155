export const dummyClientDetails = [
  {
    body: 'Dupont',
    formControl: 'organizationName',
    response: 'CustomerName',
    title: 'Organization Name',
  },
  {
    body: 'Financial Services',
    formControl: 'operatingGroup',
    response: 'PrimaryOperatingGroup',
    title: 'Market',
  },
  {
    body: 'United States',
    formControl: 'country',
    response: 'SoldToCountryName',
    title: 'Country',
  },
  {
    body: 'Dummy',
    formControl: 'operatingUnit',
    response: 'PrimaryOperatingUnit',
    title: 'Market Unit',
  },
  {
    body: 'Dummy',
    formControl: 'industrySegment',
    response: 'IndustrySegment',
    title: 'Industry',
  },
  {
    body: 'Dummy',
    formControl: 'g2000Rank',
    response: 'G2000PositionCode',
    title: 'G2000 Rank',
  },
  {
    body: 'Dummy',
    formControl: 'accountStatus',
    response: 'CustomerStatus',
    title: 'Account Status',
  },
  {
    body: 'Dummy',
    formControl: 'diamond',
    response: 'GlobalClientClassificationName',
    title: 'MC Classification',
  },
];
