import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'ioh-contacts',
  styleUrls: ['./contacts.component.scss'],
  templateUrl: './contacts.component.html',
})
export class ContactsComponent {
  @Input() lookUps: any;
  @Input() isDisabled: boolean;

  parentForm;

  constructor(private readonly controlContainer: ControlContainer) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
  }
}
