import * as GeneralActions from './general.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
  EngagementTeamMember,
  ICustomer,
  ITag,
  PeopleResponse,
} from '@ioh/types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { GeneralEntity } from './general.models';

export const GENERAL_FEATURE_KEY = 'general';

export interface GeneralState extends EntityState<GeneralEntity> {
  selectedId?: string | number; // which General record has been selected
  loaded: boolean; // has the General list been loaded
  error?: string | null; // last none error (if any)
  prepopulated: boolean;
  cancellationReason: any | null;
  cancellationDate: string | null;
  engagementStatus: string | null;
  engagementStatusResults: any | null;
  engagementTitle: string | null;
  engagementType: any | null;
  engagementStartDate: string | null;
  engagementEndDate: string | null;
  engagementTypeResults: any | null;
  tags: ITag[] | null;
  tagsSearchResults: ITag[] | null;
  engagementSummary: string | null;
  adminComments: string | null;
  engagementSponsorSearchResults: PeopleResponse[] | null;
  engagementSponsors: any[] | null | string;
  primaryContactSearchResults: PeopleResponse[] | null;
  primaryContacts: any[] | null | string;
  additionalContactSearchResults: PeopleResponse[] | null;
  additionalContacts: any[] | null | string;
  organizationExternalCategoriesResults: any | null;
  organizationAccentureEntitiesResults: any | null;
  organizations: any | null;
  clientOrganizationSearchResults: ICustomer[] | null;
  organizationTypes: any[] | null;
  datesAndLocations: any | null;
  datesAndLocationsRooms: any | null;
  logistics: any | null;
  noFeedback: any | null;
  engagementGroup: any | null;
  engagementGroupResults: any | null;
  engagementGroupEcosystemResults: any | null;
  engagementIndustryRDResults: any | null;
  engagementLabsRDResults: any | null;
  engagementCountryResults: any | null;
  engagementTeam: EngagementTeamMember[] | null;
  engagementTeamRoles: any | null;
  engagementTeamMemberSearchResults: any | null;
  engagementLead: any | null;
  engagementLeadSearchResults: PeopleResponse[] | null;
  groupParticipation: any | null;
  groupEcosystem: any | null;
  eurCountry: any | null;
  industryRDTopics: any | null;
  labsRDTopics: any | null;
  eiDropdownResults: any | null;
  clientType: any | null;
}

export interface GeneralPartialState {
  readonly [GENERAL_FEATURE_KEY]: GeneralState;
}

export const generalAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (peopleResponse) =>
    peopleResponse.peopleKey ||
    peopleResponse.CustomerNumber ||
    peopleResponse.id,
});

export const initialState: GeneralState = generalAdapter.getInitialState({
  additionalContactSearchResults: [],
  additionalContacts: [],
  adminComments: '',
  clientOrganizationSearchResults: [],
  cancellationReason: null,
  cancellationDate: null,
  engagementGroup: null,
  engagementGroupResults: [],
  engagementGroupEcosystemResults: [],
  engagementIndustryRDResults: [],
  engagementLabsRDResults: [],
  engagementCountryResults: [],
  engagementLead: null,
  engagementLeadSearchResults: [],
  engagementSponsorSearchResults: [],
  engagementSponsors: [],
  engagementStatus: '',
  engagementStatusResults: [],
  engagementSummary: '',
  engagementTeam: [],
  engagementTeamRoles: [],
  engagementTeamMemberSearchResults: null,
  engagementTitle: '',
  engagementType: null,
  engagementTypeResults: [],
  groupParticipation: null,
  groupEcosystem: null,
  eurCountry: null,
  industryRDTopics: null,
  labsRDTopics: null,
  // set initial required properties
  loaded: false,
  prepopulated: false,
  organizationAccentureEntitiesResults: [],
  organizationExternalCategoriesResults: [],
  organizations: [],
  organizationTypes: null,
  datesAndLocations: [],
  datesAndLocationsRooms: [],
  logistics: null,
  noFeedback: null,
  primaryContactSearchResults: [],
  primaryContacts: [],
  tags: [],
  tagsSearchResults: [],
  engagementStartDate: null,
  engagementEndDate: null,
  eiDropdownResults: [],
  clientType: [],
});

const generalReducer = createReducer(
  initialState,
  on(GeneralActions.markAsPrepopulated, (state, { value }) => {
    return Object.assign({}, state, { prepopulated: value });
  }),
  on(
    GeneralActions.updateCancellationReason,
    (state, { cancellationReason }) => {
      return Object.assign({}, state, { cancellationReason });
    }
  ),
  on(GeneralActions.updateCancellationDate, (state, { cancellationDate }) => {
    return Object.assign({}, state, { cancellationDate });
  }),
  on(GeneralActions.updateEngagementStatus, (state, { engagementStatus }) => {
    return Object.assign({}, state, { engagementStatus });
  }),
  on(GeneralActions.updateTitle, (state, { engagementTitle }) => {
    return Object.assign({}, state, { engagementTitle });
  }),
  on(GeneralActions.getEngagementTypeResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.getEngagementTypeResultsSuccess,
    (state, { engagementTypeResults }) =>
      generalAdapter.addAll(engagementTypeResults, {
        ...state,
        engagementTypeResults,
        loaded: true,
      })
  ),
  on(GeneralActions.getEngagementTypeResultsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GeneralActions.getEIDropdownEntityResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.getEIDropdownEntityResultsSuccess,
    (state, { eiDropdownResults }) =>
      generalAdapter.addAll(eiDropdownResults, {
        ...state,
        eiDropdownResults,
        loaded: true,
      })
  ),
  on(GeneralActions.getEIDropdownEntityResultsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GeneralActions.updateEngagementType, (state, { engagementType }) => {
    return Object.assign({}, state, { engagementType });
  }),

  on(GeneralActions.loadTagsResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(GeneralActions.loadTagsResultsSuccess, (state, { tagsSearchResults }) =>
    generalAdapter.addAll(tagsSearchResults, {
      ...state,
      loaded: true,
      tagsSearchResults,
    })
  ),
  on(
    GeneralActions.updateEngagementStartDate,
    (state, { engagementStartDate }) => {
      return Object.assign({}, state, { engagementStartDate });
    }
  ),
  on(GeneralActions.updateEngagementEndDate, (state, { engagementEndDate }) => {
    return Object.assign({}, state, { engagementEndDate });
  }),
  on(GeneralActions.loadTagsResultsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GeneralActions.updateTags, (state, { tags }) => {
    return Object.assign({}, state, { tags });
  }),
  on(GeneralActions.updateClientTypeDropdown, (state, { clientType }) => {
    return Object.assign({}, state, { clientType });
  }),
  on(GeneralActions.updateSummary, (state, { engagementSummary }) => {
    return Object.assign({}, state, { engagementSummary });
  }),
  on(GeneralActions.updateAdminComments, (state, { adminComments }) => {
    return Object.assign({}, state, { adminComments });
  }),
  on(GeneralActions.updateNoFeedback, (state, { noFeedback }) => {
    return Object.assign({}, state, { noFeedback });
  }),
  on(GeneralActions.getEngagementSponsorSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.getEngagementSponsorSearchResultsSuccess,
    (state, { engagementSponsorSearchResults }) =>
      generalAdapter.addAll(engagementSponsorSearchResults, {
        ...state,
        engagementSponsorSearchResults,
        loaded: true,
      })
  ),
  on(
    GeneralActions.getEngagementSponsorSearchResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    GeneralActions.updateEngagementSponsors,
    (state, { engagementSponsors }) => {
      return Object.assign({}, state, { engagementSponsors });
    }
  ),
  on(GeneralActions.getPrimaryContactSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.getPrimaryContactSearchResultsSuccess,
    (state, { primaryContactSearchResults }) =>
      generalAdapter.addAll(primaryContactSearchResults, {
        ...state,
        loaded: true,
        primaryContactSearchResults,
      })
  ),
  on(
    GeneralActions.getPrimaryContactSearchResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(GeneralActions.updatePrimaryContacts, (state, { primaryContacts }) => {
    return Object.assign({}, state, { primaryContacts });
  }),
  on(GeneralActions.getAdditionalContactsSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.getAdditionalContactsSearchResultsSuccess,
    (state, { additionalContactSearchResults }) =>
      generalAdapter.addAll(additionalContactSearchResults, {
        ...state,
        additionalContactSearchResults,
        loaded: true,
      })
  ),
  on(
    GeneralActions.getAdditionalContactsSearchResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(GeneralActions.updateOrganizations, (state, { organizations }) => {
    return Object.assign({}, state, { organizations });
  }),
  on(GeneralActions.updateDatesAndlocations, (state, { datesAndLocations }) => {
    return Object.assign({}, state, { datesAndLocations });
  }),
  on(GeneralActions.updateLogistics, (state, { logistics }) => {
    return Object.assign({}, state, { logistics });
  }),
  on(GeneralActions.getExternalOrganizationCategoriesResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.getExternalOrganizationCategoriesResultsSuccess,
    (state, { organizationExternalCategoriesResults }) =>
      generalAdapter.addAll(organizationExternalCategoriesResults, {
        ...state,
        loaded: true,
        organizationExternalCategoriesResults,
      })
  ),
  on(
    GeneralActions.getExternalOrganizationCategoriesResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(GeneralActions.getClientOrganizationSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.getClientOrganizationSearchResultsSuccess,
    (state, { clientOrganizationSearchResults }) =>
      generalAdapter.addAll(clientOrganizationSearchResults, {
        ...state,
        clientOrganizationSearchResults,
        loaded: true,
      })
  ),
  on(
    GeneralActions.getClientOrganizationSearchResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(GeneralActions.getOrganizationTypes, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.getOrganizationTypesSuccess,
    (state, { organizationTypes }) => ({
      ...state,
      organizationTypes: organizationTypes,
    })
  ),
  on(GeneralActions.getOrganizationTypesFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GeneralActions.getAccentureEntities, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.getAccentureEntitiesSuccess,
    (state, { organizationAccentureEntitiesResults }) =>
      generalAdapter.addAll(organizationAccentureEntitiesResults, {
        ...state,
        loaded: true,
        organizationAccentureEntitiesResults,
      })
  ),
  on(GeneralActions.getAccentureEntitiesFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    GeneralActions.updateAdditionalContacts,
    (state, { additionalContacts }) => {
      return Object.assign({}, state, { additionalContacts });
    }
  ),
  on(GeneralActions.updateEngagementGroup, (state, { engagementGroup }) => {
    return Object.assign({}, state, { engagementGroup });
  }),
  on(GeneralActions.loadEngagementGroupResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.loadEngagementGroupResultsSuccess,
    (state, { engagementGroupResults }) =>
      generalAdapter.addAll(engagementGroupResults, {
        ...state,
        engagementGroupResults,
        loaded: true,
      })
  ),
  on(GeneralActions.loadEngagementGroupResultsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GeneralActions.loadEngagementGroupEcosystemResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.loadEngagementGroupEcosystemResultsSuccess,
    (state, { engagementGroupEcosystemResults }) =>
      generalAdapter.addAll(engagementGroupEcosystemResults, {
        ...state,
        engagementGroupEcosystemResults,
        loaded: true,
      })
  ),
  on(
    GeneralActions.loadEngagementGroupEcosystemResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(GeneralActions.loadEngagementIndustryRDResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.loadEngagementIndustryRDResultsSuccess,
    (state, { engagementIndustryRDResults }) =>
      generalAdapter.addAll(engagementIndustryRDResults, {
        ...state,
        engagementIndustryRDResults,
        loaded: true,
      })
  ),
  on(
    GeneralActions.loadEngagementIndustryRDResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(GeneralActions.loadEngagementLabsRDResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.loadEngagementLabsRDResultsSuccess,
    (state, { engagementLabsRDResults }) =>
      generalAdapter.addAll(engagementLabsRDResults, {
        ...state,
        engagementLabsRDResults,
        loaded: true,
      })
  ),
  on(GeneralActions.loadEngagementLabsRDResultsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GeneralActions.loadEngagementCountryResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.loadEngagementCountryResultsSuccess,
    (state, { engagementCountryResults }) =>
      generalAdapter.addAll(engagementCountryResults, {
        ...state,
        engagementCountryResults,
        loaded: true,
      })
  ),
  on(
    GeneralActions.loadEngagementCountryResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(GeneralActions.loadEngagementLeadResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.loadEngagementLeadResultsSuccess,
    (state, { engagementLeadSearchResults }) =>
      generalAdapter.addAll(engagementLeadSearchResults, {
        ...state,
        engagementLeadSearchResults,
        loaded: true,
      })
  ),
  on(GeneralActions.loadEngagementLeadResultsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GeneralActions.updateEngagementLead, (state, { engagementLead }) => {
    return Object.assign({}, state, { engagementLead });
  }),
  on(GeneralActions.updateEngagementTeam, (state, { engagementTeam }) => {
    return Object.assign({}, state, { engagementTeam });
  }),
  on(GeneralActions.loadEngagementTeamMemberResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.loadEngagementTeamMemberResultsSuccess,
    (state, { engagementTeamMemberSearchResults }) =>
      generalAdapter.addAll(engagementTeamMemberSearchResults, {
        ...state,
        engagementTeamMemberSearchResults,
        loaded: true,
      })
  ),
  on(
    GeneralActions.loadEngagementTeamMemberResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    GeneralActions.updateGroupParticipation,
    (state, { groupParticipation }) => {
      return Object.assign({}, state, { groupParticipation });
    }
  ),
  on(GeneralActions.updateGroupEcosystem, (state, { groupEcosystem }) => {
    return Object.assign({}, state, { groupEcosystem });
  }),
  on(GeneralActions.updateCountryEur, (state, { eurCountry }) => {
    return Object.assign({}, state, { eurCountry });
  }),
  on(GeneralActions.updateIndustryRD, (state, { industryRDTopics }) => {
    return Object.assign({}, state, { industryRDTopics });
  }),
  on(GeneralActions.updateLabsRD, (state, { labsRDTopics }) => {
    return Object.assign({}, state, { labsRDTopics });
  }),
  on(GeneralActions.loadDatesAndLocationsRooms, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.loadDatesAndLocationsRoomsSuccess,
    (state, { datesAndLocationsRooms }) => {
      return Object.assign({}, state, { datesAndLocationsRooms });
    }
  ),
  on(GeneralActions.loadDatesAndLocationsRoomsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GeneralActions.getEngagementTeamRoles, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    GeneralActions.getEngagementTeamRolesSuccess,
    (state, { engagementTeamRoles }) => {
      return Object.assign({}, state, { engagementTeamRoles });
    }
  ),
  on(GeneralActions.getEngagementTeamRolesFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GeneralActions.clearStateObject, () => {
    return Object.assign({}, initialState);
  })
);

export function reducer(state: GeneralState | undefined, action: Action) {
  return generalReducer(state, action);
}
