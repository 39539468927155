<div [formGroup]="visitDetailsGroup">
  <div class="visit">
    <ioh-duration
      [formGroup]="visitDetailsGroup"
    >
    </ioh-duration>
  <div *ngFor="let value of visitTypes" class="attendee__radio__container">
    <ioh-radio-button
    radioId="{{ value.radioId }}"
      label="{{ value.label }}"
      field="{{ value.field }}"
      [values]="value.values"
      [formGroup]="visitDetailsGroup"
      ></ioh-radio-button>
    </div>
    <ioh-wbs-container [wbsDetails]="wbsDetails" (fetchWbsResults)="getWbs($event)">
    </ioh-wbs-container>
    <div class="covid" tabindex="0">Please visit <a href="https://ts.accenture.com/sites/GlobalLocationsDirectory/Lists/Location%20List/default.aspx" title="GlobalLocationsDirectory" aria-label="opens the globallocation directory page in a new window" target="_blank">Global Location Directory</a> for COVID protocols for your office or country</div>
  </div>
</div>
