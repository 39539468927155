import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class AppLoadingService {
  loading: boolean;
  saveLoading: boolean;

  constructor() {
    this.loading = false;
    this.saveLoading = false;
  }

  loadingStart() {
    setTimeout(() => {
      this.loading = true;
      const elementBody = document.getElementsByTagName('body')[0];
      elementBody.setAttribute('class', 'mat-typography body-overflow');
    }, 100);
  }

  loadingEnd() {
    setTimeout(() => {
      this.loading = false;
      const elementBody = document.getElementsByTagName('body')[0];
      elementBody.setAttribute('class', 'mat-typography');
    }, 100);
  }

  saveLoadingStart() {
    setTimeout(() => {
      this.saveLoading = true;
      const elementBody = document.getElementsByTagName('body')[0];
      elementBody.setAttribute('class', 'mat-typography body-overflow');
    }, 100);
  }

  saveLoadingEnd() {
    setTimeout(() => {
      this.saveLoading = false;
      const elementBody = document.getElementsByTagName('body')[0];
      elementBody.setAttribute('class', 'mat-typography');
    }, 100);
  }
}
