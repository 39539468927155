<div fxLayout="column" class="look-up" [formGroup]="parentForm">
  <label *ngIf="label" fxFlex attr.for="{{ lookUpId }}" class="look-up__label" tabindex="0">
    <span *ngIf="required" class="look-up__asterisk">*</span>
    {{ label }}<ioh-tooltip [detail]="tooltipObj"></ioh-tooltip>
  </label>
  <mat-form-field
    [ngClass]="{'team': teamName}"
    class="look-up__list"
    appearance="outline"
    color="primary"
  >
    <mat-chip-grid
      #chipGrid
      attr.aria-label="{{ label }} selection"
      [formGroup]="getLookUpGroup"
      >
      <mat-chip
        [formArrayName]="lookupArrayFieldName"
        class="look-up__chips"
        fxLayout="row"
        fxLayoutAlign="space-around center"
        attr.aria-selected="true"
        *ngFor="let oppGroup of getResponseArray.controls; let index = index"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(index)"
        [class.error]="oppGroup.get('chip').hasError('unique')"
      >
        <span [formGroup]="oppGroup"  *ngIf="oppGroup.get('chip').value!=' '||oppGroup.get('chip').value!=null">
            <input
              hidden
              value="{{oppGroup.get('chip').value}}"
              formControlName="chip">
            <span>{{oppGroup.get('chip').value}}</span>
        </span>
        <mat-icon *ngIf="!isDisabled"
          class="look-up__chips__icon material-icons"
          aria-label="remove button"
          matChipRemove
        >clear</mat-icon
        >
      </mat-chip>
      <input
        [formControlName]="lookupInputFieldName"
        [disabled]="isDisabled"
        #lookUpInput
        class="look-up__input"
        [class.look-up__placeholder__hide]="getResponseArray.controls.length !==0"
        placeholder="{{placeholder}}"
        attr.aria-label="{{lookupInputFieldName}}"
        id="{{ lookUpId }}"
        maxlength="100"
        attr.aria-controls="{{ 'autocomplete-' + lookUpId }}"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matAutocomplete]="auto"
        (matChipInputTokenEnd)="added($event)"/>
    </mat-chip-grid>
    <mat-autocomplete
      #auto
      (optionSelected)="selected($event)"
      id="{{ 'autocomplete-' + lookUpId }}"
    >
      <mat-option *ngIf="(isLoading$ | async)">
        <mat-spinner color="accent" diameter="30"></mat-spinner>
      </mat-option>
      <mat-option
        *ngFor="let value of searchResults$ | async"
        [value]="value.trim()">
        {{ value }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <ioh-error
    class="look-up__error"
    *ngIf="(getLookUpInput.touched || getLookUpInput.dirty) && chipsError"
    errorMsg="This field was already selected!">
  </ioh-error>
  <ioh-error
    class="look-up__error"
    *ngIf="(getLookUpInput.touched || getLookUpInput.dirty) &&
      getResponseArray.hasError('required') && lookUpId !== 'opportunity'"
    errorMsg="A valid {{ label }} is required">
  </ioh-error>
  <ioh-error
  class="look-up__error"
  *ngIf="(getLookUpInput.touched || getLookUpInput.dirty) &&
    getResponseArray.hasError('required') && lookUpId === 'opportunity' && !noMMS && isCompletedStatus"
  errorMsg="Please either enter a valid MMS ID or else check the box to indicate that no existing MMS ID applies to this engagement.">
  </ioh-error>
  <ioh-error
    class="look-up__error"
    *ngIf="lookUpId === 'opportunity' && parentForm.get('noMmsOpportunityId').value  == 'Added to MMS Campaign' && !isCompletedStatus  && 
    parentForm.get('opportunityInput').get('oppResult').value.length < 1 && parentForm.get('opportunityInput').get('oppResult').touched"
    errorMsg="A valid {{ label }} is required">
  </ioh-error>
  <ioh-error
    class="look-up__error"
    *ngIf="(getLookUpInput.touched || getLookUpInput.dirty) &&
      getResponseArray.hasError('maxLimitReached')"
    errorMsg="There can only be {{ this.limit }}!">
  </ioh-error>
</div>
