import { AddBookingService } from '../sections/add-booking/add-booking.service';
import { AdditionalTab } from '@utils/index';
import {
  AdminFacade,
  ArchiveFacade,
  GeneralFacade,
  ReportService
} from '@ioh/core-data';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BookingTab } from '@utils/index';
import { Buffer, Column, Workbook, Worksheet } from 'exceljs';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { DatesTab } from '@utils/index';
import { EngTeamTab } from '@utils/index';
import { ExcelExportService } from '@services';
import { FeedbackTab } from '@utils/index';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JournalTab } from '@utils/index';
import { MMSTab } from '@utils/index';
import { MappedObjects } from '@utils/index';
import { ModalService } from '@services';
import { MsalService } from '@azure/msal-angular';
import { OrgTab } from '@utils/index';
import { Router } from '@angular/router';
import { WorkshopService } from '../../services/workshop.service';
import {
  downloadFields,
  hubArchive,
  menuItems
} from '../../../assets/data/menu-details';
import { dropdownList } from '@assets/data/index';
import { filter } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { toLower } from 'lodash';
@Component({
  selector: 'ioh-menu',
  styleUrls: ['./menu.component.scss'],
  templateUrl: './menu.component.html'
})
export class MenuComponent {
  dropdownFields = downloadFields;
  menuItems = menuItems;
  isShowHelpBox = false;
  allArchiveData$: BehaviorSubject<any> = new BehaviorSubject(null);
  selectHub = '';
  isArchive: boolean;
  data = [];
  showAdminMenu: any = [];
  ProgramList: string;
  isEUR = false;
  EURHubList = ['BCN', 'SOA', 'DEMO'];
  dropDownValues = {
    fiscalYear: [],
    engagementStatus: []
  };

  isDialogOpen = false;

  userId = this.authService.instance.getAllAccounts()[0].username.split('@')[0];
  widthWarningModal = '446x';
  heightWarningModal = '395px';
  workbook: any;
  downloadSource = [];
  programCodelist = [];
  archiveData = [];
  private readonly subscriptions: Subscription = new Subscription();
  archivedEngagementRoute = '/admin/archived-engagement';
  myEngagementsRoute = '/admin/home';
  sidebarMenuRoute = '/admin/sidebarmenu';
  adminMenuRoute = '/admin/user-access';
  adminEcosystemRoute = '/admin/ecosystem';
  adminEngPurposeRoute = '/admin/engagement-purpose';
  adminEngTypeRoute = '/admin/engagement-type';
  adminGroupOwnerRoute = '/admin/group-owner';
  adminInnovationRoute = '/admin/innovation';
  downloadGroup: FormGroup;
  hubArchive = hubArchive;

  @ViewChild('DownloadTemplate') modalTemplate: TemplateRef<any>;
  isbackToMainSite: boolean;
  constructor(
    private readonly fb: FormBuilder,
    readonly router: Router,
    readonly adminFacade: AdminFacade,
    private readonly generalTabFacade: GeneralFacade,
    readonly archiveFacade: ArchiveFacade,
    readonly reportService: ReportService,
    readonly exportService: ExcelExportService,
    private readonly workshopService: WorkshopService,
    private readonly modalService: ModalService,
    private readonly authService: MsalService,
    readonly addBookingService: AddBookingService
  ) {}

  ngOnInit(): void {
    this.initFilterForm();
    this.getDropdown();
    this.subscriptions.add(
      this.adminFacade.getAdminProgramList$.subscribe(adminProgramList => {
        this.showAdminMenu = adminProgramList.length > 0;
        this.programCodelist = adminProgramList.map(v => v.code);
      })
    );
    this.showHideMenu();
    this.subscriptions.add(
      this.adminFacade.getProgramList$.subscribe(programList => {
        this.ProgramList = programList.map(item => item.code).join(',');
      })
    );
  }

  initFilterForm() {
    this.downloadGroup = this.fb.group({
      fiscalYear: this.fb.control([]),
      archivefiscalYear: this.fb.control([]),
      engagementStatus: this.fb.control([]),
      hubArchive: this.fb.control('Active Engagements', Validators.required)
    });
  }

  setFiscalYear() {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const FYYear = this.getFiscalyear(currentMonth, currentYear);
    const ArchiveFYYear = this.getArchiveFiscalyear(currentMonth, currentYear);
    this.downloadGroup
      .get('fiscalYear')
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      .setValue([{ id: null, value: 'FY' + FYYear.toString().substr(-2) }]);
    this.downloadGroup
      .get('archivefiscalYear')
      .setValue([
        { id: null, value: 'FY' + ArchiveFYYear.toString().substr(-2) }
      ]);
  }

  getFiscalyear(currentMonth, currentYear) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return currentMonth < 9 ? currentYear : currentYear + 1;
  }

  getArchiveFiscalyear(currentMonth, currentYear) {
    return currentMonth < 9 ? currentYear - 3 : currentYear - 2;
  }

  getDropdown() {
    this.generalTabFacade.EIDropdownResults$.pipe(
      filter(v => v && v.length > 0)
    ).subscribe(dropdown => {
      this.dropDownValues[dropdownList.fiscalYear] = dropdown.filter(
        option => option.typeName === dropdownList.fiscalYear
      );
      this.dropDownValues[dropdownList.engagementStatus] = dropdown.filter(
        option => option.typeName === dropdownList.engagementStatus
      );
      this.dropDownValues[dropdownList.archivefiscalYear] = dropdown.filter(
        option => option.typeName === dropdownList.archivefiscalYear
      );
    });
  }

  clearCloneWorkshop() {
    this.adminFacade.updatedIsCloneWorkshop(false);
  }

  showHideMenu()
  {
      if(toLower(window.location.href).indexOf('user-acess')!=-1){
        this.isbackToMainSite = true;
      }
      else(
          this.isbackToMainSite = false
      )
}

  navigateTo(routeValue) {
    this.adminFacade.updatedCloneWorkshop(null);
    this.adminFacade.updatedIsCloneWorkshop(false);
    this.adminFacade.updateResponse(null);
    this.archiveFacade.updatedIsArchive(false);
    this.archiveFacade.updatedSelectedArchiveWorkshop(null);
    this.addBookingService.clearAllStateObjects();
    if (
      !this.router.url.includes('/general') &&
      !this.router.url.includes('/consultation') &&
      !this.router.url.includes('/recovery') &&
      !this.router.url.includes('/documents')
    ) {
      sessionStorage.setItem('listView', this.router.url);
    }
    if(
      routeValue.includes('/admin/user-access')
    ){
     }
    this.isbackToMainSite = routeValue.includes('/admin/user-access');
    if(
      routeValue.includes('/admin/user-access')
    ){
     }
    this.isbackToMainSite = routeValue.includes('/admin/user-access');
    this.router.navigateByUrl(routeValue);
  }

  downloadAction() {
    const dataModal = { template: this.modalTemplate };
    this.modalService.openDialog(
      dataModal,
      this.widthWarningModal,
      this.heightWarningModal
    );
    this.initFilterForm();
    this.isDialogOpen = true;
    this.setFiscalYear();
  }

  cancel() {
    this.modalService.closeDialog();
  }

  downloadReport() {
    const isArchive =
      this.downloadGroup.get('hubArchive').value === 'Archived Engagements';
    if (!isArchive) {
      this.downloadFilterReport();
    } else {
      this.dowbloadArchiveReport();
    }
  }

  dowbloadArchiveReport() {
    this.exportService.isDownloadReport = true;
    this.archiveFacade.loadAllArchiveDataByUserId(this.userId);
    this.subscriptions.add(
      this.workshopService.getArchiveWorkshops$.subscribe(archiveWorkshops => {
        if (this.isDialogOpen) {
          this.archiveData = this.formatArchiveWorkshop(archiveWorkshops);
          this.downloadArReport(this.archiveData);
        }
      })
    );
  }

  downloadArReport(data) {
    const archiveData = data?.filter(
      v => !!v && this.programCodelist.includes(v.program.code)
    );
    const filterData = this.filterResponse(
      archiveData,
      this.downloadGroup.value
    );
    const bufferFile = this.transferResponseToBuffer(filterData);
    this.exportService.loadDownloadReport(bufferFile, 'report.xlsx');
    this.modalService.closeDialog();
    this.isDialogOpen = false;
  }

  formatArchiveWorkshop(data) {
    return data.map(o => {
      return this.formatJson(o.data);
    });
  }

  formatJson(data) {
    if (data) {
      return JSON.parse(data);
    }
  }

  async downloadFilterReport() {
    this.downloadSource = [];
    this.exportService.isDownloadReport = true;
    const groupValue = this.downloadGroup.value;
    const hubList = (
      sessionStorage.getItem('exportProgramList') || this.ProgramList
    ).split(',');
    const promiseList = [];
    for (const hub of hubList) {
      promiseList.push(this.fetchReportSource(hub, groupValue));
    }
    await Promise.all(promiseList).then(() => {
      let data = [];
      this.downloadSource.map(function(value, index, array) {
        data = data.concat(value);
      });
      const bufferFile = this.transferResponseToBuffer(data);
      this.exportService.loadDownloadReport(bufferFile, 'report.xlsx');
      this.modalService.closeDialog();
    });
  }

  async fetchReportSource(hub, groupValue): Promise<any> {
    return new Promise((resolve, reject) => {
      this.reportService.getExcelReport(hub).then(response => {
        const filterResult = this.filterResponse(response.data, groupValue);
        this.downloadSource.push(filterResult);
        resolve(response);
      });
    });
  }

  filterResponse(dataSource, filterValue) {
    const dataClone = cloneDeep(dataSource);
    let fiscalYearArray = [];
    const isArchive =
      this.downloadGroup.get('hubArchive').value === 'Archived Engagements';
    if (isArchive) filterValue.fiscalYear = filterValue.archivefiscalYear;
    if (
      !isEmpty(filterValue.fiscalYear) &&
      typeof filterValue.fiscalYear !== 'string'
    ) {
      fiscalYearArray = filterValue.fiscalYear.map(item => {
        return item.value;
      });
    }
    let engagementStatusArray = [];
    if (!isEmpty(filterValue.engagementStatus)) {
      engagementStatusArray = filterValue.engagementStatus.map(item => {
        return item.value;
      });
    }
    return this.findResult(dataClone, fiscalYearArray, engagementStatusArray);
  }

  findResult(dataClone, yearArray, statusArray) {
    let result = [];
    if (Array.isArray(dataClone) && !isEmpty(dataClone)) {
      result = dataClone.filter(data => {
        var checkInFiscalYear = yearArray.length
          ? yearArray.includes(data.fiscalYear)
          : true;
        var checkEngageStatus = statusArray.length
          ? statusArray.includes(data.status.value)
          : true;
        return checkInFiscalYear && checkEngageStatus;
      });
      return result;
    }
  }

  async transferResponseToBuffer(response): Promise<Buffer> {
    const result = response || [];
    this.workbook = new Workbook();
    const mappedObjects: MappedObjects = this.getMappedObjects(result);
    this.generateAllTabs(mappedObjects);
    return await this.workbook.xlsx.writeBuffer();
  }

  private getMappedObjects(queryRes: any): MappedObjects {
    const Bookings: BookingTab[] = queryRes.map(
      workshop => new BookingTab(workshop)
    );
    const AdditionalFields: AdditionalTab[] = queryRes
      .filter(
        elem =>
          elem.program &&
          elem.program.code &&
          this.EURHubList.includes(elem.program.code)
      )
      .map(workshop => new AdditionalTab(workshop));
    const MMS: MMSTab[] = queryRes
      .map(workshop => {
        if (
          workshop.consultation &&
          workshop.consultation.opportunities &&
          workshop.consultation.opportunities.length > 0
        ) {
          return workshop.consultation.opportunities.map(
            opp => new MMSTab(workshop, opp)
          );
        } else {
          return new MMSTab(workshop, { id: '', description: '' });
        }
      })
      .filter(elem => elem)
      .reduce((acc, val) => acc.concat(val), []);
    const Organizations: OrgTab[] = queryRes
      .map(
        workshop =>
          workshop.general &&
          workshop.general.organizations &&
          workshop.general.organizations.length > 0 &&
          workshop.general.organizations.map(
            org => org && new OrgTab(workshop, org)
          )
      )
      .filter(elem => elem)
      .reduce((acc, val) => acc.concat(val), []);

    const Dates: DatesTab[] = queryRes
      .map(
        workshop =>
          workshop.general &&
          workshop.general.schedule &&
          workshop.general.schedule.dates &&
          workshop.general.schedule.dates.length > 0 &&
          workshop.general.schedule.dates.map(
            calendarDate => new DatesTab(workshop, calendarDate)
          )
      )
      .filter(elem => elem)
      .reduce((acc, val) => acc.concat(val), []);

    const EngagementTeam: EngTeamTab[] = queryRes
      .map(
        workshop =>
          workshop.general &&
          workshop.general.engagement &&
          workshop.general.engagement.team &&
          workshop.general.engagement.team.length > 0 &&
          workshop.general.engagement.team.map(
            engTeam => new EngTeamTab(workshop, engTeam)
          )
      )
      .filter(elem => elem)
      .reduce((acc, val) => acc.concat(val), []);

    const JournalEntries: JournalTab[] = queryRes
      .map(
        workshop =>
          workshop.recovery &&
          workshop.recovery.costs &&
          workshop.recovery.costs.length > 0 &&
          workshop.recovery.costs.map(cost => new JournalTab(workshop, cost))
      )
      .filter(elem => elem)
      .reduce((acc, val) => acc.concat(val), []);

    const Feedback: FeedbackTab[] = queryRes
      .map(
        workshop =>
          workshop.general &&
          workshop.general.engagement &&
          workshop.program &&
          workshop.program.code &&
          workshop.surveyAnswer &&
          workshop.surveyAnswer.map(survey => new FeedbackTab(workshop, survey))
      )
      .filter(elem => elem)
      .reduce((acc, val) => acc.concat(val), []);
    return {
      Bookings,
      AdditionalFields,
      MMS,
      Organizations,
      Dates,
      EngagementTeam,
      JournalEntries,
      Feedback
    };
  }

  public generateTab(
    worksheetName: string,
    columnHeaders: Array<Partial<Column>>,
    rowData: any[]
  ): void {
    // Create Tab
    const worksheet: Worksheet = this.workbook.addWorksheet(worksheetName);
    // Set Headers
    worksheet.columns = columnHeaders;
    // Set Rows
    worksheet.addRows(rowData);
    // Apply bold to header and border to content
    worksheet.getRow(1).font = { bold: true };

    worksheet.eachRow({ includeEmpty: true }, row => {
      row.eachCell({ includeEmpty: true }, cell => {
        cell.border = {
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
          top: { style: 'thin' }
        };
      });
    });

    if (worksheetName == 'Bookings') {
      worksheet.eachRow({ includeEmpty: true }, row => {
        this.isEUR = false;
        row.eachCell({ includeEmpty: true }, cell => {
          this.isEUR =
            this.isEUR || this.bookingsEUR(cell, BookingTab.getColumns);
          cell = this.bookingCurrencyFormat(
            cell,
            BookingTab.getColumns,
            this.isEUR
          );
        });
      });
    }
    if (worksheetName == 'Journal Entries') {
      worksheet.eachRow({ includeEmpty: true }, row => {
        this.isEUR = false;
        row.eachCell({ includeEmpty: true }, cell => {
          this.isEUR =
            this.isEUR || this.journalEntriesEUR(cell, JournalTab.getColumns);
          cell = this.journalCurrencyFormat(
            cell,
            JournalTab.getColumns,
            this.isEUR
          );
        });
      });
    }
  }

  public bookingsEUR(cell, column) {
    return (
      column[Number(cell.col) - 1] &&
      column[Number(cell.col) - 1].key == 'HubLocation' &&
      this.EURHubList.includes(cell.value)
    );
  }

  public journalEntriesEUR(cell, column) {
    return (
      column[Number(cell.col) - 1] &&
      column[Number(cell.col) - 1].key == 'Currency' &&
      cell.value == 'EUR'
    );
  }

  public bookingCurrencyFormat(cell, column, isEUR) {
    if (cell.style && cell.style.numFmt && isEUR) {
      cell.style.numFmt =
        column[Number(cell.col) - 1] &&
        (column[Number(cell.col) - 1].key == 'TotalCost' ||
          column[Number(cell.col) - 1].key == 'TotalDiscounts' ||
          column[Number(cell.col) - 1].key == 'RecoveryTotal' ||
          column[Number(cell.col) - 1].key == 'PassThroughEstimate')
          ? '€#,##0.00'
          : '$#,##0.00';
    }
    return cell;
  }

  public journalCurrencyFormat(cell, column, isEUR) {
    if (cell.style && cell.style.numFmt && isEUR) {
      cell.style.numFmt =
        column[Number(cell.col) - 1] &&
        (column[Number(cell.col) - 1].key == 'Amount' ||
          column[Number(cell.col) - 1].key == 'Recovery2percent' ||
          column[Number(cell.col) - 1].key == 'Cost' ||
          column[Number(cell.col) - 1].key == 'Discount')
          ? '€#,##0.00'
          : '$#,##0.00';
    }
    return cell;
  }

  private generateAllTabs({
    Bookings,
    AdditionalFields,
    MMS,
    Organizations,
    Dates,
    EngagementTeam,
    JournalEntries,
    Feedback
  }: MappedObjects): void {
    this.generateTab('Bookings', BookingTab.getColumns, Bookings);
    this.generateTab(
      'Additional Fields',
      AdditionalTab.getColumns,
      AdditionalFields
    );
    this.generateTab('MMS', MMSTab.getColumns, MMS);
    this.generateTab('Organizations', OrgTab.getColumns, Organizations);
    this.generateTab('Event Dates', DatesTab.getColumns, Dates);
    this.generateTab('Engagement Team', EngTeamTab.getColumns, EngagementTeam);
    this.generateTab('Journal Entries', JournalTab.getColumns, JournalEntries);
    this.generateTab('Engagement Feedback', FeedbackTab.getColumns, Feedback);
  }

  toggleHellBox(): void {
    this.isShowHelpBox = !this.isShowHelpBox;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
