import {
  AdminFacade,
  ConsultationFacade,
  ConsultationState,
  GeneralFacade,
  GeneralState,
  RecoveryFacade,
  RecoveryState,
} from '@ioh/core-data';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DateFormatService, LookupService, WorkshopService } from '@services';
import { FormGroup } from '@angular/forms';
import { Injectable, OnDestroy } from '@angular/core';
import { add, startOfToday } from 'date-fns';
import { filter } from 'rxjs/operators';
import { isNonNull } from '@utils/index';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

@Injectable({
  providedIn: 'root',
})
export class AddBookingService implements OnDestroy {
  generalFacadeStateObject: any = {};
  programList: any = [];
  consultationFacadeStateObject: any = {};
  recoveryFacadeStateObject: any = {};
  documentFacadeStateObject: any = {};
  workshopGeneral: any = {};
  workshopConsultation: any = {};
  workshopRecovery: any = {};
  workshopDocument: any = {};
  cloneWorkshop: any = {};
  private readonly subscriptions: Subscription = new Subscription();
  generalTabIsPrepopulated: boolean;
  consultationTabIsPrepopulated: boolean;
  recoveryTabIsPrepopulated: boolean;
  documentTabIsPrepopulated: boolean;
  workshopStatus$ = new BehaviorSubject<any>(null);
  cancellationReason$ = new BehaviorSubject<any>(null);
  cancellationDate$ = new BehaviorSubject<string>(null);
  engagementStartDate$ = new BehaviorSubject<string>(null);
  engagementEndDate$ = new BehaviorSubject<string>(null);
  currentWorkshopStatus: any;
  _saveButtonToggle$ = new BehaviorSubject<boolean>(false);
  _deleteButtonToggle$ = new BehaviorSubject<boolean>(false);
  isNewWorkshop$ = new BehaviorSubject<boolean>(false);
  hasOrgHistory$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly adminFacade: AdminFacade,
    private readonly workshopService: WorkshopService,
    private readonly generalFacade: GeneralFacade,
    private readonly consultationFacade: ConsultationFacade,
    private readonly recoveryFacade: RecoveryFacade,
    private readonly lookupService: LookupService,
    private readonly dateFormatService: DateFormatService
  ) {
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.getPrepopulatedValues();
    this.getGeneralWorkshop();
    this.getConsultationWorkshop();
    this.getRecoveryWorkshop();
    this.getDocumentWorkshop();
    this.getGeneralState();
    this.getProgramListState();
    this.getConsultationState();
    this.getRecoveryState();
    this.getDocumentState();
    this.getWorkshopStatus();
    this.updateCancellationReason();
    this.updateCancellationDate();
    this.updateEngagementStartDate();
    this.updateEngagementEndDate();
    this.getWorkshopSaveError();
    this.getCloneWorkshop();
  }

  getCloneWorkshop(): void {
    this.subscriptions.add(
      this.workshopService.selectedCloneWorkshop$.subscribe((cloneWorkshop) => {
        this.cloneWorkshop = cloneWorkshop;
      })
    );
  }

  getWorkshopSaveError(): void {
    this.subscriptions.add(
      this.workshopService.saveWorkshopError$
        .pipe(filter(isNonNull))
        .subscribe(() => {
          this.disableSaveButton(false);
          this.workshopService.openFailureToast();
        })
    );
  }

  disableSaveButton(value: boolean): void {
    this._saveButtonToggle$.next(value);
  }

  disableDeleteButton(value: boolean): void {
    this._deleteButtonToggle$.next(value);
  }

  getPrepopulatedValues(): void {
    this.subscriptions.add(
      this.generalFacade.prepopulated$.subscribe((prepopulated) => {
        this.generalTabIsPrepopulated = prepopulated;
      })
    );
    this.subscriptions.add(
      this.consultationFacade.prepopulated$.subscribe((prepopulated) => {
        this.consultationTabIsPrepopulated = prepopulated;
      })
    );
    this.subscriptions.add(
      this.recoveryFacade.prepopulated$.subscribe((prepopulated) => {
        this.recoveryTabIsPrepopulated = prepopulated;
      })
    );
    this.subscriptions.add(
      this.adminFacade.documentPrepopulated$.subscribe((prepopulated) => {
        this.documentTabIsPrepopulated = prepopulated;
      })
    );
  }

  getGeneralWorkshop(): void {
    this.subscriptions.add(
      this.workshopService.selectedWorkshopGeneral$.subscribe((general) => {
        this.workshopGeneral = general;
      })
    );
  }

  getConsultationWorkshop(): void {
    this.subscriptions.add(
      this.workshopService.selectedWorkshopConsultation$.subscribe(
        (consultation) => {
          this.workshopConsultation = consultation;
        }
      )
    );
  }

  getRecoveryWorkshop(): void {
    this.subscriptions.add(
      this.workshopService.selectedWorkshopRecovery$.subscribe((recovery) => {
        this.workshopRecovery = recovery;
      })
    );
  }

  getDocumentWorkshop(): void {
    this.subscriptions.add(
      this.workshopService.selectedWorkshopDocument$.subscribe((document) => {
        this.workshopDocument = document;
      })
    );
  }

  getGeneralState(): void {
    this.subscriptions.add(
      this.generalFacade.allGeneralTab$.subscribe((generalTab) => {
        // this is the general tab state object - if any changes have been made to the general form group
        // then this is the most up to date record of the general tab
        this.generalFacadeStateObject = generalTab;
      })
    );
  }

  getProgramListState(): void {
    this.subscriptions.add(
      this.adminFacade.getProgramList$.subscribe((programList) => {
        // this is the program List state object
        this.programList = this.formatProgramList(programList);
      })
    );
  }

  public formatProgramList(programeList) {
    let programList = programeList.map((item) => {
      return {
        value: item.code,
        id: item.id,
      };
    });
    programList = programList.sort(this.sortName);
    return programList;
  }

  sortName(a, b) {
    var max = a.value.toUpperCase();
    var min = b.value.toUpperCase();
    if (max < min) {
      return -1;
    }
    if (max > min) {
      return 1;
    }
    return 0;
  }

  getConsultationState(): void {
    this.subscriptions.add(
      this.consultationFacade.allConsultationTab$.subscribe(
        (consultationTab) => {
          // this is the consultation tab state object - if any changes have been made to the consultation form group
          // then this is the most up to date record of the consultation tab
          this.consultationFacadeStateObject = consultationTab;
        }
      )
    );
  }

  getRecoveryState(): void {
    this.subscriptions.add(
      this.recoveryFacade.allRecoveryTab$.subscribe((recoveryTab) => {
        // this is the recovery tab state object - if any changes have been made to the recovery form group
        // then this is the most up to date record of the recovery tab
        this.recoveryFacadeStateObject = recoveryTab;
      })
    );
  }

  getDocumentState(): void {
    this.subscriptions.add(
      this.adminFacade.allAdmin$.subscribe((allAdmin) => {
        // this is the document tab state object - if any changes have been made to the document form group
        // then this is the most up to date record of the document tab
        this.documentFacadeStateObject = allAdmin.document;
      })
    );
  }

  prepopulateCancellationReason(
    bookingFormGroup: FormGroup,
    generalWorkshop
  ): void {
    bookingFormGroup.get('cancellationReason').setValue({
      id: get(generalWorkshop, 'cancellation.reason.id'),
      value: get(generalWorkshop, 'cancellation.reason.value'),
    });
  }

  prepopulateWorkshopStatus(
    bookingFormGroup: FormGroup,
    generalWorkshop
  ): void {
    bookingFormGroup.get('status').setValue({
      id: generalWorkshop.workshop.status.id,
      value: generalWorkshop.workshop.status.value,
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updateCancellationReason() {
    this.cancellationReason$.subscribe((cancellationReason) => {
      this.generalFacade.updateCancellationReason(cancellationReason);
    });
  }

  updateCancellationDate() {
    this.cancellationDate$.subscribe((cancellationDate) => {
      this.generalFacade.updateCancellationDate(cancellationDate);
    });
  }

  updateEngagementStartDate() {
    this.engagementStartDate$.subscribe((engagementStartDate) => {
      this.generalFacade.updateEngagementStartDate(engagementStartDate);
    });
  }

  updateEngagementEndDate() {
    this.engagementEndDate$.subscribe((engagementEndDate) => {
      this.generalFacade.updateEngagementEndDate(engagementEndDate);
    });
  }

  getWorkshopStatus(): void {
    this.subscriptions.add(
      this.workshopStatus$.subscribe((workshopStatus) => {
        this.currentWorkshopStatus = workshopStatus;
      })
    );
  }

  private removeSearchResults(searchResults: string[], state): {} {
    const stateClone = clone(state);
    searchResults.forEach((searchResult) => {
      stateClone[searchResult] = null;
    });
    return stateClone;
  }

  public resetAllTabs() {
    this.generalFacade.updatePrepopulated(false);
    this.consultationFacade.updatePrepopulated(false);
    this.recoveryFacade.updatePrepopulated(false);
    this.adminFacade.updateDocumentPrepopulated(false);
    this.workshopService.setSelectedWorkshopID(null);
  }

  public clearAllStateObjects(): void {
    this.generalFacade.clearStateObject();
    this.consultationFacade.clearStateObject();
    this.recoveryFacade.clearStateObject();
    this.adminFacade.updatedDocument([]);
  }

  public createRequest() {
    const generalTab = this.workshopGeneral
      ? this.getUpToDateGeneralTab()
      : this.getNewGeneralTabData();
    const consultationTab = cloneDeep(this.getUpToDateConsultationTab());
    if (consultationTab.workshop) {
      delete consultationTab.workshop;
    }
    consultationTab.createBy = this.workshopConsultation.createBy;
    consultationTab.modifyBy = sessionStorage.getItem('userId');
    consultationTab.updatedAt = new Date().toISOString();
    const recoveryTab = this.workshopRecovery
      ? this.getUpToDateRecoveryTab()
      : this.getNewRecoveryTab();
    const documentTab = this.getUpToDateDocumentTab();
    const workshop = cloneDeep(this.getUpToDateWorkshop());
    workshop.program = sessionStorage.getItem('programId');
    workshop.oldStatus = sessionStorage.getItem('oldStatus');
    workshop.document = documentTab;
    const request = {
      general: generalTab,
      consultation: consultationTab,
      recovery: recoveryTab,
      workshop: workshop,
    };
    return request;
  }

  public getNewGeneralTabData() {
    return this.checkGeneralProgramSpecialValues(
      this.formatGeneralTab(this.generalFacadeStateObject, true)
    );
  }

  public getNewRecoveryTab() {
    return this.formatRecoveryTab(this.recoveryFacadeStateObject, true);
  }

  public createCloneWorkshopRequest() {
    const formatedGeneralTab = this.formatGeneralTab(
      this.generalFacadeStateObject,
      true
    );
    const generalTab = this.checkGeneralProgramSpecialValues(
      formatedGeneralTab
    );
    const consultationTab = this.consultationTabIsPrepopulated
      ? cloneDeep(
          this.formatConsultationTab(this.consultationFacadeStateObject, true)
        )
      : cloneDeep(this.cloneWorkshop.consultation);
    const formatedRecoveryTab = this.recoveryTabIsPrepopulated
      ? this.formatRecoveryTab(this.recoveryFacadeStateObject, true)
      : cloneDeep(this.cloneWorkshop.recovery) ||
        this.formatRecoveryTab(this.recoveryFacadeStateObject, true);
    const documnetTab = this.formatDocumentTab(
      cloneDeep(this.documentFacadeStateObject)
    );
    consultationTab.createBy = sessionStorage.getItem('userId');
    consultationTab.modifyBy = sessionStorage.getItem('userId');
    consultationTab.updatedAt = new Date().toISOString();
    const fiscalYearDate = generalTab.engagementEndDate;
    const recoveryTab = formatedRecoveryTab;
    this.deleteTabIdAndWorkshop(generalTab);
    this.deleteTabIdAndWorkshop(consultationTab);
    this.deleteTabIdAndWorkshop(recoveryTab);
    const request = {
      general: generalTab,
      consultation: consultationTab,
      recovery: recoveryTab,
      workshop: {
        program: sessionStorage.getItem('programId'),
        status:
          this.currentWorkshopStatus &&
          this.currentWorkshopStatus.id &&
          Number(this.currentWorkshopStatus.id) !== 1
            ? this.currentWorkshopStatus
            : 1,
        softLockExpirationDate: this.addSoftlockDate(),
        fiscalYear: this.setFiscalYear(fiscalYearDate),
        document: documnetTab,
      },
    };
    return request;
  }

  deleteTabIdAndWorkshop(tabData) {
    // eslint-disable-next-line no-prototype-builtins
    if (tabData.hasOwnProperty('workshop')) {
      delete tabData.workshop;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (tabData.hasOwnProperty('id')) {
      delete tabData.id;
    }
  }

  public createNewWorkshopRequest() {
    const formatedGeneralTab = this.formatGeneralTab(
      this.generalFacadeStateObject,
      true
    );
    const generalTab = this.checkGeneralProgramSpecialValues(
      formatedGeneralTab
    );
    const consultationTab = cloneDeep(
      this.formatConsultationTab(this.consultationFacadeStateObject, true)
    );
    const documnetTab = this.formatDocumentTab(
      cloneDeep(this.documentFacadeStateObject)
    );
    consultationTab.createBy = sessionStorage.getItem('userId');
    consultationTab.modifyBy = sessionStorage.getItem('userId');
    consultationTab.updatedAt = new Date().toISOString();
    const formatedRecoveryTab = this.formatRecoveryTab(
      this.recoveryFacadeStateObject,
      true
    );
    const fiscalYearDate = generalTab.engagementEndDate;
    const recoveryTab = formatedRecoveryTab;
    const request = {
      general: generalTab,
      consultation: consultationTab,
      recovery: recoveryTab,
      workshop: {
        program: sessionStorage.getItem('programId'),
        status:
          this.currentWorkshopStatus.id &&
          Number(this.currentWorkshopStatus.id) !== 1
            ? this.currentWorkshopStatus
            : 1,
        softLockExpirationDate: this.addSoftlockDate(),
        fiscalYear: this.setFiscalYear(fiscalYearDate),
        document: documnetTab,
      },
    };
    return request;
  }

  public setFiscalYear(fiscalYearDate) {
    const fiscalYear = fiscalYearDate ? new Date(fiscalYearDate) : new Date();
    let currentFY = '';
    const month = Number(fiscalYear.getMonth()) + 1;
    const year =
      month < 9
        ? Number(fiscalYear.getFullYear())
        : Number(fiscalYear.getFullYear()) + 1;
    currentFY = 'FY' + year.toString().substr(-2);
    return currentFY;
  }

  private addSoftlockDate() {
    const today = startOfToday();
    const weekFromToday = add(today, { weeks: 1 });
    const formattedDate = this.dateFormatService.getDateToSave(
      weekFromToday.toISOString()
    );
    return formattedDate;
  }

  private getUpToDateWorkshop() {
    if (
      this.workshopGeneral === undefined ||
      this.workshopRecovery === undefined
    ) {
      return this.workshopConsultation.workshop;
    } else
      return {
        ...this.workshopGeneral.workshop,
        status:
          this.currentWorkshopStatus || this.workshopGeneral.workshop.status,
      };
  }

  // check the workshop against the facade object. if they're not equal, then use the facade object.
  /**
   * Check the workshop object from the DB against the
   * general tab state object from the FE
   * if they are equal, then use the DB response
   * if they're not, then use the FE response
   */
  private getUpToDateGeneralTab() {
    const hasChange = this.generalTabHasUnsavedChanges();
    if (this.generalTabIsPrepopulated && hasChange) {
      const formatedGengeralTab = this.formatGeneralTab(
        this.generalFacadeStateObject,
        false
      );
      return this.checkGeneralProgramSpecialValues(formatedGengeralTab);
    } else {
      const GeneralObject = cloneDeep(this.workshopGeneral);
      this.deleteWorkshop(hasChange, GeneralObject);
      return this.checkGeneralProgramSpecialValues(GeneralObject);
    }
  }

  public deleteWorkshop(Ischange, genObj) {
    if (Ischange) {
      delete genObj.workshop;
    }
  }

  private readonly maplookupChip = (ele) => ele.chip;

  private generalTabHasUnsavedChanges() {
    // 0 index workshop
    // 1 index state object
    return ![
      ['cancellation.reason.id', 'cancellationReason.id'],
      ['engagement.title', 'engagementTitle'],
      ['engagement.adminComments', 'adminComments'],
      ['engagement.type.id', 'engagementType.id'],
      ['engagement.tags', 'tags'],
      ['engagement.summary', 'engagementSummary'],
      ['schedule.dates', 'dates'],
      ['contactDetails.additionalContacts', 'additionalContacts'], // map 1
      ['contactDetails.engagementSponsor', 'engagementSponsors'], // map 1 [0]
      ['contactDetails.primaryPointOfContact', 'primaryContacts'], // map 1 [0]
      ['contactDetails.additionalContacts', 'additionalContacts'], // map 1
      ['engagement.group.id', 'engagementGroup.id'],
      ['engagement.lead', 'engagementLead'], // map 1
      ['engagement.engagement', 'engagementTeam'],
      ['engagement.engagementStartDate', 'engagementStartDate'],
      ['engagement.engagementEndDate', 'engagementEndDate'],
    ].every(([workshopProp, stateProp]) => {
      let stateValue;
      const workshopValue = get(this.workshopGeneral, workshopProp, undefined);

      if (stateProp.includes('.')) {
        stateValue = get(this.generalFacadeStateObject, stateProp, undefined);
      } else {
        stateValue = this.generalFacadeStateObject[stateProp];
      }

      // handle mapping and flattening an array where necessary
      if (typeof workshopValue == 'string' && Array.isArray(stateValue)) {
        stateValue = stateValue.map(this.maplookupChip)[0];
      } else if (
        Array.isArray(workshopValue) &&
        Array.isArray(stateValue) &&
        stateProp !== 'tags'
      ) {
        stateValue = stateValue.map(this.maplookupChip);
      }

      return isEqual(workshopValue, stateValue);
    });
  }

  private getUpToDateConsultationTab() {
    if (
      this.consultationTabIsPrepopulated &&
      this.consultationTabHasUnsavedChanges()
    ) {
      return this.formatConsultationTab(
        this.consultationFacadeStateObject,
        false
      );
    } else {
      return this.workshopConsultation;
    }
  }

  private consultationTabHasUnsavedChanges(): boolean {
    // 0 index workshop
    // 1 index state object
    return ![
      ['internalPurpose.value', 'selectedInternalPurpose.value'],
      ['opportunities', 'selectedOpportunityIds'],
      ['duration', 'duration'],
      ['visitType', 'visitType'],
      ['softLockExpirationDate', 'softLockExpirationDate'],
      ['consultationOwner', 'consultationOwner'],
      ['completeDate', 'completeConsultationDate'],
      ['backgroundText', 'backgroundText'],
      ['objectivesText', 'objectivesText'],
      ['additionalComments', 'additionalComments'],
      ['accentureAttendees', 'accentureAttendees'],
      ['clientOtherAttendees', 'clientCSuiteAttendees'],
      ['otherAttendees', 'otherAttendees'],
      ['totalAttendees', 'totalAttendees'],
      ['leadershipLevel', 'leadershipLevel'],
      ['attendeeDetails', 'attendeeDetails'],
    ].every(([workshopProp, stateProp]) => {
      let workshopValue, stateValue;
      workshopValue = get(this.workshopConsultation, workshopProp, undefined);
      workshopValue = this.formatArrayValue(workshopValue);
      stateValue = get(
        this.consultationFacadeStateObject,
        stateProp,
        undefined
      );
      stateValue = this.formatArrayValue(stateValue);
      return isEqual(workshopValue, stateValue);
    });
  }

  formatArrayValue(value) {
    let result = value;
    if (Array.isArray(result)) {
      result = result.length !== 0 ? this.formatId(result) : null;
    }
    return result;
  }

  formatId(item) {
    return item.map((v) => v.id);
  }

  private recoveryTabHasUnsavedChanges() {
    // 0 index workshop
    // 1 index state object
    return ![
      ['recoveryTotal', 'recoveryTotal'],
      ['passThroughEstimate', 'passThroughEstimate'],
      ['totalDiscounts', 'totalDiscounts'],
      ['chargeDate', 'chargeDate'],
      ['comments', 'recoveryComments'],
      ['chargeCodes', 'wbsCodes'],
      ['costs', 'recoveryEntries'],
    ].every(([workshopProp, stateProp]) => {
      return isEqual(
        this.workshopRecovery[workshopProp],
        this.recoveryFacadeStateObject[stateProp]
      );
    });
  }

  private getUpToDateRecoveryTab() {
    if (this.recoveryTabIsPrepopulated && this.recoveryTabHasUnsavedChanges()) {
      var formatedObject = this.formatRecoveryTab(
        this.recoveryFacadeStateObject,
        false
      );
      return formatedObject;
    } else {
      return this.workshopRecovery;
    }
  }

  // check the workshop against the facade object. if they're not equal, then use the facade object.
  /**
   * Check the workshop object from the DB against the
   * general tab state object from the FE
   * if they are equal, then use the DB response
   * if they're not, then use the FE response
   */
  public getUpToDateDocumentTab() {
    if (this.documentTabIsPrepopulated && this.documentTabHasUnsavedChanges()) {
      const documnetTab = this.formatDocumentTab(
        cloneDeep(this.documentFacadeStateObject)
      );
      return documnetTab;
    } else {
      return this.workshopDocument;
    }
  }

  private documentTabHasUnsavedChanges() {
    // 0 index workshop
    // 1 index state object
    const workshopDocumentArr = this.workshopDocument.map((item) => {
      const document = {
        id: item.id,
        fileName: item.fileName,
        s3Key: item.s3Key,
        uploadedDate: item.uploadedDate,
        uploadedBy: item.uploadedBy,
        comments: item.comments,
      };
      return document;
    });
    const stateDocumentArr = this.documentFacadeStateObject.map((item) => {
      const document = {
        id: item.id,
        fileName: item.fileName,
        s3Key: item.s3Key,
        uploadedDate: item.uploadedDate,
        uploadedBy: item.uploadedBy,
        comments: item.comments,
      };
      return document;
    });
    return !isEqual(workshopDocumentArr, stateDocumentArr);
  }

  private formatGeneralTab(generalTab: GeneralState, isNewRequest: boolean) {
    const formattedGeneralTabClone = this.removeSearchResults(
      [
        'ids',
        'entities',
        'additionalContactSearchResults',
        'clientOrganizationSearchResults',
        'engagementGroupResults',
        'engagementGroupEcosystemResults',
        'engagementLeadSearchResults',
        'engagementSponsorSearchResults',
        'engagementStatusResults',
        'engagementTeamMemberSearchResults',
        'engagementTypeResults',
        'engagementTeamRoles',
        'datesAndLocationsRooms',
        'organizationAccentureEntitiesResults',
        'organizationExternalCategoriesResults',
        'organizationTypes',
        'primaryContactSearchResults',
        'tagsSearchResults',
      ],
      generalTab
    ) as any;
    return isNewRequest
      ? formattedGeneralTabClone
      : this.mapGeneralOneToOneIds(formattedGeneralTabClone);
  }

  private mapGeneralOneToOneIds(generalObject) {
    return {
      id: this.workshopGeneral.id,
      contactDetails: {
        id: this.workshopGeneral.contactDetails.id,
      },
      engagement: {
        id: this.workshopGeneral.engagement?.id,
      },
      schedule: {
        id: this.workshopGeneral.schedule?.id,
      },
      cancellation: {
        id: this.workshopGeneral.cancellation?.id,
      },
      ...cloneDeep(generalObject),
    };
  }

  private mapRecoveryOneToOneIds(recoveryObject) {
    return {
      id: this.workshopRecovery.id,
      ...cloneDeep(recoveryObject),
    };
  }

  private mapConsultationOneToOneIds(consultationObject) {
    return {
      id: this.workshopConsultation.id,
      ...cloneDeep(consultationObject),
    };
  }

  private formatConsultationTab(
    consultationTab: ConsultationState,
    isNewRequest: boolean
  ) {
    const formattedConsultationTabClone = this.removeSearchResults(
      [
        'ids',
        'entities',
        'consultationOwnerSearchResults',
        'internalPurposeResults',
        'opportunityIdsSearchResults',
        'purposeOptions',
      ],
      consultationTab
    ) as any;
    formattedConsultationTabClone.selectedOpportunityIds = this.formatOpportunityIds(
      formattedConsultationTabClone.selectedOpportunityIds
    );
    return isNewRequest
      ? formattedConsultationTabClone
      : this.mapConsultationOneToOneIds(formattedConsultationTabClone);
  }

  private formatOpportunityIds(opportunityIds: any[]) {
    return opportunityIds?.map((opp) => {
      return {
        id: this.lookupService.getID(opp.chip),
        description: this.lookupService.getDescription(opp.chip),
      };
    });
  }

  private formatRecoveryTab(recoveryTab: RecoveryState, isNewRequest: boolean) {
    const formattedRecoveryTabClone = this.removeSearchResults(
      ['ids', 'entities'],
      recoveryTab
    ) as any;
    return isNewRequest
      ? formattedRecoveryTabClone
      : this.mapRecoveryOneToOneIds(formattedRecoveryTabClone);
  }

  public formatDocumentTab(documentTab) {
    documentTab.map((item) => delete item.uploadParamsList);
    documentTab.map(
      (item) =>
        (item.uploadedDate = this.dateFormatService.getDateToSave(
          item.uploadedDate
        ))
    );
    return documentTab;
  }

  public checkGeneralProgramSpecialValues(generalTab: any) {
    const checkedGeneralObject = cloneDeep(generalTab);
    var emptyObject = { id: '', value: '' };
    var dates =
      (checkedGeneralObject.datesAndLocations &&
        checkedGeneralObject.datesAndLocations.dates) ||
      (checkedGeneralObject.schedule && checkedGeneralObject.schedule.dates);
    if (!!dates && dates.length > 0) {
      var locationRoomValues = this.generalFacadeStateObject[
        'datesAndLocationsRooms'
      ];
      for (var i = 0; i < dates.length; i++) {
        const location = dates[i].location;
        if (!!location && !!location.value) {
          const matchRoom = locationRoomValues.filter((room) => {
            return room.value == location.value;
          });
          switch (matchRoom.length) {
            case 0:
              dates[i].location = emptyObject;
              break;
            case 1:
              dates[i].location = matchRoom[0];
              break;
          }
        }
      }
    }

    var engagementTeam =
      checkedGeneralObject.engagementTeam ||
      (checkedGeneralObject.engagement && checkedGeneralObject.engagement.team);
    if (!!engagementTeam && engagementTeam.length > 0) {
      var engagementRoleValues = this.generalFacadeStateObject[
        'engagementTeamRoles'
      ];
      for (var z = 0; z < engagementTeam.length; z++) {
        if (!!engagementTeam[z].role && !!engagementTeam[z].role.value) {
          const matchRole = engagementRoleValues.filter((role) => {
            return role.value == engagementTeam[z].role.value;
          });
          switch (matchRole.length) {
            case 0:
              engagementTeam[z].role = emptyObject;
              break;
            case 1:
              engagementTeam[z].role = matchRole[0];
              break;
          }
        }
      }
    }
    return checkedGeneralObject;
  }
}
