import { HttpService } from '../http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../ioh/apps/ioh-app/src/environments';

@Injectable({
  providedIn: 'root',
})
export class RecoveryService {
  constructor(private readonly http: HttpService) {}

  updateRecoveryEntries(cost: any): Observable<any> {
    const url = `${environment.endpoints.recovery.updateRecoveryEntries}`;
    return this.http.post(url, cost);
  }
}
