import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { ConsultationFacade } from '@ioh/core-data';
import { Observable, Subscription } from 'rxjs';
import { mmsRadio, noCampaignMmsRadio } from '@assets/data/index';
import { tooltipConst } from '@assets/data/index';
@Component({
  selector: 'ioh-details-engagement',
  styleUrls: ['./details-engagement.component.scss'],
  templateUrl: './details-engagement.component.html',
})
export class DetailsEngagementComponent implements OnInit {
  @Input() textareas: any;
  @Input() lookupGroupFieldName: string;
  @Input() lookupArrayFieldName: string;
  @Input() lookupInputFieldName: string;
  @Input() dropdownOptionsPurpose: any;
  @Input() opportunityLimit: number;
  @Input() opportunityResults: Observable<any>;
  @Input() opportunityLoading: Observable<any>;
  @Input() noCampaign: boolean = false;
  @Input() isDisabled: boolean = false;

  private readonly subscriptions: Subscription = new Subscription();
  parentForm: AbstractControl;
  mmsRadio: any;
  mmsTooltipObj: any = {};
  engagementPurposeTooltipObj: any = {};
  mmsInputValue: any;
  constructor(
    private readonly parentControl: ControlContainer,
    private readonly consultationFacade: ConsultationFacade
  ) {}

  ngOnInit() {
    this.parentForm = this.parentControl.control;
    this.mmsTooltipObj = tooltipConst.mmsOppTooltipObj;
    this.mmsRadio = this.noCampaign ? noCampaignMmsRadio : mmsRadio;
    this.requiredHint();
    this.subscriptions.add(
      this.consultationFacade.mmsOption$.subscribe(() => {
        this.requiredHint();
      })
    );
    this.parentForm
      .get('noMmsOpportunityId')
      .valueChanges.subscribe((mmsOption) => {
        if (mmsOption == mmsRadio[0].values[2]) {
          this.requiredHint();
        }
      });
    this.parentForm
      .get('opportunityInput')
      .get('oppResult')
      .valueChanges.subscribe((mmsInput) => {
        this.requiredHint();
      });
  }

  requiredHint() {
    const mmsOption = this.parentForm.get('noMmsOpportunityId').value;
    const mmsInput = this.parentForm.get('opportunityInput').get('oppResult')
      .value;
    if (mmsOption == mmsRadio[0].values[2] && mmsInput.length < 1) {
      this.parentForm.get('opportunityInput').get('oppResult').markAsTouched();
    } else if (
      (mmsOption == mmsRadio[0].values[0] ||
        mmsOption == mmsRadio[0].values[1]) &&
      mmsInput.length > 0
    ) {
      this.parentForm.get('noMmsOpportunityId').setValue('');
    }
    this.mmsInputValue = this.mmsHasValue(mmsInput);
  }

  // get oppResult() {
  //   return (
  //     this.parentForm.get('opportunityInput').get('oppResult').value.length > 0
  //   );
  // }

  get noMmsOpportunityId() {
    if (
      this.parentForm.get('noMmsOpportunityId').value ==
        mmsRadio[0].values[0] ||
      this.parentForm.get('noMmsOpportunityId').value == mmsRadio[0].values[1]
    ) {
      return !!this.parentForm.get('noMmsOpportunityId').value;
    } else if (
      this.parentForm.get('noMmsOpportunityId').value == mmsRadio[0].values[2]
    ) {
      return !this.parentForm.get('noMmsOpportunityId').value;
    }
  }

  mmsHasValue(mmsInput) {
    if (mmsInput.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
