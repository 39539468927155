<div class="comments">
  <ioh-text-area
    fxFlex="60%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    label="Additional Comments"
    name="comments"
    textareaId="comments"
    placeholder="Is there anything else you want us to know about this request?"
    [formGroup]="myGroup"
    value="comments"
    maxCharLimit="1000"
    description="comments-description"
    height="4rem"
  ></ioh-text-area>
</div>

<mat-divider></mat-divider>
