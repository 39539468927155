export const feedbackQuestions = [
  {
    id: 1,
    value:
      'Overall, how satisfied were you with your experience at the programNeedtoReplace Innovation Hub?',
    anwser: '',
  },
  {
    id: 2,
    value:
      'How likely are you to return to the programNeedtoReplace Innovation Hub with your client?',
    anwser: '',
  },
  {
    id: 3,
    value:
      "How satisfied were you with the Hub Team's planning, preparation, and execution of your event?",
    anwser: '',
  },
  {
    id: 4,
    value:
      'How satisfied were you with the physical/virtual experience of your event?',
    anwser: '',
  },
  {
    id: 5,
    value:
      "How satisfied were you with the event outcome and the ability to enhance the client's perception of Accenture?",
    anwser: '',
  },
];

export const feedbackReportColumns = {
  listViewId: 'Engagement ID',
  programCode: 'Program',
  feedbackEID: 'Enterprise ID',
  title: 'Engagement Title',
  org: 'Organization Name',
  feedbackDate: 'Feedback Date',
  q1Answer:
    '1. Overall, how satisfied were you with your experience at the Innovation Hub?',
  q2Answer:
    '2. How likely are you to return to the Innovation Hub with your client?',
  q3Answer:
    "3. How satisfied were you with the Hub Team's planning, preparation, and execution of your event?",
  q4Answer:
    '4. How satisfied were you with the physical/virtual experience of your event?',
  q5Answer:
    "5. How satisfied were you with the event outcome and the ability to enhance the client's perception of Accenture?",
  comments:
    'Please share any additional feedback that you have for the Innovation Hub team. Thank you!',
};

export const commentsTitle =
  'Please share any additional feedback that you have for the programNeedtoReplace Innovation Hub team. Thank you!';
