import { HttpService } from '../http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../ioh/apps/ioh-app/src/environments';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private readonly http: HttpService) {}

  async getExcelReport(exportProgramList: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${environment.endpoints.report.getFullReport}${exportProgramList}`;
      this.http.get(url).subscribe((response) => {
        resolve(response);
      });
    });
  }
}
