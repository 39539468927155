import {
  RECOVERY_FEATURE_KEY,
  RecoveryPartialState,
  RecoveryState,
} from './recovery.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'Recovery' feature state managed by NgRx
export const getRecoveryState = createFeatureSelector<
  RecoveryPartialState,
  RecoveryState
>(RECOVERY_FEATURE_KEY);

export const getRecoveryLoaded = createSelector(
  getRecoveryState,
  (state: RecoveryState) => state.loaded
);

export const getCurrencyDropdown = createSelector(
  getRecoveryState,
  (state: RecoveryState) => state.currencyDropdown
);

export const getPrepopulated = createSelector(
  getRecoveryState,
  (state: RecoveryState) => state.prepopulated
);

export const getAllWBSSearchResults = createSelector(
  getRecoveryState,
  (state: RecoveryState) => state.wbsCodeResults
);

export const getRecoveryError = createSelector(
  getRecoveryState,
  (state: RecoveryState) => state.error
);

export const getExchangeRate = createSelector(
  getRecoveryState,
  (state: RecoveryState) => state.exchangeRate
);

export const updateStatusSuccessfully = createSelector(
  getRecoveryState,
  (state: RecoveryState) => state.updatedRecoveryStatusSuccessfully
);

export const updatedRecoveryStatusResponse = createSelector(
  getRecoveryState,
  (state: RecoveryState) => state.updatedRecoveryStatusResponse
);

export const updateStatusError = createSelector(
  getRecoveryState,
  (state: RecoveryState) => state.updatedRecoveryStatusError
);
