import { Component, Input } from '@angular/core';
@Component({
  selector: 'ioh-tooltip',
  styleUrls: ['./tooltip.component.scss'],
  templateUrl: './tooltip.component.html',
})
export class TooltipComponent {
  tooltipObj: any;
  showTooltip: boolean;
  @Input() detail: any;

  mouseOver() {
    this.showTooltip = !this.showTooltip;
  }
}
