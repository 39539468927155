import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  // Get the date to be prepopulated without timezone browser conversion
  public getDateToPrepopulate(originalDate: string): Date {
    if (originalDate) {
      if (originalDate.includes('/')) {
        originalDate = this.forMatoriginalDate(originalDate);
      }
      const newDate = new Date(originalDate);
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
      return newDate;
    }
  }

  public forMatoriginalDate(originalDate) {
    const newDate = originalDate.split('/');
    const dateMonth = newDate[0];
    const dateDay = newDate[1];
    const dateYear = newDate[2];
    const NewOriginalDate = [];
    NewOriginalDate[0] = dateYear;
    NewOriginalDate[1] = dateMonth;
    NewOriginalDate[2] = dateDay;
    return NewOriginalDate.join('-');
  }

  public getTimestampToPrepopulate(originalDate) {
    if (originalDate) {
      return originalDate;
    }
  }

  public getMorningTimestampDatePrepopulate(data) {
    let res;
    if (data) {
      res = moment(data).toDate();
      res = new Date(res).setHours(0, 0, 0, 0);
      res = new Date(res);
      // res = format(res, 'MM/dd/yyyy');
    } else {
      res = '';
    }
    return res;
  }

  public getDateToSave(originalDate: string): string {
    if (originalDate) {
      const date = new Date(originalDate);
      const dateToSave = this.formatDateToSave(date);
      return dateToSave;
    }
  }

  private formatDateToSave(date: Date): string {
    const monthString = (date.getMonth() + 1).toString();
    const dayString = date.getDate().toString();

    const day = dayString.length < 2 ? `0${dayString}` : dayString;
    const month = monthString.length < 2 ? `0${monthString}` : monthString;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  public timeToDate(data, time) {
    if (data) {
      const hours: number = Number(time.split(':')[0]);
      const minutes: number = time.split(':')[1]
        ? Number(time.split(':')[1])
        : 0;
      const date = new Date(data);
      date.setHours(0, 0, 0, 0);
      date.setHours(date.getHours() + hours);
      date.setMinutes(date.getMinutes() + minutes);
      return date;
    } else {
      return null;
    }
  }

  public addOneDay(data) {
    if (data) {
      const date = new Date(data);
      const hours: number = Number(new Date(data).getHours()) + 24;
      date.setHours(0, 0, 0, 0);
      date.setHours(date.getHours() + hours);
      return date;
    } else {
      return null;
    }
  }
}
