<div
    fxFlex
    fxLayout="column"
    class="organisation__section"
    [ngClass]="browser()"
    [formGroup]="parentOrganizationsFormArray"
    >
    <div
        fxFlex
        fxLayout="row"
        fxLayout.sm="column"
        fxLayout.xs="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.sm="space-between stretch"
        fxLayoutAlign.xs="space-between stretch"
    >
        <ioh-dropdown
            class="organisation__dropdown hasTooltipIcon"
            fxFlex="25%"
            label="{{organizationTypeElemName.label}}"
            dropdownId="{{organizationTypeElemName.dropdownId}}"
            value={{organizationTypeElemName.value}}
            [formGroup]="dropDownSelectedTypeOfOrganization"
            isMultiple = "{{organizationTypeElemName.isMultiple}}"
            [values] = "organizationTypes$"
            field = "{{organizationTypeElemName.field}}"
            [required] = "organizationTypeElemName.required"
            [isDisabled]="isDisabled"
            placeholder = "{{organizationTypeElemName.placeholder}}"
            [tooltipObj] = "orgTooltipObj"
            name = "{{organizationTypeElemName.name}}"
            direction = "column"
        ></ioh-dropdown>
        <button
          mat-flat-button
          color="primary"
          class="organisation__add"
          ngClass.sm="organisation__add__small__device"
          ngClass.xs="organisation__add__small__device"
          [disabled]="!dropDownSelectedTypeOfOrganization.getRawValue().value"
          (click)="addOrganization()"
        >
          <span class="material-icons">
            add
          </span>
        </button>
    </div>
    <ioh-error *ngIf="(dropDownSelectedTypeOfOrganization.get('value').touched || dropDownSelectedTypeOfOrganization.get('value').dirty) && parentOrganizationsFormArray.value.length < 1" errorMsg="You must add an organization"></ioh-error>
    <ioh-list-view
      tableName="Organization"
      *ngIf="formattedTableData$"
      [allowReroute]=false
      [colNames]="colNames"
      [data$]="formattedTableData$"
      (onEdit)="editEntry($event)"
      (onDelete)="deleteEntry($event)"
      colour="#f2f2fc"
      [noNeedSpinner] = true
      [isDisabled]="isDisabled"
    ></ioh-list-view>
      <ioh-list-view
      tableName="Organization"
      [hidden]=!orgHistoryTable
      [allowReroute]=true
      [colNames]="historyColNames"
      [data$]="orgHistoryListview$"
      [orgHistory] = true
      [noNeedSpinner] = true
      [noFilter] = true
      openInNewTab = true
      colour="#f2f2fc"
    ></ioh-list-view>
</div>
<ng-template #OrgsTemplate>
  <h2 class="modal__title" role="banner" tabindex="0" mat-dialog-title>Organization Details</h2>
  <mat-dialog-content role="contentinfo" class="mat-typography">
    <ng-container [formGroup]="formGroupForTemplate">
      <ioh-internal [accentureEntitiesResults$]="accentureEntitiesResults$" *ngIf="isInternalOrg()"></ioh-internal>
      <ioh-external [externalOrganizationsCategories$]="externalOrganizationsCategories$" *ngIf="isExternalOrg()"></ioh-external>
      <ioh-client *ngIf="isClientOrg()"></ioh-client>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" role="navigation">
    <button class="modal__save" mat-flat-button color="primary" (click)="saveOrUpdate()">
      <span class="material-icons">
        save
      </span>
      <span>
        Save
      </span>
    </button>
  </mat-dialog-actions>
</ng-template>
