import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import _ from 'lodash';
import _get from 'lodash/get';
import moment from 'moment';
import uniq from 'lodash/uniq';
@Injectable({
  providedIn: 'root',
})
export class TableService {
  private readonly DATE_FORMAT: string = 'MM/dd/yyyy';
  public formatDate = (date: string): string => {
    const newDate = new Date(date);
    const formatedISODate = this.getUTCDateISOFormat(newDate.toISOString());
    return format(new Date(formatedISODate), this.DATE_FORMAT);
  };

  public getUTCDateISOFormat(originalDate: string): string {
    if (originalDate) {
      const newDate = new Date(originalDate);
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
      const isoDate = newDate.toISOString();
      return isoDate;
    }
  }

  public tableHandle(data) {
    return data && data.length > 0 ? this.flattenAndFormatWorkshops(data) : [];
  }

  public recoveryTableHandle(
    data,
    recoveryEntryIdList,
    sortName: any,
    sortDirection: any
  ) {
    const dataSource = this.filterRecoveryEntryById(
      this.formatRecoveryEntries(this.flattenAndFormatWorkshops(data)),
      recoveryEntryIdList
    );
    let sortData = dataSource;
    sortData = this.sortByRecovery(sortName, sortDirection, sortData);
    return data && data.length > 0 ? sortData : [];
  }

  public sortByRecovery(sortName, sortDirection, sortData) {
    switch (sortName) {
      case 'chargeDate':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = !a.chargeDate ? null : new Date(a.chargeDate).getTime();
          value2 = !b.chargeDate ? null : new Date(b.chargeDate).getTime();
          if (sortDirection === 'asc') {
            return value1 - value2;
          } else {
            return value2 - value1;
          }
        });
        break;
      case 'listViewId':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = a.id;
          value2 = b.id;
          if (sortDirection === 'asc') {
            return value1 - value2;
          } else {
            return value2 - value1;
          }
        });
        break;
      case 'title':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = a.title === null || a.title === 'N/A' ? '' : a.title;
          value2 = b.title === null || b.title === 'N/A' ? '' : b.title;
          if (sortDirection === 'asc') {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        break;
      case 'status':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = a.status === null || a.status === 'N/A' ? '' : a.status;
          value2 = b.status === null || b.status === 'N/A' ? '' : b.status;
          if (sortDirection === 'asc') {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        break;
      case 'lead':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = a.lead === null || a.lead === 'N/A' ? '' : a.lead;
          value2 = b.lead === null || b.lead === 'N/A' ? '' : b.lead;
          if (sortDirection === 'asc') {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        break;
      case 'chargeStatus':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = a.chargeStatus === null ? '' : a.chargeStatus;
          value2 = b.chargeStatus === null ? '' : b.chargeStatus;
          if (sortDirection === 'asc') {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        break;
      case 'code':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = a.code === null ? '' : a.code;
          value2 = b.code === null ? '' : b.code;
          if (sortDirection === 'asc') {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        break;
      case 'org':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = !a.org ? '' : a.org;
          value2 = !b.org ? '' : b.org;
          if (sortDirection === 'asc') {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        break;
      case 'recoveryTotal':
        sortData = sortData.sort(function (a, b) {
          var minNum = -Number.MIN_VALUE;
          var value1, value2;
          value1 = a.total === null ? minNum : a.total;
          value2 = b.total === null ? minNum : b.total;
          if (sortDirection === 'asc') {
            return value1 - value2;
          } else {
            return value2 - value1;
          }
        });
        break;
    }
    return sortData;
  }

  public sortByEvent(isAll, sortName, sortDirection, sortData) {
    switch (sortName) {
      case 'null':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = new Date(a.date).getTime();
          value2 = new Date(b.date).getTime();
          if (isAll) {
            return value2 - value1;
          } else {
            return value1 - value2;
          }
        });
        break;
      case 'eventStartTime':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = a.startTime === null ? '' : a.startTime;
          value2 = b.startTime === null ? '' : b.startTime;
          if (sortDirection === 'asc') {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        break;
      case 'eventEndTime':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = a.endTime === null ? '' : a.endTime;
          value2 = b.endTime === null ? '' : b.endTime;
          if (sortDirection === 'asc') {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        break;
      case 'eventDate':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = new Date(a.date).getTime();
          value2 = new Date(b.date).getTime();
          if (sortDirection === 'asc') {
            return value1 - value2;
          } else {
            return value2 - value1;
          }
        });
        break;
      case 'title':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = !a.title ? '' : a.title;
          value2 = !b.title ? '' : b.title;
          if (sortDirection === 'asc') {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        break;
      case 'org':
        sortData = sortData.sort(function (a, b) {
          var value1, value2;
          value1 = !a.org ? '' : a.org;
          value2 = !b.org ? '' : b.org;
          if (sortDirection === 'asc') {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        break;
    }
    return sortData;
  }

  public filterRecoveryEntryById(recoveryEntries, recoveryEntryIdList) {
    return recoveryEntryIdList && recoveryEntries
      ? recoveryEntries.filter((recoveryEntry) =>
          recoveryEntryIdList.includes(recoveryEntry.costId)
        )
      : [];
  }

  public tableHandleByEventDate(
    isAll,
    data,
    eventDateIds: any[],
    sortName: any,
    sortDirection: any
  ) {
    const dataSource = this.addDownloadAction(
      this.formatEventDates(
        this.flattenAndFormatWorkshops(data)
      ).filter((item) =>
        eventDateIds.some((eventDateId) => item.dateId === eventDateId)
      )
    );
    let sortData = dataSource;
    sortData = this.sortByEvent(isAll, sortName, sortDirection, sortData);
    return data && data.length > 0 ? sortData : [];
  }

  public formatHour(value) {
    if (value) {
      const time = value.split(':');
      return `${time[0]}` + ':' + `${time[1]}`;
    }
  }

  formatToHours(data) {
    let res;
    if (data) {
      res = moment(data).format('HH:mm');
    } else {
      res = '';
    }
    return res;
  }

  formatToDate(data) {
    let res;
    if (data) {
      res = moment(data).toDate();
      res = new Date(res).setHours(0, 0, 0, 0);
      res = format(res, this.DATE_FORMAT);
    } else {
      res = '';
    }
    return res;
  }

  public timeToDate(data, time) {
    const hours: number = Number(time.split(':')[0]);
    const minutes: number = time.split(':')[1] ? Number(time.split(':')[1]) : 0;
    const date = new Date(data);
    date.setHours(0, 0, 0, 0);
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() + minutes);
    return date;
  }

  public addDownloadAction(datas) {
    return datas.map((data) => {
      data.action = 'downloadCSI';
      return data;
    });
  }

  formatToFY(data) {
    let currentFY = '';
    const date = new Date(data);
    const month = date.getMonth() + 1;
    let year = date.getFullYear();
    year = month < 9 ? year : year + 1;
    currentFY = 'FY' + year.toString().substr(-2);
    return currentFY;
  }

  formatFYToMonthYear(fiscalYear: string[], month: string[]) {
    const monthList: string[] = [];
    fiscalYear.forEach((fy) => {
      month.forEach((m) => {
        const monthYear =
          m == 'Sep' || m == 'Oct' || m == 'Nov' || m == 'Dec'
            ? `${m}` + ' 20' + `${+fy.replace(/[^\d]/g, '') - 1}`
            : `${m}` + ' 20' + `${+fy.replace(/[^\d]/g, '')}`;
        monthList.push(monthYear);
      });
    });
    return monthList;
  }

  formatLookupItem(value: string[], type: string = '') {
    const item = value.map((v) => {
      return { id: '', typeName: type, value: v };
    });
    return item;
  }

  flattenAndFormatOrgList(array: any[]) {
    return array.map((general) => {
      return {
        organizations: general.organizations ? general.organizations : [],
        title: _get(general, 'engagement.title', 'N/A'),
        engagementEndDate: _get(general, 'engagement.engagementEndDate', null)
          ? this.formatDate(_get(general, 'engagement.engagementEndDate', null))
          : null,
        program: _get(general, 'workshop.program.code', ''),
        lead: general.engagement?.lead ? general.engagement.lead : 'N/A',
        status: _get(general, 'workshop.status.value'),
        id: _get(general, 'workshop.id'),
      };
    });
  }

  flattenAndFormatWorkshops(array: any[]) {
    if (Array.isArray(array)) {
      return array
        .map((workshop) => {
          var newWorkshop = {
            feedback: workshop.feedback,
            ...workshop.consultation,
            ...workshop.general,
            ...workshop.recovery,
            ...workshop.consultation?.workshop.program,
          };
          newWorkshop.updatedAt = workshop.consultation?.updatedAt;
          return newWorkshop;
        })
        .map((workshop) => {
          return {
            modifyBy: _get(workshop, 'modifyBy', ''),
            updatedAt: _get(workshop, 'updatedAt', ''),
            attendees: _get(workshop, 'totalAttendees', ''),
            comments: _get(workshop, 'comments', 'N/A'),
            complete: workshop.completeDate
              ? this.formatDate(workshop.completeDate)
              : '',
            costs: _get(workshop, 'costs', []),
            expiration: workshop.softLockExpirationDate
              ? this.formatDate(workshop.softLockExpirationDate)
              : '',
            id: _get(workshop, 'workshop.id'),
            fiscalYear: _get(workshop, 'workshop.fiscalYear'),
            listViewId:
              `${_get(workshop, 'code')} - ` +
              `${_get(workshop, 'workshop.id')}`,
            lead: workshop.engagement?.lead ? workshop.engagement.lead : 'N/A',
            org: this.flattenOrganizations(workshop.organizations),
            internalPurpose: workshop.internalPurpose
              ? workshop.internalPurpose.value
              : 'N/A',
            organizationType: this.flattenOrganizationsType(
              workshop.organizations ? workshop.organizations : []
            ),
            organizations: workshop.organizations ? workshop.organizations : [],
            organizationTypes:
              workshop.organizations && workshop.organizations.length > 0
                ? this.flattenOrganizationsTypes(workshop.organizations)
                : 'N/A',
            orgClientName:
              workshop.organizations && workshop.organizations.length > 0
                ? this.flattenOrgClientName(workshop.organizations)
                : [],
            owner: _get(workshop, 'consultationOwner', 'N/A'),
            poc: _get(workshop, 'contactDetails.primaryPointOfContact', ''),
            status: _get(workshop, 'workshop.status.value'),
            team: this.mapEngagementTeam(_get(workshop, 'engagement.team', [])),
            title: _get(workshop, 'engagement.title', 'N/A'),
            engagementStartDate: _get(
              workshop,
              'engagement.engagementStartDate',
              null
            )
              ? this.formatDate(
                  _get(workshop, 'engagement.engagementStartDate', null)
                )
              : null,
            engagementEndDate: _get(
              workshop,
              'engagement.engagementEndDate',
              null
            )
              ? this.formatDate(
                  _get(workshop, 'engagement.engagementEndDate', null)
                )
              : null,
            total: _get(workshop, 'recoveryTotal', 'N/A'),
            totalCosts: _get(workshop, 'totalCosts', 'N/A'),
            type: _get(workshop, 'engagement.type.value', 'N/A'),
            wbs: this.flattenChargeCodes(workshop.costs),
            programId: _get(workshop, 'id', ''),
            programCode: _get(workshop, 'code', ''),
            groupOwner: _get(workshop, 'engagement.group.value', ''),
            tags: this.flattenTags(_get(workshop, 'engagement.tags', [])),
            eventDate: _get(workshop, 'schedule.dates', []),
            feedback: _get(workshop, 'feedback', ''),
            visitType: _get(workshop, 'visitType', ''),
          };
        });
    }
  }

  formatRecoveryEntries(data) {
    const recoveryEntriesData = [];
    if (Array.isArray(data)) {
      data.map((item) => {
        if (item.costs && item.costs.length > 0) {
          item.costs.map((entries) => {
            const cloneEntries = _.cloneDeep(entries);
            if (!cloneEntries.costId) {
              cloneEntries.costId = cloneEntries.id;
            }
            cloneEntries.id = item.id;
            if (
              cloneEntries.chargeDate &&
              cloneEntries.chargeDate.includes('/')
            ) {
              cloneEntries.chargeDate = this.forMatoriginalDate(
                cloneEntries.chargeDate
              );
            }
            cloneEntries.currency =
              cloneEntries.currency && cloneEntries.currency.value
                ? cloneEntries.currency.value
                : 'USD';
            cloneEntries.listViewId = item.listViewId;
            cloneEntries.chargeDate = cloneEntries.chargeDate
              ? this.formatDate(cloneEntries.chargeDate)
              : '';
            cloneEntries.title = item.title;
            cloneEntries.org = item.org;
            cloneEntries.totalRecoveryOrg = item.org.split(',').join(';');
            cloneEntries.engagementstartdate = item.engagementStartDate;
            cloneEntries.engagementEndDate = item.engagementEndDate;
            cloneEntries.status = item.status;
            cloneEntries.lead = item.lead;
            cloneEntries.team = item.team;
            cloneEntries.costElement = 900101;
            cloneEntries.isValid = cloneEntries.isValid ? 'Valid' : 'Invalid';
            cloneEntries.total = cloneEntries.subTotal
              ? cloneEntries.subTotal
              : this.calculateSubTotal(cloneEntries);
            cloneEntries.recoveryTotal = cloneEntries.subTotal
              ? this.formatIntegerCurrency(
                  +cloneEntries.subTotal,
                  cloneEntries.currency
                ).replace(/[a-zA-Z]/g, '')
              : null;
            cloneEntries.amount = cloneEntries.total
              ? this.formatCurrency(cloneEntries.total)
              : '';
            cloneEntries.recovery2P = cloneEntries.recovery2P
              ? this.formatCurrency(cloneEntries.recovery2P)
              : '';
            cloneEntries.programCode = item.programCode;
            cloneEntries.programId = item.programId;
            cloneEntries.updatedAt = item.updatedAt;
            cloneEntries.modifyBy = item.modifyBy;
            cloneEntries.groupOwner = item.groupOwner;
            cloneEntries.engagementTitle =
              `${cloneEntries.programCode} - ` +
              `${cloneEntries.id} | ` +
              `${cloneEntries.title}`;
            cloneEntries.bookingId =
              `${cloneEntries.programCode} - ` + `${cloneEntries.id}`;
            cloneEntries.subTotal = +cloneEntries.subTotal;
            cloneEntries.fiscalYear = item.fiscalYear;
            cloneEntries.tags = item.tags;
            recoveryEntriesData.push(cloneEntries);
          });
        }
      });
    }
    return recoveryEntriesData;
  }

  forMatoriginalDate(originalDate) {
    const newDate = originalDate.split('/');
    const dateMonth = newDate[0];
    const dateDay = newDate[1];
    const dateYear = newDate[2];
    const NewOriginalDate = [];
    NewOriginalDate[0] = dateYear;
    NewOriginalDate[1] = dateMonth;
    NewOriginalDate[2] = dateDay;
    return NewOriginalDate.join('-');
  }

  formatEventDates(data) {
    const eventDatesData = [];
    if (Array.isArray(data)) {
      data.map((item) => {
        if (item.eventDate && item.eventDate.length > 0) {
          item.eventDate.map((dates) => {
            const cloneDates = _.cloneDeep(dates);
            cloneDates.dateId = cloneDates.id;
            cloneDates.id = item.id;
            cloneDates.listViewId = item.listViewId;
            cloneDates.status = item.status;
            cloneDates.title = item.title;
            cloneDates.org = item.org;
            cloneDates.morningTime = cloneDates.date
              ? this.formatDate(cloneDates.date)
              : cloneDates.eventDate
              ? this.formatToDate(cloneDates.eventDate)
              : null;
            cloneDates.calendarStartTime = cloneDates.startTime
              ? this.timeToDate(cloneDates.morningTime, cloneDates.startTime)
              : cloneDates.eventStartTime
              ? cloneDates.eventStartTime
              : null;
            cloneDates.calendarEndTime = cloneDates.endTime
              ? this.timeToDate(cloneDates.morningTime, cloneDates.endTime)
              : cloneDates.eventEndTime
              ? cloneDates.eventEndTime
              : null;
            cloneDates.eventStartTime = cloneDates.startTime
              ? this.formatHour(cloneDates.startTime)
              : cloneDates.eventStartTime
              ? this.formatToHours(cloneDates.eventStartTime)
              : null;
            cloneDates.eventEndTime = cloneDates.endTime
              ? this.formatHour(cloneDates.endTime)
              : cloneDates.eventEndTime
              ? this.formatToHours(cloneDates.eventEndTime)
              : null;
            cloneDates.clientArrivalTime = cloneDates.arrivalTime
              ? cloneDates.arrivalTime
              : cloneDates.clientArrivalTime
              ? cloneDates.clientArrivalTime
              : null;
            cloneDates.eventDate = cloneDates.date
              ? this.formatDate(cloneDates.date)
              : cloneDates.eventDate
              ? this.formatToDate(
                  cloneDates.calendarStartTime ||
                    cloneDates.calendarEndTime ||
                    cloneDates.clientArrivalTime ||
                    cloneDates.eventDate
                )
              : null;
            cloneDates.eventLocation = cloneDates.location
              ? cloneDates.location.value
                ? cloneDates.location.value
                : ''
              : '';
            cloneDates.lead = item.lead;
            cloneDates.poc = item.poc;
            cloneDates.programCode = item.programCode;
            cloneDates.programId = item.programId;
            cloneDates.tags = item.tags;
            cloneDates.visitType = item.visitType;
            eventDatesData.push(cloneDates);
          });
        }
      });
    }

    return eventDatesData;
  }

  calculateSubTotal(entry) {
    if (entry.cost || entry.discount) {
      return (entry.cost - entry.discount).toFixed(2);
    } else {
      return null;
    }
  }

  sortTableDataById(array) {
    const sortedList = array.sort((a, b) => -a.id - -b.id);
    return sortedList;
  }

  sortTableDataByAlphabeticalOrder(
    data: any[],
    column: string,
    sensitiveCase = true
  ) {
    data.sort((a, b) => {
      var max = sensitiveCase ? a[column] : a[column].toUpperCase();
      var min = sensitiveCase ? b[column] : b[column].toUpperCase();
      if (max < min) {
        return -1;
      }
      if (max > min) {
        return 1;
      }
      return 0;
    });
    return data;
  }

  sortTableDataByDate(data, asc: boolean) {
    data = data.filter((o) => {
      return (o.date || o.eventDate) != null;
    });
    let sortedDateList = [];
    if (asc) {
      sortedDateList = data.sort(
        (a, b) =>
          new Date(a.date || a.eventDate).getTime() -
          new Date(b.date || b.eventDate).getTime()
      );
    } else {
      sortedDateList = data.sort(
        (a, b) =>
          new Date(b.date || b.eventDate).getTime() -
          new Date(a.date || a.eventDate).getTime()
      );
    }
    return sortedDateList;
  }

  flattenChargeCodes(array: any[]): string {
    return this.flattenArray({ array, key: 'code' });
  }

  flattenTags(array: any[]): string {
    return this.flattenArray({ array, key: 'name' });
  }

  mapEngagementTeam(engagementTeam: any[]): string {
    return engagementTeam.map((member) => member.name).join();
  }

  flattenOrganizations(array: any[]): string {
    return this.flattenArray({
      array,
      key: 'organizationName',
      secondaryKey: 'accentureEntity.value',
    });
  }

  flattenOrganizationsType(array: any[]) {
    return array.map((org) => {
      return org.type;
    });
  }

  flattenOrgClientName(organizations: any[]) {
    const orgClients = organizations
      .filter(
        (org) =>
          (org.type.id === '4' || org.type.id === '3') &&
          org.organizationName &&
          !org.organizationName.toLowerCase().includes('accenture')
      )
      .map((item) => {
        return item.organizationName;
      });
    const result = uniq(orgClients);
    return result;
  }

  flattenOrganizationsTypes(organizationTypes: any[]) {
    var orgTypes = '';
    orgTypes = organizationTypes
      .map((org) => {
        return org.type.value.includes('Client') ? 'Client' : org.type.value;
      })
      .join(',');
    return orgTypes;
  }

  flattenArray({
    array,
    key,
    secondaryKey,
  }: {
    array: any[];
    key?: string;
    secondaryKey?: string;
  }): string {
    if (array) {
      const { length } = array;
      if (length == 1) {
        return this.formattedElement(array[0], key, secondaryKey);
      } else {
        return array
          .map((element) => {
            const subString =
              `${this.formattedElement(element, key, secondaryKey)}` == ''
                ? ''
                : `${this.formattedElement(element, key, secondaryKey)},`;
            return subString;
          })
          .join('\n')
          .slice(0, -1);
      }
    } else return '';
  }

  formattedElement(element, key?: string, secondaryKey?: string): string | any {
    return _get(element, key) || _get(element, secondaryKey)
      ? _get(element, key) || _get(element, secondaryKey)
      : '';
  }

  filterMetricsCondition(engagement, conditionList, property, dateCondition) {
    const result = [];
    if (conditionList) {
      for (const item of conditionList) {
        const filteredData = this.filterEngagement(engagement, property, item);
        const counts = this.engagementCountsOfMonth(
          filteredData,
          dateCondition
        );
        result.push({ conditionName: item, counts: counts });
      }
      return result;
    }
  }

  filterEngagement(engagement, condition, conditionValue) {
    return engagement.filter((o) => {
      return o[condition] == conditionValue;
    });
  }

  formatMultiSelectData(data) {
    const newData = _.cloneDeep(data);
    Object.keys(newData).forEach((key) => {
      if (key !== 'engagementLead') {
        newData[key] === ''
          ? (newData[key] = [])
          : (newData[key] = newData[key].map((item) => item.value));
      } else {
        newData[key].engagementLeadList.length < 1 ||
        (newData[key].engagementLeadList.length == 1 &&
          newData[key].engagementLeadList[0].chip.trim() == '')
          ? (newData[key].engagementLeadList = [])
          : (newData[key].engagementLeadList = newData[
              key
            ].engagementLeadList.map((item) => item.chip.trim()));
      }
    });
    return newData;
  }

  engagementCountsOfMonth(filteredEngagement, dateCondition) {
    let counts = {};
    let JanCount = 0;
    let FebCount = 0;
    let MarCount = 0;
    let AprCount = 0;
    let MayCount = 0;
    let JunCount = 0;
    let JulCount = 0;
    let AugCount = 0;
    let SepCount = 0;
    let OctCount = 0;
    let NovCount = 0;
    let DecCount = 0;
    let blankCount = 0;
    let totalCount = 0;
    blankCount = filteredEngagement.filter((o) => {
      return !o[dateCondition];
    }).length;

    const noBlankDate = filteredEngagement.filter((o) => {
      return (
        o[dateCondition] &&
        (dateCondition == 'engagementEndDate'
          ? this.formatToFY(o['engagementEndDate']) == o.fiscalYear
          : true)
      );
    });
    for (const item of noBlankDate) {
      const endDate = new Date(item[dateCondition]);
      const month = Number(endDate.getMonth()) + 1;
      switch (month) {
        case 1:
          JanCount++;
          break;
        case 2:
          FebCount++;
          break;
        case 3:
          MarCount++;
          break;
        case 4:
          AprCount++;
          break;
        case 5:
          MayCount++;
          break;
        case 6:
          JunCount++;
          break;
        case 7:
          JulCount++;
          break;
        case 8:
          AugCount++;
          break;
        case 9:
          SepCount++;
          break;
        case 10:
          OctCount++;
          break;
        case 11:
          NovCount++;
          break;
        case 12:
          DecCount++;
          break;
      }
    }
    totalCount =
      blankCount +
      SepCount +
      OctCount +
      NovCount +
      DecCount +
      JanCount +
      FebCount +
      MarCount +
      AprCount +
      MayCount +
      JunCount +
      JulCount +
      AugCount;
    counts = {
      blank: blankCount == 0 ? null : blankCount,
      Sep: SepCount == 0 ? null : SepCount,
      Oct: OctCount == 0 ? null : OctCount,
      Nov: NovCount == 0 ? null : NovCount,
      Dec: DecCount == 0 ? null : DecCount,
      Jan: JanCount == 0 ? null : JanCount,
      Feb: FebCount == 0 ? null : FebCount,
      Mar: MarCount == 0 ? null : MarCount,
      Apr: AprCount == 0 ? null : AprCount,
      May: MayCount == 0 ? null : MayCount,
      Jun: JunCount == 0 ? null : JunCount,
      Jul: JulCount == 0 ? null : JulCount,
      Aug: AugCount == 0 ? null : AugCount,
      total: totalCount == 0 ? null : totalCount,
    };
    return counts;
  }

  public formatCurrency(currency) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(currency);
  }

  public formatIntegerCurrency(amount: number, currencyCode: string) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  }

  paginate(totalItems, currentPage, pageSize, maxPages) {
    if (currentPage === undefined) {
      currentPage = 1;
    }
    if (pageSize === undefined) {
      pageSize = 10;
    }
    if (maxPages === undefined) {
      maxPages = 10;
    }
    var totalPages = Math.ceil(totalItems / pageSize);
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    var startPage, endPage;
    if (totalPages <= maxPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      var maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      var maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = maxPages;
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - maxPagesBeforeCurrentPage;
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }
    var startIndex = (currentPage - 1) * pageSize;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    var pages = Array.from(Array(endPage + 1 - startPage).keys()).map(function (
      i
    ) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return startPage + i;
    });
    if (currentPage === 0) {
      currentPage = 1;
    }
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
