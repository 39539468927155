export const recoveryCurrency = {
  HOU: {
    currency: 'USD',
    id: 1,
  },
  CAD: {
    currency: 'CAD',
    id: 2,
  },
  CBUS: {
    currency: 'USD',
    id: 3,
  },
  ATL: {
    currency: 'USD',
    id: 4,
  },
  BOS: {
    currency: 'USD',
    id: 5,
  },
  CHI: {
    currency: 'USD',
    id: 6,
  },
  CMH: {
    currency: 'USD',
    id: 7,
  },
  DET: {
    currency: 'USD',
    id: 8,
  },
  NYC: {
    currency: 'USD',
    id: 9,
  },
  SEA: {
    currency: 'USD',
    id: 10,
  },
  SF: {
    currency: 'USD',
    id: 11,
  },
  TST: {
    currency: 'USD',
    id: 12,
  },
  WDC: {
    currency: 'USD',
    id: 13,
  },
  BCN: {
    currency: 'EUR',
    id: 14,
  },
  SOA: {
    currency: 'EUR',
    id: 15,
  },
  DEMO: {
    currency: 'EUR',
    id: 16,
  },
  PHI: {
    currency: 'USD',
    id: 17,
  },
};
