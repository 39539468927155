import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsultationFacade } from '@ioh/core-data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ioh-radio-button',
  styleUrls: ['./radio-button.component.scss'],
  templateUrl: './radio-button.component.html',
})
export class RadioButtonComponent implements OnInit {
  @Input() radioId: string;
  @Input() label: string;
  @Input() field: any;
  @Input() values: any[];
  @Input() font: string;
  @Input() required: boolean;
  @Input() mmsHasValue: boolean;
  @Output() selected = new EventEmitter<string>();
  parentForm: AbstractControl;
  disableOption: boolean = false;

  private readonly subscriptions: Subscription = new Subscription();
  constructor(
    public controlContainer: ControlContainer,
    private readonly consultationFacade: ConsultationFacade
  ) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
    this.subscriptions.add(
      this.consultationFacade.mmsOption$.subscribe(() => {
        this.disableMMSOption();
      })
    );
    this.subscriptions.add(
      this.consultationFacade.allOpportunityIdSearchResults$.subscribe(() => {
        this.disableMMSOption();
      })
    );
  }

  selectedRadio(radio) {
    const selectedValue = radio.value;
    this.selected.emit(selectedValue);
  }

  disableMMSOption() {
    if (this.parentForm.get('opportunityInput')) {
      this.parentForm
        .get('opportunityInput')
        .get('oppResult')
        .valueChanges.subscribe((oppResult) => {
          if (oppResult.length > 0) {
            oppResult.filter((res) => {
              return !!res.chip.trim();
            });
          }
          if (oppResult.length > 0) {
            this.disableOption = true;
          } else {
            this.disableOption = false;
          }
        });
    }
  }
}
