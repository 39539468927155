<h3 class="attendee__title" tabindex="0">Organization Details</h3>
<div>
  <ioh-organization
    [frontOffice] = true
    [formGroup]="attendeeDetailsFormGroup"
    [externalOrganizationsCategories$]="externalOrganizationCategories$ | async"
    [accentureEntitiesResults$]="accentureEntitiesResults$ | async"
    [organizationTypes$]="organizationTypesResults$ | async">
  </ioh-organization>
  <ioh-details-attendee
    [formGroup]="attendeeDetailsFormGroup"
    [radioValues]="radioValues"
    [requiredAttendeeNumber]="true"
    (totalAttendee)="updateTotal($event)">
  </ioh-details-attendee>
</div>
