
const isIE =
  window.navigator.userAgent.includes('MSIE ') ||
  window.navigator.userAgent.includes('Trident/');

export const environment = {
  config: {
    auth: {
      clientId: '9283362e-217d-4664-a69f-c69963039235',
      authority:
        'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/',
      redirectUri: 'https://ioh-dev.ciodev.accenture.com',
      postLogoutRedirectUri: 'https://ioh-dev.ciodev.accenture.com/logout',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    timeOut: 3600000,
  },
  options: {
    consentScopes: ['9283362e-217d-4664-a69f-c69963039235/.default'],
  },
  awsBucketStorage: {
    bucket: '50309-engageinnovation-dev',
    keyOfEnv: 'dev/',
  },
  endpoints: {
    // base: 'https://fbuvh8yquj.execute-api.us-east-1.amazonaws.com/dev/',
    base: 'https://ioh-dev.ciodev.accenture.com/api/',
    report: {
      getFullReport: 'report?exportProgramList=',
      getFullReportPowerBI: 'powerBI?user=',
    },
    orchestrator: {
      createRequest: 'orchestrator/frontoffice',
      getAllWorkshopsByUserId: 'orchestrator/backoffice',
      getAllWorkshopsByHub: 'orchestrator/backoffice?hub=',
      getAllArchiveDataByUserId: 'orchestrator/archive',
      updateWorkshopById: 'orchestrator/backoffice',
      saveSurvey: 'orchestrator/saveSurvey',
      getWorkshopByPageDetails: 'orchestrator/backoffice',
      deleteWorkshop: 'orchestrator/deleteWorkshop',
      checkIsValidWorkshop: 'orchestrator/checkIsValidWorkshop',
    },
    recovery: {
      updateRecoveryEntries: 'recovery/cost',
    },
    program: {
      getById: 'program',
      getAllPrograms: 'program/getAll',
    },
    reference: {
      getEIDropdownEntity: 'reference/eiDropdownEntity',
      getAccentureEntities: 'reference/accEntities',
      getClients: 'reference/customer',
      getEngagementGroup: 'reference/engagementgroup',
      getEngagementGroupEcosystem: 'reference/engagementgroupEcosystem',
      getEngagementIndustryRD: 'reference/engagementIndustryRD',
      getEngagementLabsRD: 'reference/engagementLabsRD',
      getEngagementCountry: 'reference/engagementCountry',
      getEngagementType: 'reference/engagementtype',
      getOperatingGroup: 'reference/operatingGroup',
      getOrganizationCategories: 'reference/orgCategory',
      getOrganizationTypes: 'reference/orgtype',
      getPeopleProfile: 'reference/people-profile',
      // getProfilePicture: 'reference/profile-picture',
      getTermAndCondition: 'reference/termsconditions?program=',
      internalPurpose: 'reference/internalPurpose',
      opportunityId: 'reference/opportunity/',
      tags: 'reference/tags?partialName=',
      wbs: 'reference/wbs/',
      getWorkshopStatus: 'reference/workshopstatus',
      getCancellationReason: 'reference/cancellationreason',
      getTeamRoles: 'reference/role?program=',
      getDatesAndLocationsRooms: 'reference/locationroom?program=',
      getExchangeRate: 'reference/exchange',
      getSurveyQuestionnaire: 'reference/surveyQuestionnaire',
      getAnswerOptions: 'reference/answerOptions',
      getProgramName: 'reference/programName?program=',
      saveView: 'reference/saveView',
      getView: 'reference/getView',
      saveColumnChanges: 'reference/saveColumnChanges',
      removeColumnChanges: 'reference/removeColumnChanges',
      getColumnChanges: 'reference/columntype',
      removeSelectedView: 'reference/removeSelectedView',
    },
    appstate: {
      state: 'appstate',
    },
    user: {
      access: 'user/access',
      removeAccess: 'user/removeAccess',
      region:'us-east-1'
    },
    orgListUrl: 'general/getOrgList',
    getGeneralDetail: 'general/getGeneralDetail?workshopId=',
  },
  toggle: {
    document: true,
  },
  production: false,
  environmentType: 'dev',
  launchDarklyID: '6221a6bf084dfc14ef813b0a',
};
