import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { AzureInterceptor } from './interceptors/azure.interceptor';
import { BackOfficeModule } from './back-office/back-office.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { FormModule } from './form/form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { FullCalendarModule } from '@fullcalendar/angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { SurveyModule } from './survey/survey.module';

import { CalendarModule } from './back-office/sections/calendar/calendar.module';
import { SectionsModule } from './back-office/sections/sections.module';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule}  from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

const isIE =
  window.navigator.userAgent.includes('MSIE ') ||
  window.navigator.userAgent.includes('Trident/');
const protectedResourceMap = new Map<string, string[]>();
protectedResourceMap.set('https://graph.microsoft.com', ['user.read.all']);

@NgModule({
  bootstrap: [AppComponent, MsalRedirectComponent],
  declarations: [AppComponent],
  providers: [
    { provide: 'Window', useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AzureInterceptor,
      multi: true,
    },
    MsalGuard,
    MsalBroadcastService,
    MsalService,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatMenuModule,
    HttpClientModule,
    MatTableModule,
    MatCheckboxModule,
    AppRoutingModule,
    SharedModule,
    MatButtonModule,
    MatDialogModule,
    CalendarModule,
    MatInputModule,
    NgFor,
    MatSelectModule,
    MatFormFieldModule,
    // FullCalendarModule,
    BackOfficeModule,
    SectionsModule,
    FormModule,
    SurveyModule,
    FormsModule,
    ReactiveFormsModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        // MSAL Configuration
        auth: {
          clientId: environment.config.auth.clientId,
          authority: environment.config.auth.authority,
          redirectUri: environment.config.auth.redirectUri,
        },
        ...environment.options,
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
          loggerOptions: {
            piiLoggingEnabled: false,
          },
        },
      }),
      {
        interactionType: InteractionType.Popup, // MSAL Guard Configuration
        authRequest: {
          scopes: ['user.read', 'openid', 'profile'],
        },
        loginFailedRoute: '/login-failed',
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap,
      }
    ),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
