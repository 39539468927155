import { HttpService } from '../http';
import { IOpportunityResponse } from '@utils/index';
import { IOrgType, IOrganizationCategory, PeopleRequest } from '@ioh/types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, retry } from 'rxjs/operators';
import { environment } from '../../../../../../ioh/apps/ioh-app/src/environments';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataService {
  constructor(private readonly http: HttpService) {}

  // saveData(dataToSend: {}) {
  //   throw new Error('Method not implemented.');
  // }
  
  saveColumnChanges(columnViewObj: any): Observable<any>{
    const url = `${environment.endpoints.reference.saveColumnChanges}`;
    return this.http.post(url, columnViewObj);
  }
  removeColumnChanges(columnViewObj: any): Observable<any>{
    const url = `${environment.endpoints.reference.removeColumnChanges}`;
    return this.http.post(url, columnViewObj);
  }

  getColumnChanges(userid: string,tablename:string): Observable<any> {
    const url = `${environment.endpoints.reference.getColumnChanges}?userid=${userid}&tablename=${tablename}`;
    return this.http.get(url);
  }
  getInternalPurpose(): Observable<any> {
    const url = `${environment.endpoints.reference.internalPurpose}`;
    return this.http.get(url);
  }

  getEIDropdownEntityResults(): Observable<any> {
    const url = `${environment.endpoints.reference.getEIDropdownEntity}`;
    return this.http.get(url);
  }

  saveSurvey(surveyAnswer) {
    const url = `${environment.endpoints.orchestrator.saveSurvey}`;
    return this.http.post(url, surveyAnswer);
  }

  public getPeopleProfile({
    requestId = 1,
    exactMatch = 0,
    searchText,
  }: PeopleRequest) {
    const url = environment.endpoints.reference.getPeopleProfile;
    return this.http.post(url, {
      exactmatch: exactMatch,
      requestid: requestId,
      searchtxt: searchText,
    });
  }

  /**
   * Subscribe to this and it will return an object with a data property that contains
   * the already formated base64 string, ready to use as an image src
   * @param param0
   */
  // - Comment out profile-picture API story ID: 1123800
  // public getProfilePicture({ eid }: { eid: string }): Observable<any> {
  //   const url = `${environment.endpoints.reference.getProfilePicture}/?eid=${eid}`;
  //   return this.http.get(url);
  // }

  getWBS(code: string): Observable<any> {
    const url = `${environment.endpoints.reference.wbs}` + `${code}`;
    return this.http.get(url);
  }

  getOpportunityId(value): Observable<IOpportunityResponse> {
    const url: string = `${environment.endpoints.reference.opportunityId}${value}`;
    return this.http.get(url);
  }

  getTermAndCondition(programId: string): Observable<any> {
    const url: string = `${environment.endpoints.reference.getTermAndCondition}${programId}`;
    return this.http.get(url);
  }

  getOrganizationsTypes(): Observable<{ data: IOrgType[] }> {
    const url = environment.endpoints.reference.getOrganizationTypes;
    return this.http.get(url);
  }

  getOrganizationCategories(): Observable<{ data: IOrganizationCategory[] }> {
    const url: string =
      environment.endpoints.reference.getOrganizationCategories;
    return this.http.get(url);
  }

  getOperatingGroup(): Observable<any> {
    const url: string = environment.endpoints.reference.getOperatingGroup;
    return this.http.get(url);
  }

  getAccentureEntities(): Observable<any> {
    const url: string = environment.endpoints.reference.getAccentureEntities;
    return this.http.get(url);
  }

  getEngagementTypes(): Observable<any> {
    const url: string = environment.endpoints.reference.getEngagementType;
    return this.http.get(url);
  }

  getEngagementGroup(): Observable<any> {
    const url: string = environment.endpoints.reference.getEngagementGroup;
    return this.http.get(url);
  }

  getEngagementGroupEcosystem(): Observable<any> {
    const url: string =
      environment.endpoints.reference.getEngagementGroupEcosystem;
    return this.http.get(url);
  }

  getEngagementIndustryRD(): Observable<any> {
    const url: string = environment.endpoints.reference.getEngagementIndustryRD;
    return this.http.get(url);
  }

  getEngagementLabsRD(): Observable<any> {
    const url: string = environment.endpoints.reference.getEngagementLabsRD;
    return this.http.get(url);
  }

  getEngagementCountry(): Observable<any> {
    const url: string = environment.endpoints.reference.getEngagementCountry;
    return this.http.get(url);
  }

  getClients(searchQuery: string): Observable<any> {
    const url = `${environment.endpoints.reference.getClients}?customerName=${searchQuery}`;
    return this.http.get(url);
  }

  getTags(partialName: string): Observable<any> {
    const url: string = `${environment.endpoints.reference.tags}${partialName}`;
    return this.http.get(url);
  }

  /**
   * Returns the values used for the workshop status dropdown
   */
  getWorkshopStatus(): Observable<any> {
    const url = `${environment.endpoints.reference.getWorkshopStatus}`;
    return this.http.get(url);
  }

  getCancellationReason(): Observable<any> {
    const url = `${environment.endpoints.reference.getCancellationReason}`;
    return this.http.get(url);
  }

  getEngagementTeamRoles(programId: string): Observable<any> {
    const url = `${environment.endpoints.reference.getTeamRoles}${programId}`;
    return this.http.get(url);
  }

  getDatesAndLocationsRooms(programId: string): Observable<any> {
    const url = `${environment.endpoints.reference.getDatesAndLocationsRooms}${programId}`;
    return this.http.get(url);
  }

  getExchangeRate(searchQuery: {
    fromCurr: string;
    toCurr: string;
  }): Observable<any> {
    const url = `${environment.endpoints.reference.getExchangeRate}?fromCurr=${searchQuery.fromCurr}&toCurr=${searchQuery.toCurr}`;
    return this.http.get(url);
  }

  getSurveyQuestionnaire(): Observable<any> {
    const url = `${environment.endpoints.reference.getSurveyQuestionnaire}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      retry(4),
      delay(1000)
    );
  }

  getAnswerOptions(): Observable<any> {
    const url = `${environment.endpoints.reference.getAnswerOptions}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      retry(4),
      delay(1000)
    );
  }

  getProgramName(programId: string): Observable<any> {
    const url = `${environment.endpoints.reference.getProgramName}${programId}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      retry(4),
      delay(1000)
    );
  }

  saveView(view) {
    const url = `${environment.endpoints.reference.saveView}`;
    return this.http.post(url, view);
  }

  getView() {
    const url = `${environment.endpoints.reference.getView}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      retry(4),
      delay(1000)
    );
  }

  removeSelectedView(viewInfo: any): Observable<any> {
    const url = `${environment.endpoints.reference.removeSelectedView}`;
    return this.http.post(url, viewInfo);
  }
}
