import { Utils } from './utils';

export class OrgTab extends Utils {
  FiscalYear: string;
  OrganizationID: string;
  Organization: string;
  BookingID: string;
  Status: string;
  OrganizationType: string;
  AttendeeCategory: string;
  OperatingGroup: string;
  OperatingUnit: string;
  Country: string;
  Segment: string;
  G2000Rank: string;
  MCClassification: string;
  AccountStatus: string;

  constructor(workshop: any, organization: any) {
    super();
    const program = workshop.program;
    const workshopStatus = workshop.status ? workshop.status : '';

    this.OrganizationID = organization.clientName
      ? organization.clientName.split('-')[0]
      : '';
    this.Organization =
      organization.type && organization.type.value === 'Internal'
        ? organization.accentureEntity && organization.accentureEntity.value
        : organization.organizationName;
    this.BookingID =
      workshop.id && program.code
        ? `${program.code} - ` + `${workshop.id}`
        : '';
    this.Status = workshopStatus.value ? workshopStatus.value : '';
    this.OrganizationType =
      organization.type && organization.type.value
        ? organization.type.value.includes('Client')
          ? 'Client'
          : organization.type.value
        : '';
    this.AttendeeCategory =
      workshop.general && workshop.general.attendeeCategory
        ? workshop.general.attendeeCategory
        : '';
    this.OperatingGroup =
      organization.type && organization.type.value === 'Internal'
        ? ''
        : organization.operatingGroup
        ? organization.operatingGroup
        : '';
    this.OperatingUnit = organization.operatingUnit
      ? organization.operatingUnit
      : '';
    this.Country = organization.country ? organization.country : '';
    this.Segment = organization.industrySegment
      ? organization.industrySegment
      : '';
    this.G2000Rank = organization.g2000Rank ? organization.g2000Rank : '';
    this.MCClassification = organization.diamond ? organization.diamond : '';
    this.AccountStatus = organization.accountStatus
      ? organization.accountStatus
      : '';
    this.FiscalYear = workshop.fiscalYear || '';
  }

  public static getColumns = [
    { header: 'Fiscal Year', key: 'FiscalYear' },
    { header: 'Organization ID', key: 'OrganizationID' },
    { header: 'Organization', key: 'Organization' },
    { header: 'Booking ID', key: 'BookingID' },
    { header: 'Engagement Status', key: 'Status' },
    { header: 'Organization Type', key: 'OrganizationType' },
    { header: 'Organization Category', key: 'AttendeeCategory' },
    { header: 'Market', key: 'OperatingGroup' },
    { header: 'Market Unit', key: 'OperatingUnit' },
    { header: 'Country', key: 'Country' },
    { header: 'Industry', key: 'Segment' },
    { header: 'G2000 Rank', key: 'G2000Rank' },
    { header: 'MC Classification', key: 'MCClassification' },
    { header: 'Account Status', key: 'AccountStatus' },
  ];
}
