import { Component, Input } from '@angular/core';

@Component({
  selector: 'ioh-response',
  styleUrls: ['./response.component.scss'],
  templateUrl: './response.component.html',
})
export class ResponseComponent {
  @Input() response: boolean;
  title;
  icon;
  text;

  ngOnInit() {
    if (this.response) {
      this.title = 'Success';
      this.icon = 'check_circle';
      this.text =
        'Your request has been received, our Scheduling Team will contact you within 1-3 business days.';
    } else {
      this.title = 'Oh Dear';
      this.icon = 'highlight_off';
      this.text =
        "We're sorry but it looks like there was a problem when creating your request. Please try again in a while.";
    }
  }
}
