import { HttpService } from '@ioh/core-data';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { delay, retry } from 'rxjs/operators';
import { environment } from '@env/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  constructor(
    private readonly sharedService: SharedService,
    private readonly http: HttpService
  ) {}

  getState(): Observable<any> {
    const overrideCode = sessionStorage.getItem('overrideCode');
    const overrideCodePath =
      overrideCode && overrideCode !== 'undefined'
        ? `?overrideCode=${overrideCode}`
        : '';
    const url = `${environment.endpoints.base}${environment.endpoints.appstate.state}${overrideCodePath}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        let isFishing;

        if (res.data.overrideEnabled) {
          isFishing =
            res.data.maintenanceModeEnabled && !res.data.overrideValid;
        } else {
          isFishing = res.data.maintenanceModeEnabled;
        }

        this.sharedService.goneFishing.next(isFishing);
      }),
      retry(4),
      delay(1000)
    );
  }
}
