import { IEngagement } from './interfaces';
import { format } from 'date-fns';

export const transformDates = (value: IEngagement): IEngagement => {
  value.engagementStartDate =
    value.engagementStartDate &&
    handleEngageStartEndDate(value.engagementStartDate);
  value.engagementEndDate =
    value.engagementEndDate &&
    handleEngageStartEndDate(value.engagementEndDate);
  value.chargeDate =
    value.chargeDate && format(new Date(value.chargeDate), 'MM/dd/yyyy');
  value.expiration =
    value.expiration && format(new Date(value.expiration), 'MM/dd/yyyy');
  value.complete =
    value.complete && format(new Date(value.complete), 'MM/dd/yyyy');
  return value;
};

export const handleEngageStartEndDate = (value: string): string => {
  const dateArray = value.slice(0, 10).split('-');
  if (value.includes('-')) {
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  } else {
    return value;
  }
};

export const checkStatusIs = (value: any, status: string): boolean => {
  return value.status.toLowerCase() == status.toLowerCase();
};
