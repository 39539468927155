<div class="wbs" [formGroup]="parentForm">
  <ng-container *ngIf="wbsDetails; else noWbsDetails">
    <h3 class="wbs__title__recovery">WBS's</h3>
  </ng-container>
 <ng-template #noWbsDetails>
<div class="wbs__title wbs__mb" tabindex="0">WBS Details<ioh-tooltip class="tooltip-font" [detail]="wbsDetailTooltipObj"></ioh-tooltip></div>
  <small class="wbs__hint wbs__mb" tabindex="0"
    >WBS will not be charged prior to the consultation of costs and fees.</small
  >
 </ng-template>
  <ng-container formArrayName="wbsFormArray">
    <div *ngFor="let wbs of wbsFormArray.controls; let indexWbs = index">
      <ioh-wbs
      [formGroupName]="indexWbs"
      [wbsDetails]="wbsDetails"
      [responseLoaded]="wbs.get('responseLoaded').value"
      [isValid]="wbs.get('isValid').value"
      [wbsType]="wbs.get('type').value"
      [wbsDescription]="wbs.get('description').value"
      [wbsStatus]="wbs.get('wbsStatus').value"
      [index]="indexWbs"
      (fetchWbsResults)="getWbsResults($event)"
      ></ioh-wbs>
    </div>
    <div class="wbs__error">
      <ioh-error
        *ngIf="parentForm
            .get('wbsFormArray')
            .hasError('maxPercentageError')"
        errorMsg="Please make sure WBS percentage split totals 100%!"
      ></ioh-error>
      <ioh-error
        *ngIf="parentForm
            .hasError('invalidBreakdownTotal')"
        errorMsg="WBS breakdown values must sum to match Recovery Total"
    ></ioh-error>
    </div>
  </ng-container>
</div>
