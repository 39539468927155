import { FormBuilder, FormGroup } from '@angular/forms';
import { FormControlUtils } from '@utils/index';
import { Injectable } from '@angular/core';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
@Injectable({
  providedIn: 'root',
})
export class LookUpEntryService {
  constructor(
    private readonly fb: FormBuilder,
    private readonly formControlUtils: FormControlUtils
  ) {}

  public createLookUpEntry(entry?: any): FormGroup {
    return this.fb.group({
      chip: this.fb.control(this.mapChip(entry), RxwebValidators.unique()),
    });
  }

  public createOpportunityLookupEntry(entry?: any): FormGroup {
    return this.fb.group({
      id: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'id' })
      ),
      description: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'description' })
      ),
      chip: this.fb.control(this.mapChip(entry), RxwebValidators.unique()),
    });
  }

  // if the tags response is coming from the db then it will use .name
  // if it is coming from the state object then it will use .chip
  private mapChip(entry = ''): string {
    const chipValue =
      this.formControlUtils.mapProperty({ entry, property: 'chip' }) ||
      this.formControlUtils.mapProperty({ entry, property: 'name' }) ||
      entry;
    return chipValue;
  }
}
