import { Utils } from './utils';
import { format } from 'date-fns';
import moment from 'moment';

export class DatesTab extends Utils {
  FiscalYear: string;
  Date: any;
  OrganizationID: string;
  Organization: string;
  BookingID: string;
  Status: string;
  EngagementLead: string;
  EngagementTeam: string;
  ClientArrival: string;
  StartTime: string;
  EndTime: string;
  Hours: number;
  Location: string;
  Comments: string;

  constructor(workshop: any, calendarDate: any) {
    super();

    const program = workshop.program ? workshop.program : '';
    const orgs =
      workshop.general && workshop.general.organizations
        ? workshop.general.organizations
        : [];
    const eng =
      workshop.general && workshop.general.engagement
        ? workshop.general.engagement
        : '';
    const workshopStatus = workshop.status ? workshop.status : '';

    this.Date = calendarDate.date
      ? this.dateFormat(calendarDate.date)
      : calendarDate.eventDate
      ? this.formatToDate(
          calendarDate.clientArrivalTime ||
            calendarDate.eventStartTime ||
            calendarDate.eventEndTime ||
            calendarDate.eventDate
        )
      : null;
    this.OrganizationID = this.mapJoin(orgs, 'id');
    this.Organization = orgs
      .map((elem) =>
        elem.type && elem.type.value === 'Internal'
          ? elem.accentureEntity && elem.accentureEntity.value
          : elem.organizationName
      )
      .join(this.JOIN_SEPARATOR);
    this.BookingID =
      workshop.id && program.code
        ? `${program.code} - ` + `${workshop.id}`
        : '';
    this.Status = workshopStatus.value ? workshopStatus.value : '';
    this.EngagementLead = eng.lead ? eng.lead : '';
    this.EngagementTeam = eng.team ? this.mapJoin(eng.team, 'name') : '';
    this.ClientArrival = calendarDate.arrivalTime
      ? calendarDate.arrivalTime
      : calendarDate.clientArrivalTime
      ? this.formatToHours(calendarDate.clientArrivalTime)
      : '';
    this.StartTime = calendarDate.startTime
      ? calendarDate.startTime
      : calendarDate.eventStartTime
      ? this.formatToHours(calendarDate.eventStartTime)
      : '';
    this.EndTime = calendarDate.endTime
      ? calendarDate.endTime
      : calendarDate.eventEndTime
      ? this.formatToHours(calendarDate.eventEndTime)
      : '';
    this.Hours = calendarDate.hours ? +calendarDate.hours : 0;
    this.Location =
      calendarDate.location && calendarDate.location.value
        ? calendarDate.location.value
        : '';
    this.Comments = calendarDate.comments;
    this.FiscalYear = workshop.fiscalYear || '';
  }

  public static getColumns = [
    { header: 'Fiscal Year', key: 'FiscalYear' },
    { header: 'Date', key: 'Date' },
    { header: 'Organization ID', key: 'OrganizationID' },
    { header: 'Organization', key: 'Organization' },
    { header: 'Booking ID', key: 'BookingID' },
    { header: 'Engagement Status', key: 'Status' },
    { header: 'Engagement Lead', key: 'EngagementLead' },
    { header: 'Engagement Team', key: 'EngagementTeam' },
    { header: 'Client Arrival', key: 'ClientArrival' },
    { header: 'Start Time', key: 'StartTime' },
    { header: 'End Time', key: 'EndTime' },
    { header: 'Hours', key: 'Hours' },
    { header: 'Location', key: 'Location' },
    { header: 'Comments', key: 'Comments' },
  ];

  formatToHours(data) {
    let res;
    res = moment(data).format('HH:mm');
    return res;
  }

  formatToDate(data) {
    let res;
    res = moment(data).toDate();
    res = format(res, this.DATE_FORMAT);
    return res;
  }
}
