import { HttpService } from '../http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../ioh/apps/ioh-app/src/environments';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  constructor(private readonly http: HttpService) {}

  getPrograms(): Observable<any> {
    const url = `${environment.endpoints.program.getAllPrograms}`;
    return this.http.get(url);
  }
}
