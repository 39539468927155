import { AdditionalNotesComponent } from '../accordian-sections/additional-notes/additional-notes.component';
import { AttendeeDetailsComponent } from '../accordian-sections/attendee-details/attendee-details.component';
import { Component } from '@angular/core';
import { ContactDetailsComponent } from '../accordian-sections/contact-details/contact-details.component';
import { EngagementDetailsComponent } from '../accordian-sections/engagement-details/engagement-details.component';
import { VisitDetailsComponent } from '../accordian-sections/visit-details/visit-details.component';
import { sectionAccordian } from '@assets/data/index';

@Component({
  entryComponents: [
    ContactDetailsComponent,
    EngagementDetailsComponent,
    AttendeeDetailsComponent,
    AdditionalNotesComponent,
    VisitDetailsComponent,
  ],
  selector: 'ioh-accordian',
  styleUrls: ['./accordian.component.scss'],
  templateUrl: './accordian.component.html',
})
export class AccordianComponent {
  showStatus = false;
  sections = sectionAccordian;
}
