import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ioh-input',
  styleUrls: ['./input-component.component.scss'],
  templateUrl: './input-component.component.html',
})
export class InputComponent implements OnInit {
  @Input() label: string;
  @Input() inputId: any;
  @Input() name: any;
  @Input() placeholder: string;
  @Input() value: any;
  @Input() width: string;
  @Input() required: boolean;
  @Input() isDisabled: boolean;
  @Input() regex: any;
  @Input() maxLength: string;
  @Output() inputBlurred = new EventEmitter();
  @Input() tooltipObj: any;
  parentForm: AbstractControl;

  constructor(private readonly controlContainer: ControlContainer) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
    this.disableInput();
  }

  decimalLimit() {
    const formControl = this.parentForm.get(this.value);
    return formControl.hasError('threeDecimalPoints') ? 3 : 2;
  }

  onBlur(value: string): void {
    this.inputBlurred.emit(value);
  }
  omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}

  disableInput() {
    // will disable input, dropdown, date-picker, text-area, checkbox
    // left lookup, organization section, events, people picker, team member section
    if (this.isDisabled) {
      this.parentForm.disable();
    }
  }
}
