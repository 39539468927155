 <ioh-loading *ngIf="this.checkingLink"></ioh-loading> 
<main class="container"
  ngClass.md="container__md"
  ngClass.sm="container__sm"
  ngClass.xs="container__xs"
>

  <div class="hubComments">
    <img src="../../../../../assets/images/logoTransparent.png" alt="engage-banner" tabindex="0">
    <!-- <h1 class="container__title">Service Request Form for the {{programName}} Innovation Hub</h1> -->
    <h1 *ngIf="programName=='Philippine'" class="container__title" tabindex="0">Service Request Form for the {{programName}} Hub For Innovation</h1>

    <h1 *ngIf="programName!='Philippine'" class="container__title" tabindex="0">Service Request Form for the {{programName}} Innovation Hub</h1>
    <p tabindex="0s">The below form will create a request for an innovation session consultation with the {{programName}} Innovation Hub team.  Please fill out as much information as possible but if you don’t have everything below it’s OK and it will be discussed in the initial consultation.   Once we review the form our goal to reach out to you within 1-3 business days to schedule a consultation.</p>
    <p tabindex="0">For more information about this hub please visit <a id="inAccLink" title="InnovationDirectory" aria-label="innovaton directory open in a new window" class="container__link" href="https://in.accenture.com/innovation/directory">Innovation Directory</a></p>
  </div>
  <div class="container__form mat-elevation-z2"
    ngClass.md="container__form__md"
    ngClass.sm="container__form__sm"
  >
    <form>
      <ioh-accordian></ioh-accordian>
    </form>
    <div
      class="container__footer"
      fxLayout.xl="row"
      fxLayout.lg="row"
      fxLayout.md="row"
      fxLayout.sm="row"
      fxLayout.xs="column"
      fxLayoutAlign.xl="space-between center"
      fxLayoutAlign.lg="space-between center"
      fxLayoutAlign.md="space-around center"
      fxLayoutAlign.sm="space-around center"
      fxLayoutAlign.xs="space-around center"
    >
    <ioh-checkbox
      checkId="terms"
      value="checkbox"
      [formGroup]="baseForm"
      required=true
      label="Terms and Conditions selection"
      (keydown.enter)="onCheckboxKeyDown($event, 'checkbox')"
    >
      <div tabindex="0">
        I agree to the
        <a title="Terms and Conditions" aria-label="terms and conditions pop up opens"

          class="container__link"
          routerLink="."
          (click)="openDialog($event)">Terms and Conditions</a>
      </div>
    </ioh-checkbox>
    <button
      mat-raised-button color="accent"
      [ngClass]="!termsAndConditionsAccepted || isSavingWorkshop ? 'container__footer__submit--disabled' : 'container__footer__submit'" 
      [disabled]="!termsAndConditionsAccepted || isSavingWorkshop" 
      (click)="createRequest()"
    >
      <div *ngIf="isSavingWorkshop; else text" fxLayout="row" fxLayoutAlign="start center">
        <mat-spinner diameter="20"></mat-spinner>
        <p class="container__form__submit__text">Saving ...</p>
      </div>
      <ng-template #text>
        Submit
      </ng-template>
  </button>
  </div>
  </div>
</main>
<ng-template #TermAndConditionTemplate>
  <h2 class="modal__title" role="banner" mat-dialog-title>Terms and Conditions</h2>
  <mat-dialog-content role="contentinfo" class="mat-typography">
    <ng-container *ngFor="let content of contents">
      <h3 class="modal__title">{{content.title}}</h3>
      <p class="modal__text">{{content.text}}</p>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" role="navigation">
    <button mat-stroked-button color="primary" (click)="onDownload()">Download</button>
    <button class="modal__accept__button" mat-flat-button (click)="onAccept()" color="primary">Accept</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #FailedSubmissionTemplate class="print__area">
  <h2 class="modal__title" role="banner" mat-dialog-title>Something went wrong!</h2>
  <mat-dialog-content role="contentinfo" class="mat-typography" [innerHTML]="errorMessage" style="white-space: pre-line;">
        {{errorMessage}}
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" role="navigation">
    <button class="modal__accept__button" mat-flat-button (click)="closeFailedSubmissionDialog()" color="primary">Got it!</button>
  </mat-dialog-actions>
</ng-template>


