import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  invalidLinkDetails,
  logoutErrorDetails,
  unauthorisedDetails,
  wildcardErrorDetails,
} from '@assets/data/index';

@Component({
  selector: 'ioh-error-page',
  styleUrls: ['./error-page.component.scss'],
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent {
  url: string;
  errorDetails: any;
  isLogoutPage: boolean;
  isInvlidLink: boolean = false;
  private readonly subscription: Subscription = new Subscription();
  constructor(private readonly router: Router) {
    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd && event.url === '/logout') {
          this.errorDetails = logoutErrorDetails;
          this.isLogoutPage = true;
        } else if (
          event instanceof NavigationEnd &&
          event.url === '/unauthorized'
        ) {
          this.errorDetails = unauthorisedDetails;
          this.isLogoutPage = false;
        } else if (
          event instanceof NavigationEnd &&
          event.url === '/invalidLink'
        ) {
          this.errorDetails = invalidLinkDetails;
          this.isLogoutPage = false;
          this.isInvlidLink = true;
        } else {
          this.errorDetails = wildcardErrorDetails;
          this.isLogoutPage = false;
        }
      })
    );
  }

  login() {
    const path = sessionStorage.getItem('loginPath');
    this.router.navigateByUrl(path);
  }
}
