<div class="text-area-container" *ngFor="let textarea of textareas" [formGroup]="parentForm">
  <ioh-text-area
    fxFlex="60%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    label={{textarea.label}}
    name={{textarea.name}}
    textareaId={{textarea.id}}
    placeholder={{textarea.placeholder}}
    [formGroup]="parentForm"
    value={{textarea.value}}
    maxCharLimit={{textarea.limit}}
    description={{textarea.description}}
    height="{{textarea.height}}"
    [required]=textarea.required
  ></ioh-text-area>
</div>
<div class="dropdown-container tooltipWrap">
  <ioh-dropdown
    fxFlex="60%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    label="Engagement Purpose"
    dropdownId="purpose"
    [formGroup]="parentForm"
    [required]="true"
    isMultiple="false"
    field="purposeOptions"
    [values]="dropdownOptionsPurpose | sortDropdownById"
    placeholder="What is the purpose of this engagement for Accenture"
    direction="column"
    name="purpose"
  ></ioh-dropdown>
   <!-- <ioh-imgTooltip left='180px' imgSrc="../../../../../assets/images/purpose.PNG" ></ioh-imgTooltip> -->
</div>

<div class="input-container">
  <ioh-lookup
    fxFlex="60%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    [formGroup]="parentForm"
    lookUpId="opportunity"
    label="MMS Opportunity ID"
    class="hasTooltipIcon mmsId-lookup"
    placeholder="Search for MMS ID"
    [tooltipObj] = "mmsTooltipObj"
    [lookupGroupFieldName]="lookupGroupFieldName"
    [lookupArrayFieldName]="lookupArrayFieldName"
    [lookupInputFieldName]="lookupInputFieldName"
    [limit]="opportunityLimit"
    [required]="false"
    [searchResults$]="opportunityResults"
    [isLoading$]="opportunityLoading"
    [noMMS] = "noMmsOpportunityId"
    [isDisabled]="isDisabled"
  >
  </ioh-lookup>
</div>
<div *ngFor="let value of mmsRadio" class="attendee__radio__container">
  <ioh-radio-button
  radioId="{{ value.radioId }}"
    label="{{ value.label }}"
    field="noMmsOpportunityId"
    [values]="value.values"
    [formGroup]="parentForm"
    [mmsHasValue] = "mmsInputValue"
    ></ioh-radio-button>
  </div>