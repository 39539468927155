export const durationTextArea = {
  description: 'Dates and Durations',
  height: '4rem',
  id: 'duration',
  label: 'Dates and Durations',
  limit: 1000,
  name: 'Dates and Durations',
  placeholder:
    'Tell us about your desired start and end dates, and and specific deadlines you are working against, etc. We will review this with you in the consultation.',
  required: false,
  value: 'duration',
};
export const textAreasConsultation = [
  {
    description: 'Background description',
    height: '4rem',
    id: 'background',
    label: 'Request Overview',
    limit: 1000,
    name: 'Background',
    placeholder:
      "Please enter as much detail around this request and what is hoped to be explored or accomplished.  If known, things like technology or business context are great details, but this will also be covered in the initial consultation call, so if you don't have these details at this time it's OK.",
    required: true,
    value: 'backgroundText',
  },
  {
    description: 'Objectives and Expectations',
    height: '4rem',
    id: 'objectives-expectations',
    label: 'Scope',
    limit: 1000,
    name: 'Objectives and Expectations',
    placeholder:
      'What do you think you need from the Innovation team, e.g. a meeting, a workshop, a content request, something else, etc.',
    required: true,
    value: 'objectivesText',
  },
  {
    description: 'Additional Comments',
    height: '4rem',
    id: 'additionalComments',
    label: 'Additional Comments',
    limit: 1000,
    name: 'Additional Comments',
    placeholder:
      'Is there anything else you want us to know about this request?',
    required: false,
    value: 'additionalComments',
  },
];

export const radioBtnConsultationFields = [
  {
    field: 'leadershipLevel',
    hint: 'What is the highest level attendee you are expecting?',
    label: 'Leadership Level',
    radioId: 'leadership',
    required: false,
    values: ['C-Suite', 'Other'],
  },
];
