import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, from } from 'rxjs';
import { environment } from '../../environments/environment';

import { mergeMap } from 'rxjs/operators';

@Injectable()
export class AzureInterceptor {
  constructor(private readonly msalService: MsalService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.msalService.instance.getAllAccounts()[0]) {
      return from(
        this.getToken({ scopes: environment.options.consentScopes })
      ).pipe(
        mergeMap((token) => {
          request = this.injectToken(request, token);
          return next.handle(request);
        })
      );
    }
  }

  private async getToken(scopes: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window['ApplePaySession']) {
        this.msalService.acquireTokenRedirect(scopes);
      } else {
        this.msalService.instance
          .acquireTokenSilent(scopes)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.errorHandler(error);
          });
      }
    });
  }

  private errorHandler(error: any): void {
    if (error.name == 'InteractionRequiredAuthError') {
      console.error(error);
      this.msalService.loginRedirect();
    } else {
      console.error(error);
      this.msalService.instance
        .acquireTokenPopup({ scopes: environment.options.consentScopes })
        .catch((error) => {
          console.error(error);
          this.msalService.loginRedirect();
        });
    }
  }

  private injectToken(request: HttpRequest<any>, token: any): HttpRequest<any> {
    request = this.addHeader(
      request,
      'authorization',
      `Bearer ${token.accessToken}`
    );
    return request;
  }

  private addHeader(
    request: HttpRequest<any>,
    headerName: string,
    headerValue: string
  ): HttpRequest<any> {
    request = request.clone({
      headers: request.headers.set(headerName, headerValue),
    });
    return request;
  }
}
