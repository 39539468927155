import { Utils } from './utils';

export class MMSTab extends Utils {
  BookingID: string;
  MMSOpportunityId: string;
  MMSOpportunityName: string;
  NoMmsOpportunityId: string;

  constructor(workshop: any, opportunity: any) {
    super();
    const program = workshop.program ? workshop.program : '';
    this.BookingID =
      workshop.id && program.code
        ? `${program.code} - ` + `${workshop.id}`
        : '';
    this.MMSOpportunityId = opportunity.id;
    this.MMSOpportunityName = opportunity.description;
    this.NoMmsOpportunityId =
      workshop.consultation && workshop.consultation.mmsRadioValue
        ? workshop.consultation.mmsRadioValue
        : '';
  }

  public static getColumns = [
    { header: 'Booking ID', key: 'BookingID' },
    { header: 'MMS Opportunity ID', key: 'MMSOpportunityId' },
    { header: 'MMS Opportunity Name', key: 'MMSOpportunityName' },
    { header: 'MMS N/A', key: 'NoMmsOpportunityId' },
  ];
}
