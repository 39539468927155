import * as GeneralActions from './general.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ReferenceDataService } from '../../../../reference-data';
import { catchError, delay, exhaustMap, map, retry, switchMap } from 'rxjs/operators';

import { of } from 'rxjs';
@Injectable()
export class GeneralEffects {
  getEngagementTypeResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getEngagementTypeResults),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementTypes().pipe(
          map((res: any) =>
            GeneralActions.getEngagementTypeResultsSuccess({
              engagementTypeResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getEngagementTypeResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  getEngagementSponsorSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getEngagementSponsorSearchResults),
      switchMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactMatch,
            requestId: action.searchQuery.requestId,
            searchText: action.searchQuery.searchText,
          })
          .pipe(
            map((res: any) =>
              GeneralActions.getEngagementSponsorSearchResultsSuccess({
                engagementSponsorSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                GeneralActions.getEngagementSponsorSearchResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadPrimaryContactSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getPrimaryContactSearchResults),
      switchMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactMatch,
            requestId: action.searchQuery.requestId,
            searchText: action.searchQuery.searchText,
          })
          .pipe(
            map((res: any) =>
              GeneralActions.getPrimaryContactSearchResultsSuccess({
                primaryContactSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                GeneralActions.getPrimaryContactSearchResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadAdditionalContactSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getAdditionalContactsSearchResults),
      switchMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactMatch,
            requestId: action.searchQuery.requestId,
            searchText: action.searchQuery.searchText,
          })
          .pipe(
            map((res: any) =>
              GeneralActions.getAdditionalContactsSearchResultsSuccess({
                additionalContactSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                GeneralActions.getAdditionalContactsSearchResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadEngagementGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementGroupResults),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementGroup().pipe(
          map((res: any) =>
            GeneralActions.loadEngagementGroupResultsSuccess({
              engagementGroupResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.loadEngagementGroupResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadEngagementGroupEcosystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementGroupEcosystemResults),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementGroupEcosystem().pipe(
          map((res: any) =>
            GeneralActions.loadEngagementGroupEcosystemResultsSuccess({
              engagementGroupEcosystemResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.loadEngagementGroupEcosystemResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadEngagementIndustryRD$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementIndustryRDResults),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementIndustryRD().pipe(
          map((res: any) =>
            GeneralActions.loadEngagementIndustryRDResultsSuccess({
              engagementIndustryRDResults: res.data
                .slice()
                .sort(function (o1, o2) {
                  return o1.id - o2.id;
                }),
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.loadEngagementIndustryRDResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadEngagementLabsRD$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementLabsRDResults),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementLabsRD().pipe(
          map((res: any) =>
            GeneralActions.loadEngagementLabsRDResultsSuccess({
              engagementLabsRDResults: res.data.slice().sort(function (o1, o2) {
                return o1.id - o2.id;
              }),
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.loadEngagementLabsRDResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadEngagementCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementCountryResults),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementCountry().pipe(
          map((res: any) =>
            GeneralActions.loadEngagementCountryResultsSuccess({
              engagementCountryResults: res.data
                .slice()
                .sort(function (o1, o2) {
                  return o1.id - o2.id;
                }),
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.loadEngagementCountryResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadEngagementLeadResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementLeadResults),
      switchMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactMatch,
            requestId: action.searchQuery.requestId,
            searchText: action.searchQuery.searchText,
          })
          .pipe(
            map((res: any) =>
              GeneralActions.loadEngagementLeadResultsSuccess({
                engagementLeadSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                GeneralActions.loadEngagementLeadResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadEngagementTeamMemberResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementTeamMemberResults),
      switchMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactMatch,
            requestId: action.searchQuery.requestId,
            searchText: action.searchQuery.searchText,
          })
          .pipe(
            map((res: any) => {
              if(res.data.length == 0){
                res.data = [{
                  peopleKey: 0,
                  enterpriseID: '',
                  displaytxt:'',
                  name: '',
                  displayName: '',
                  nickName: '',
                  WorkPhone: '',
                  customMobile: '',
                  email: '',
                  organization1: '',
                  organization2: '',
                  firstname: '',
                  lastname: '',
                  level: '',
                  isFollowing: 0,
                  EngagementTeamMemberIndex: action.index,
                  isEmptyarrayHandling:true
                }]
              }
              else{
              res.data.forEach((e) => {
                e.EngagementTeamMemberIndex = action.index;
                e.isEmptyarrayHandling = false;
              });
            }
              return GeneralActions.loadEngagementTeamMemberResultsSuccess({
                engagementTeamMemberSearchResults: res.data,
              });
            }),
            catchError((error) =>
              of(
                GeneralActions.loadEngagementTeamMemberResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  getExternalOrganizationCategoriesResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getExternalOrganizationCategoriesResults),
      exhaustMap((action) =>
        this.referenceDataService.getOrganizationCategories().pipe(
          map((res: any) =>
            GeneralActions.getExternalOrganizationCategoriesResultsSuccess({
              organizationExternalCategoriesResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getExternalOrganizationCategoriesResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  getEIDropdownEntityResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getEIDropdownEntityResults),
      exhaustMap((action) =>
        this.referenceDataService.getEIDropdownEntityResults().pipe(
          map((res: any) =>
            GeneralActions.getEIDropdownEntityResultsSuccess({
              eiDropdownResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getEIDropdownEntityResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  getAccentureEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getAccentureEntities),
      exhaustMap((action) =>
        this.referenceDataService.getAccentureEntities().pipe(
          map((res: any) =>
            GeneralActions.getAccentureEntitiesSuccess({
              organizationAccentureEntitiesResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getAccentureEntitiesFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadOrganizationTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getOrganizationTypes),
      exhaustMap((action) =>
        this.referenceDataService.getOrganizationsTypes().pipe(
          map((res) =>
            GeneralActions.getOrganizationTypesSuccess({
              organizationTypes: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getOrganizationTypesFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  getTagsSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadTagsResults),
      exhaustMap((action) =>
        this.referenceDataService.getTags(action.searchQuery).pipe(
          map((res: any) =>
            GeneralActions.loadTagsResultsSuccess({
              tagsSearchResults: res.data,
            })
          ),
          catchError((error) =>
            of(
              GeneralActions.loadTagsResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadClientOrganizationSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getClientOrganizationSearchResults),
      exhaustMap((action) =>
        this.referenceDataService.getClients(action.searchQuery).pipe(
          map((res) =>
            GeneralActions.getClientOrganizationSearchResultsSuccess({
              clientOrganizationSearchResults: res.data,
            })
          ),
          catchError((error) =>
            of(
              GeneralActions.getClientOrganizationSearchResultsFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  loadDatesAndLocationsRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadDatesAndLocationsRooms),
      exhaustMap((action) =>
        this.referenceDataService
          .getDatesAndLocationsRooms(action.programId)
          .pipe(
            map((res) =>
              GeneralActions.loadDatesAndLocationsRoomsSuccess({
                datesAndLocationsRooms: res.data,
              })
            ),
            retry(4),
            delay(1000),
            catchError((error) =>
              of(
                GeneralActions.loadDatesAndLocationsRoomsFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  getEngagementTeamRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getEngagementTeamRoles),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementTeamRoles(action.programId).pipe(
          map((res) =>
            GeneralActions.getEngagementTeamRolesSuccess({
              engagementTeamRoles: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getEngagementTeamRolesFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly referenceDataService: ReferenceDataService
  ) {}
}
