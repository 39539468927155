import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { durationTextArea } from '@assets/data/index';

@Component({
  selector: 'ioh-duration',
  styleUrls: ['./duration.component.scss'],
  templateUrl: './duration.component.html',
})
export class DurationComponent implements OnInit {
  parentForm: AbstractControl;
  textarea = durationTextArea;

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.parentForm = this.controlContainer.control;
  }
}
