<ioh-loading *ngIf="this.loading"></ioh-loading>
<main class="container" ngClass.md="container__md" ngClass.sm="container__sm" ngClass.xs="container__xs">
    <div class="hubComments">
        <img src="../../../../../assets/images/logoTransparent.png" alt="logoTransparent">
        <h1 class="container__title">Engage Innovation User Experience Survey</h1>
    </div>
    <div class="container__form mat-elevation-z2" ngClass.md="container__form__md" ngClass.sm="container__form__sm">
        <div id="questions">
            <mat-expansion-panel class="mat-elevation-z0 panel display-contents"[expanded]="true">
              <mat-expansion-panel-header
                class="panel__header"
                [collapsedHeight]="'6rem'"
                [expandedHeight]="'6rem'"
                fxLayoutAlign="start end"
              >
              <h2 class="survey_title" *ngIf="this.showTitle">Questions for the {{programName}} engagement - {{engagementTitle}}</h2>
              </mat-expansion-panel-header>
              <div class="question_content">
                  <div *ngFor="let question of questionList; let i=index;" class="questionListBox">
                    <span class="radio__asterisk">*</span>
                      {{i+1}} - <span [innerHTML]="question.value"></span>
                      <div fxLayout="row">
                        <div *ngFor="let value of question.option" fxFlex="100%">
                          <ioh-radio-button
                            radioId="{{ value.optionId }}"
                            field="{{ value.field }}"
                            [values]="value.values"
                            [formGroup]="surveyForm"
                            ></ioh-radio-button>
                        </div>
                      </div>
                  </div>
              </div>
            </mat-expansion-panel>
        </div>
        <div id="additionalNotes" style="flex-direction:column;">
            <mat-expansion-panel class="mat-elevation-z0 panel display-contents" [expanded]="true">
                <mat-expansion-panel-header
                  class="panel__header"
                  [collapsedHeight]="'6rem'"
                  [expandedHeight]="'6rem'"
                  fxLayoutAlign="start end">
                    <h2 class="survey_title">Additional Notes</h2>
                </mat-expansion-panel-header>
                <div class="question_content">
                    <ioh-text-area
                      fxFlex.sm="100%"
                      fxFlex.xs="100%"
                      label="{{notesLabel}}"
                      name="Additional Notes"
                      textareaId="comments"
                      placeholder=""
                      [formGroup]="surveyForm"
                      value="comments"
                      [maxCharLimit]="1000"
                      description="text area where you add notes about survey"
                      height="10rem"
                      [required]=false
                    ></ioh-text-area>
                </div>
      </mat-expansion-panel>
        </div>
        <div class="container__footer surveyTerms" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
            fxLayout.xs="column" fxLayoutAlign.xl="space-between center" fxLayoutAlign.lg="space-between center"
            fxLayoutAlign.md="space-around center" fxLayoutAlign.sm="space-around center"
            fxLayoutAlign.xs="space-around center">
            <ioh-checkbox checkId="terms" value="checkbox" [formGroup]="baseForm" required=true
                label="Terms and Conditions selection">
                <div class="agreeBox">
                    I agree to the
                    <a class="container__link" routerLink="." (click)="openDialog($event)">Terms and Conditions</a>
                </div>
            </ioh-checkbox>
            <button mat-raised-button color="accent"
                [ngClass]="!surveyForm.valid || !termsAndConditionsAccepted || isSavingSurvey ? 'container__footer__submit--disabled' : 'container__footer__submit'"
                [disabled]="!surveyForm.valid || !termsAndConditionsAccepted || isSavingSurvey" (click)="saveSurvey()">
                <div *ngIf="isSavingSurvey; else text" fxLayout="row" fxLayoutAlign="start center" class="row-survery-Box">
                    <mat-spinner diameter="20"></mat-spinner>
                    <p class="container__form__submit__text">Saving ...</p>
                </div>
                <ng-template #text>
                    Submit
                </ng-template>
            </button>
        </div>
    </div>
</main>
<ng-template #TermAndConditionTemplate>
    <h2 class="modal__title" role="banner" mat-dialog-title>Terms and Conditions</h2>
    <mat-dialog-content role="contentinfo" class="mat-typography">
        <ng-container *ngFor="let content of contents">
            <h3 class="modal__title">{{content.title}}</h3>
            <p class="modal__text surveyText">{{content.text}}</p>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" role="navigation">
        <button mat-stroked-button color="primary" (click)="onDownload()">Download</button>
        <button class="modal__accept__button" mat-flat-button (click)="onAccept()" color="primary">Accept</button>
    </mat-dialog-actions>
</ng-template>
<ng-template #FailedSubmissionTemplate class="print__area">
    <h2 class="modal__title" role="banner" mat-dialog-title>Something went wrong!</h2>
    <mat-dialog-content role="contentinfo" class="mat-typography" [innerHTML]="errorMessage"
        style="white-space: pre-line;">
        {{errorMessage}}
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" role="navigation">
        <button class="modal__accept__button" mat-flat-button (click)="closeFailedSubmissionDialog()"
            color="primary">Got it!</button>
    </mat-dialog-actions>
</ng-template>