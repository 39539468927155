import { dropdownList } from './admin-details';
export const menuItems = [
  {
    icon: 'home',
    name: 'My Engagements',
    route: '/admin/home',
  },
  {
    icon: 'announcement',
    name: 'Requested Engagements',
    route: '/admin/requested',
  },
  {
    icon: 'update',
    name: 'Booked Engagements',
    route: '/admin/in-progress',
  },
  {
    icon: 'event',
    name: 'Event Dates',
    route: '/admin/event-dates',
  },
  {
    icon: 'date_range',
    name: 'Event Calendar',
    route: '/admin/calendar',
  },
  {
    icon: 'credit_card',
    name: 'Recovery Chargebacks',
    route: '/admin/pending',
  },
  {
    icon: 'list',
    name: 'All Engagements',
    route: '/admin/closed',
  },
  {
    icon: 'assessment',
    name: 'Summary Metrics',
    route: '/admin/metrics',
  },
  {
    icon: 'history',
    name: 'Client History',
    route: '/admin/client-history',
  },
  {
    icon: 'comment',
    name: 'Engagement Feedback',
    route: '/admin/feedback',
  },
];
export const hubArchive = [
  {
    field: 'hubArchive',
    radioId: 'hubArchive',
    values: ['Active Engagements', 'Archived Engagements'],
  },
];
export const downloadFields = [
  {
    type: 'multiSelect',
    flex: '100%',
    id: 'fiscalYearField',
    isMultiple: true,
    name: 'fiscalYear dropdown',
    field: dropdownList.fiscalYear,
    placeholder: 'Fiscal Year',
    values: dropdownList.fiscalYear,
  },
  {
    type: 'multiSelect',
    flex: '100%',
    id: 'archivefiscalYearField',
    isMultiple: true,
    name: 'fiscalYear dropdown',
    field: dropdownList.archivefiscalYear,
    placeholder: 'Fiscal Year',
    values: dropdownList.archivefiscalYear,
  },
  {
    type: 'multiSelect',
    flex: '100%',
    id: 'engagementStatusField',
    isMultiple: true,
    name: 'engagementStatus dropdown',
    field: dropdownList.engagementStatus,
    placeholder: 'Eng Status',
    values: dropdownList.engagementStatus,
  },
];
