import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SOND } from '@utils/index';
import { cloneDeep } from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class ClosedService {
  fiscalYearDropdown$ = new BehaviorSubject(null);
  inAllPage$ = new BehaviorSubject(null);

  formatMonthArray(seledtedMonth) {
    const result = cloneDeep(seledtedMonth);
    const formatedMonth = result.map((month) => {
      month.month = month.value.substr(0, 3);
      if (month.value != 'No Date Identified' && SOND.includes(month.month)) {
        month.fiscalYear = Number(month.value.substr(-2)) + 1;
      } else if (
        month.value != 'No Date Identified' &&
        !SOND.includes(month.month)
      ) {
        month.fiscalYear = Number(month.value.substr(-2));
      } else {
        month.fiscalYear = 'All';
      }
      return month;
    });
    return formatedMonth;
  }
}
