import { Utils } from './utils';

export class BookingTab extends Utils {
  FiscalYear: string;
  BookingID: string;
  Status: string;
  HubLocation: string;
  EngagementType: string;
  EngagementTitle: string;
  Summary: string;
  OrganizationID: string;
  Organization: string;
  OperatingGroup: string;
  Segment: string;
  G2000Rank: string;
  MCClassification: string;
  AccountStatus: string;
  Tags: string;
  StartDate: any;
  EndDate: any;
  Dates: string;
  Locations: string;
  AdminComments: string;
  EngagementGroup: string;
  EngagementLead: string;
  EngagementSponsor: string;
  PrimaryContact: string;
  AdditionalContacts: string;
  EngagementTeam: string;
  GroupParticipation: string;
  GroupEcosystem: string;
  DatesAndDurations: string;
  VisitType: string;
  SoftlockExpiration: any;
  ConsultationOwner: string;
  ConsultationCompleteDate: any;
  Background: string;
  ObjectivesandExpectations: string;
  SuccessCriteria: string;
  InternalPurpose: string;
  Challenges: string;
  AdditionalComments: string;
  MMSOpportunityIds: string;
  MMSOpportunities: string;
  NoMmsOpportunityId: string;
  AccentureAttendees: number;
  ClientCSuiteAttendees: number;
  ClientOtherAttendees: number;
  OtherAttendees: number;
  TotalAttendees: number;
  AttendeeCategory: string;
  LeadershipLevel: string;
  AttendeeDetails: string;
  RecoveryTotal: number;
  PassThroughEstimate: number;
  TotalDiscounts: number;
  TotalCost: number;
  ChargeDate: string;
  RecoveryComments: string;
  WBSs: string;
  CreateBy: string;
  ModifyBy: string;
  UpdatedAt: any;
  CreatedAt: any;

  constructor(workshop: any) {
    super();
    const program = workshop.program ? workshop.program : '';
    const general = workshop.general ? workshop.general : '';
    const engagement = general.engagement ? general.engagement : '';
    const engType = engagement.type ? engagement.type : '';
    const organizations = general.organizations ? general.organizations : [];
    const schedule = general.schedule ? general.schedule : '';
    const consul = workshop.consultation ? workshop.consultation : '';
    const recov = workshop.recovery ? workshop.recovery : '';
    const workshopStatus = workshop.status ? workshop.status : '';
    this.CreateBy = consul.createBy ? consul.createBy : 'Unknown User';
    this.ModifyBy = consul.modifyBy ? consul.modifyBy : 'Unknown User';
    this.UpdatedAt = consul.updatedAt
      ? this.dateTimeFormat(consul.updatedAt)
      : '';
    this.CreatedAt = consul.createdAt
      ? this.dateTimeFormat(consul.createdAt)
      : '';

    organizations.map((item) => {
      item.operatingGroup =
        item.type && item.type.value === 'Internal' ? '' : item.operatingGroup;
    });
    this.BookingID =
      workshop.id && program.code
        ? `${program.code} - ` + `${workshop.id}`
        : '';
    this.Status = workshopStatus.value ? workshopStatus.value : '';
    this.HubLocation = program.code ? program.code : '';
    this.EngagementTitle = engagement.title ? `${engagement.title}` : '';
    this.EngagementType = engType.value ? engType.value : '';
    this.Summary = engagement.summary ? engagement.summary : '';
    this.OrganizationID = this.mapJoin(this.getSAPID(organizations), 'id');
    this.Organization = organizations
      .map((elem) =>
        elem.type && elem.type.value === 'Internal'
          ? elem.accentureEntity && elem.accentureEntity.value
          : elem.organizationName
      )
      .join(this.JOIN_SEPARATOR);
    this.OperatingGroup = this.mapJoin(organizations, 'operatingGroup');
    this.Segment = this.mapJoin(organizations, 'industrySegment');
    this.G2000Rank = this.mapJoin(organizations, 'g2000Rank');
    this.MCClassification = this.mapJoin(organizations, 'diamond');
    this.AccountStatus = this.mapJoin(organizations, 'accountStatus');
    this.Tags = engagement.tags ? this.mapJoin(engagement.tags, 'name') : '';
    this.StartDate = engagement.engagementStartDate
      ? this.dateFormat(engagement.engagementStartDate)
      : null;
    this.EndDate = engagement.engagementEndDate
      ? this.dateFormat(engagement.engagementEndDate)
      : null;
    this.Dates = schedule.dates
      ? this.formatToDate(this.mapJoinDate(schedule.dates, 'date')) ||
        this.formatToDate(this.mapJoin(schedule.dates, 'eventDate'))
      : '';
    this.Locations = schedule.dates
      ? schedule.dates
          .map((date) =>
            date.location && date.location.value ? date.location.value : ''
          )
          .join(this.JOIN_SEPARATOR)
      : '';
    this.AdminComments = engagement.adminComments
      ? engagement.adminComments
      : '';
    this.EngagementGroup =
      engagement.group && engagement.group.value ? engagement.group.value : '';
    this.EngagementSponsor =
      !!general &&
      !!general.contactDetails &&
      general.contactDetails.engagementSponsor
        ? general.contactDetails.engagementSponsor
        : '';
    this.PrimaryContact =
      !!general &&
      !!general.contactDetails &&
      general.contactDetails.primaryPointOfContact
        ? general.contactDetails.primaryPointOfContact
        : '';
    this.AdditionalContacts =
      !!general &&
      !!general.contactDetails &&
      general.contactDetails.additionalContacts
        ? general.contactDetails.additionalContacts.join(';')
        : '';
    this.EngagementLead = engagement.lead ? engagement.lead : '';
    this.EngagementTeam = engagement.team
      ? this.mapJoin(engagement.team, 'name')
      : '';
    this.GroupParticipation = engagement.participationGroup
      ? this.mapJoin(engagement.participationGroup, 'value')
      : '';
    this.GroupEcosystem = engagement.groupEcosystem
      ? this.mapJoin(engagement.groupEcosystem, 'value')
      : '';
    this.DatesAndDurations = consul.duration ? consul.duration : '';
    this.VisitType = consul.visitType ? consul.visitType : '';
    this.SoftlockExpiration = consul.softLockExpirationDate
      ? this.dateFormat(consul.softLockExpirationDate)
      : null;
    this.ConsultationOwner = consul.consultationOwner
      ? consul.consultationOwner
      : '';
    this.ConsultationCompleteDate = consul.completeDate
      ? this.dateFormat(consul.completeDate)
      : null;
    this.Background = consul.backgroundText ? consul.backgroundText : '';
    this.ObjectivesandExpectations = consul.objectivesText
      ? consul.objectivesText
      : '';
    this.SuccessCriteria = consul.successCriteria ? consul.successCriteria : '';
    this.InternalPurpose =
      consul.internalPurpose && consul.internalPurpose.value
        ? consul.internalPurpose.value
        : '';
    this.Challenges = consul.challengesText ? consul.challengesText : '';
    this.AdditionalComments = consul.additionalComments
      ? consul.additionalComments
      : '';
    this.MMSOpportunityIds = consul.opportunities
      ? this.mapJoin(consul.opportunities, 'id')
      : '';
    this.MMSOpportunities = consul.opportunities
      ? this.mapJoin(consul.opportunities, 'description')
      : '';
    this.NoMmsOpportunityId = consul.mmsRadioValue ? consul.mmsRadioValue : '';
    this.AccentureAttendees = consul.accentureAttendees
      ? +consul.accentureAttendees
      : 0;
    this.ClientCSuiteAttendees = consul.clientCSuiteAttendees
      ? +consul.clientCSuiteAttendees
      : 0;
    this.ClientOtherAttendees = consul.clientOtherAttendees
      ? +consul.clientOtherAttendees
      : 0;
    this.OtherAttendees = consul.otherAttendees ? +consul.otherAttendees : 0;
    this.TotalAttendees = consul.totalAttendees ? +consul.totalAttendees : 0;
    this.AttendeeCategory = general.attendeeCategory
      ? general.attendeeCategory
      : '';
    this.LeadershipLevel = consul.leadershipLevel ? consul.leadershipLevel : '';
    this.AttendeeDetails = consul.attendeeDetails ? consul.attendeeDetails : '';
    this.RecoveryTotal =
      recov.recoveryTotal && recov.costs.length > 0 ? +recov.recoveryTotal : 0;
    this.PassThroughEstimate =
      recov.passThroughEstimate && recov.costs.length > 0
        ? +recov.passThroughEstimate
        : 0;
    this.TotalDiscounts =
      recov.totalDiscounts && recov.costs.length > 0
        ? +recov.totalDiscounts
        : 0;
    this.TotalCost = this.RecoveryTotal + this.TotalDiscounts;
    this.ChargeDate = recov.costs
      ? this.mapJoinChargeDate(recov.costs, 'chargeDate')
      : '';
    this.RecoveryComments = recov.comments ? recov.comments : '';
    this.WBSs = recov.costs ? this.mapJoin(recov.costs, 'code') : '';
    this.FiscalYear = workshop.fiscalYear || '';
  }

  public dateTimeFormat(time) {
    var timeDate = new Date(time);
    var utcReduce6 = timeDate.setHours(timeDate.getHours() - 6);
    return new Date(utcReduce6);
  }

  public getSAPID(organizations) {
    organizations.forEach((organization) => {
      organization.id = organization.clientName
        ? organization.clientName.split('-')[0]
        : '';
    });
    return organizations;
  }

  public static getColumns = [
    { header: 'Fiscal Year', key: 'FiscalYear' },
    { header: 'Booking ID', key: 'BookingID' },
    { header: 'Engagement Status', key: 'Status' },
    { header: 'Program', key: 'HubLocation' },
    { header: 'Engagement Type', key: 'EngagementType' },
    { header: 'Engagement Title', key: 'EngagementTitle' },
    { header: 'Summary', key: 'Summary' },
    { header: 'Organization ID', key: 'OrganizationID' },
    { header: 'Organization', key: 'Organization' },
    { header: 'Market', key: 'OperatingGroup' },
    { header: 'Industry', key: 'Segment' },
    { header: 'G2000 Rank', key: 'G2000Rank' },
    { header: 'MC Classification', key: 'MCClassification' },
    { header: 'Account Status', key: 'AccountStatus' },
    { header: 'Tags', key: 'Tags' },
    { header: 'Engagement Start Date', key: 'StartDate' },
    { header: 'Engagement End Date', key: 'EndDate' },
    { header: 'Event Dates', key: 'Dates' },
    { header: 'Locations', key: 'Locations' },
    { header: 'Admin Comments', key: 'AdminComments' },
    { header: 'Group Owner', key: 'EngagementGroup' },
    { header: 'Engagement Sponsor', key: 'EngagementSponsor' },
    { header: 'Primary Contact', key: 'PrimaryContact' },
    { header: 'Additional Contacts', key: 'AdditionalContacts' },
    { header: 'Engagement Lead', key: 'EngagementLead' },
    { header: 'Engagement Team', key: 'EngagementTeam' },
    {
      header: 'Group Participation - Innovation Internal',
      key: 'GroupParticipation',
    },
    {
      header: 'Group Participation - Ecosystem External',
      key: 'GroupEcosystem',
    },
    { header: 'Dates and Durations', key: 'DatesAndDurations' },
    { header: 'Visit Type', key: 'VisitType' },
    { header: 'Softlock Expiration', key: 'SoftlockExpiration' },
    { header: 'Consultation Owner', key: 'ConsultationOwner' },
    {
      header: 'Consultation Complete Date',
      key: 'ConsultationCompleteDate',
    },
    { header: 'Request Overview', key: 'Background' },
    {
      header: 'Scope',
      key: 'ObjectivesandExpectations',
    },
    { header: 'Success Criteria', key: 'SuccessCriteria' },
    { header: 'Engagement Purpose', key: 'InternalPurpose' },
    { header: 'Challenges', key: 'Challenges' },
    { header: 'Additional Comments', key: 'AdditionalComments' },
    { header: 'MMS Opportunity ID', key: 'MMSOpportunityIds' },
    { header: 'MMS Opportunity Name', key: 'MMSOpportunities' },
    { header: 'MMS N/A', key: 'NoMmsOpportunityId' },
    { header: 'Internal Attendees', key: 'AccentureAttendees' },
    { header: 'Client Attendees (C-Suite)', key: 'ClientCSuiteAttendees' },
    { header: 'Client Attendees', key: 'ClientOtherAttendees' },
    { header: 'External Attendees', key: 'OtherAttendees' },
    { header: 'Number of Attendees', key: 'TotalAttendees' },
    { header: 'Organization Category', key: 'AttendeeCategory' },
    { header: 'Leadership Level', key: 'LeadershipLevel' },
    { header: 'Attendee Details', key: 'AttendeeDetails' },
    {
      header: 'Total Costs',
      key: 'TotalCost',
      style: { numFmt: '$#,##0.00' },
    },
    {
      header: 'Total Discounts',
      key: 'TotalDiscounts',
      style: { numFmt: '$#,##0.00' },
    },
    {
      header: 'Total Recovery',
      key: 'RecoveryTotal',
      style: { numFmt: '$#,##0.00' },
    },
    {
      header: 'Direct Charges Estimated',
      key: 'PassThroughEstimate',
      style: { numFmt: '$#,##0.00' },
    },
    { header: 'Charge Date', key: 'ChargeDate' },
    { header: 'Recovery Comments', key: 'RecoveryComments' },
    { header: 'WBSs', key: 'WBSs' },
    { header: 'Created Date', key: 'CreatedAt' },
    { header: 'Created By', key: 'CreateBy' },
    { header: 'Modified Date', key: 'UpdatedAt' },
    { header: 'Modified By', key: 'ModifyBy' },
  ];
}
