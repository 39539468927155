<main class="container">
    <section class="responseWrapper">
        <h1 class="response" class="response--success">
            <i class="material-icons response__icon">
                check_circle
            </i>
            <span class="response__title">
                Success
            </span>
        </h1>
        <p class="response__text">
            The Survey has been submitted!
        </p>
    </section>
</main>
