import {
  ADMIN_FEATURE_KEY,
  AdminPartialState,
  AdminState,
  adminAdapter,
} from './admin.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import isEmpty from 'lodash/isEmpty';

// Lookup the 'Admin' feature state managed by NgRx
export const getAdminState = createFeatureSelector<
  AdminPartialState,
  AdminState
>(ADMIN_FEATURE_KEY);

const { selectAll, selectEntities } = adminAdapter.getSelectors();

export const getOriginalDocList = createSelector(
  getAdminState,
  (state: AdminState) => state.originalDocList
);
export const getUserId = createSelector(
  getAdminState,
  (state: AdminState) => state.userId
);

export const getProgramId = createSelector(
  getAdminState,
  (state: AdminState) => state.programId
);

export const getProgramList = createSelector(
  getAdminState,
  (state: AdminState) => state.programList
);

export const getAdminProgramList = createSelector(
  getAdminState,
  (state: AdminState) => state.adminProgramList
);

export const getUserInfoList = createSelector(
  getAdminState,
  (state: AdminState) => state.userInfoList
);
export const getProgramCode = createSelector(
  getAdminState,
  (state: AdminState) => state.programCode
);

export const getResponse = createSelector(
  getAdminState,
  (state: AdminState) => state.response
);

export const getWorkshopEntities = createSelector(
  getAdminState,
  (state: AdminState) => selectEntities(state)
);

export const getWorkshops = createSelector(getAdminState, (state: AdminState) =>
  selectAll(state)
);

export const getSelectedId = createSelector(
  getAdminState,
  (state: AdminState) => state.selectedId
);

export const updatedSuccessfully = createSelector(
  getAdminState,
  (state: AdminState) => state.updatedSuccessfully
);

export const updatedAdminSuccessfully = createSelector(
  getAdminState,
  (state: AdminState) => state.updatedAdminSuccessfully
);

export const getWorkshop = createSelector(
  getAdminState,
  (state: AdminState) => state.getWorkshop
);

export const saveWorkshopError = createSelector(
  getAdminState,
  (state: AdminState) => state.saveWorkshopError
);

export const loadOrgList = createSelector(
  getAdminState,
  (state: AdminState) => state.orgList
);

export const saveAdminError = createSelector(
  getAdminState,
  (state: AdminState) => state.saveAdminError
);

export const saveViewSuccessfully = createSelector(
  getAdminState,
  (state: AdminState) => state.saveViewSuccessfully
);

export const saveViewError = createSelector(
  getAdminState,
  (state: AdminState) => state.saveViewError
);

export const getWorkshopStatus = createSelector(
  getAdminState,
  (state: AdminState) => state.workshopStatus
);

export const getCancellationReasons = createSelector(
  getAdminState,
  (state: AdminState) => state.cancellationReasons
);

export const getSelectedHub = createSelector(
  getAdminState,
  (state: AdminState) => state.selectedHub
);

export const getDropdownFilter = createSelector(
  getAdminState,
  (state: AdminState) => state.dropdownFilter
);

export const getSelectedAdminHub = createSelector(
  getAdminState,
  (state: AdminState) => state.selectedAdminHub
);

export const getSelected = createSelector(
  getWorkshopEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const getSelectedGeneral = createSelector(
  getWorkshopEntities,
  getSelectedId,
  (entities, selectedId) =>
    selectedId &&
    !isEmpty(entities) &&
    entities[selectedId] &&
    entities[selectedId].general
);

export const getSelectedConsultation = createSelector(
  getWorkshopEntities,
  getSelectedId,
  (entities, selectedId) =>
    selectedId &&
    !isEmpty(entities) &&
    entities[selectedId] &&
    entities[selectedId].consultation
);

export const getSelectedRecovery = createSelector(
  getWorkshopEntities,
  getSelectedId,
  (entities, selectedId) =>
    selectedId &&
    !isEmpty(entities) &&
    entities[selectedId] &&
    entities[selectedId].recovery
);

export const getSelectedDocument = createSelector(
  getWorkshopEntities,
  getSelectedId,
  (entities, selectedId) =>
    selectedId &&
    !isEmpty(entities) &&
    entities[selectedId] &&
    entities[selectedId].document
);

export const removeAccessSuccessfully = createSelector(
  getAdminState,
  (state: AdminState) => state.removeAccessSuccessfully
);

export const removeUserAccessError = createSelector(
  getAdminState,
  (state: AdminState) => state.removeUserAccessError
);

export const getUserNameSearchResults = createSelector(
  getAdminState,
  (state: AdminState) => state.userNameSearchResults
);

export const getWorkshopIds = createSelector(
  getAdminState,
  (state: AdminState) => state.ids
);
export const getDocument = createSelector(
  getAdminState,
  (state: AdminState) => state.document
);
export const getDocumentPrepopulated = createSelector(
  getAdminState,
  (state: AdminState) => state.documentPrepopulate
);
export const getEngStatus = createSelector(
  getAdminState,
  (state: AdminState) => state.engStatus
);
export const getCloneWorkshop = createSelector(
  getAdminState,
  (state: AdminState) => state.cloneWorkshop
);

export const getSelectedArchiveWorkshop = createSelector(
  getAdminState,
  (state: AdminState) => state.selectedArchiveWorkshop
);
export const getArchiveWorkshops = createSelector(
  getAdminState,
  (state: AdminState) => state.archiveWorkshop
);
export const getIsCloneWorkshop = createSelector(
  getAdminState,
  (state: AdminState) => state.IsCloneWorkshop
);
export const getViewSuccess = createSelector(
  getAdminState,
  (state: AdminState) => state.pipelineView
);

export const saveViewSuccess = createSelector(
  getAdminState,
  (state: AdminState) => state.response
);

export const getInitWorkshops = createSelector(
  getAdminState,
  (state: AdminState) => state.workshops
);
