import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';

import * as FormActions from './form.actions';
import { OrchestratorService } from '../../../orchestrator-service';
import { ProgramService } from '../../../program';
import { ReferenceDataService } from '../../../reference-data';
import { catchError, delay, exhaustMap, map, retry } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class FormEffects {
  loadTermsandConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.loadTermsAndConditions),
      exhaustMap((action) =>
        this.referenceDataService.getTermAndCondition(action.programId).pipe(
          map((res) =>
            FormActions.loadTermsAndConditionsSuccess({
              termsAndConditions: res.data,
            })
          ),
          retry(4),
          delay(1500),
          catchError((error) =>
            of(FormActions.loadTermsAndConditionsFailure({ error: error }))
          )
        )
      )
    )
  );

  loadPrograms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.loadAllPrograms),
      exhaustMap((action) =>
        this.programService.getPrograms().pipe(
          map((res) =>
            FormActions.loadProgramsSuccess({
              programs: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              FormActions.loadProgramsFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  createRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.createRequest),
      exhaustMap((action) =>
        this.orchestratorService.createRequest(action.request).pipe(
          map((res) =>
            FormActions.createRequestSuccess({
              response: res,
            })
          ),
          retry(4),
          delay(1500),
          catchError((error) =>
            of(FormActions.createRequestFailure({ error: error }))
          )
        )
      )
    )
  );

  saveSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.saveSurvey),
      exhaustMap((action) =>
        this.orchestratorService.saveSurvey(action.surveyRequest).pipe(
          map((res) =>
            FormActions.saveSurveySuccess({
              surveyResponse: res,
            })
          ),
          retry(4),
          delay(1500),
          catchError((error) =>
            of(FormActions.saveSurveyFailure({ surveyError: error }))
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly referenceDataService: ReferenceDataService,
    private readonly programService: ProgramService,
    private readonly orchestratorService: OrchestratorService
  ) {}
}
