export const textAreas = [
  {
    description: 'Background description',
    height: '4rem',
    id: 'background',
    label: 'Request Overview',
    limit: 1000,
    name: 'Background',
    placeholder:
      "Please enter as much detail around this request and what is hoped to be explored or accomplished.  If known, things like technology or business context are great details, but this will also be covered in the initial consultation call, so if you don't have these details at this time it's OK.",
    required: true,
    value: 'backgroundText',
  },
  {
    description: 'Objectives and Expectations',
    height: '4rem',
    id: 'objectives-expectations',
    label: 'Scope',
    limit: 1000,
    name: 'Objectives and Expectations',
    placeholder:
      'What do you think you need from the Innovation team, e.g. a meeting, a workshop, a content request, something else, etc.',
    required: true,
    value: 'objectivesText',
  },
];
