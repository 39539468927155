<div class="table" [ngClass]="{'admin-table' : tableName === 'User List'}">
  <img [hidden]=!showAdminTooltip src="../../../../../assets/images/admin-tooltip.png" alt="admin-tooltip" id="admin-tooltip-img">
  <div class="table__header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem"  [ngClass]="{'all-engagements-filter' : tableName === 'All Engagements'}">
    <h3 *ngIf="tableName !== 'User List' && tableName !== 'Recovery Chargebacks' && tableName !== 'All Engagements'"
      [ngClass]="{'urgent' : urgentTableName}" fxFlex="70%" tabindex="0">{{tableName}}</h3>
    <div *ngIf="tableName === 'Recovery Chargebacks' || tableName === 'All Engagements'" fxFlex="90%"></div>
    <div *ngIf="tableName === 'User List'" fxFlex="70%">
      <button class="table__button__export" mat-flat-button color="primary" fxFlex="2%" matTooltip="Add User"
        matTooltipPosition="below" (click)="addUser()">
        <span class="material-icons">
          add
        </span>
      </button>
      <span class="btn-text">Add User</span>
    </div>
    <mat-form-field class="filter-field" [formGroup]="textFilterForm" fxFlex="25%" *ngIf="(initialLoad && !noFilter) || (dataSource?.data?.length > 0 && tableName!=='Client History')">
      <mat-label>Filter</mat-label>
      <input matInput placeholder="Filter" [(ngModel)]="textFilter" formControlName="textFilterCtrl" maxlength="100">
    </mat-form-field>
    <button [disabled]="!allReportData" class="table__button__export exportReport" mat-flat-button color="primary" fxFlex="2%" *ngIf="hasReport && !(triggerLoading$ | async)"
      matTooltip="Download Table" matTooltipPosition="below" (click)="exportTable(reportColNames)">
      <mat-icon> save_alt </mat-icon>
    </button>
  </div>
  <div>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" ngClass.sm="table__small__device"
      ngClass.xs="table__small__device" #table>
      <ng-container *ngFor="let disCol of colNamesKeys; let i = index" matColumnDef="{{disCol}}">
        <ng-container *ngIf="disCol ==='selected'; else notSelected">
          <th class="table__header" mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              [disabled]="dataSource && getPageData().length == 0"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isEntirePageSelected()"
              [indeterminate]="selection.hasValue() && !isEntirePageSelected()"
              (keydown.enter)="onCheckboxKeydown($event)"
              id="selectAll"
              color="primary"
              class="table__checkbox">
              <label fxHide="true">select all</label>
            </mat-checkbox>
           </th>
           <td mat-cell *matCellDef="let element" tabindex="0">
            <mat-checkbox
              [disabled]="element.chargeStatus=='Direct Charge'"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null"
              [checked]="selection.isSelected(element)"
              (keydown.enter)="onCheckboxKeydownRow($event, element)">
              color="primary"
              class="table__checkbox recoveryCheckbook">
              <label fxHide="true">select</label>
            </mat-checkbox>
           </td>
        </ng-container>
        <ng-template #notSelected>
          <th [hidden]="disCol == 'tags'" class="table__header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortPage(disCol)"> 
            {{colNames[disCol]}} 
            <mat-icon class="material-icons header-icon" tabindex="0" id='icon-admin' *ngIf="(colNames[disCol]==='Hub Reminder Emails' || colNames[disCol]==='Event Emails') && tableName === 'User List'" (mouseover)="iconIn()" (mouseout)="iconOut()" (focusin)="iconIn()" (focusout)="iconOut()">help</mat-icon>
            <mat-icon class="material-icons header-icon" tabindex="0" matTooltip="Admin Users are able to add, edit and remove users from the hub they are admins for" *ngIf="colNames[disCol]==='Access Level' && tableName === 'User List'">help</mat-icon>
          </th>
        </ng-template>
        <td mat-cell *matCellDef="let element" tabindex="0" (click)="openWorkshop(element)"
          [hidden]="disCol == 'tags'"
          (keydown.enter)="openWorkshop(element)" matTooltip="{{element[disCol] !='downloadCSI'? element[disCol]:'Download .ics'}}" [matTooltipShowDelay]="500"
          [matTooltipHideDelay]="500" matTooltipPosition="below" [ngClass]="{'numberCol' : disCol==='recoveryTotal','access-deny' : element.accessDeny}">
          <div *ngIf="element[disCol]==='edit delete'; else text">
            <button [disabled]="(!!element.userId && element.userId == currentUser) || isDisabled" mat-flat-button color="primary" class="square" (click)="editRow(element)">
              <span class="material-icons">
                edit
              </span>
            </button>
            <button [disabled]="!!element.userId && element.userId == currentUser || isDisabled" mat-flat-button color="primary" class="square" (click)="deleteRow(element)">
              <span class="material-icons">
                delete
              </span>
            </button>
          </div>
          <div *ngIf="element[disCol]==='delete' && tableName === 'User List'; else text2">
            <button mat-flat-button color="primary" class="square"
               (click)="deleteRow(element)">
              <span class="material-icons">
                delete
              </span>
            </button>
          </div>
          <!-- add download button for Event Date Tables -->
          <div *ngIf="element[disCol]==='downloadCSI'; else text2">
            <button class="square" mat-flat-button color="primary"
              (click)="downloadCSIRow(element)">
              <mat-icon class="event-date-download"> save_alt </mat-icon>
            </button>
          </div>
          <div *ngIf="element[disCol]==='More'">
            <button class="square width-50px" mat-flat-button color="primary"
              (click)="showFeedbackPopup(element)">
              <span>More</span>
            </button>
          </div>
          <ng-template #text>
            {{element[disCol] !== 'delete' && element[disCol] !== 'downloadCSI' && element[disCol] !== 'More'? element[disCol]:''}}
          </ng-template>
          <ng-template #text2 *ngIf="tableName === 'User List'">
            {{element[disCol]}}
          </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="colNamesKeys"></tr>
      <tr mat-row *matRowDef="let row; columns: colNamesKeys;"></tr>
    </table>
    <div [hidden]="hasPagination && !alldataFetchDone">
      <mat-paginator 
      [pageSizeOptions] = "pageSizeOption"
      [pageSize]="pageLength" showFirstLastButtons
      (page)="clearSelections()"
      [style.background-color]="colour"
    ></mat-paginator>
    </div>
      <div class="ioh-pagination" [hidden]="!(hasPagination && !alldataFetchDone)">
        <span class="text">Items per page:</span>
        <mat-form-field appearance="fill">
          <mat-select [(ngModel)]="ioh_pageSize" (selectionChange)="pageSizeChange()">
            <mat-option  [value]="option" *ngFor="let option of pageSizeOption">{{option}}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <select name="ioh-pagesize" id="ioh-pagesize">
          <option [ngValue]="option" >{{option}}</option>
        </select> -->
        <span class="text">{{pageRange}} of {{totalCount}}</span>
        <!-- button  First page-->
        <button #tooltip="matTooltip"
        matTooltip="First page" matTooltipPosition="above" [matTooltipDisabled]="pager.currentPage === 1" mat-icon-button="" type="button" class="mat-focus-indicator mat-paginator-navigation-first mat-icon-button mat-button-base ng-star-inserted" aria-label="First page" 
        [disabled]="pager.currentPage === 1"
        (click)="setPage(1)"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon" alt="pagination-icon" title="First Page"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path></svg></span><div matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></div><div class="mat-button-focus-overlay"></div></button>
        <!-- button  Previous page-->
        <button #tooltip="matTooltip"
        matTooltip="Previous page" matTooltipPosition="above" [matTooltipDisabled]="pager.currentPage === 1" mat-icon-button="" type="button" class="mat-focus-indicator mat-paginator-navigation-previous mat-icon-button mat-button-base" ng-reflect-message="Previous page"
        [disabled]="pager.currentPage === 1"
        (click)="setPage(pager.currentPage - 1)"
        ng-reflect-position="above" aria-label="Previous page"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon" title="Previeous Page"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg></span><div matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></div><div class="mat-button-focus-overlay"></div></button>
        <!-- button  Next page-->
        <button #tooltip="matTooltip"
        matTooltip="Next page" matTooltipPosition="above" [matTooltipDisabled]="pager.currentPage === totalpage" mat-icon-button="" type="button" class="mat-focus-indicator mat-paginator-navigation-next mat-icon-button mat-button-base" ng-reflect-message="Next page" 
        [disabled]="pager.currentPage === totalpage"
        (click)="setPage(pager.currentPage + 1)"
        ng-reflect-disabled="false" ng-reflect-position="above" aria-label="Next page"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon" alt="pagination-icon" title="Next Page"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span><div matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></div><div class="mat-button-focus-overlay"></div></button>
        <!-- button  Last page-->
        <button #tooltip="matTooltip"
        matTooltip="Last page" matTooltipPosition="above" [matTooltipDisabled]="pager.currentPage === totalpage" mat-icon-button="" type="button" class="mat-focus-indicator mat-paginator-navigation-last mat-icon-button mat-button-base ng-star-inserted" ng-reflect-message="Last page" 
        [disabled]="pager.currentPage === totalpage"
        (click)="setPage(totalpage)"
        ng-reflect-disabled="false" ng-reflect-position="above" aria-label="Last page"><span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon" alt="pagination-icon" title="Last Page"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path></svg></span><div matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></div><div class="mat-button-focus-overlay"></div></button>
        <!-- button End -->
      </div>
  <!-- </div> -->
  <div *ngIf="!noFilter" class="no-data" [hidden]="(dataSource?.data?.length > 0) || !dataSource || startInput" tabindex="0">
    No data to display for this table.
  </div>
  <div [hidden]="((data$ | async) && (loadingFlag || noNeedSpinner || hasPagination)) || !(listviewLoading$ | async) || !startInput">
    <mat-spinner  [ngClass]="{'pendingList' : tableName === 'Recovery Chargebacks' || tableName === 'All Engagements'}" class="no-data" color="accent" diameter="20"></mat-spinner>
  </div>
</div>
<!-- <div [hidden]="((data$ | async) && (loadingFlag || noNeedSpinner || hasPagination)) || !(listviewLoading$ | async)">
  <h3 *ngIf="tableName !== 'User List' && tableName !== 'Recovery Chargebacks' && tableName !== 'All Engagements'"
    [ngClass]="{'urgent' : urgentTableName}">{{tableName}}</h3>
  <mat-spinner  [ngClass]="{'pendingList' : tableName === 'Recovery Chargebacks' || tableName === 'All Engagements'}" class="no-data" color="accent" diameter="20"></mat-spinner>
</div> -->
<div *ngIf="triggerLoading$ | async">
  <mat-spinner class="no-data recoveryLoading" color="accent" diameter="20"></mat-spinner>
</div>