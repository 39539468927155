import { Utils } from './utils';

export class FeedbackTab extends Utils {
  EngagementID: string;
  programCode: string;
  EnterpriseID: string;
  EngagementTitle: string;
  OrganizationName: string;
  FeedbackDate: any;
  Q1: string;
  Q2: string;
  Q3: string;
  Q4: string;
  Q5: string;
  Comments: string;

  constructor(workshop: any, survey: any) {
    super();
    const program = workshop.program;
    this.programCode = program.code;
    const general = workshop.general;
    const feedback = survey;
    const engagement = general.engagement;
    const organizations = general.organizations ? general.organizations : [];
    this.EngagementID =
      workshop.id && program.code
        ? `${program.code} - ` + `${workshop.id}`
        : '';
    this.EnterpriseID = feedback.surveyUser ? `${feedback.surveyUser}` : '';
    this.EngagementTitle = engagement.title ? `${engagement.title}` : '';
    this.OrganizationName = organizations
      .map((elem) =>
        elem.type && elem.type.value === 'Internal'
          ? elem.accentureEntity && elem.accentureEntity.value
          : elem.organizationName
      )
      .join(this.JOIN_SEPARATOR);
    this.FeedbackDate = feedback.updatedAt
      ? this.dateFormat(feedback.updatedAt)
      : null;
    this.Q1 = feedback.q1Answer ? `${feedback.q1Answer}` : '';
    this.Q2 = feedback.q2Answer ? `${feedback.q2Answer}` : '';
    this.Q3 = feedback.q3Answer ? `${feedback.q3Answer}` : '';
    this.Q4 = feedback.q4Answer ? `${feedback.q4Answer}` : '';
    this.Q5 = feedback.q5Answer ? `${feedback.q5Answer}` : '';
    this.Comments = feedback.comments ? `${feedback.comments}` : '';
  }

  public static getColumns = [
    { header: 'Engagement ID', key: 'EngagementID' },
    { header: 'Program', key: 'programCode' },
    { header: 'Enterprise ID', key: 'EnterpriseID' },
    { header: 'Engagement Title', key: 'EngagementTitle' },
    { header: 'Organization Name', key: 'OrganizationName' },
    { header: 'Feedback Date', key: 'FeedbackDate' },
    {
      header:
        '1.Overall, how satisfied were you with your experience at the Innovation Hub?',
      key: 'Q1',
    },
    {
      header:
        '2.How likely are you to return to the Innovation Hub with your client?',
      key: 'Q2',
    },
    {
      header:
        "3.How satisfied were you with the Hub Team's planning, preparation, and execution of your event?",
      key: 'Q3',
    },
    {
      header:
        '4.How satisfied were you with the physical/virtual experience of your event?',
      key: 'Q4',
    },
    {
      header:
        "5.How satisfied were you with the event outcome and the ability to enhance the client's perception of Accenture?",
      key: 'Q5',
    },
    {
      header:
        'Please share any additional feedback that you have for the Innovation Hub team. Thank you!',
      key: 'Comments',
    },
  ];
}
