import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'ioh-logout',
    styleUrls: ['./logout.component.scss'],
    templateUrl: './logout.component.html',
      host: {
        '(window:resize)': 'onResize()'
      }
  })

  export class LogoutComponent implements OnInit{

    constructor( private readonly authService: MsalService){}
    
    ngOnInit(){
      this.logout();
    }

    logout(){
      sessionStorage.clear();
      localStorage.clear();
      this.authService.logout();
    }
}

  
  
  
   
