export const radioOptions = [
  {
    font: 'Graphik Medium',
    id: 'visit-option',
    label: 'Duration',
    value: ['Half Day or Less', 'Full Day', 'Multi-Day'],
  },
];

export const radioBtnVisitTypeFields = [
  {
    field: 'visitType',
    label: 'Visit Type',
    radioId: 'visitType',
    values: ['In Person', 'Virtual', 'Hybrid'],
  },
];

export const mmsRadio = [
  {
    field: 'noMmsOpportunityId',
    label: '',
    radioId: 'noMmsOpportunityId',
    values: ['No MMS ID', 'MMS ID Pending', 'Added to MMS Campaign'],
  },
];

export const noCampaignMmsRadio = [
  {
    field: 'noMmsOpportunityId',
    label: '',
    radioId: 'noMmsOpportunityId',
    values: ['No MMS ID', 'MMS ID Pending'],
  },
];
