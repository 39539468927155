import { createAction, props } from '@ngrx/store';

export const markAsPrepopulated = createAction(
  '[Consultation] Set prepopulated',
  props<{ value: boolean }>()
);

export const loadInternalPurposeResults = createAction(
  '[Consultation] Load InternalPurposeResults',
  props<{ programId: string }>()
);

export const loadInternalPurposeResultsSuccess = createAction(
  '[Consultation] Load InternalPurposeResults Success',
  props<{ internalPurposeResults: any }>()
);

export const loadInternalPurposeResultsFailure = createAction(
  '[Consultation] Load InternalPurposeResults Failure',
  props<{ error: any }>()
);

export const selectInternalPurpose = createAction(
  '[Consultation] Select InternalPurpose',
  props<{ selectedInternalPurpose: any }>()
);

export const loadConsultationOwnerSearchResults = createAction(
  '[Consultation] Load ConsultationOwnerSearchResults',
  props<{
    searchQuery: {
      searchtxt: string;
      requestid: number;
      exactmatch: number;
    };
  }>()
);

export const loadConsultationOwnerSearchResultsSuccess = createAction(
  '[Consultation] Load ConsultationOwnerSearchResults Success',
  props<{ consultationOwnerSearchResults: any }>()
);
export const loadConsultationOwnerSearchResultsFailure = createAction(
  '[Consultation] Load ConsultationOwnerSearchResults Failure',
  props<{ error: any }>()
);

export const updateConsultationOwner = createAction(
  '[Consultation] Update Selected ConsultationOwner',
  props<{ consultationOwner: string }>()
);

export const updateCompleteConsultationDate = createAction(
  '[Consultation] Update CompleteConsultationDate',
  props<{ completeConsultationDate: any }>()
);

export const updateBackgroundText = createAction(
  '[Consultation] Update BackgroundText',
  props<{ backgroundText: string }>()
);

export const updateObjectivesText = createAction(
  '[Consultation] Update ObjectivesText',
  props<{ objectivesText: string }>()
);

export const updateAdditionalComments = createAction(
  '[Consultation] Update AdditionalComments',
  props<{ additionalComments: string }>()
);

export const updateAccentureAttendees = createAction(
  '[Consultation] Update AccentureAttendees',
  props<{ accentureAttendees: number }>()
);

export const updateClientOtherAttendees = createAction(
  '[Consultation] Update ClientOtherAttendees',
  props<{ clientOtherAttendees: number }>()
);

export const updateOtherAttendees = createAction(
  '[Consultation] Update OtherAttendees',
  props<{ otherAttendees: number }>()
);

export const updateTotalAttendees = createAction(
  '[Consultation] Update TotalAttendees',
  props<{ totalAttendees: number }>()
);

export const updateLeadershipLevel = createAction(
  '[Consultation] Update LeadershipLevel',
  props<{ leadershipLevel: string }>()
);

export const updateAttendeeDetails = createAction(
  '[Consultation] Update AttendeeDetails',
  props<{ attendeeDetails: string }>()
);

export const updateSelectedOpportunityIds = createAction(
  '[Consultation] Update SelectedOpportunityIds',
  props<{ selectedOpportunityIds: any }>()
);

export const updateDuration = createAction(
  '[Consultation] Update Duration',
  props<{ duration: any }>()
);

export const updateVisitType = createAction(
  '[Consultation] Update VisitType',
  props<{ visitType: any }>()
);

export const updateMyEventsRequestId = createAction(
  '[Consultation] Update MyEvents Request Id',
  props<{ myEventsRequestId: string }>()
);

export const updateSoftlockExpirationDate = createAction(
  '[Consultation] Update SoftLockExpirationDate',
  props<{ softLockExpirationDate: any }>()
);

export const updateNoMmsOpportunityId = createAction(
  '[Consultation] Update NoMmsOpportunityId',
  props<{ noMmsOpportunityId: any }>()
);
/**
 * OpportunityIds
 */
export const loadOpportunityIdSearchResults = createAction(
  '[Consultation] Load OpportunityIdSearchResults',
  props<{ searchQuery: any }>()
);

export const loadOpportunityIdsSearchResultsSuccess = createAction(
  '[Consultation] Load OpportunityIdsSearchResults Success',
  props<{ opportunityIdsSearchResults: any }>()
);

export const loadOpportunityIdsSearchResultsFailure = createAction(
  '[Consultation] Load OpportunityIdsSearchResults Failure',
  props<{ error: any }>()
);

export const clearStateObject = createAction(
  '[Consultation] Clear State Object'
);
