import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ioh-shellSurvey',
  styleUrls: ['./shell-survey.component.scss'],
  templateUrl: './shell-survey.component.html',
})
export class ShellSurveyComponent {
  response: boolean;

  constructor(private readonly router: Router) {
    this.response =
      this.router.getCurrentNavigation().extras.state.response || null;
  }
}
