import * as ContactDetailsActions from './contact-details.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ReferenceDataService } from '../../../reference-data';
import { catchError, exhaustMap, map,switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ContactDetailsEffects {
  loadEngagementSponsorSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactDetailsActions.loadEngagementSponsorSearchResults),
      switchMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchTerm.exactmatch,
            requestId: action.searchTerm.requestid,
            searchText: action.searchTerm.searchtxt,
          })
          .pipe(
            map((res: any) =>
              ContactDetailsActions.loadEngagementSponsorSearchResultsSuccess({
                engagementSponsorSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                ContactDetailsActions.loadEngagementSponsorSearchResultsFailure(
                  { error }
                )
              )
            )
          )
      )
    )
  );

  loadPrimaryContactSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactDetailsActions.loadPrimaryContactSearchResults),
      switchMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchTerm.exactmatch,
            requestId: action.searchTerm.requestid,
            searchText: action.searchTerm.searchtxt,
          })
          .pipe(
            map((res: any) =>
              ContactDetailsActions.loadPrimaryContactSearchResultsSuccess({
                primaryContactSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                ContactDetailsActions.loadPrimaryContactSearchResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadAdditionalContactSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactDetailsActions.loadAdditionalContactSearchResults),
      switchMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchTerm.exactmatch,
            requestId: action.searchTerm.requestid,
            searchText: action.searchTerm.searchtxt,
          })
          .pipe(
            map((res: any) =>
              ContactDetailsActions.loadAdditionalContactSearchResultsSuccess({
                additionalContactSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                ContactDetailsActions.loadAdditionalContactSearchResultsFailure(
                  {
                    error,
                  }
                )
              )
            )
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly referenceDataService: ReferenceDataService
  ) {}
}
