import * as ArchiveActions from './archive.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ArchiveEntity } from './archive.models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export const ARCHIVE_FEATURE_KEY = 'archive';

export interface ArchiveState extends EntityState<ArchiveEntity> {
  loaded: boolean;
  userId: string;
  error?: any;
  selectedId?: string | number; // which workshop record has been selected
  archiveWorkshop: any | null;
  selectedArchiveWorkshop: any | null;
  isArchive: any | null;
}

export interface ArchivePartialState {
  readonly [ARCHIVE_FEATURE_KEY]: ArchiveState;
}

export const archiveAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (entity) => entity.id,
});

export const initialState: ArchiveState = archiveAdapter.getInitialState({
  error: null,
  loaded: false,
  userId: null,
  archiveWorkshop: null,
  selectedArchiveWorkshop: null,
  isArchive: false,
});

const archiveReducer = createReducer(
  initialState,
  on(ArchiveActions.setSelectedWorkshopId, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),

  on(
    ArchiveActions.loadAllArchiveDataByUserIdSuccess,
    (state, { archiveWorkshop }) =>
      archiveAdapter.addAll(archiveWorkshop, {
        ...state,
        loaded: true,
        archiveWorkshop,
      })
  ),
  on(ArchiveActions.loadAllArchiveDataByUserIdFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),

  on(ArchiveActions.saveUserId, (state, { userId }) => ({
    ...state,
    userId,
  })),

  on(ArchiveActions.updatedIsArchive, (state, { value }) => {
    return Object.assign({}, state, { isArchive: value });
  }),
  on(
    ArchiveActions.updatedSelectedArchiveWorkshop,
    (state, { selectedArchiveWorkshop }) => ({
      ...state,
      selectedArchiveWorkshop,
    })
  )
);

export function reducer(state: ArchiveState | undefined, action: Action) {
  return archiveReducer(state, action);
}
