import { Utils } from './utils';

// Commented must be added in future phases
export class JournalTab extends Utils {
  ChargeDate: any;
  ChargeStatus: string;
  Status: string;
  WBSType: string;
  WBSStatus: string;
  SenderWBS: string;
  CostElement: string;
  Amount: number;
  Currency: string;
  WBS: string;
  Description: string;
  Recovery2percent: number;
  Organization: string;
  GroupOwner: string;
  BookingID: string;
  Cost: number;
  Discount: number;
  FiscalYear: string;

  constructor(workshop: any, cost: any) {
    super();
    const program = workshop.program ? workshop.program : '';
    const orgs =
      workshop.general && workshop.general.organizations
        ? workshop.general.organizations
        : [];
    const eng =
      workshop.general && workshop.general.engagement
        ? workshop.general.engagement
        : '';
    const recov = workshop.recovery;
    const workshopStatus =
      recov.workshop && recov.workshop.status && recov.workshop.status.value
        ? recov.workshop.status.value
        : '';
    this.ChargeDate = cost.chargeDate ? this.dateFormat(cost.chargeDate) : null;
    this.ChargeStatus = cost.chargeStatus ? cost.chargeStatus : '';
    this.Status = workshopStatus;
    this.WBSType = cost.wbsType ? cost.wbsType : '';
    this.WBSStatus =
      cost.isValid == null ? '' : cost.isValid ? 'Valid' : 'Invalid';
    this.SenderWBS = '';
    this.CostElement = '900101';
    this.Amount = cost.subTotal ? +cost.subTotal : 0;
    this.Currency =
      cost.currency && cost.currency.value ? cost.currency.value : '';
    this.WBS = cost.code ? cost.code : '';
    this.BookingID =
      workshop.id && program.code
        ? `${program.code} - ` + `${workshop.id}`
        : '';
    this.Description = cost.description ? cost.description : '';
    this.Recovery2percent = cost.recovery2P ? +cost.recovery2P : 0;
    this.Organization = orgs
      .map((elem) =>
        elem.type && elem.type.value === 'Internal'
          ? elem.accentureEntity && elem.accentureEntity.value
          : elem.organizationName
      )
      .join(this.JOIN_SEPARATOR);
    this.GroupOwner = eng.group && eng.group.value ? eng.group.value : '';
    this.Cost = cost.cost ? +cost.cost : 0;
    this.Discount = cost.discount ? +cost.discount : 0;
    this.FiscalYear = workshop.fiscalYear || '';
  }

  public static getColumns = [
    { header: 'Charge Date', key: 'ChargeDate' },
    { header: 'Charge Status', key: 'ChargeStatus' },
    { header: 'Engagement Status', key: 'Status' },
    { header: 'WBS Type', key: 'WBSType' },
    { header: 'WBS Status', key: 'WBSStatus' },
    { header: 'Sender WBS', key: 'SenderWBS' },
    { header: 'Cost Element', key: 'CostElement' },
    { header: 'Amount', key: 'Amount', style: { numFmt: '$#,##0.00' } },
    { header: 'Currency', key: 'Currency' },
    { header: 'WBS', key: 'WBS' },
    { header: 'Description', key: 'Description' },
    {
      header: '2% Recovery',
      key: 'Recovery2percent',
      style: { numFmt: '$#,##0.00' },
    },
    { header: 'Organization Name', key: 'Organization' },
    { header: 'Group Owner', key: 'GroupOwner' },
    { header: 'Booking ID', key: 'BookingID' },
    { header: 'Cost', key: 'Cost', style: { numFmt: '$#,##0.00' } },
    { header: 'Discount', key: 'Discount', style: { numFmt: '$#,##0.00' } },
    { header: 'Fiscal Year', key: 'FiscalYear' },
  ];
}
