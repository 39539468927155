export const ENGAGEMENT_GROUP = 'engagementGroup';
export const GROUP_PARTICIPATION = 'groupParticipation';
export const GROUP_ECOSYSTEM = 'groupEcosystem';
export const COUNTRY_EUR = 'engagementCountry';
export const INDUSTRY_RD = 'engagementIndustry';
export const LABS_RD = 'engagementLabs';
export const LOGISTICS = 'logistics';
export const DATES = 'dates';
export const DATES_LOCATIONS = 'datesAndLocation';
export const COMPLETE_ENG_STATUS = ['Completed', 'Cancelled', 'Withdrawn'];
export const COMPLETE_METRICS_ENG_STATUS = ['Completed'];
export const METRICS_ENG_ALL_STATUS = [
  'Requested',
  'In Review',
  'Booked',
  'Completed',
  'Cancelled',
  'Withdrawn',
];
export const CALENDAR_ENG_STATUS = [
  'In Review',
  'Booked',
  'On Hold',
  'Completed',
  'Requested',
];
export const ACTIVE_ENG_STATUS = ['Requested', 'In Review', 'Booked'];
export const INVISIBLE_OPTIONS = ['Analyst', 'Recruiting Candidates'];
export const NonBackofficeSearchTable = [
  'All Feedback',
  'User List',
  'Organization',
];
export const LOGISTICS_REQUESTS = [
  {
    label: 'Parking',
    value: 'parking',
  },

  {
    label: 'Catering',
    value: 'catering',
  },

  {
    label: 'Specific Tech',
    value: 'specificTech',
  },
];
