export const programDropdown = {
  direction: 'column',
  dropdownId: 'programId',
  isMultiple: false,
  label: 'Program',
  name: 'Program ID',
  placeholder: 'Select a program',
  required: true,
};

export const accessLevelSetting = {
  direction: 'column',
  dropdownId: 'accessLevelId',
  isMultiple: false,
  label: 'Access Level',
  name: 'Access Level',
  placeholder: 'Select a Access Level',
  required: true,
};

export const accessLevelList = [
  {
    id: '001',
    value: 'Normal User',
  },
  {
    id: '002',
    value: 'Admin',
  },
];

export const dropdownList = {
  calendarEngStatus: 'calendarEngStatus',
  currency: 'currency',
  month: 'month',
  recoveryStatus: 'recoveryStatus',
  engagementStatus: 'engagementStatus',
  clientType: 'clientType',
  fiscalYear: 'fiscalYear',
  archivefiscalYear: 'archivefiscalYear',
  engagementType: 'engagementType',
  engagementPurpose: 'engagementPurpose',
  engagementLead: 'engagementLead',
  organizationType: 'organizationType',
};
export const hubReminderFields = [
  {
    field: 'hubReminder',
    label: 'Hub reminder email receiver',
    radioId: 'hubReminder',
    required: true,
    values: ['Yes', 'No'],
  },
];
export const eventReminderFields = [
  {
    field: 'eventReminder',
    label: 'Event reminder email receiver',
    radioId: 'eventReminder',
    required: true,
    values: ['Yes', 'No'],
  },
];

export const pipelineViewDropdown = {
  direction: 'column',
  dropdownId: 'viewId',
  isMultiple: false,
  label: 'User Created Views',
  value: 'Pipeline View',
  placeholder: 'Please select saved view',
  required: true,
};
