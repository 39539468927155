<div fxLayout="column" class="wbs-input" [formGroup]="parentForm" [ngClass]="browser()">
  <label *ngIf="smallScreen" for="inputId" class="wbsLabel" tabindex="0">
    WBS Code</label>
  <mat-form-field
    [style.width]="width"
    appearance="outline"
    color="primary"
    [ngClass]="browser()"
  >
    <input
      matInput
      placeholder="{{ placeholder }}"
      formControlName="{{ value }}"
      name="{{ name }}"
      id="{{ inputId }}"
      [pattern]="regex"
      [maxlength]="maxLength"
      attr.aria-invalid="{{ value }} === '' ? true : false"
      attr.aria-label="{{value}}"
      #input
    />
    <div matSuffix class="wbs-input__status" *ngIf="response && !!wbs">
      <span
        class="wbs-input__status--valid material-icons"
        *ngIf="success; else notValid"
        matTooltip="Valid WBS"
        matTooltipPosition="below"
      >
        done
      </span>
      <ng-template #notValid>
        <span
          class="wbs-input__status--invalid material-icons"
          matTooltip="Invalid WBS"
          matTooltipPosition="below"
        >
          clear
        </span>
      </ng-template>
    </div>
    <div matSuffix class="wbs-input__status" *ngIf="(loading$ | async) && !response">
      <mat-spinner color="accent" diameter="20"></mat-spinner>
    </div>
  </mat-form-field>
  <div *ngIf="response">
    <ioh-error
      class="wbs-input__error"
      *ngIf="
        (parentForm.get(value).touched ||
        parentForm.get(value).dirty) &&
        !(success) && !!wbs && !valid
      "
      errorMsg="This WBS is not valid!"
    >
    </ioh-error>
    <ioh-error
    class="wbs-input__error"
    *ngIf="
      (parentForm.get(value).touched ||
      parentForm.get(value).dirty) &&
      (success && expired)
    "
    errorMsg="This WBS is Expired!"
  >
  </ioh-error>
  </div>
  <ioh-error
    class="wbs-input__error"
    *ngIf="
      (parentForm.get(value).touched ||
      parentForm.get(value).dirty) &&
      parentForm.get(value).hasError('min')
    "
    errorMsg="Only positive number above 0!"
  >
  </ioh-error>
  <ioh-error
    class="wbs-input__error"
    *ngIf="
      (parentForm.get(value).touched ||
      parentForm.get(value).dirty) &&
      parentForm.get(value).hasError('max')
    "
    errorMsg="Only positive number below 100!"
  >
  </ioh-error>
  <ioh-error
    class="wbs-input__error"
    *ngIf="success && !!type && !!wbs && type.toLocaleUpperCase() === 'EXTERNAL CHARGEABLE SERVICES'"
    errorMsg="Ext. Chg costs should be charged direct"
  >
  </ioh-error>
  <ioh-error
    class="wbs-input__error"
    *ngIf="(parentForm.get(value).touched || parentForm.get(value).dirty) && 
      parentForm.get(value).hasError('required')"
    errorMsg="WBS Code is required"
  ></ioh-error>
  <ioh-error
    class="wbs-input__error"
    [class.wbs-input__error__mt]="maxLength"
    *ngIf="
      (parentForm.get(value).touched || parentForm.get(value).dirty) &&
      parentForm.get(value).hasError('pattern')
    "
    errorMsg="Numbers only. No letters or special characters."
  ></ioh-error>
</div>
