import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControlUtils } from '@utils/index';
import { Injectable } from '@angular/core';
import { LookUpValidatorsService } from '@utils/index';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Injectable({
  providedIn: 'root',
})
export class TeamMemberService {
  nameLimit = 1;
  constructor(
    private readonly lookUpValidator: LookUpValidatorsService,
    private readonly fb: FormBuilder,
    private readonly formControlUtils: FormControlUtils
  ) {}

  public createTeamMember(entry?: any): FormGroup {
    return this.fb.group({
      id: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'id' })
      ),
      closeOutDay: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'closeOutDay' }) ||
          this.formControlUtils.mapProperty({
            entry,
            property: 'closeOutDays',
          }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: {
              threeDecimalPoints: /^([0-9]{1,3})?(?:.[0-9]{1,3})?$/,
            },
          }),
        ]
      ),
      execDay: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'execDay' }) ||
          this.formControlUtils.mapProperty({
            entry,
            property: 'executionDays',
          }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: {
              threeDecimalPoints: /^([0-9]{1,3})?(?:.[0-9]{1,3})?$/,
            },
          }),
        ]
      ),
      nameInput: this.fb.group({
        input: this.fb.control(''),
        nameResult: this.fb.array(
          [],
          [
            this.lookUpValidator.validateRequired,
            this.lookUpValidator.validateLimit(this.nameLimit),
          ]
        ),
        nameResultsIsLoading: this.fb.control(false),
        searchResults: this.fb.control(''),
      }),
      prepDay: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'prepDay' }) ||
          this.formControlUtils.mapProperty({
            entry,
            property: 'preparationDays',
          }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: {
              threeDecimalPoints: /^([0-9]{1,3})?(?:.[0-9]{1,3})?$/,
            },
          }),
        ]
      ),
      role: this.fb.control({
        id: this.formControlUtils.mapProperty({ entry, property: 'role.id' }),
        value: this.formControlUtils.mapProperty({
          entry,
          property: 'role.value',
        }),
      }),
      totalDay: this.fb.control({
        value: this.formControlUtils.mapProperty({
          entry,
          property: 'totalDays',
        }),
        disabled: true,
      }),
    });
  }
}
