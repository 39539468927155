import { AccessGuard } from './guards/access.guard';
import { AuthGuard } from './guards/auth.guard';
import { BackOfficeComponent } from './back-office/back-office.component';
import {
  ErrorPageComponent,
  ShellComponent,
  ShellSurveyComponent,
} from '@ioh/ui-library';
import { FormComponent } from './form/form.component';
import { MaintenanceModeGuard } from './guards/maintenance-mode.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SurveyComponent } from './survey/survey.component';
import { LogoutComponent } from './back-office/sections/logout/logout.component';
const routes: Routes = [
  {
    canActivate: [AuthGuard, MaintenanceModeGuard],
    component: FormComponent,
    path: 'request-form',
  },
  {
    canActivate: [AuthGuard, MaintenanceModeGuard],
    component: SurveyComponent,
    path: 'survey/:id/:programId',
  },
  {
    canActivate: [AuthGuard, MaintenanceModeGuard],
    component: SurveyComponent,
    path: 'survey/:id/:programId/:score',
  },
  {
    canActivate: [AuthGuard, MaintenanceModeGuard],
    component: ShellComponent,
    path: 'submit-complete',
  },
  {
    canActivate: [AuthGuard, MaintenanceModeGuard],
    component: ShellSurveyComponent,
    path: 'survey-complete',
  },
  {
    path: 'request-form',
    pathMatch: 'full',
    redirectTo: '/request-form',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/admin',
  },
  {
    component: LogoutComponent,
    path: 'logout',
  },
  {
    canActivate: [AuthGuard, AccessGuard, MaintenanceModeGuard],
    component: BackOfficeComponent,
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    loadChildren: () =>
      import('./back-office/back-office-routing.module').then(
        (m) => m.BackOfficeRoutingModule
      ),
    path: 'admin',
  },
  { component: ErrorPageComponent, path: '**' },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
})
export class AppRoutingModule {}
