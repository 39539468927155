import { FormEntity } from './form.models';
import { createAction, props } from '@ngrx/store';

export const loadForm = createAction('[Form] Load Form');

export const loadFormSuccess = createAction(
  '[Form] Load Form Success',
  props<{ form: FormEntity[] }>()
);

export const loadFormFailure = createAction(
  '[Form] Load Form Failure',
  props<{ error: any }>()
);

export const loadTermsAndConditions = createAction(
  '[Form] Load TermsAndConditions',
  props<{ programId: string }>()
);

export const loadTermsAndConditionsSuccess = createAction(
  '[Form] Load TermsAndConditions SUCCESS',
  props<{ termsAndConditions: any }>()
);

export const loadTermsAndConditionsFailure = createAction(
  '[Form] Load TermsAndConditions FAILURE',
  props<{ error: any }>()
);

export const loadAllPrograms = createAction('[Form] Load All Programs');

export const loadProgramsSuccess = createAction(
  '[Form] Load Programs SUCCESS',
  props<{ programs: any }>()
);

export const loadProgramsFailure = createAction(
  '[Form] Load Programs FAILURE',
  props<{ error: any }>()
);

export const createRequest = createAction(
  '[Form] Create Request',
  props<{ request: any }>()
);

export const createRequestSuccess = createAction(
  '[Form] Create Request Success',
  props<{ response: any }>()
);

export const createRequestFailure = createAction(
  '[Form] Create Request Failure',
  props<{ error: any }>()
);

export const saveSurvey = createAction(
  '[Form] Save Survey',
  props<{ surveyRequest: any }>()
);

export const saveSurveySuccess = createAction(
  '[Form] Save Survey Success',
  props<{ surveyResponse: any }>()
);

export const saveSurveyFailure = createAction(
  '[Form] Save Survey Failure',
  props<{ surveyError: any }>()
);

export const saveProgramId = createAction(
  '[Form] Save Program ID',
  props<{ programId: string }>()
);

export const clearRequestSuccessful = createAction(
  '[Form] Clear Request Successful'
);

export const clearSurveyRequestSuccessful = createAction(
  '[Form] Clear Survey Successful'
);
