import { Utils } from './utils';

export class EngTeamTab extends Utils {
  FiscalYear: string;
  Name: string;
  Role: string;
  BookingID: string;
  Status: string;
  OrganizationID: string;
  Organization: string;
  EngagementType: string;
  TotalSessionHours: number;
  PrepDays: number;
  ExecutionDays: number;
  CloseOutDays: number;
  TotalDays: number;

  constructor(workshop: any, engTeam: any) {
    super();
    const program = workshop.program ? workshop.program : '';
    const orgs =
      workshop.general && workshop.general.organizations
        ? workshop.general.organizations
        : [];
    const eng = workshop.general.engagement;
    const schedule =
      workshop.general && workshop.general.schedule
        ? workshop.general.schedule
        : '';
    const workshopStatus = workshop.status ? workshop.status : '';

    this.Name = engTeam.name ? engTeam.name : '';
    this.Role = engTeam.role && engTeam.role.value ? engTeam.role.value : '';
    this.BookingID =
      workshop.id && program.code
        ? `${program.code} - ` + `${workshop.id}`
        : '';
    this.Status = workshopStatus.value ? workshopStatus.value : '';
    this.OrganizationID = this.mapJoin(orgs, 'id');
    this.Organization = orgs
      .map((elem) =>
        elem.type && elem.type.value === 'Internal'
          ? elem.accentureEntity && elem.accentureEntity.value
          : elem.organizationName
      )
      .join(this.JOIN_SEPARATOR);
    this.EngagementType = eng.type && eng.type.value ? eng.type.value : '';
    this.TotalSessionHours = schedule.totalHours ? +schedule.totalHours : 0;
    this.PrepDays = engTeam.preparationDays ? +engTeam.preparationDays : 0;
    this.ExecutionDays = engTeam.executionDays ? +engTeam.executionDays : 0;
    this.CloseOutDays = engTeam.closeOutDays ? +engTeam.closeOutDays : 0;
    this.TotalDays = engTeam.totalDays ? +engTeam.totalDays : 0;
    this.FiscalYear = workshop.fiscalYear || '';
  }

  public static getColumns = [
    { header: 'Fiscal Year', key: 'FiscalYear' },
    { header: 'Name', key: 'Name' },
    { header: 'Role', key: 'Role' },
    { header: 'Booking ID', key: 'BookingID' },
    { header: 'Engagement Status', key: 'Status' },
    { header: 'Organization ID', key: 'OrganizationID' },
    { header: 'Organization', key: 'Organization' },
    { header: 'Engagement Type', key: 'EngagementType' },
    { header: 'Total Session Hours', key: 'TotalSessionHours' },
    { header: 'Prep Days', key: 'PrepDays' },
    { header: 'Execution Days', key: 'ExecutionDays' },
    { header: 'Close Out Days', key: 'CloseOutDays' },
    { header: 'Total Days', key: 'TotalDays' },
  ];
}
