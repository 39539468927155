import { createAction, props } from '@ngrx/store';

export const saveProgramId = createAction(
  '[Admin] Save Program ID',
  props<{ programId: string }>()
);

export const setSelectedHub = createAction(
  '[Admin] Set Selected Hub',
  props<{ selectedHub: string }>()
);

export const setDropdownFilter = createAction(
  '[Admin] Set Dropdown Filter',
  props<{ dropdownFilter: any }>()
);

export const setAdminSelectedHub = createAction(
  '[Admin] Set Admin Selected Hub',
  props<{ selectedAdminHub: string }>()
);

export const saveProgramList = createAction(
  '[Admin] Save Program List',
  props<{ programList: any[] }>()
);
export const saveAdminProgramList = createAction(
  '[Admin] Save Admin Program List',
  props<{ adminProgramList: any[] }>()
);

export const saveUserAccessList = createAction(
  '[Admin] Save User Access List',
  props<{ userInfoList: any[] }>()
);

export const saveUserId = createAction(
  '[Admin] Save User ID',
  props<{ userId: string }>()
);

export const saveProgramCode = createAction(
  '[Admin] Save Program Code',
  props<{ programCode: string }>()
);

export const getProgramById = createAction(
  '[Admin] Get Program by ID',
  props<{ programId: string }>()
);
export const getProgramByIdSuccess = createAction(
  '[Admin] Get Program by ID Success',
  props<{ programCode: any }>()
);
export const getProgramByIdFailure = createAction(
  '[Admin] Get Program by ID Failure',
  props<{ error: any }>()
);
export const loadOrgList = createAction(
  '[Admin] Load loadOrgList',
  props<{ userId: any }>()
);
export const loadOrgListSuccess = createAction(
  '[Admin] Load loadOrgList Success',
  props<{ orgList: any[] }>()
);
export const loadOrgListFailure = createAction(
  '[Admin] Load loadOrgList Failure',
  props<{ error: any }>()
);
export const loadAllWorkshopsByUserId = createAction(
  '[Admin] Load AllWorkshopsByUserId',
  props<{ userId: string }>()
);
export const loadAllArchiveDataByUserId = createAction(
  '[Admin] Load loadAllArchiveDataByUserId',
  props<{ userId: string }>()
);
export const loadAllWorkshopsByUserIdSuccess = createAction(
  '[Admin] Load AllWorkshopsByUserId Success',
  props<{ workshops: any[]; getWorkshop: boolean }>()
);
export const loadAllWorkshopsByUserIdFailure = createAction(
  '[Admin] Load AllWorkshopsByUserId Failure',
  props<{ error: any; getWorkshop: boolean }>()
);
export const loadAllArchiveDataByUserIdSuccess = createAction(
  '[Admin] Load loadAllArchiveDataByUserId Success',
  props<{ archiveWorkshop: any[]; getWorkshop: boolean }>()
);
export const loadAllArchiveDataByUserIdFailure = createAction(
  '[Admin] Load loadAllArchiveDataByUserId Failure',
  props<{ error: any; getWorkshop: boolean }>()
);

export const setSelectedWorkshopId = createAction(
  '[Admin] Set SelectedWorkshopId',
  props<{ id: string }>()
);

export const updateWorkshopById = createAction(
  '[Admin] Update WorkshopById',
  props<{ workshop: any }>()
);
export const addWorkshopAfterSave = createAction(
  '[Admin] addWorkshopAfterSave',
  props<{ workshop: any }>()
);

export const updateWorkshopAfterSave = createAction(
  '[Admin] updateWorkshopAfterSave',
  props<{ workshop: any }>()
);
export const updateResponse = createAction(
  '[Admin] Update Response',
  props<{ response: any }>()
);
export const updateWorkshopByIdSuccess = createAction(
  '[Admin] Update WorkshopById Success',
  props<{ response: any }>()
);
export const updateWorkshopByIdFailure = createAction(
  '[Admin] Update WorkshopById Failure',
  props<{ error: any }>()
);
export const updateSuccess = createAction(
  '[Admin] Update Successfully',
  props<{ value: any }>()
);
export const updateOriginalDocList = createAction(
  '[Admin] updateOriginalDocList',
  props<{ originalDocList: any }>()
);
export const updatedWorkshop = createAction(
  '[Admin] Update Successfully 1',
  props<{ getWorkshop: any }>()
);

export const updateAdminSuccess = createAction(
  '[Admin] Update updatedUserSuccessfully',
  props<{ value: any }>()
);

export const getWorkshopStatus = createAction('[Admin] Get Workshop Status');
export const getWorkshopStatusSuccess = createAction(
  '[Admin] Get Workshop Status Success',
  props<{ workshopStatus: any }>()
);
export const getWorkshopStatusFailure = createAction(
  '[Admin] Get Workshop Status Failure',
  props<{ error: any }>()
);

export const getCancellationReason = createAction(
  '[Admin] Get Cancellation Reasons'
);
export const getCancellationReasonSuccess = createAction(
  '[Admin] Get Cancellation Reasons Success',
  props<{ cancellationReasons: any }>()
);
export const getCancellationReasonFailure = createAction(
  '[Admin] Get Cancellation Reasons Failure',
  props<{ error: any }>()
);

export const removeUserAccess = createAction(
  '[Admin] Remove User Access',
  props<{ userAccess: any }>()
);
export const removeUserAccessSuccess = createAction(
  '[Admin] Remove User Access Success',
  props<{ response: any }>()
);
export const removeUserAccessFailure = createAction(
  '[Admin] Remove User Access Failure',
  props<{ error: any }>()
);
export const removeAccessSuccess = createAction(
  '[Admin] Remove Access Successfully',
  props<{ value: any }>()
);

export const loadAccessUserListByUserId = createAction(
  '[Admin] Load AccessUserListByUserId',
  props<{ userId: string }>()
);
export const loadAccessUserListByUserIdSuccess = createAction(
  '[Admin] Load AccessUserListByUserId Success',
  props<{ userInfo: any[] }>()
);
export const loadAccessUserListByUserIdFailure = createAction(
  '[Admin] Load AccessUserListByUserId Failure',
  props<{ error: any }>()
);

export const loadUserNameSearchResults = createAction(
  '[Admin] Load UserNameSearchResults',
  props<{
    searchQuery: {
      searchtxt: string;
      requestid: number;
      exactmatch: number;
    };
  }>()
);

export const loadUserNameSearchResultsSuccess = createAction(
  '[Admin] Load UserNameSearchResults Success',
  props<{ userNameSearchResults: any }>()
);
export const loadUserNameSearchResultsFailure = createAction(
  '[Admin] Load UserNameSearchResults Failure',
  props<{ error: any }>()
);

export const updateUserName = createAction(
  '[Admin] Update Selected UserName',
  props<{ userName: string }>()
);

export const updateAdminById = createAction(
  '[Admin] Update AdminById',
  props<{ admin: any }>()
);

export const updatedDocument = createAction(
  '[Admin] Update Document',
  props<{ document: any }>()
);

export const updatedEngStatus = createAction(
  '[Admin] Update EngStatus',
  props<{ engStatus: any }>()
);

export const updateAdminByIdSuccess = createAction(
  '[Admin] Update AdminById Success',
  props<{ response: any }>()
);

export const updateAdminByIdFailure = createAction(
  '[Admin] Update AdminById Failure',
  props<{ error: any }>()
);

export const markDocumentAsPrepopulated = createAction(
  '[Admin] Update document prepopulated value',
  props<{ value: boolean }>()
);

export const updatedCloneWorkshop = createAction(
  '[Admin] Update clone workshop',
  props<{ cloneWorkshop: any }>()
);

export const updatedSelectedArchiveWorkshop = createAction(
  '[Admin] Update select Archive workshop',
  props<{ selectedArchiveWorkshop: any }>()
);

export const updatedIsCloneWorkshop = createAction(
  '[Admin] Update Is CloneWorkshop',
  props<{ value: boolean }>()
);

export const saveView = createAction(
  '[Admin] Save Pipeline View',
  props<{ view: any }>()
);

export const saveViewSuccess = createAction(
  '[Admin] saveViewSuccess Success',
  props<{ response: any }>()
);

export const saveViewFailure = createAction(
  '[Admin] saveViewSuccess Failure',
  props<{ error: any }>()
);

export const getView = createAction('[Admin] Get Pipeline View');

export const getViewSuccess = createAction(
  '[Admin] Get Pipeline View Success',
  props<{ pipelineView: any }>()
);

export const getViewFailure = createAction(
  '[Admin] Get Pipeline View Failure',
  props<{ error: any }>()
);

export const updateViewSuccess = createAction(
  '[Admin] Update updatedViewSuccessfully',
  props<{ value: any }>()
);

export const updatedWorkshopByinitialData = createAction(
  '[Admin]  firstload data workshop updated',
  props<{ workshops: any[]; getWorkshop: boolean }>()
);
