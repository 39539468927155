import { format } from 'date-fns';
import moment from 'moment';

export class Utils {
  public readonly JOIN_SEPARATOR: string = ';';
  public readonly DATE_FORMAT: string = 'MM/dd/yyyy';

  public mapJoin = <T>(array: T[], fieldName: string): string =>
    array.map((elem: T) => elem[fieldName]).join(this.JOIN_SEPARATOR);

  public formatDate = (date: string): string =>
    format(new Date(this.getUTCDateISOFormat(date)), this.DATE_FORMAT);

  public getUTCDateISOFormat(originalDate: string): string {
    const newDate = new Date(originalDate);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    const isoDate = newDate.toISOString();
    return isoDate;
  }

  public reportStartEndFormat = (value: string): string => {
    const dateArray = value.split('-');
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  };

  public dateFormat(date) {
    return new Date(date);
  }

  formatToDate(data) {
    let res;
    let dates;
    if (data) {
      dates = data.split(';').filter((res) => {
        return !!res;
      });
      dates = dates.map((res) => {
        res = moment(res).toDate();
        res = new Date(res).setHours(0, 0, 0, 0);
        res = format(res, 'MM/dd/yyyy');
        return res;
      });
      res = dates.join(';');
    } else {
      res = '';
    }
    return res;
  }

  public mapJoinDate = <T>(array: T[], fieldName: string): string =>
    array
      .map((elem: T) => elem[fieldName] && this.formatDate(elem[fieldName]))
      .filter((elem) => elem)
      .join(this.JOIN_SEPARATOR);

  public mapJoinChargeDate = <T>(array: T[], fieldName: string): string =>
    array
      .map(
        (elem: T) =>
          elem[fieldName] && this.reportStartEndFormat(elem[fieldName])
      )
      .filter((elem) => elem)
      .join(this.JOIN_SEPARATOR);
}
