export const tooltipConst = {
  organizaitonTooltipObj: {
    tooltip: {
      titleTooltip: `Please identify which organizations are the target audience for the engagement. Note: This should not include groups that are co-hosting or co-presenting, which should be identified separately under "Group Particiption".`,
    },
  },
  myEventsRequestIdObj: {
    tooltip: {
      titleTooltip: `If you have a myEvents Request Id for an in person event, please include it here.`,
    },
  },
  mmsOppTooltipObj: {
    tooltip: {
      titleTooltip: `If the hub team had a meaningful contribution to the origination or sales enablement of a particular sales pursuit, please identify the MMS Opportunity ID. Please also update the MMS Opportunity record with appropriate Sales Team participation or Sales Campaigns.`,
    },
  },
  chargeDateTooltipObj: {
    tooltip: {
      titleTooltip: `Indicates in which month the recovery chargeback should be processed. For example: A charge date of June 22 will be processed at the end of June.`,
    },
  },
  dateLocationTooltipObj: {
    tooltip: {
      titleTooltip: `Please identify when there are specific event dates planned for this engagement.  For example, if there is a 2-day workshop planned with the client team, please add one row for each event date (two rows total).  If your engagement does not have specific event dates, then do not populate any rows.  Internal or planning meetings should NOT be included.  Please see illustrative timeline:<insert powerpoint visual>`,
    },
  },
  engagementStatusTooltipObj: {
    tooltip: {
      position: 'bottom-right',
      optionTooltips: [
        {
          content: `<strong>Draft</strong>: This is a placeholder status for very preliminary engagements that may or may not happen.  This could be used for initial Leads if the Hub doesn’t want to use the Requested status.`,
        },
        {
          content: `<strong>Requested</strong>: Indicates that a request has been received but the scope, timeline, staffing, and pricing have not yet been confirmed.`,
        },
        {
          content: `<strong>Booked</strong>: Indicates that the scope, timeline, staffing, and pricing have been confirmed. This includes confirmation of the WBS for the chargeback recovery.  All engagements in a "Booked" status become subject to cancellation fees per policy.`,
        },
        {
          content: `<strong>Completed</strong>: Indicates that the full scope of work has been delivered.  Note: Before saving an engagement as "Completed", all mandatory fields in the Engage tool must be updated. The "Completed" status is independent of the recovery chargeback timeline and status because chargebacks may be executed during or after an engagement. `,
        },
        {
          content: `<strong>On Hold</strong>: Indicates that a request has been fully scoped out but the work is not yet ready to start – work will likely happen but the dates are not finalized yet.`,
        },
        {
          content: `<strong>Withdrawn</strong>: Indicates that a request has been withdrawn before any significant work was completed for the engagement.  There are usually no cancellation fees associated with "Withdrawn" engagements.`,
        },
        {
          content: `<strong>Cancelled</strong>: Indicates that a request has been cancelled after the team has already contributed meaningful work towards the engagement.  There are usually cancellation fees associated with "Cancelled" engagements.`,
        },
      ],
    },
  },
  engagementTypeTooltipObj: {
    tooltip: {
      titleTooltip: `Please indicate which engagement type best describes the scope of work.`,
      optionTooltips: [
        {
          content: `<strong>Innovation Applied</strong>: The work is directly related to client’s innovation agenda and engages multiple components of Accenture’s innovation capabilities. Must result in meaningful co-creation and/or scale delivery of innovation. This is Capital “I” Innovation.`,
        },
        {
          content: `<strong>Innovation Methodology</strong>: The work employs an innovation mindset or FORM skills to solve a client’s business problem, but the solution itself would not be considered innovative or is not part of a broader innovation agenda. May involve Accenture’s innovation capabilities, but only to a limited degree.  This is Lowercase “i” innovation. `,
        },
        {
          content: `<strong>Other</strong>: The work is supported by the hub, but overall contribution is limited.  This work may include some elements of innovation (storytelling, visual design, facilitation), but does not represent a broader innovation mindset or approach. “Other” should also be used to track non-innovation work efforts in Engage for logistical reasons or financial chargebacks. Note: All “Other” engagements are excluded from reporting metrics.`,
        },
      ],
    },
  },
  chargeStatusTooltipObj: {
    tooltip: {
      optionTooltips: [
        {
          content: `<strong>Draft</strong>: Indicates that the charge details are pending updates and not yet ready for processing.`,
        },
        {
          content: `<strong>Ready for Submission</strong>: Indicates that the charge details are complete and ready for processing on the charge date.`,
        },
        {
          content: `<strong>Processed</strong>: Indicates that the charge has been processed on the charge date.`,
        },
        {
          content: `<strong>Direct Charge</strong>: Indicates that the hub team will book time/expense to the WBS directly without the need for processing a separate charge.`,
        },
      ],
    },
  },
  wbsDetailTooltipObj: {
    tooltip: {
      titleTooltip: `A WBS is not needed for consultation but must be provided once the engagement is booked and work begins.`,
    },
  },
  noFeedbackTooltipObj: {
    tooltip: {
      position: 'bottom-right',
      titleTooltip: `Check this box to not send a feedback survey email when this engagement is set to “Complete”.`,
    },
  },
  userViewTooltipObj: {
    tooltip: {
      titleTooltip: `Once you have selected filters you frequently use, you can save this set of filters in a saved view which you can name and then select from this dropdown`,
    },
  },
};
