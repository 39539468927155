import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';
import { Injectable } from '@angular/core';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
@Injectable({
  providedIn: 'root',
})
export class ExcelExportService {
  public isDownloadReport = false;
  isEUR = false;
  exportMetricsReport(dataObj: object) {
    let data = [];
    let sheetName = '';
    const workbook = new Excel.Workbook();
    workbook.creator = 'ioh.admin';
    workbook.created = new Date();
    for (var option in dataObj) {
      let sheet: any;
      if (dataObj[option].sheetName) {
        data = dataObj[option].data;
        sheetName = dataObj[option].sheetName;
        sheet = workbook.addWorksheet(sheetName);
        sheet.addRows(data);
        sheet.getRow(1).font = { bold: true };
        sheet.getRow(2).font = { bold: true };
        sheet.eachRow({ includeEmpty: true }, (row, index) => {
          row.eachCell({ includeEmpty: true }, (cell) => {
            cell.border = {
              top: { style: 'thin' },
              bottom: { style: 'thin' },
              left: { style: 'thin' },
              right: { style: 'thin' },
            };
          });
        });
      }
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      this.saveAsExcelFile(buffer, 'MetricsReport');
    });
  }

  exportTable(data, tableName, colNames) {
    data.forEach((rowData) => {
      if (rowData.chargeDate) {
        rowData.chargeDate = new Date(rowData.chargeDate);
        rowData.chargeDate.setMinutes(
          rowData.chargeDate.getMinutes() -
            rowData.chargeDate.getTimezoneOffset()
        );
      }
      if (rowData.eventDate) {
        rowData.eventDate = new Date(rowData.eventDate);
        rowData.eventDate.setMinutes(
          rowData.eventDate.getMinutes() - rowData.eventDate.getTimezoneOffset()
        );
      }
      if (rowData.engagementStartDate) {
        rowData.engagementStartDate = new Date(rowData.engagementStartDate);
        rowData.engagementStartDate.setMinutes(
          rowData.engagementStartDate.getMinutes() -
            rowData.engagementStartDate.getTimezoneOffset()
        );
      }
      if (rowData.engagementEndDate) {
        rowData.engagementEndDate = new Date(rowData.engagementEndDate);
        rowData.engagementEndDate.setMinutes(
          rowData.engagementEndDate.getMinutes() -
            rowData.engagementEndDate.getTimezoneOffset()
        );
      }
    });
    const workbook = new Excel.Workbook();
    workbook.creator = 'ioh.admin';
    workbook.created = new Date();
    const sheet = workbook.addWorksheet(tableName);
    const columnsItems = [];
    for (var item in colNames) {
      columnsItems.push({ header: colNames[item], key: item });
    }
    sheet.columns = columnsItems;
    sheet.addRows(data);
    this.setWidth(sheet);
    sheet.eachRow({ includeEmpty: true }, (row) => {
      this.isEUR = false;
      row.eachCell({ includeEmpty: true }, (cell) => {
        this.isEUR = this.isEUR || this.recoveryEUR(cell, columnsItems);
        cell = this.currencyFormat(cell, columnsItems, this.isEUR);
      });
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      this.saveAsExcelFile(buffer, tableName);
    });
  }

  public currencyFormat(cell, column, isEUR) {
    if (
      column[Number(cell.col) - 1] &&
      column[Number(cell.col) - 1].key == 'subTotal'
    ) {
      cell.style.numFmt = isEUR ? '€#,##0.00' : '$#,##0.00';
    }
    return cell;
  }

  recoveryEUR(cell, column) {
    return (
      column[Number(cell.col) - 1] &&
      column[Number(cell.col) - 1].key == 'currency' &&
      cell.value == 'EUR'
    );
  }

  loadDownloadReport(loadBuffer, exportName) {
    const workbook = new Excel.Workbook();
    workbook.xlsx.load(loadBuffer).then((wb) => {
      wb.xlsx.writeBuffer().then((writeBuffer) => {
        this.saveAsExcelFile(writeBuffer, exportName);
        this.isDownloadReport = false;
      });
    });
  }

  setWidth(sheet) {
    sheet.columns.forEach((column) => {
      let dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.length : 0;
        if (columnLength > dataMax) {
          dataMax = columnLength;
        }
      });
      column.width = 12;
    });
  }

  saveAsExcelFile(buffer, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName);
  }

  saveBufferAsFile(buffer, fileName: string): void {
    const data: Blob = new Blob([buffer]);
    FileSaver.saveAs(data, fileName);
  }
}
