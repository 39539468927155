import { CalendarComponent } from './calendar.component';
import { CommonModule } from '@angular/common';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { BackOfficeModule } from '../../back-office.module'
//'./banner/banner.component';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction';

// FullCalendarModule.registerPlugins([dayGridPlugin, interactionPlugin]);

@NgModule({
  declarations: [CalendarComponent],
  imports: [CommonModule, FullCalendarModule, SharedModule,BackOfficeModule],
})
export class CalendarModule {}
