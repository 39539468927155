import * as ConsultationActions from './consultation.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ReferenceDataService } from '../../../../reference-data';
import { catchError, delay, exhaustMap, map, retry,switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ConsultationEffects {
  loadConsultationOwnerSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.loadConsultationOwnerSearchResults),
      switchMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactmatch,
            requestId: action.searchQuery.requestid,
            searchText: action.searchQuery.searchtxt,
          })
          .pipe(
            map((res: any) =>
              ConsultationActions.loadConsultationOwnerSearchResultsSuccess({
                consultationOwnerSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                ConsultationActions.loadConsultationOwnerSearchResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadInternalPurposeResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.loadInternalPurposeResults),
      exhaustMap((action) =>
        this.referenceDataService.getInternalPurpose().pipe(
          map((res: any) =>
            ConsultationActions.loadInternalPurposeResultsSuccess({
              internalPurposeResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              ConsultationActions.loadInternalPurposeResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadOpportunityIDs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.loadOpportunityIdSearchResults),
      exhaustMap((action) =>
        this.referenceDataService.getOpportunityId(action.searchQuery).pipe(
          map((res) =>
            ConsultationActions.loadOpportunityIdsSearchResultsSuccess({
              opportunityIdsSearchResults: res.data,
            })
          ),
          catchError((error) =>
            of(
              ConsultationActions.loadOpportunityIdsSearchResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly referenceDataService: ReferenceDataService
  ) {}
}
