<div fxLayout="column">
  <label fxFlex attr.for="{{ textareaId }}" class="textarea__label" tabindex="0"
  [style.font-weight]="styles">
    <span *ngIf="required" class="textarea__asterisk">*</span>
    <span [innerHTML]="label"></span></label
  >
  <mat-form-field fxFlex [formGroup]="parentForm" appearance="outline">
    <textarea
      matInput
      placeholder="{{ placeholder }}"
      id="{{ textareaId }}"
      formControlName="{{ value }}"
      name="{{ name }}"
      [style.height]="height"
      #textarea
      maxlength="{{ maxCharLimit }}"
      attr.aria-label="{{ description }}"
    ></textarea>
    <mat-hint align="end" tabindex="0"
      >{{ textarea.value?.length || 0 }}/{{ maxCharLimit }}</mat-hint
    >
  </mat-form-field>
  <ioh-error
    class="textarea__error"
    *ngIf="
      (parentForm.get(value).touched || parentForm.get(value).dirty) &&
      parentForm.get(value).hasError('required')
    "
    errorMsg="{{ label }} is required"
  ></ioh-error>
  <ioh-error
    class="textarea__error"
    *ngIf="
      (parentForm.get(value).touched || parentForm.get(value).dirty) &&
      parentForm.get(value).errors?.maxlength
    "
    errorMsg="Oops, too many characters"
  ></ioh-error>
</div>
