import * as AdminActions from './admin.actions';
import * as AdminSelectors from './admin.selectors';
import * as fromAdmin from './admin.reducer';
import { Action, Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter, first } from 'rxjs/operators';

@Injectable()
export class AdminFacade {
  allAdmin$ = this.store.pipe(select(AdminSelectors.getAdminState));
  getUserId$ = this.store.pipe(select(AdminSelectors.getUserId));
  getProgramList$ = this.store.pipe(select(AdminSelectors.getProgramList));
  getAdminProgramList$ = this.store.pipe(
    select(AdminSelectors.getAdminProgramList)
  );

  documentPrepopulated$ = this.store.pipe(
    select(AdminSelectors.getDocumentPrepopulated)
  );

  getIsCloneWorkshop$ = this.store.pipe(
    select(AdminSelectors.getIsCloneWorkshop)
  );

  originalDocList$ = this.store.pipe(select(AdminSelectors.getOriginalDocList));
  getDocumentList$ = this.store.pipe(select(AdminSelectors.getDocument));
  getcloneWorkshop$ = this.store.pipe(select(AdminSelectors.getCloneWorkshop));
  getSelectedArchiveWorkshop$ = this.store.pipe(
    select(AdminSelectors.getSelectedArchiveWorkshop)
  );

  getEngStatus$ = this.store.pipe(select(AdminSelectors.getEngStatus));
  getUserInfoList$ = this.store.pipe(select(AdminSelectors.getUserInfoList));
  getProgramId$ = this.store.pipe(select(AdminSelectors.getProgramId));
  getWorkshopsIds$ = this.store.pipe(select(AdminSelectors.getWorkshopIds));
  allUserNameSearchResults$ = this.store.pipe(
    select(AdminSelectors.getUserNameSearchResults),
    filter((result) => Boolean(result))
  );

  allDocumentTab$ = this.store.pipe(select(AdminSelectors.getDocument));

  getProgramCode$ = this.store.pipe(select(AdminSelectors.getProgramCode));
  response$ = this.store.pipe(select(AdminSelectors.getResponse));
  allWorkshopEntities$ = this.store.pipe(
    select(AdminSelectors.getWorkshopEntities)
  );

  getWorkshops$ = this.store.pipe(select(AdminSelectors.getWorkshops));
  getInitWorkshops$ = this.store.pipe(select(AdminSelectors.getInitWorkshops));
  getArchiveWorkshops$ = this.store.pipe(
    select(AdminSelectors.getArchiveWorkshops)
  );

  selectedWorkshop$ = this.store.pipe(select(AdminSelectors.getSelected));
  selectedHub$ = this.store.pipe(select(AdminSelectors.getSelectedHub));
  dropdownFilter$ = this.store.pipe(select(AdminSelectors.getDropdownFilter));
  selectedAdminHub$ = this.store.pipe(
    select(AdminSelectors.getSelectedAdminHub)
  );

  selectedWorkshopGeneral$ = this.store.pipe(
    select(AdminSelectors.getSelectedGeneral)
  );

  selectedWorkshopConsultation$ = this.store.pipe(
    select(AdminSelectors.getSelectedConsultation)
  );

  selectedWorkshopRecovery$ = this.store.pipe(
    select(AdminSelectors.getSelectedRecovery)
  );

  selectedWorkshopDocument$ = this.store.pipe(
    select(AdminSelectors.getSelectedDocument)
  );

  loadOrgList$ = this.store.pipe(select(AdminSelectors.loadOrgList));

  selectedWorkshopId$ = this.store.pipe(select(AdminSelectors.getSelectedId));

  selectedWorkshopUpdatedSuccess$ = this.store.pipe(
    select(AdminSelectors.updatedSuccessfully)
  );

  selectedAdminUpdatedSuccess$ = this.store.pipe(
    select(AdminSelectors.updatedAdminSuccessfully)
  );

  selectedworkshopSuccess$ = this.store.pipe(
    select(AdminSelectors.getWorkshop)
  );

  saveWorkshopError$ = this.store.pipe(
    select(AdminSelectors.saveWorkshopError)
  );

  removeAccessSuccessfully$ = this.store.pipe(
    select(AdminSelectors.removeAccessSuccessfully)
  );

  removeUserAccessError$ = this.store.pipe(
    select(AdminSelectors.removeUserAccessError)
  );

  saveAdminError$ = this.store.pipe(select(AdminSelectors.saveAdminError));

  saveViewSuccessfully$ = this.store.pipe(
    select(AdminSelectors.saveViewSuccessfully)
  );

  saveViewError$ = this.store.pipe(select(AdminSelectors.saveViewError));

  workshopStatus$ = this.store.pipe(select(AdminSelectors.getWorkshopStatus));

  cancellationReasons$ = this.store.pipe(
    select(AdminSelectors.getCancellationReasons)
  );

  getViewSuccess$ = this.store.pipe(select(AdminSelectors.getViewSuccess));
  saveViewSuccess$ = this.store.pipe(select(AdminSelectors.saveViewSuccess));

  constructor(private readonly store: Store<fromAdmin.AdminPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  updateSuccess(value) {
    this.dispatch(AdminActions.updateSuccess({ value }));
  }

  addWorkshopAfterSave(workshop) {
    this.dispatch(AdminActions.addWorkshopAfterSave({ workshop }));
  }

  updateWorkshopAfterSave(workshop) {
    this.dispatch(AdminActions.updateWorkshopAfterSave({ workshop }));
  }

  updateOriginalDocList(originalDocList) {
    this.dispatch(AdminActions.updateOriginalDocList({ originalDocList }));
  }

  updatedWorkshop(getWorkshop) {
    this.dispatch(AdminActions.updatedWorkshop({ getWorkshop }));
  }

  updateAdminSuccess(value) {
    this.dispatch(AdminActions.updateAdminSuccess({ value }));
  }

  setSelectedHub(selectedHub: string) {
    this.dispatch(AdminActions.setSelectedHub({ selectedHub }));
  }

  setDropdownFilter(dropdownFilter: any) {
    this.dispatch(AdminActions.setDropdownFilter({ dropdownFilter }));
  }

  setAdminSelectedHub(selectedAdminHub: string) {
    this.dispatch(AdminActions.setAdminSelectedHub({ selectedAdminHub }));
  }

  saveProgramId(programId: string) {
    this.dispatch(AdminActions.saveProgramId({ programId }));
  }

  saveProgramList(programList: any[]) {
    this.dispatch(AdminActions.saveProgramList({ programList }));
  }

  saveAdminProgramList(adminProgramList: any[]) {
    this.dispatch(AdminActions.saveAdminProgramList({ adminProgramList }));
  }

  saveUserAccessList(userInfoList: any[]) {
    this.dispatch(AdminActions.saveUserAccessList({ userInfoList }));
  }

  saveUserId(userId: string) {
    this.dispatch(AdminActions.saveUserId({ userId }));
  }

  saveProgramCode(programCode: string) {
    this.dispatch(AdminActions.saveProgramCode({ programCode }));
  }

  setupWorkshopFromHubGross(workshopsList?: any[]) {
    this.dispatch(
      AdminActions.loadAllWorkshopsByUserIdSuccess({
        workshops: workshopsList,
        getWorkshop: true,
      })
    );
  }

  loadAllWorkshopsByUserId(userId?: string) {
    if (userId) {
      this.dispatch(AdminActions.loadAllWorkshopsByUserId({ userId }));
    } else {
      this.getUserId$.pipe(first()).subscribe((val) => {
        this.dispatch(AdminActions.loadAllWorkshopsByUserId({ userId: val }));
      });
    }
  }

  loadAllArchiveDataByUserId(userId?: string) {
    if (userId) {
      this.dispatch(AdminActions.loadAllArchiveDataByUserId({ userId }));
    } else {
      this.getUserId$.pipe(first()).subscribe((val) => {
        this.dispatch(AdminActions.loadAllArchiveDataByUserId({ userId: val }));
      });
    }
  }

  loadOrgList() {
    this.dispatch(AdminActions.loadOrgList({ userId: '' }));
  }

  setSelectedWorkshopId(workshopId: string) {
    this.dispatch(AdminActions.setSelectedWorkshopId({ id: workshopId }));
  }

  updateResponse(response) {
    this.dispatch(AdminActions.updateResponse({ response }));
  }

  updateWorkshopById(workshop) {
    this.dispatch(AdminActions.updateWorkshopById({ workshop }));
  }

  getWorkshopStatus() {
    this.dispatch(AdminActions.getWorkshopStatus());
  }

  getProgramById(programId: string) {
    this.dispatch(AdminActions.getProgramById({ programId }));
  }

  getCancellationReasons() {
    this.dispatch(AdminActions.getCancellationReason());
  }

  removeUserAccess(userAccess) {
    this.dispatch(AdminActions.removeUserAccess({ userAccess }));
  }

  removeAccessSuccess(value) {
    this.dispatch(AdminActions.removeAccessSuccess({ value }));
  }

  loadAccessUserListByUserId(userId: string) {
    this.dispatch(AdminActions.loadAccessUserListByUserId({ userId }));
  }

  loadUserNameSearchResults(searchQuery) {
    this.dispatch(AdminActions.loadUserNameSearchResults({ searchQuery }));
  }

  updateUserName(value) {
    this.dispatch(AdminActions.updateUserName({ userName: value }));
  }

  updateAdminById(admin) {
    this.dispatch(AdminActions.updateAdminById({ admin }));
  }

  updatedDocument(document) {
    this.dispatch(AdminActions.updatedDocument({ document }));
  }

  updateDocumentPrepopulated(value: boolean) {
    this.dispatch(AdminActions.markDocumentAsPrepopulated({ value }));
  }

  updatedEngStatus(engStatus) {
    this.dispatch(AdminActions.updatedEngStatus({ engStatus }));
  }

  updatedCloneWorkshop(cloneWorkshop) {
    this.dispatch(AdminActions.updatedCloneWorkshop({ cloneWorkshop }));
  }

  updatedSelectedArchiveWorkshop(selectedArchiveWorkshop) {
    this.dispatch(
      AdminActions.updatedSelectedArchiveWorkshop({ selectedArchiveWorkshop })
    );
  }

  updatedIsCloneWorkshop(value: boolean) {
    this.dispatch(AdminActions.updatedIsCloneWorkshop({ value }));
  }

  saveView(view: any) {
    this.dispatch(AdminActions.saveView({ view }));
  }

  getView() {
    this.dispatch(AdminActions.getView());
  }

  updateViewSuccess(value) {
    this.dispatch(AdminActions.updateViewSuccess({ value }));
  }

  updatedWorkshopByinitialData(value) {
    this.dispatch(
      AdminActions.updatedWorkshopByinitialData({
        workshops: value,
        getWorkshop: true,
      })
    );
  }
}
