export const itemDropdown = {
  direction: 'column',
  dropdownId: 'item',
  isMultiple: false,
  name: 'Select an item',
  placeholder: 'Select one',
  required: false,
  values: [
    { id: 1, value: 'Resourcing' },
    { id: 2, value: 'Hub Recovery' },
    { id: 3, value: 'Materials' },
    { id: 4, value: 'Experience' },
    { id: 5, value: 'Contingency' },
    { id: 6, value: 'Printing' },
    { id: 7, value: 'Catering' },
    { id: 8, value: 'Other' },
  ],
};
export const chargeStatusDropdown = {
  direction: 'column',
  dropdownId: 'status',
  isMultiple: false,
  name: 'Charge status',
  placeholder: 'Select charge status',
  required: false,
  values: [
    {
      id: 1,
      value: 'Draft',
      tooltip:
        'Draft: Indicates that the charge details are pending updates and not yet ready for processing.',
    },
    {
      id: 2,
      value: 'Ready for Submission',
      tooltip:
        'Ready for Submission: Indicates that the charge details are complete and ready for processing on the charge date.',
    },
    {
      id: 3,
      value: 'Processed',
      tooltip:
        'Processed: Indicates that the charge has been processed on the charge date.',
    },
    {
      id: 4,
      value: 'Direct Charge',
      tooltip:
        'Direct Charge: Indicates that the hub team will book time/expense to the WBS directly without the need for processing a separate charge.',
    },
  ],
};
export const chargeStatusDropdownWithoutDirectCharge = {
  direction: 'column',
  dropdownId: 'status',
  isMultiple: false,
  name: 'Charge status',
  placeholder: 'Select Charge Status',
  required: true,
  values: [
    {
      id: 1,
      value: 'Draft',
      tooltip:
        'Draft: Indicates that the charge details are pending updates and not yet ready for processing.',
    },
    {
      id: 2,
      value: 'Ready for Submission',
      tooltip:
        'Ready for Submission: Indicates that the charge details are complete and ready for processing on the charge date.',
    },
    {
      id: 3,
      value: 'Processed',
      tooltip:
        'Processed: Indicates that the charge has been processed on the charge date.',
    },
  ],
};

export const currencyDropdownSettings = {
  direction: 'column',
  dropdownId: 'currency',
  isMultiple: false,
  name: 'Currency',
  required: false,
};

export const inputsRecoveryEntry = [
  {
    flex: '6%',
    id: 'cost',
    label: 'Cost',
    name: 'Cost of recovery entry',
    placeholder: '-',
    required: false,
    value: 'cost',
  },
  {
    flex: '6%',
    id: 'discount',
    label: 'Discount',
    maxLength: null,
    name: 'Discount of recovery entry',
    placeholder: '-',
    required: false,
    value: 'discount',
  },
  {
    flex: '7%',
    id: 'subTotal',
    label: 'Recovery Amount',
    name: 'Subtotal of recovery entry',
    placeholder: '-',
    required: false,
    value: 'subTotal',
  },
  {
    flex: '7%',
    id: 'recovery2P',
    label: '2% Recovery',
    name: '2% Recovery',
    placeholder: '-',
    required: false,
    value: 'recovery2P',
  },
  {
    flex: '12%',
    id: 'description',
    label: 'Description',
    maxLength: '100',
    name: 'Description of recovery entry',
    placeholder: '-',
    required: false,
    value: 'description',
  },
];

export const summaryDetails = [
  {
    label: 'Total Costs',
    name: 'totalCosts',
    tooltip: '',
  },
  {
    label: 'Total Discounts',
    name: 'discounts',
    tooltip: '',
  },
  {
    label: 'Total Recovery',
    name: 'recovery',
    tooltip: '',
  },
  {
    label: 'Direct Charges (Estimated)',
    name: 'passThrough',
    tooltip: {
      titleTooltip:
        'Direct Charges are booked directly to the provided WBS, and are tracked for estimation purposes only.',
    },
  },
];
