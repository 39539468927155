import { ExchangeSearchQuery } from '@utils/index';
import { createAction, props } from '@ngrx/store';

export const updatePrepopulated = createAction(
  '[Recovery] Update prepopulated',
  props<{ value: boolean }>()
);

export const updateCurrencyDropdown = createAction(
  '[Recovery] Update Currency',
  props<{ currencyDropdown: any }>()
);

export const updateRecoveryComments = createAction(
  '[Recovery] Update RecoveryComments',
  props<{ recoveryComments: string }>()
);

export const updateWbsCodes = createAction(
  '[Recovery] Update WbsCodes',
  props<{ wbsCodes: any }>()
);

export const updateRecoveryTotal = createAction(
  '[Recovery] Update RecoveryTotal',
  props<{ recoveryTotal: any }>()
);

export const updateTotalCosts = createAction(
  '[Recovery] Update TotalCosts',
  props<{ totalCosts: any }>()
);

export const updateTotalDiscounts = createAction(
  '[Recovery] Update TotalDiscounts',
  props<{ totalDiscounts: any }>()
);

export const updatePassThrough = createAction(
  '[Recovery] Update PassThroughEstimate',
  props<{ passThroughEstimate: any }>()
);

export const updateRecoveryEntries = createAction(
  '[Recovery] Update RecoveryEntries',
  props<{ recoveryEntries: any }>()
);

export const loadWBSLookupResults = createAction(
  '[Recovery] Load WbsLookupSearchResults',
  props<{ wbs: string; index: number }>()
);

export const loadWBSLookupResultsSuccess = createAction(
  '[Recovery] Load WBSLookupResultsSuccess',
  props<{ wbsCodeResults: any }>()
);

export const loadWBSLookupResultsFailure = createAction(
  '[Recovery] Load WBSLookupFailure',
  props<{ error: string }>()
);

export const getExchangeRate = createAction(
  '[Recovery] Get Exchange Rate',
  props<{ searchQuery: ExchangeSearchQuery }>()
);

export const getExchangeRateSuccess = createAction(
  '[Recovery] Get Exchange Rate Success',
  props<{ exchangeRate: any }>()
);

export const getExchangeRateFailure = createAction(
  '[Recovery] Get Exchange Rate Failure',
  props<{ error: any }>()
);

export const updateRecoveriesStatus = createAction(
  '[Recovery] Update Recoveries Status',
  props<{ recoveryEntries: any }>()
);

export const updateRecoveriesStatusSuccess = createAction(
  '[Recovery] Update Recoveries Status Success',
  props<{ response: any }>()
);

export const updateRecoveriesStatusFailure = createAction(
  '[Recovery] Update Recoveries Status Failure',
  props<{ error: any }>()
);

export const updateRecoverSuccess = createAction(
  '[Admin] Update updateRecoverSuccess',
  props<{ value: any }>()
);

export const clearStateObject = createAction('[Recovery] Clear State Object');
