import { AttendeeDetailsFacade } from '@ioh/core-data';
import { AttendeeNumberService } from '@ioh/ui-library';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrganizationService } from '../../../services/organization.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  organizationType,
  radioButtonsFields,
} from '@assets/data/index';

@Component({
  selector: 'ioh-attendee-details',
  styleUrls: ['./attendee-details.component.scss'],
  templateUrl: './attendee-details.component.html',
})
export class AttendeeDetailsComponent {
  organizationType = organizationType;
  attendeeDetailsFormGroup: FormGroup;
  radioValues = radioButtonsFields;
  externalOrganizationCategories$ = this.attendeeDetailsFacade
    .allOrganizationCategories$;

  accentureEntitiesResults$ = this.attendeeDetailsFacade
    .allAccentureEntitiesResults$;

  organizationTypesResults$ = this.attendeeDetailsFacade
    .allOrganizationTypesResults$;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly fb: FormBuilder,
    private readonly attendeeDetailsFacade: AttendeeDetailsFacade,
    private readonly organizationService: OrganizationService,
    public attendeeNumberService: AttendeeNumberService
  ) {}

  ngOnInit() {
    this.attendeeDetailsFormGroup = this.fb.group({
      leadershipLevel: this.fb.control('', [Validators.required]),
      attendeeNumber: this.attendeeNumberService.createAttendeeNumber(),
      organisations: this.fb.array([]),
    });
    this.loadCategories();
    this.loadOrganizationTypes();
    this.loadOperatingGroupResults();
    this.loadAccentureEntities();
    this.registerSubscriptions();
  }

  registerSubscriptions() {
    this.getClientSearchResults();
    this.updateOrganizationSearchResults();
    this.updateOrganizations();
    this.updateNumberOfAttendees();
    this.updateLeadershipLevel();
  }

  loadCategories() {
    this.attendeeDetailsFacade.getOrganizationCategoriesResults();
  }

  loadOrganizationTypes() {
    this.attendeeDetailsFacade.getOrganizationTypesResults();
  }

  loadOperatingGroupResults() {
    this.attendeeDetailsFacade.getOperatingGroupResults();
  }

  loadAccentureEntities() {
    this.attendeeDetailsFacade.getAccentureEntities();
  }

  updateOrganizations() {
    this.subscriptions.add(
      this.attendeeDetailsFormGroup
        .get('organisations')
        .valueChanges.subscribe((changes) => {
          this.attendeeDetailsFacade.updateOrganizations(changes);
        })
    );
  }

  updateOrganizationSearchResults() {
    this.subscriptions.add(
      this.attendeeDetailsFacade.allClientSearchResults$.subscribe((res) => {
        this.organizationService.updateSearchResults(res);
      })
    );
  }

  getClientSearchResults() {
    this.subscriptions.add(
      this.organizationService.clientSearchQuery
        .pipe(filter((res: string) => !!res))
        .subscribe((currentSearchQuery: string) => {
          this.attendeeDetailsFacade.getClientOrganizationSearchResults(
            currentSearchQuery
          );
          this.organizationService.clientSearchResultsLoading.next(true);
        })
    );
  }

  get numberOfAttendees(): FormGroup {
    return this.attendeeDetailsFormGroup.get('attendeeNumber') as FormGroup;
  }

  updateNumberOfAttendees() {
    this.subscriptions.add(
      this.attendeeDetailsFormGroup
        .get('attendeeNumber')
        .valueChanges.subscribe(() => {
          this.attendeeDetailsFacade.updateNumberOfAttendees(
            this.numberOfAttendees.getRawValue()
          );
        })
    );
  }

  updateTotal(totalAttendee: number) {
    this.attendeeDetailsFacade.updateTotalAttendees(totalAttendee);
  }

  updateLeadershipLevel() {
    this.subscriptions.add(
      this.attendeeDetailsFormGroup
        .get('leadershipLevel')
        .valueChanges.subscribe((changes) => {
          this.attendeeDetailsFacade.updateSelectedLeadershipLevel(changes);
        })
    );
  }
}
