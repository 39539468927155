import {
  ARCHIVE_FEATURE_KEY,
  ArchivePartialState,
  ArchiveState,
  archiveAdapter,
} from './archive.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'Archive' feature state managed by NgRx
export const getArchiveState = createFeatureSelector<
  ArchivePartialState,
  ArchiveState
>(ARCHIVE_FEATURE_KEY);

const { selectAll, selectEntities } = archiveAdapter.getSelectors();

export const getWorkshopEntities = createSelector(
  getArchiveState,
  (state: ArchiveState) => selectEntities(state)
);

export const getUserId = createSelector(
  getArchiveState,
  (state: ArchiveState) => state.userId
);
export const getWorkshops = createSelector(
  getArchiveState,
  (state: ArchiveState) => selectAll(state)
);
export const getSelectedId = createSelector(
  getArchiveState,
  (state: ArchiveState) => state.selectedId
);

export const getSelected = createSelector(
  getWorkshopEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const getSelectedArchiveWorkshop = createSelector(
  getArchiveState,
  (state: ArchiveState) => state.selectedArchiveWorkshop
);
export const getArchiveWorkshops = createSelector(
  getArchiveState,
  (state: ArchiveState) => state.archiveWorkshop
);

export const getIsArchive = createSelector(
  getArchiveState,
  (state: ArchiveState) => state.isArchive
);
