import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { browser } from '@utils/index';

@Component({
  selector: 'ioh-date-picker',
  styleUrls: ['./date-picker.component.scss'],
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent implements OnInit {
  @Input() icon: string;
  @Input() value: any;
  @Input() minDate: Date;
  @Input() ariaLabel: string;
  @Input() isEndDate: boolean;
  // @Input() isBlank: boolean;
  @Input() warning: boolean;
  @Input() placeholder: string;
  parentForm: AbstractControl;
  browser = browser;

  constructor(public readonly controlContainer: ControlContainer) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
  }

  formControlHasMultipleErrors(value: string) {
    if (this.parentForm.get(value).errors) {
      return Object.keys(this.parentForm.get(value).errors).length > 1;
    }
  }

  formHasMultipleErrors() {
    if (this.parentForm.errors) {
      return Object.keys(this.parentForm.errors).length > 1;
    }
  }
}
