import { dropdownList } from './admin-details';

export const monthStatusDropdownSettings = {
  direction: 'row',
  dropdownId: 'monthField',
  isMultiple: true,
  label: '',
  name: 'month dropdown',
  placeholder: 'Select Month',
  required: false,
};
export const chargeStatusDropdownSettings = {
  direction: 'row',
  dropdownId: 'chargeStatusField',
  isMultiple: true,
  label: '',
  name: 'charge Status dropdown',
  placeholder: 'Charge Status',
  required: false,
};
export const engStatusDropdownSettings = {
  direction: 'row',
  dropdownId: 'EngagementField',
  isMultiple: true,
  label: '',
  name: 'Engagement dropdown',
  placeholder: 'Engagement Status',
  required: false,
};
export const fiscalYearDropdownSettings = {
  direction: 'row',
  dropdownId: 'fiscalYearField',
  isMultiple: true,
  label: '',
  name: 'Engagement dropdown',
  placeholder: 'Select Fiscal Year',
  required: false,
};
export const monthMap = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const NoDateIdentifiedOption = 'No Date Identified';
export const recoveryMultiSelectFields = [
  {
    type: 'multiSelect',
    flex: '28%',
    id: 'monthField',
    isMultiple: true,
    name: 'month dropdown',
    field: dropdownList.month,
    placeholder: 'Select Month',
    values: dropdownList.month,
  },
  {
    type: 'multiSelect',
    flex: '35%',
    id: 'chargeStatusField',
    isMultiple: true,
    name: 'charge Status dropdown',
    field: dropdownList.recoveryStatus,
    placeholder: 'Charge Status',
    values: dropdownList.recoveryStatus,
  },
  {
    type: 'multiSelect',
    flex: '35%',
    id: 'EngagementField',
    isMultiple: true,
    name: 'Engagement Status',
    field: dropdownList.engagementStatus,
    placeholder: 'Engagement Status',
    values: dropdownList.engagementStatus,
  },
];
export const metricsMultiSelectFields = [
  {
    type: 'multiSelect',
    flex: '35%',
    id: 'fiscalYearField',
    isMultiple: true,
    name: 'fiscalYear dropdown',
    field: dropdownList.fiscalYear,
    placeholder: 'Select Fiscal Year',
    values: dropdownList.fiscalYear,
  },
];
