import * as ArchiveActions from './archive.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { OrchestratorService } from '../../../orchestrator-service';
import { catchError, delay, exhaustMap, map, retry } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ArchiveEffects {
  loadAllArchiveDataByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.loadAllArchiveDataByUserId),
      exhaustMap((action) =>
        this.orchestratorService.getAllArchiveDataByUserId(action.userId).pipe(
          map((res) =>
            ArchiveActions.loadAllArchiveDataByUserIdSuccess({
              archiveWorkshop: res,
              getWorkshop: true,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              ArchiveActions.loadAllArchiveDataByUserIdFailure({
                error: error,
                getWorkshop: true,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly orchestratorService: OrchestratorService
  ) {}
}
