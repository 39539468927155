import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AdminFacade, FormFacade } from '@ioh/core-data';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ITermsConditions } from '@ioh/types';
import { ModalService } from '@services';
import { MsalService } from '@azure/msal-angular';
import { OrchestratorService } from '../../../../../libs/core-data/src/lib/orchestrator-service';
import { ReferenceDataService } from '../../../../../libs/core-data/src/lib/reference-data';
import { Subscription, of } from 'rxjs';
import { clone } from 'lodash';
import { filter, switchMap } from 'rxjs/operators';
import isEmpty from 'lodash/isEmpty';

@Component({
  selector: 'ioh-survey',
  styleUrls: ['./survey.component.scss'],
  templateUrl: './survey.component.html',
})
export class SurveyComponent implements OnInit, OnDestroy {
  workshopId: string;
  programId: string;
  isSavingSurvey = false;
  errorMessage: string;
  userId = this.authService.instance.getAllAccounts()[0].username.split('@')[0].toLowerCase();
  private readonly subscriptions: Subscription = new Subscription();
  @ViewChild('TermAndConditionTemplate') modalTemplate: TemplateRef<any>;
  @ViewChild('FailedSubmissionTemplate')
  failedSubmissionTemplate: TemplateRef<any>;

  width = 'fit-content';
  baseForm: FormGroup;
  contents: ITermsConditions;
  surveySubmitted: boolean = false;
  surveyForm: FormGroup;
  questionList: any;
  optionList: any;
  loading: boolean = true;
  programName: any;
  notesLabel: any = '';
  engagementTitle: any = '';
  showTitle: boolean = false;
  programList: any = [];
  constructor(
    private readonly adminFacade: AdminFacade,
    private readonly modalService: ModalService,
    private readonly authService: MsalService,
    private readonly referenceDataService: ReferenceDataService,
    private readonly orchestratorService: OrchestratorService,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly formFacade: FormFacade,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getQuestion();
  }

  initForm() {
    this.surveyForm = this.fb.group({
      q1Answer: this.fb.control('', Validators.required),
      q2Answer: this.fb.control('', Validators.required),
      q3Answer: this.fb.control('', Validators.required),
      q4Answer: this.fb.control('', Validators.required),
      q5Answer: this.fb.control('', Validators.required),
      comments: this.fb.control(''),
      surveyUser: this.fb.control(this.userId),
    });
    this.getRoutelink();
    this.baseForm = this.formBuilder.group({
      checkbox: this.formBuilder.control(false, [Validators.requiredTrue]),
    });
    this.subscriptions.add(
      this.formFacade.termsAndConditions$.subscribe((res: ITermsConditions) => {
        this.contents = res;
      })
    );
    this.subscriptions.add(
      this.formFacade.surveySubmitted$.subscribe((res: boolean) => {
        this.surveySubmitted = res;
      })
    );
    this.subscriptions.add(
      this.adminFacade.getProgramList$.subscribe((programList) => {
        // this is the program List state object
        this.programList = programList;
      })
    );
    this.subscriptions.add(
      this.formFacade.surveyRequestSuccessful$
        .pipe(filter((x) => !!x))
        .subscribe((surveyRequestSuccessful: boolean) => {
          this.setSurveySuccessfulRequest(
            surveyRequestSuccessful,
            this.router.url
          );
        })
    );
    this.subscriptions.add(
      this.formFacade.surveyError$
        .pipe(
          filter((error: any) => error?.url.includes('orchestrator/saveSurvey'))
        )
        .subscribe(() => {
          this.errorMessage =
            "Don't worry, all your Survey details are still stored in the form, just try clicking the submit button again in a few moments.";
          this.openFailedRequestDialog();
          this.isSavingSurvey = false;
        })
    );
  }

  setSurveySuccessfulRequest(requestSuccessful, roter) {
    if (
      this.surveySubmitted &&
      roter.includes('/survey') &&
      requestSuccessful
    ) {
      this.router.navigate(['survey-complete'], {
        state: { response: requestSuccessful },
      });
      this.formFacade.clearSurveySuccessfulRequest();
      this.isSavingSurvey = false;
    }
  }

  saveSurvey() {
    try {
      const surveyAnswer = this.surveyForm.value;
      surveyAnswer.workshop = {
        id: this.workshopId,
        status: null,
        program: null,
        fiscalYear: null,
        document: [],
        surveyAnswer: [],
      };
      this.isSavingSurvey = true;
      this.formFacade.saveSurvey(surveyAnswer);
    } catch (error) {
      const errorMsg: string = String(error).split('Error: ')[1];
      this.errorMessage = errorMsg;
      this.openFailedRequestDialog();
    }
  }

  openFailedRequestDialog() {
    this.modalService.openDialog(
      { template: this.failedSubmissionTemplate },
      this.width
    );
  }

  closeFailedSubmissionDialog() {
    this.modalService.closeDialog();
  }

  getRoutelink(): void {
    this.subscriptions.add(
      this.route.paramMap
        .pipe(switchMap((params: ParamMap) => of(params.get('id'))))
        .subscribe((id) => {
          this.workshopId = id;
          this.orchestratorService
            .getGeneralDetail(this.workshopId)
            .subscribe((data) => {
              this.setEngTitle(data.data);
              this.checkUserAccess(data.data);
            });
        })
    );
    this.subscriptions.add(
      this.route.paramMap
        .pipe(switchMap((params: ParamMap) => of(params.get('programId'))))
        .subscribe((programId) => {
          this.programId = programId;
          this.formFacade.getTermsAndConditions(this.programId);
        })
    );
  }

  checkUserAccess(data) {
    let accessFail = false;
    if (!isEmpty(this.programList) && !isEmpty(data)) {
      const validProgram = this.programList.find(
        (o) => o.id === this.programId
      );
      const validWorkshop = this.programList.find(
        (o) => o.id === data[0].workshop.program.id
      );
      if (!validProgram || !validWorkshop) {
        accessFail = true;
      }
      if (this.programId !== data[0].workshop.program.id) {
        accessFail = true;
      }
    }
    if (accessFail) {
      this.router.navigateByUrl('/invalidLink');
    }
  }

  setEngTitle(data) {
    if (!isEmpty(data)) {
      this.showTitle = true;
      this.engagementTitle = data[0].engagement && data[0].engagement.title;
    } else {
      this.router.navigateByUrl('/invalidLink');
    }
  }

  onAccept(): void {
    this.baseForm.patchValue({ checkbox: true });
    this.modalService.closeDialog();
  }

  get termsAndConditionsAccepted() {
    return this.baseForm.get('checkbox').value;
  }

  onDownload(): void {
    window.print();
  }

  openDialog(event): void {
    event.preventDefault();
    const dataModal = { template: this.modalTemplate };
    this.modalService.openDialog(dataModal, this.width);
  }

  getQuestion() {
    const programId = this.router.url.split('/')[3];
    this.referenceDataService.getProgramName(programId).subscribe((data) => {
      this.programName = data.data.name;
      this.notesLabel = `Please share any additional feedback that you have for the ${this.programName} Innovation Hub team.  Thank you!`;
      this.referenceDataService.getSurveyQuestionnaire().subscribe((data) => {
        this.questionList = data.data;
        this.formatQuestionReplacement();
        this.getSurveyOptions();
      });
    });
  }

  formatQuestionReplacement() {
    this.questionList.map((question) => {
      question.value = question.value.replace(
        'programNeedtoReplace',
        this.programName
      );
    });
  }

  getSurveyOptions() {
    this.referenceDataService.getAnswerOptions().subscribe((data) => {
      this.loading = false;
      const options = data.data;
      this.formatQuestionList(options);
      this.setQ1Answer();
    });
  }

  formatQuestionList(options) {
    const question = clone(this.questionList);
    question.forEach((element) => {
      const answerOptions = [];
      options.forEach((option) => {
        if (element.keyword == option.keyword) {
          answerOptions.push(option);
        }
      });
      element.option = [
        {
          field: `q${element.id}Answer`,
          optionId: `q${element.id}Answer`,
          values: answerOptions.map((answer) => {
            return answer.value;
          }),
        },
      ];
      return element;
    });
  }

  setQ1Answer() {
    const score = Number(this.router.url.split('/')[4]);
    if (score && score < 6 && score > 0) {
      const q1Score = Number(this.router.url.split('/')[4]) - 1;
      const q1Answer = this.questionList[0].option[0].values[q1Score];
      this.surveyForm.get('q1Answer').setValue(q1Answer);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
