import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { VisitDetailsFacade } from '@ioh/core-data';
import { WBSValidators } from '@utils/index';
import { WbsEntryService } from '@ioh/ui-library';
import { filter } from 'rxjs/operators';
import {
  radioBtnVisitTypeFields,
  radioOptions,
} from '@assets/data/index';
@Component({
  selector: 'ioh-visit-details',
  styleUrls: ['./visit-details.component.scss'],
  templateUrl: './visit-details.component.html',
})
export class VisitDetailsComponent {
  private readonly subscriptions: Subscription = new Subscription();
  constructor(
    private readonly fb: FormBuilder,
    private readonly wbsValidators: WBSValidators,
    public wbsEntryService: WbsEntryService,
    private readonly visitDetailsFacade: VisitDetailsFacade
  ) {}

  visitDetailsGroup: FormGroup;
  minDate: Date = new Date();
  dateLimit = 2;
  options = radioOptions;
  visitTypes = radioBtnVisitTypeFields;
  maxPercentage: number = 100;
  totalRemainingPercentageAllocation: number = 100;
  wbsDetails = false;

  ngOnInit(): void {
    this.visitDetailsGroup = this.fb.group({
      duration: this.fb.control(''),
      visitType: this.fb.control(''),
      wbsFormArray: this.fb.array(
        [this.wbsEntryService.createPercentageWbs()],
        this.wbsValidators.percentageControlsValidator
      ),
    });
    this.mapWBSLookupSearchResults();
    this.updateDuration();
    this.updateVisitType();
    this.updateSelectedWBSCodes();
  }

  get wbsFormArray(): FormArray {
    return this.visitDetailsGroup.get('wbsFormArray') as FormArray;
  }

  mapWBSLookupSearchResults() {
    this.subscriptions.add(
      this.visitDetailsFacade.wbsLookupSearchResults$
        .pipe(filter((x) => !!x))
        .subscribe((searchResults) => {
          this.wbsFormArray
            .at(searchResults.WbsComponentIndex)
            .get('isValid')
            .setValue(searchResults.IsValid);
          this.wbsFormArray
            .at(searchResults.WbsComponentIndex)
            .get('responseLoaded')
            .setValue(true);
          this.wbsFormArray
            .at(searchResults.WbsComponentIndex)
            .get('type')
            .setValue(searchResults.BusinessActivityLevel1Description);
          this.wbsFormArray
            .at(searchResults.WbsComponentIndex)
            .get('description')
            .setValue(searchResults.WbsDescription);
          this.wbsFormArray
            .at(searchResults.WbsComponentIndex)
            .get('wbsStatus')
            .setValue(searchResults.WbsStatusDescription);
          // this.wbsFormArray
          //   .at(searchResults.WbsComponentIndex)
          //   .get('expiration')
          //   .setValue(searchResults.BasicFinishDate);
        })
    );
  }

  updateDuration() {
    this.subscriptions.add(
      this.visitDetailsGroup
        .get('duration')
        .valueChanges.subscribe((changes) => {
          this.visitDetailsFacade.updateDuration(changes);
        })
    );
  }

  updateVisitType() {
    this.subscriptions.add(
      this.visitDetailsGroup
        .get('visitType')
        .valueChanges.subscribe((changes) => {
          this.visitDetailsFacade.updateVisitType(changes);
        })
    );
  }

  updateSelectedWBSCodes() {
    this.subscriptions.add(
      this.visitDetailsGroup
        .get('wbsFormArray')
        .valueChanges.subscribe((changes) => {
          this.visitDetailsFacade.updateSelectedWBSCodes(changes);
        })
    );
  }

  getWbs(event) {
    this.visitDetailsFacade.getWBS(event.wbs, event.index);
  }
}
