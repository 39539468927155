import { AdminFacade } from '@ioh/core-data';
import { AppLoadingService } from '@services';
import { BehaviorSubject } from 'rxjs';
import { Component } from '@angular/core';
import { ConsultationFacade, GeneralFacade } from '@ioh/core-data';
import { MsalService } from '@azure/msal-angular';
import { OrchestratorService } from '../../../../../libs/core-data/src/lib/orchestrator-service/index';
import { Router } from '@angular/router';
import { dropDownLookUp } from '@utils/index';
import { dropdownList } from '@assets/data/index';
import { monthMap } from '@assets/data/index';
@Component({
  selector: 'ioh-back-office',
  styleUrls: ['./back-office.component.scss'],
  templateUrl: './back-office.component.html',
})
export class BackOfficeComponent {
  queryParam: string = '';
  userId = this.authService.instance.getAllAccounts()[0].username.split('@')[0];
  isAdmin = false;
  ProgramList: any;
  workshopList: any;
  allInternalPurposes$ = new BehaviorSubject<any>(null);
  engagementTypes$ = new BehaviorSubject<any>(null);
  constructor(
    private readonly adminFacade: AdminFacade,
    private readonly orchestratorService: OrchestratorService,
    private readonly authService: MsalService,
    public readonly appLoadingService: AppLoadingService,
    private readonly generalTabFacade: GeneralFacade,
    private readonly consultationFacade: ConsultationFacade,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.adminFacade.getProgramList$.subscribe((programList) => {
      this.ProgramList = programList.map((item) => item.id).join(',');
    });
    this.adminFacade.getWorkshopStatus();
    this.adminFacade.getCancellationReasons();
    // get pipelineview dropdown
    this.adminFacade.getView();
    this.generalTabFacade.getEngagementTypeResults();
    this.generalTabFacade.getEngagementGroupResults('');
    this.generalTabFacade.getEngagementGroupEcosystemResults('');
    this.generalTabFacade.getOrganizationTypes();
    this.generalTabFacade.getAccentureEntities();
    this.generalTabFacade.getExternalOrganizationCategoriesResults();
    this.consultationFacade.loadInternalPurposeResults('');
    this.adminFacade.getAdminProgramList$.subscribe((adminProgramList) => {
      this.isAdmin = adminProgramList.length > 0;
    });
    this.consultationFacade.allInternalPurposes$.subscribe((dropdown) => {
      if (dropdown?.length > 0) {
        this.allInternalPurposes$.next(dropdown);
      }
    });
    this.generalTabFacade.engagementTypeResults$.subscribe((dropdown) => {
      if (dropdown?.length > 0) {
        this.engagementTypes$.next(dropdown);
      }
    });
    if (sessionStorage.getItem('path')) {
      // if user isn't admin and want to navaigate to admin/user-access, system will redirect to admin/home
      if (
        (sessionStorage.getItem('path') == 'admin/user-access' ||
          sessionStorage.getItem('path') == 'admin/archived-engagement') &&
        !this.isAdmin
      ) {
        const path = 'admin/home';
        this.router.navigateByUrl(path);
      }
      // for building the login url in case of logout
      sessionStorage.setItem('loginPath', sessionStorage.getItem('path'));
      sessionStorage.removeItem('path');
    }
    this.adminFacade.saveUserId(this.userId);
    sessionStorage.setItem('userId', this.userId);
    // setTimeout(() => {
    this.getWorkshopsByHub();
    // }, 10000);
    this.adminFacade.loadOrgList();
    const date = new Date();
    const currentYear = date.getFullYear();
    const month = date.getMonth();
    const currentMonthYear: dropDownLookUp[] = [
      {
        id: null,
        typeName: dropdownList.month,
        value: `${monthMap[month]} ${currentYear}`,
      },
    ];
    sessionStorage.setItem(
      'chargeBackMultiSelectFilter',
      JSON.stringify({
        month: currentMonthYear,
        recoveryStatus: [],
        engagementStatus: [],
      })
    );
    const currentMonth = date.getMonth() + 1;
    const FYYear = currentMonth < 9 ? currentYear : currentYear + 1;
    const currentFY: dropDownLookUp[] = [
      {
        id: null,
        typeName: dropdownList.fiscalYear,
        value: 'FY' + FYYear.toString().substr(-2),
      },
    ];
    sessionStorage.setItem(
      'metricsMultiSelectFilter',
      JSON.stringify({
        fiscalYear: currentFY,
      })
    );
    sessionStorage.setItem(
      'allEngageMultiSelectFilter',
      JSON.stringify({
        fiscalYear: currentFY,
        month: [],
        engagementStatus: [],
        engagementType: [],
        engagementPurpose: [],
        engagementLead: {
          engagementLeadInput: '',
          engagementLeadList: [],
        },
        organizationType: [],
      })
    );
    sessionStorage.setItem(
      'downloadReportMultiSelectFilter',
      JSON.stringify({
        fiscalYear: currentFY,
        engagementStatus: [],
      })
    );
  }

  async getWorkshopsByHub() {
    this.workshopList = [];
    const hubList = this.ProgramList ? this.ProgramList.split(',') : [];
    const promiseList = [];
    for (const hub of hubList) {
      promiseList.push(this.fetchSingleHubWorkshop(hub));
    }
    await Promise.all(promiseList).then(() => {
      let data = [];
      this.workshopList.map(function (value) {
        data = data.concat(value);
      });
      this.adminFacade.setupWorkshopFromHubGross(data);
    });
  }

  async fetchSingleHubWorkshop(hub): Promise<any> {
    return new Promise((resolve, reject) => {
      this.orchestratorService.getWorkshopsByHub(hub).then((response) => {
        this.workshopList.push(response);
        resolve(response);
      });
    });
  }
}
