import { createAction, props } from '@ngrx/store';

export const loadAllArchiveDataByUserId = createAction(
  '[Archive] Load loadAllArchiveDataByUserId',
  props<{ userId: string }>()
);
export const loadAllArchiveDataByUserIdSuccess = createAction(
  '[Archive] Load loadAllArchiveDataByUserId Success',
  props<{ archiveWorkshop: any[]; getWorkshop: boolean }>()
);
export const loadAllArchiveDataByUserIdFailure = createAction(
  '[Archive] Load loadAllArchiveDataByUserId Failure',
  props<{ error: any; getWorkshop: boolean }>()
);

export const updatedSelectedArchiveWorkshop = createAction(
  '[Archive] Update select Archive workshop',
  props<{ selectedArchiveWorkshop: any }>()
);

export const updatedIsArchive = createAction(
  '[Archive] Update is Archive workshop',
  props<{ value: any }>()
);

export const setSelectedWorkshopId = createAction(
  '[Archive] Set SelectedWorkshopId',
  props<{ id: string }>()
);

export const saveUserId = createAction(
  '[Archive] Save User ID',
  props<{ userId: string }>()
);
