import * as ld from 'launchdarkly-js-client-sdk';
// import {
//   SecretsManagerClient,
//   GetSecretValueCommand,
// } from "@aws-sdk/client-secrets-manager";
import { AccessService } from '@services';
import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  OnChanges,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AppStateService } from './services/appstate.service';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  AuthenticationResult,
} from '@azure/msal-browser';
import * as msal from "@azure/msal-browser";



import { GeneralFacade, RecoveryFacade } from '@ioh/core-data';
import { ModalService } from './services/modal.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { dropdownList } from '@assets/data/index';
import { environment } from '../environments/environment';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ioh-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  title = 'ioh-app';
  defaultName = 'c.chetankumar.thekdi';
  widthTimeOutModal = '26rem';
  isloaded:boolean=true;
  userId = this.authService.instance.getAllAccounts()[0]?this.authService.instance.getAllAccounts()[0].username.split('@')[0]: this.defaultName;
  notConsentRadioBtn: HTMLInputElement;
  private readonly subscription: Subscription = new Subscription();
  private interval: any;
  private radioClickListener: () => void;
  @ViewChild('timeOutTemplate') modalTemplate: TemplateRef<any>;
  private readonly _destroying$ = new Subject<void>();
  user = {
    key: this.userId,
  };
  public isAuthenticated = false;
  client: ld.LDClient = ld.initialize(environment.launchDarklyID, this.user);
  
  constructor(
    private readonly generalTabFacade: GeneralFacade,
    private readonly recoveryFacade: RecoveryFacade,
    private readonly router: Router,
    public appStateService: AppStateService,
    public readonly modalService: ModalService,
    public accessService: AccessService,
    private readonly renderer: Renderer2,
    public readonly authService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnChanges(){
    console.log('changes');
  }

  onOutletLoaded(event:any) {
    this.isloaded=false;
  }

     ngOnInit() {
    //secrets();
    
 const msalConfig = {
  auth: {
    clientId: environment.config.auth.clientId,
          authority: environment.config.auth.authority,
          redirectUri: environment.config.auth.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

 const msalInstance = new msal.PublicClientApplication(msalConfig);
//  msalInstance.handleRedirectPromise().then(authResult=>{
//   const account = msalInstance.getActiveAccount();
//   // if(!account){
//   //       msalInstance.loginRedirect();}
//   console.log(account, 'accounthandle')
//       }).catch(err=>{
//         console.log(err);}); 
    const idToken = sessionStorage.getItem('msal.idtoken');
    if(!idToken){
      this.ldInitFlag();
      sessionStorage.setItem(
        'userId'
        , this.userId);
      
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        localStorage.setItem('msal.idtoken', result.payload['idToken']);
        sessionStorage.setItem('idtoken', result.payload['idToken']);
      });
      this.authService.handleRedirectObservable().subscribe({
        next: (result: AuthenticationResult) => {
          console.log("result",result);
          // Perform actions related to user accounts here
        },
        error: (error) => console.log(error)
      });
      this.subscription.add(this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe((status: InteractionStatus) => {
        const accounts = msalInstance.getAllAccounts();
        // await this.authService.instance.handleRedirectPromise();
        console.log("accounts",accounts);
       //await this.authService.instance.handleRedirectPromise();
        this.checkAuthorizationStatus();
        if (!this.isAuthenticated) {
          // msalInstance.handleRedirectPromise();
         
            this.authService.loginRedirect();
            const msalUser = this.checkAndSetActiveAccount();
            this.checkAuthorizationStatus();
            if (msalUser) {
              sessionStorage.setItem('userId', msalUser.username.split('@')[0]);
            }
        } 
      }));
    }
   
    

    this.generalTabFacade.getEIDropdownEntityResults('');
    this.interval = setInterval(() => {
      this.openModal();
    }, environment.config.timeOut);
    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          sessionStorage.setItem(
            'path',
            decodeURIComponent(event.url.replace(/^\/|\/$/g, ''))
          );
          sessionStorage.setItem(
            'overrideCode',
            decodeURIComponent(
              event.url.replace(/^\/|\/$/g, '').split('overrideCode=')[1]
            )
          );

          if (this.authService.instance.getAllAccounts()[0]) {
            this.subscription.add(this.appStateService.getState().subscribe());
            this.subscription.add(this.accessService.checkAccess().subscribe());
          }
        }
      })
    );
    
    this.subscription.add(
      this.generalTabFacade.EIDropdownResults$.subscribe((dropdown) => {
        // currency dropdown
        const currencyDropdown = dropdown.filter(
          (option) => option.typeName == dropdownList.currency
        );
        this.recoveryFacade.updateCurrencyDropdown(currencyDropdown);
        // org catagory dropdown
        const clientTypeDropdown = dropdown.filter(
          (option) => option.typeName == dropdownList.clientType
        );
        this.generalTabFacade.updateClientTypeDropdown(clientTypeDropdown);
      })
    );
      // const path = sessionStorage.getItem('path');
      // if(path=='')
      // {
      //   this.router.navigateByUrl('');
      // }
      // else
      // {
      //   this.router.navigateByUrl(path);
      // }
      if (sessionStorage.getItem('path')) {
        const path = sessionStorage.getItem('path');
        this.router.navigateByUrl(path);
      }
  
   let userDetails=this.authService.instance.getAllAccounts()[0];
    if (userDetails) {
      window['acnprivacy'].getUserSelection().then(
        function (consent) {
          if (consent == 0) {
            this.authService.logout();
          }
        }.bind(this)
      );
    }
  }

  ldInitFlag() {
    this.client.waitForInitialization().then(() => {
      const featureFlag = this.client.variation('ei-test', false);
      sessionStorage.setItem('featureFlag', featureFlag);
    });
  }
  private  checkAuthorizationStatus() {
    this.isAuthenticated = !! this.authService.instance.getActiveAccount();
  }

  private  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    // let accounts=this.authService.instance.getAllAccounts();
    // let tenantAccounts = accounts.filter(x => x.tenantId == "f3211d0e-125b-42c3-86db-322b19a65a22")
  
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      // activeAccount = accounts[0];
       this.authService.instance.setActiveAccount(accounts[0]);
    }
  
    if (activeAccount) {
      console.debug('Active Account set');
    } else {
      console.debug('No account available to set as active account');
    }
  
    return activeAccount;
  }
  ngAfterViewChecked() {
    this.notConsentRadioBtn = document.querySelector('.acn-consent-radio-2');
    if (
      this.notConsentRadioBtn &&
      this.authService.instance.getAllAccounts()[0]
    ) {
      this.radioClickListener = this.renderer.listen(
        this.notConsentRadioBtn,
        'click',
        () => {
          this.authService.logout();
        }
      );
    }
  }

  openModal(): void {
    const dataModal = { template: this.modalTemplate };
    this.modalService.openDialog(dataModal, this.widthTimeOutModal);
    window.clearInterval(this.interval);
  }

  closeModal() {
    this.modalService.closeDialog();
    this.logout();
  }

  renewToken() {
    const requestObj = {
      scopes: environment.options.consentScopes,
    };
    this.authService.instance
      .acquireTokenSilent(requestObj)
      .then(function (tokenResponse) {
        console.log('Token will expire On: ', tokenResponse.expiresOn);
      })
      .catch((error) => {
        this.errorHandler(error);
      });
    this.modalService.closeDialog();
    this.interval = setInterval(() => {
      this.openModal();
    }, environment.config.timeOut);
  }

  public errorHandler(error: any): void {
    if (error.name == 'InteractionRequiredAuthError') {
      console.error(error);
      this.authService.loginRedirect();
    } else {
      console.error(error);
      this.authService.instance
        .acquireTokenPopup({ scopes: environment.options.consentScopes })
        .catch((error) => {
          console.error(error);
          this.logout();
        });
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    window.clearInterval(this.interval);
    if (this.radioClickListener) {
      this.radioClickListener();
    }
  }
}

