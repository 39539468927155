import * as ArchiveActions from './archive.actions';
import * as ArchiveSelectors from './archive.selectors';
import * as fromArchive from './archive.reducer';
import { Action, Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

@Injectable()
export class ArchiveFacade {
  getUserId$ = this.store.pipe(select(ArchiveSelectors.getUserId));
  getIsArchive$ = this.store.pipe(select(ArchiveSelectors.getIsArchive));

  getSelectedArchiveWorkshop$ = this.store.pipe(
    select(ArchiveSelectors.getSelectedArchiveWorkshop)
  );

  getArchiveWorkshops$ = this.store.pipe(
    select(ArchiveSelectors.getArchiveWorkshops)
  );

  constructor(private readonly store: Store<fromArchive.ArchivePartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  loadAllArchiveDataByUserId(userId?: string) {
    if (userId) {
      this.dispatch(ArchiveActions.loadAllArchiveDataByUserId({ userId }));
    } else {
      this.getUserId$.pipe(first()).subscribe((val) => {
        this.dispatch(
          ArchiveActions.loadAllArchiveDataByUserId({ userId: val })
        );
      });
    }
  }

  updatedSelectedArchiveWorkshop(selectedArchiveWorkshop) {
    this.dispatch(
      ArchiveActions.updatedSelectedArchiveWorkshop({ selectedArchiveWorkshop })
    );
  }

  updatedIsArchive(value: boolean) {
    this.dispatch(ArchiveActions.updatedIsArchive({ value }));
  }
}
