<div [formGroup]="parentForm">
  <ioh-text-area
    class="duration-style"
    fxFlex="60%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    label={{textarea.label}}
    name={{textarea.name}}
    textareaId={{textarea.id}}
    placeholder={{textarea.placeholder}}
    [formGroup]="parentForm"
    value={{textarea.value}}
    maxCharLimit={{textarea.limit}}
    description={{textarea.description}}
    height="{{textarea.height}}"
    [required]=textarea.required
  ></ioh-text-area>
</div>
