export const engagementTypeDropdown = {
  direction: 'column',
  dropdownId: 'engagementType',
  isMultiple: false,
  label: 'Engagement Type',
  name: 'type of service being provided',
  placeholder: 'Type of service that is being provided',
  required: true,
};

export const generalTextAreas = [
  {
    description: 'Summary of problem/challenge statement or engagement purpose',
    height: '4rem',
    id: 'summary',
    label: 'Summary',
    limit: 300,
    name: 'Summary',
    placeholder:
      'Provide short problem/challenge statement or summary of engagement purpose that will guide design or execution.',
    required: true,
    value: 'summary',
  },
  {
    description:
      'Administrative notes for tracking updates and internal information',
    height: '4rem',
    id: 'adminComments',
    label: 'Admin Comments',
    limit: 1000,
    name: 'Admin Comments',
    placeholder:
      'Administrative notes for tracking updates and internal information',
    required: false,
    value: 'adminComments',
  },
];

export const engagementGroupDropdown = {
  direction: 'column',
  dropdownId: 'engagementGroup',
  isMultiple: false,
  label: 'Group Owner',
  name: 'Accenture group driving the engagement',
  placeholder: 'Accenture group driving the engagement',
  required: false,
  values: [
    { value: 'CIS' },
    { value: 'Accenture Office' },
    { value: 'Account Team' },
    { value: 'Applied Intelligence' },
    { value: 'Avanade' },
    { value: 'Digital Products' },
    { value: 'Fjord' },
    { value: 'Industry X.0' },
    { value: 'Labs' },
    { value: 'Liquid Studio' },
    { value: 'Marketing' },
    { value: 'POD' },
    { value: 'Research' },
    { value: 'Ventures' },
    { value: 'Other' },
  ],
};

export const EIDropdownResults = [
  { id: 1, typeName: 'currency', value: 'USD' },
  { id: 2, typeName: 'currency', value: 'CAD' },
  { id: 3, typeName: 'currency', value: 'EUR' },
  { id: 6, typeName: 'month', value: 'No Date Identified' },
  { id: 7, typeName: 'month', value: 'Sep 2020' },
  { id: 11, typeName: 'month', value: 'Jan 2021' },
  { id: 99, typeName: 'month', value: 'Jan 2099' },
];

export const engagementTeamRoleDropdown = {
  direction: 'column',
  isMultiple: false,
  label: 'Role',
  name: 'engagement role dropdown',
  placeholder: 'Choose one',
  required: false,
};

export const inputEngagementTeam = [
  {
    id: 'prepDay',
    label: 'Prep Days',
    maxLength: '7',
    name: 'Prep Day',
    placeholder: '-',
    required: false,
    value: 'prepDay',
  },
  {
    id: 'execDay',
    label: 'Execution Days',
    maxLength: '7',
    name: 'Exec Day',
    placeholder: '-',
    required: false,
    value: 'execDay',
  },
  {
    id: 'closeOutDay',
    label: 'Close Out Days',
    maxLength: '7',
    name: 'Close out Day',
    placeholder: '-',
    required: false,
    value: 'closeOutDay',
  },
  {
    id: 'totalDay',
    label: 'Total Days',
    name: 'Total Day',
    placeholder: '-',
    required: false,
    value: 'totalDay',
  },
];

export const groupParticipationDropdown = {
  direction: 'column',
  dropdownId: 'groupParticipation',
  isMultiple: true,
  label: 'Innovation (Internal)',
  name: 'group partecipation',
  placeholder: 'Select groups',
  required: false,
  values: [
    { id: 1, value: 'CIS' },
    { id: 14, value: 'Accenture Office' },
    { id: 12, value: 'Account Team' },
    { id: 8, value: 'Applied Intelligence' },
    { id: 3, value: 'Avanade' },
    { id: 9, value: 'Digital Products' },
    { id: 11, value: 'Fjord' },
    { id: 10, value: 'Industry X.0' },
    { id: 5, value: 'Labs' },
    { id: 2, value: 'Liquid Studio' },
    { id: 13, value: 'Marketing' },
    { id: 7, value: 'POD' },
    { id: 4, value: 'Research' },
    { id: 6, value: 'Ventures' },
    { id: 15, value: 'Other' },
  ],
};

export const groupEcosystemDropdown = {
  direction: 'column',
  dropdownId: 'groupEcosystem',
  isMultiple: true,
  label: 'Ecosystem (External)',
  name: 'group ecosystem',
  placeholder: 'Select groups',
  required: false,
  values: [
    { id: 1, value: 'Salesforce' },
    { id: 2, value: 'Microsoft' },
    { id: 3, value: 'Amazon' },
    { id: 4, value: 'Google' },
    { id: 5, value: 'SAP' },
    { id: 6, value: 'Other Ecosystem Partner​' },
  ],
};

export const countryDropdown = {
  direction: 'column',
  dropdownId: 'country',
  isMultiple: false,
  label: 'Country',
  name: 'Country',
  placeholder: 'Select Country',
  required: false,
  values: [
    { id: 4, value: 'Andorra' },
    { id: 5, value: 'Austria' },
  ],
};

export const industryRDTopicsDropdown = {
  direction: 'column',
  dropdownId: 'industryRD',
  isMultiple: true,
  label: 'Industry Program',
  name: 'industry RD Topics',
  placeholder: 'Select Applicable Programs',
  required: false,
  values: [
    { id: 1, value: 'S&P' },
    { id: 2, value: 'Artificial Intelligence' },
  ],
};

export const labsRDTopicsDropdown = {
  direction: 'column',
  dropdownId: 'labsRD',
  isMultiple: true,
  label: 'Labs R&D Topics',
  name: 'labs RD Topics',
  placeholder: 'Select Applicable Topics',
  required: false,
  values: [
    { id: 1, value: 'Upstream' },
    { id: 2, value: 'Fuels Retail' },
  ],
};

export const groupEcosystemMockDropdown = {
  direction: 'column',
  dropdownId: 'groupEcosystemMock',
  isMultiple: true,
  label: 'Group Participation - Ecosystem External',
  name: 'group ecosystem',
  placeholder: 'Select groups',
  required: false,
  values: [
    { value: 'Salesforce' },
    { value: 'Microsoft' },
    { value: 'Amazon' },
    { value: 'Google' },
    { value: 'SAP' },
    { value: 'Other Ecosystem Partner​' },
  ],
};

export const imageTooltipTxt = {
  startDate:
    'Engagement Start Date:  Indicates when meaningful work began for an engagement.  This field is required when the engagement is moved from “Requested” to “Booked” status.  Please see the illustrative timeline:',
  endDate:
    'Engagement End Date:  Indicates when meaningful work has completed for an engagement.  This field is required when the engagement is moved from “Booked” to “Completed” status.  Please see the illustrative timeline:',
  dateLoction:
    'Please identify when there are specific event dates planned for this engagement.  For example, if there is a 2-day workshop planned with the client team, please add one row for each event date (two rows total).  If your engagement does not have specific event dates, then do not populate any rows.  Internal or planning meetings should NOT be included.  Please see illustrative timeline:',
};

export const generalTableColumns = [
  { name: 'selected', text: 'Select Date', type: 'checkbox', placeholder: '' },
  {
    name: 'dates',
    text: 'Dates',
    type: 'datepicker',
    placeholder: 'mm/dd/yyyy',
  },
  {
    name: 'clientArrival',
    text: 'Client Arrival',
    type: 'timepicker',
    placeholder: 'hh:mm',
  },
  {
    name: 'startTime',
    text: 'Start Time',
    type: 'timepicker',
    placeholder: 'hh:mm',
  },
  {
    name: 'endTime',
    text: 'End Time',
    type: 'timepicker',
    placeholder: 'hh:mm',
  },
  { name: 'hours', text: 'Hours', type: 'input', placeholder: '-' },
  {
    name: 'location',
    text: 'Location',
    type: 'dropdown',
    placeholder: 'Select',
  },
  {
    name: 'comments',
    text: 'Comments',
    type: 'input',
    placeholder: 'List any additional rooms here',
  },
];
