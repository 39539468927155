import * as AttendeeDetailsActions from './attendee-details.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ReferenceDataService } from '../../../reference-data';
import { catchError, delay, exhaustMap, map, retry } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AttendeeDetailsEffects {
  loadCategoriesResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendeeDetailsActions.getOrganizationCategoriesResults),
      exhaustMap((action) =>
        this.referenceDataService.getOrganizationCategories().pipe(
          map((res) =>
            AttendeeDetailsActions.getOrganizationCategoriesResultsSuccess({
              categoriesResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AttendeeDetailsActions.getOrganizationCategoriesResultsFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  loadOperatingGroupResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendeeDetailsActions.getOperatingGroupResults),
      exhaustMap((action) =>
        this.referenceDataService.getOperatingGroup().pipe(
          map((res) =>
            AttendeeDetailsActions.getOperatingGroupResultsSuccess({
              operatingGroupResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AttendeeDetailsActions.getOperatingGroupResultsFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  loadAccentureEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendeeDetailsActions.getAccentureEntities),
      exhaustMap((action) =>
        this.referenceDataService.getAccentureEntities().pipe(
          map((res) =>
            AttendeeDetailsActions.getAccentureEntitiesSuccess({
              accentureEntitiesResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AttendeeDetailsActions.getAccentureEntitiesFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  loadOrganizationTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendeeDetailsActions.getOrganizationTypes),
      exhaustMap((action) =>
        this.referenceDataService.getOrganizationsTypes().pipe(
          map((res) =>
            AttendeeDetailsActions.getOrganizationTypesSuccess({
              organizationTypesResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AttendeeDetailsActions.getOrganizationTypesFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  loadClientSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendeeDetailsActions.getClientOrganizationSearchResults),
      exhaustMap((action) =>
        this.referenceDataService.getClients(action.searchQuery).pipe(
          map((res) =>
            AttendeeDetailsActions.getClientOrganizationSearchResultsSuccess({
              clientOrganizationSearchResults: res.data,
            })
          ),
          catchError((error) =>
            of(
              AttendeeDetailsActions.getClientOrganizationSearchResultsFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly referenceDataService: ReferenceDataService
  ) {}
}
