import { AdminFacade } from '@ioh/core-data';
import { ArchiveFacade } from '../../../../../libs/core-data/src/lib/state/back-office/archive/archive.facade';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from './snackbar.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkshopService implements OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();
  programCode$ = this.adminFacade.getProgramCode$;
  programId$ = this.adminFacade.getProgramId$;
  workshopsIds$ = this.adminFacade.getWorkshopsIds$;
  selectedWorkshop$ = this.adminFacade.selectedWorkshop$;
  selectedCloneWorkshop$ = this.adminFacade.getcloneWorkshop$;
  selectedArchiveWorkshop$ = this.archiveFacade.getSelectedArchiveWorkshop$;
  getIsArchive$ = this.archiveFacade.getIsArchive$;
  isCloneWorkshop$ = this.adminFacade.getIsCloneWorkshop$;
  loadOrgList$ = this.adminFacade.loadOrgList$;
  flattenedSelectedWorkshop$ = this.adminFacade.selectedWorkshop$.pipe(
    filter((x) => !!x),
    map((workshop) => {
      return {
        ...workshop.engagement,
        ...workshop.consultation,
        ...workshop.recovery,
      };
    })
  );

  selectedWorkshopId$ = this.adminFacade.selectedWorkshopId$;
  selectedWorkshopGeneral$ = this.adminFacade.selectedWorkshopGeneral$;
  selectedWorkshopConsultation$ = this.adminFacade
    .selectedWorkshopConsultation$;

  selectedWorkshopRecovery$ = this.adminFacade.selectedWorkshopRecovery$;
  selectedWorkshopDocument$ = this.adminFacade.selectedWorkshopDocument$;
  getWorkshops$ = this.adminFacade.getWorkshops$.pipe(
    filter((v) => !!v),
    map((res) => this.formatWorkshop(res))
  );

  getArchiveWorkshops$ = this.archiveFacade.getArchiveWorkshops$.pipe(
    filter((v) => !!v),
    map((res) => res)
  );

  allWorkshopEntities$ = this.adminFacade.allWorkshopEntities$;

  updatedSuccess$ = this.adminFacade.selectedWorkshopUpdatedSuccess$;
  saveWorkshopError$ = this.adminFacade.saveWorkshopError$;
  workshopStatus$ = this.adminFacade.workshopStatus$;
  cancellationReasons$ = this.adminFacade.cancellationReasons$;

  constructor(
    private readonly adminFacade: AdminFacade,
    private readonly archiveFacade: ArchiveFacade,
    private readonly router: Router,
    public snackbarService: SnackbarService
  ) {
    this.adminFacade.getProgramId$
      .pipe(filter((x) => !!x))
      .subscribe((programId) => {
        this.adminFacade.getProgramById(programId);
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  openConfirmationOfSaveModal() {
    this.snackbarService.openSnackBar({
      message: 'Workshop successfully updated!',
    });
    this.registerSnackbarSubscription();
  }

  formatWorkshop(workshop) {
    return workshop.filter((o) => {
      return 'consultation' in o;
    });
  }

  openStartSavingModal() {
    this.snackbarService.openSnackBar({
      message: 'Saving workshop...',
      duration: 5000,
    });
  }

  openFailureToast(): void {
    this.snackbarService.openSnackBar({
      message: 'Oops! Something went wrong while saving.',
    });
  }

  registerSnackbarSubscription() {
    this.subscriptions.add(
      this.snackbarService.snackbarReference.afterDismissed().subscribe(() => {
        this.adminFacade.updateSuccess(null);
        this.adminFacade.updatedWorkshop(false);
      })
    );
  }

  loadWorkshopStatus() {
    this.adminFacade.getWorkshopStatus();
  }

  loadCancellationReasons() {
    this.adminFacade.getCancellationReasons();
  }

  loadAllWorkshopsByUserId() {
    this.adminFacade.loadAllWorkshopsByUserId();
  }

  setSelectedWorkshopID(id: string) {
    this.adminFacade.setSelectedWorkshopId(id);
  }

  updateWorkshopById(workshop: any) {
    this.openStartSavingModal();
    this.adminFacade.updateWorkshopById(workshop);
  }

  updatedIsArchive(isArchive: any) {
    this.archiveFacade.updatedIsArchive(isArchive);
  }
}
