import { HttpService } from '../http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, retry } from 'rxjs/operators';
import { environment } from '../../../../../../ioh/apps/ioh-app/src/environments';
import { map } from 'rxjs/internal/operators/map';
@Injectable({
  providedIn: 'root',
})
export class OrchestratorService {
  constructor(private readonly http: HttpService) {}

  createRequest(request) {
    const url = environment.endpoints.orchestrator.createRequest;
    return this.http.post(url, request);
  }

  saveSurvey(surveyRequest) {
    const url = environment.endpoints.orchestrator.saveSurvey;
    return this.http.post(url, surveyRequest);
  }

  async getWorkshopsByHub(programList: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${environment.endpoints.orchestrator.getAllWorkshopsByHub}${programList}`;
      this.http.get(url).subscribe((response) => {
        resolve(response);
      });
    });
  }

  checkIsValidWorkshop(detail: any): Observable<any> {
    const url = environment.endpoints.orchestrator.checkIsValidWorkshop;
    return this.http.get(url, { params: detail }).pipe(
      map((res: any) => {
        return res;
      }),
      retry(4),
      delay(1000)
    );
  }

  deleteWorkshop(workshop): Observable<any> {
    const url = `${environment.endpoints.orchestrator.deleteWorkshop}`;
    return this.http.post(url, workshop);
  }

  getAllWorkshopsByUserId(userId): Observable<any> {
    const url = `${environment.endpoints.orchestrator.getAllWorkshopsByUserId}`;
    return this.http.get(url);
  }

  getAllArchiveDataByUserId(userId): Observable<any> {
    const url = `${environment.endpoints.orchestrator.getAllArchiveDataByUserId}`;
    return this.http.get(url);
  }

  loadOrgList(): Observable<any> {
    const url = `${environment.endpoints.orgListUrl}`;
    return this.http.get(url);
  }

  updateWorkshopById(workshop): Observable<any> {
    const url = environment.endpoints.orchestrator.updateWorkshopById;
    return this.http.post(url, workshop);
  }

  updateAdminById(admin): Observable<any> {
    const url = environment.endpoints.user.access;
    return this.http.post(url, admin);
  }

  getProgramById(id: string): Observable<any> {
    const url = `${environment.endpoints.program.getById}/${id}`;
    return this.http.get(url);
  }

  getGeneralDetail(workshopId: string): Observable<any> {
    const url = `${environment.endpoints.getGeneralDetail}${workshopId}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      retry(4),
      delay(1000)
    );
  }

  getWorkshopByPageDetails(pageDetail: any): Observable<any> {
    const url = environment.endpoints.orchestrator.getWorkshopByPageDetails;
    // Pagination params sample
    // pageDetail = {
    // phase 1
    //   userId:'shawn.jia',
    //   listviewName: 'Pending Engagements',
    //   textFilter: 'dsasdasd',
    //   pagination: {
    //     pageSize: 100,
    //     currentPage: 1,
    //   },

    // phase 2
    //   selectedHub: ['uuid1hub', 'uuid2hub321231-321231-32123'],
    //   order: {
    //     orderByField: 'engagementTitle',
    //     desc: false,
    //   },
    //   dropdown:{},
    // };
    return this.http.get(url, { params: pageDetail }).pipe(
      map((res: any) => {
        return res;
      }),
      retry(4),
      delay(1000)
    );
  }
}
