import {
  ReferenceDataService,
} from '../../../../core-data/src/lib/reference-data/index';
import {
  AdminFacade,
} from '../../../../core-data/src/lib/state/back-office/admin/admin.facade';
import {
  ArchiveFacade,
} from '../../../../core-data/src/lib/state/back-office/archive/archive.facade';

import { CalendarOptions, ICalendar } from 'datebook';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ExcelExportService,
  TableService,
  WorkshopService,
} from '@services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IEngagement } from '@utils/index';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NonBackofficeSearchTable } from '@utils/index';
import { Observable, Subscription } from 'rxjs';
import { OrchestratorService } from '@ioh/core-data';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { format } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
@Component({
  selector: 'ioh-list-view',
  styleUrls: ['./list-view.component.scss'],
  templateUrl: './list-view.component.html',
})
export class ListViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data$: Observable<any>;
  @Input() initialData$: Observable<any>;
  @Input() reportData$: Observable<any>;
  @Input() ioh_totalCount$: Observable<any>;
  @Input() triggerLoading$: Observable<any>;
  @Input() totalRecovery$: Observable<any>;
  @Input() colNames;
  @Input() reportColNames;
  @Input() openInNewTab: boolean = false;
  @Input() tableName: string;
  @Input() listviewLoading$: Observable<any>;
  @Input() colour;
  @Input() orgHistory;
  @Input() urgentTableName: boolean = false;
  @Input() hasReport: boolean = false;
  @Input() hasPagination: boolean = false;
  @Input() noNeedSpinner: boolean = false;
  @Input() sectionName: any;
  @Input() initialLoad: boolean = false;
  @Input() isDisabled: boolean = false;
  @Output() pageChange = new EventEmitter();
  @Output() interruptPagination = new EventEmitter();
  showAdminTooltip = false;
  clickActionButton = false;
  config: CalendarOptions;
  @Input() noFilter;

  /**
   * Default to true because all uses of the table, EXCEPT in the ioh-org component
   * should allow rerouting
   */
  @Input() allowReroute: boolean = true;
  @Input() ioh_pageSize: number;
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onMore = new EventEmitter();
  @Output() onAddUser = new EventEmitter();
  @Output() selectionChange = new EventEmitter();
  @Output() onSelectArchiveData = new EventEmitter();
  colNamesKeys: string[];
  dataSource: MatTableDataSource<IEngagement>;
  allData: object[];
  initialData: any;
  allReportData: object[];
  loadingFlag: boolean;
  currentUser = null;
  pageSizeOption = [10, 20, 50];
  textFilter = '';
  totalCount: any;
  totalpage: any;
  startRange: any;
  endRange: any;
  sortColName: any;
  sortDirection: any;
  selectHub: any;
  storage: any;
  @Input() pageLength = 10;
  pager: any = {};
  initialPage = 1;
  maxPages = 10;
  pageRange = '';
  startInput = false;
  selection: SelectionModel<any>;
  private readonly subscriptions: Subscription = new Subscription();
 // selectedworkshop$ = this.adminFacade.selectedworkshopSuccess$;
  alldataFetchDone: boolean;
  selectHubDone: boolean = false;
  dropdownFilterDone: boolean = false;
  textFilterForm: FormGroup;
  textFilterLastValue: boolean = false;
  haveAdoptListviewCondition: boolean = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable) table;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly adminFacade: AdminFacade,
    private readonly archiveFacade: ArchiveFacade,
    private readonly exportService: ExcelExportService,
    private readonly referenceService: ReferenceDataService,
    private readonly referenceDataService: ReferenceDataService,
    public readonly workshopService: WorkshopService,
    public readonly tableService: TableService,
    private readonly orchestratorService: OrchestratorService,
    private readonly matDialog: MatDialog,
    private readonly matMenuModule: MatMenuModule, 
  ) {}

  ngOnChanges(){
    if(this.colNames){
      this.colNamesKeys = Object.keys(this.colNames);
      localStorage.setItem("colNames", JSON.stringify(this.colNames));
      localStorage.getItem("colNames");
    }

    // console.log('ngonchanges',this.colNames);
  }

  

  ngOnInit(): void {
    /* localStorage.setItem("colNames", JSON.stringify(this.colNames));
    let newObj = localStorage.getItem("colNames");
    console.log(JSON.parse(newObj)); */
    localStorage.setItem("colNames", JSON.stringify(this.colNames));
    localStorage.getItem("colNames");
    this.currentUser = sessionStorage.getItem('userId').toLowerCase();
    //this.getcolumntypes();
    this.getWorkshop();
    this.getInitialData();
    this.handlePaginationSubscriptions();
    this.handlehubChange();
    this.handleDropdownFilterChange();
    this.initForm();
    console.log(this.colNames, 'colNames');
    this.textFilterForm
      .get('textFilterCtrl')
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        // this.setFilter();
        if (!this.textFilterLastValue) {
          this.textFilterLastValue = true;
        } else {
          // this.startInput = true;
          this.setFilter();
        }
      });
    if (this.tableName == 'All Engagements') {
      const multiFilter = JSON.parse(
        sessionStorage.getItem('allEngageMultiSelectFilter')
      );
      if (multiFilter.textFilter) {
        this.textFilter = multiFilter.textFilter;
      }
      multiFilter.textFilter = '';
      sessionStorage.setItem(
        'allEngageMultiSelectFilter',
        JSON.stringify(multiFilter)
      );
    }
    this.setPage(this.initialPage);
    this.selection = new SelectionModel<any>(true, []);
    this.colNamesKeys = Object.keys(this.colNames);
    this.colNamesKeys.push('tags');
    // console.log(this.colNamesKeys, 'colnamekeys');
    // console.log('abcd', this);
    const x = Object.values(this.colNames);
    x.splice(0,1);
    // console.log('x1',x)
    // console.log('x2',Object.values(this.colNames));
    if (this.orgHistory) {
      this.pageSizeOption = [5, 10, 20, 50];
      this.pageLength = 2;
    }
    this.data$.pipe(filter((v) => v)).subscribe((val) => {
      this.allData = val;
      console.log("all data",JSON.stringify(this.allData))
      this.dataSource = new MatTableDataSource<IEngagement>(val);
      this.clearSelections();
      this.dataSource.paginator = this.paginator;
      this.setSort();
      this.allFrontendDataFilter();
      if (!this.hasPagination) {
        this.applyTextFilter();
      }
      this.dataChangeTriggerFilter();
    });
    // console.log('hello')
    //  console.log(this.dataSource);
  }

  

  getInitialData(): void {
    if (this.initialData$) {
      this.initialData$.pipe(filter((v) => v)).subscribe((val) => {
        this.initialData = val;
      });
    }
  }

  dataChangeTriggerFilter() {
    if (this.alldataFetchDone) {
      this.setPage(this.pager.currentPage);
    }
  }

  applyTextFilter() {
    debugger;
    if (this.dataSource.data.length > 0 && this.textFilter) {
      this.setFilter();
    }
  }

  initForm() {
    this.textFilterForm = this.fb.group({
      textFilterCtrl: this.fb.control(''),
    });
  }

  ngAfterViewInit() {
    this.addSubscriptions();
  }

  setSort() {
    if (!this.hasPagination || this.alldataFetchDone) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          // number type data sort
          case 'listViewId':
            return +item.id;
          case 'recoveryTotal':
            return +item.subTotal;
          // date type data sort
          case 'chargeDate':
            return item.chargeDate ? new Date(item.chargeDate) : null;
          case 'eventDate':
            return item.eventDate ? new Date(item.eventDate) : null;
          case 'engagementStartDate':
            return item.engagementStartDate
              ? new Date(item.engagementStartDate)
              : null;
          case 'engagementEndDate':
            return item.engagementEndDate
              ? new Date(item.engagementEndDate)
              : null;
          case 'createdAt':
            return item.createdAt ? new Date(item.createdAt) : null;
          case 'feedbackDate':
            return item.feedbackDate ? new Date(item.feedbackDate) : null;
          // lookup type data sort with N/A
          case 'type':
            return item.type !== 'N/A' ? item.type : null;
          case 'lead':
            return item.lead !== 'N/A' ? item.lead : null;
          case 'organizationTypes':
            return item.organizationTypes !== 'N/A'
              ? item.organizationTypes
              : null;
          case 'internalPurpose':
            return item.internalPurpose !== 'N/A' ? item.internalPurpose : null;
          case 'program':
            return item.program !== 'N/A' ? item.program : null;
          case 'orgName':
            return item.orgName !== 'N/A' ? item.orgName : null;
          default:
            return item[property];
        }
      };
      this.dataSource.filterPredicate = (data, filter) => {
        return (
          this.colNamesKeys.filter(
            (col) =>
              col != 'action' &&
              !!data[col] &&
              data[col].toString().trim().toLowerCase().includes(filter)
          ).length > 0
        );
      };
    }
  }

  handlehubChange() {
    this.subscriptions.add(
      this.adminFacade.selectedHub$.subscribe((selectedHub) => {
        this.selectHub = selectedHub;
        if (this.selectHubDone) {
          this.setPage(this.pager.currentPage);
        }
        this.selectHubDone = true;
      })
    );
  }

  handleDropdownFilterChange() {
    this.subscriptions.add(
      this.adminFacade.dropdownFilter$.subscribe((dropdownFilter) => {
        if (this.dropdownFilterDone) {
          this.setPage(this.pager.currentPage);
        }
        this.dropdownFilterDone = true;
      })
    );
  }

  getcolumntypes()
  {

    let userID=sessionStorage.getItem('userId').toLowerCase();
    try {
      this.referenceDataService.getColumnChanges(userID, 'alleng').subscribe(response => {
        //Handle the response here  success message
        console.log(response);
      },
        error => {
          //Handle errors here error message
          console.log(error);
        });
    } catch (e) {

    }
  }

  getWorkshop() {
    this.subscriptions.add(
      this.adminFacade.selectedworkshopSuccess$.pipe().subscribe((workshop) => {
        this.alldataFetchDone = workshop;
        this.interruptPagination.emit(this.alldataFetchDone);
        setTimeout(() => {
          this.loadingFlag = workshop;
        }, 1500);
      })
    );
  }

  allFrontendDataFilter() {
    if (this.alldataFetchDone && !this.haveAdoptListviewCondition) {
      this.setFilter();
      this.pageSizeChange();
      this.haveAdoptListviewCondition = true;
    }
  }

  setFilter() {
    if (
      NonBackofficeSearchTable.includes(this.tableName) ||
      this.alldataFetchDone
    ) {
      this.dataSource.filter = this.textFilter.trim().toLowerCase();
      this.clearSelections();
    } else {
      this.pager.currentPage = 1;
      this.setPage(this.pager.currentPage);
    }
    this.completeLoading();
  }

  completeLoading() {
    if (this.listviewLoading$) {
      this.listviewLoading$.subscribe((value) => {
        if (!value) {
          setTimeout(() => {
            this.startInput = false;
          }, 2000);
        }
      });
    } else {
      this.setStartInput(this.data$);
    }
  }

  setStartInput(data) {
    if (data) {
      setTimeout(() => {
        this.startInput = false;
      }, 2000);
    }
  }

  editRow(element): void {
    this.onEdit.emit(element);
  }

  showFeedbackPopup(element): void {
    this.clickActionButton = true;
    this.onMore.emit(element);
  }

  deleteRow(element): void {
    this.onDelete.emit(element);
  }

  timeValid(date, startTime, endTime) {
    var start = new Date(`${date}` + 'T' + `${startTime}`);
    var end = new Date(`${date}` + 'T' + `${endTime}`);
    return (
      start.toString() != 'Invalid Date' && end.toString() != 'Invalid Date'
    );
  }

  handlePaginationSubscriptions() {
    if (this.ioh_totalCount$ !== undefined) {
      this.ioh_totalCount$.pipe(filter((v) => v)).subscribe((val) => {
        this.totalCount = Number(val);
        this.totalpage = Math.ceil(this.totalCount / this.ioh_pageSize);
        this.startRange =
          (this.pager.currentPage - 1) * this.pager.pageSize + 1;
        this.setPageRange(this.totalpage, this.pager.currentPage);
      });
    }
    if (this.reportData$ !== undefined) {
      this.reportData$.pipe(filter((v) => v)).subscribe((val) => {
        this.allReportData = val;
      });
    }
  }

  setPageRange(totalPage, currentPage) {
    if (totalPage === 0) {
      this.totalpage = 1;
    }
    if (this.totalpage === 1) {
      this.endRange = this.totalCount;
    } else {
      this.endRange =
        currentPage === this.totalpage
          ? this.totalCount
          : currentPage * this.pager.pageSize;
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    }
    if (this.totalCount === 0) {
      this.pageRange = '0';
    } else {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      this.pageRange = this.startRange + ' - ' + this.endRange;
    }
  }

  handleCalendarDateTime(date, time) {
    const dateTime = new Date(`${date}` + 'T' + `${time}`);
    // dateTime = new Date(dateTime.setMinutes(dateTime.getMinutes() - 0));
    return dateTime;
  }

  downloadCSIRow(element): void {
    this.clickActionButton = true;
    const currentDate = new Date().toISOString().slice(0, 10);
    const date = element.date ? element.date : element.eventDate ? 
    format(new Date(element.eventDate), 'yyyy-MM-dd') : currentDate;
    let startTime = element.startTime
    ? element.startTime
    : element.eventStartTime;
  let endTime = element.endTime ? element.endTime : element.eventEndTime;
    if (
      startTime &&
      endTime &&
      this.timeValid(date, startTime, endTime) &&
      endTime >= startTime
    ) {
      startTime = this.handleCalendarDateTime(date, startTime);
      endTime = this.handleCalendarDateTime(date, endTime);
    } else {
      startTime = new Date(`${date}` + 'T' + `00:00:00`);
      endTime = new Date(`${date}` + 'T' + `00:00:00`);
    }
    this.config = {
      title: 'ENGAGE EVENT - ' + `${element.title ? element.title : ''}`,
      location: element.location ? element.location.value : '',
      description:
        `IMPORTANT: please double check the times in this calendar invite before saving it to your calendar. ` +
        `This is an event reminder generated by the EngageInnovation tool for an event associated with booking ` +
        `${element.listViewId}` +
        `, please see the EngageInnovation tool for the most current event information.`,
      start: new Date(startTime),
      end: new Date(endTime),
      attendees: [
        {
          name: '',
          email: '',
        },
      ],
    };
    this.downloadCSI(new ICalendar(this.config));
  }

  downloadCSI(icalendar) {
    icalendar.download();
  }

  addUser(): void {
    this.onAddUser.emit();
  }

  iconIn(): void {
    this.showAdminTooltip = true;
  }

  iconOut(): void {
    this.showAdminTooltip = false;
  }

  openWorkshop(element) {
    this.updatedSelectInitialWorkshop();
    if (!element.accessDeny && !this.clickActionButton) {
      if (this.openInNewTab) {
        sessionStorage.setItem('path', `/admin/request/${element.id}`);
        window.open(`/admin/request/${element.id}`);
      } else {
        if (this.allowReroute && this.tableName !== 'User List') {
          sessionStorage.setItem('listView', this.router.url);
          if (this.tableName == 'Archived Engagements') {
            this.onSelectArchiveData.emit(element.id);
            this.router.navigate(['/admin/archive', element.id], {
              state: { workshop: element },
            });
          } else {
            this.archiveFacade.updatedIsArchive(false);
            this.archiveFacade.updatedSelectedArchiveWorkshop(null);
            if (this.tableName == 'Recovery Chargebacks') {
              this.router.navigate(['/admin/request', element.id, 'recovery'], {
                state: { workshop: element },
              });
            } else {
              this.router.navigate(['/admin/request', element.id], {
                state: { workshop: element },
              });
            }
          }
        }
      }
    }
    this.clickActionButton = false;
  }

  updatedSelectInitialWorkshop() {
    if (this.tableName === 'Organization') {
      return;
    }
    if (!this.alldataFetchDone && this.initialData.length !== 0) {
      this.adminFacade.updatedWorkshopByinitialData(this.initialData);
    }
  }

  exportTable(reportCol: any) {
    const exportData = this.allReportData.map((row) => {
      const filteredRow = {};
      Object.keys(this.reportColNames).forEach(
        (key) => (filteredRow[key] = row[key])
      );
      return filteredRow;
    });
    this.exportService.exportTable(exportData, this.tableName, reportCol);
  }

  getPageData() {
    return this.dataSource
      ._pageData(this.dataSource._orderData(this.dataSource.filteredData))
      .filter((row) => row['chargeStatus'] !== 'Direct Charge');
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    const filterdRow = this.getPageData();
    this.isEntirePageSelected()
      ? this.selection.deselect(...filterdRow)
      : this.selection.select(...filterdRow);
  }

  clearSelections() {
    this.selection.clear();
  }

  addSubscriptions() {
    this.subscriptions.add(
      this.selection.changed.subscribe((event) => {
        this.selectionChange.emit(this.selection.selected);
      })
    );
    this.subscriptions.add(
      this.sort.sortChange.subscribe((event) => {
        this.clearSelections();
      })
    );
  }

  setPage(page: number, flag: boolean = true) {
    this.startInput = true;
    this.pager = this.tableService.paginate(
      this.totalCount,
      page,
      this.ioh_pageSize,
      this.maxPages
    );
    if (this.alldataFetchDone && flag) {
      if (this.dataSource) {
        this.paginator.pageIndex = page - 1;
        this.paginator.pageSize = this.ioh_pageSize;
        this.dataSource.paginator = this.paginator;
        this.applyTextFilter();
        
      }
    } else {
      this.PageChangeEmit(page);
    }
    this.completeLoading();
  }

  pageSizeChange() {
    this.paginator._changePageSize(this.ioh_pageSize);
    this.setPage(this.pager.currentPage);
  }

  sortPage(colName) {
    if (this.alldataFetchDone) {
      this.setSort();
      return;
    }
    this.sortColName = colName;
    this.sortDirection = this.sort.direction;
    this.setPage(this.pager.currentPage, false);
  }

  PageChangeEmit(page: number)
  {
    var newDate = new Date();
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    const nowTimeFromClientEnd = newDate.toJSON().substring(0, 10);
    const queryParams = {
      listviewName: this.tableName,
      textFilter: this.textFilter ? this.textFilter.trim() : '',
      pageSize: this.pager.pageSize,
      currentPage: page === 1 ? 1 : this.pager.currentPage,
      sectionName: this.sectionName,
      sortColName: this.sortColName || null,
      sortDirection: this.sortDirection || null,
      selectHub: this.selectHub,
      nowTimeFromClientEnd: nowTimeFromClientEnd,
      allEngageMultiSelectFilter: sessionStorage.getItem(
        'allEngageMultiSelectFilter'
      ),
      chargeBackMultiSelectFilter: sessionStorage.getItem(
        'chargeBackMultiSelectFilter'
      ),
    };
    if (this.selectHubDone && this.dropdownFilterDone) {
      this.pageChange.emit(queryParams);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCheckboxKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.masterToggle();
    }
  }

  onCheckboxKeydownRow(event: KeyboardEvent, element: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.selection.toggle(element);
    }
  }
}
