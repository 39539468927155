export const closedColNames = {
  listViewId: 'Eng ID',
  status: 'Eng Status',
  type: 'Eng Type',
  title: 'Eng Title',
  org: 'Org Name',
  organizationTypes: 'Org Type',
  engagementStartDate: 'Eng Start Date',
  engagementEndDate: 'Eng End Date',
  lead: 'Eng Lead',
  internalPurpose: 'Eng Purpose',
  poc: 'Account Team POC',
};

export const engPurposeColNames = {
  internalPurpose: 'Engagement Purpose',
}

export const allEngagementColNames = {
  fiscalYear: 'Fiscal Year',
  listViewId: 'Engagement ID',
  status: 'Engagement Status',
  type: 'Engagement Type',
  title: 'Engagement Title',
  org: 'Organization Name',
  organizationTypes: 'Organization Type',
  engagementStartDate: 'Engagement Start Date',
  engagementEndDate: 'Engagement End Date',
  internalPurpose: 'Engagement Purpose',
  lead: 'Engagement Lead',
  poc: 'Account Team POC',
};

export const adminColNames = {
  programCode: 'Program',
  reminderEmail: 'Hub Reminder Emails',
  eventEmail: 'Event Emails',
  userId: 'Enterprise ID',
  accessLevel: 'Access Level',
  createdAt: 'Added Date',
  createdBy: 'Added By',
  action: 'Action',
};

export const adminReportColNames = {
  programCode: 'Program',
  userId: 'Enterprise EID',
  accessLevel: 'Access Level',
  createdAt: 'Added Date',
  createdBy: 'Added By',
};

export const homeColNames = {
  listViewId: 'Engagement ID',
  status: 'Engagement Status',
  title: 'Engagement Title',
  org: 'Organization Name',
  engagementStartDate: 'Engagement Start Date',
  engagementEndDate: 'Engagement End Date',
  lead: 'Engagement Lead',
  poc: 'Account Team POC',
};

export const chargeBackColNames = {
  selected: 'Select All',
  chargeDate: 'Charge Date',
  chargeStatus: 'Charge Status',
  listViewId: 'Engagement ID',
  title: 'Engagement Title',
  org: 'Organization',
  status: 'Engagement Status',
  lead: 'Engagement Lead',
  code: 'WBS',
  recoveryTotal: 'Recovery Total',
};

export const historyColNames = {
  orgName: 'ORGANIZATION NAME',
  title: 'ENGAGEMENT TITLE',
  engagementEndDate: 'ENGAGEMENT END DATE',
  program: 'PROGRAM',
  lead: 'ENGAGEMENT LEAD',
};

export const chargeBackReportColNames = {
  chargeDate: 'Charge Date',
  chargeStatus: 'Charge Status',
  listViewId: 'Engagement ID',
  title: 'Engagement Title',
  org: 'Organization',
  status: 'Engagement Status',
  lead: 'Engagement Lead',
  code: 'WBS',
  currency: 'Currency',
  subTotal: 'Recovery Total',
};

export const ArchivedEngagementColNames = {
  listViewId: 'Eng ID',
  status: 'Eng Status',
  type: 'Eng Type',
  title: 'Eng Title',
  org: 'Org Name',
  organizationTypes: 'Org Type',
  engagementStartDate: 'Eng Start Date',
  engagementEndDate: 'Eng End Date',
  internalPurpose: 'Eng Purpose',
  lead: 'Eng Lead',
  poc: 'Account Team POC',
};

export const archivedEngageReportColNames = {
  fiscalYear: 'Fiscal Year',
  listViewId: 'Engagement ID',
  status: 'Engagement Status',
  type: 'Engagement Type',
  title: 'Engagement Title',
  org: 'Organization Name',
  organizationTypes: 'Organization Type',
  engagementStartDate: 'Engagement Start Date',
  engagementEndDate: 'Engagement End Date',
  internalPurpose: 'Engagement Purpose',
  lead: 'Engagement Lead',
  poc: 'Account Team POC',
};

export const inProgressColNames = {
  listViewId: 'Engagement ID',
  status: 'Engagement Status',
  title: 'Engagement Title',
  org: 'Organization Name',
  engagementStartDate: 'Engagement Start Date',
  engagementEndDate: 'Engagement End Date',
  lead: 'Engagement Lead',
  poc: 'Account Team POC',
};

export const allFeedbackColumns = {
  listViewId: 'Engagement ID',
  feedbackEID: 'Enterprise ID',
  title: 'Engagement Title',
  org: 'Organization Name',
  feedbackDate: 'Feedback Date',
  action: 'Action',
};

export const eventDatesColNamesWithDownload = {
  listViewId: 'Engagement ID',
  status: 'Engagement Status',
  title: 'Engagement Title',
  org: 'Organization Name',
  eventDate: 'Event Date',
  eventStartTime: 'Event Start Time',
  eventEndTime: 'Event End Time',
  eventLocation: 'Event Location',
  lead: 'Engagement Lead',
  poc: 'Account Team POC',
  action: 'Action',
};

export const eventDatesColNamesWithoutDownload = {
  listViewId: 'Engagement ID',
  status: 'Engagement Status',
  title: 'Engagement Title',
  org: 'Organization Name',
  eventDate: 'Event Date',
  eventStartTime: 'Event Start Time',
  eventEndTime: 'Event End Time',
  eventLocation: 'Event Location',
  lead: 'Engagement Lead',
  poc: 'Account Team POC',
};

export const requestedColNames = {
  listViewId: 'Engagement ID',
  status: 'Engagement Status',
  title: 'Engagement Title',
  org: 'Organization Name',
  engagementStartDate: 'Engagement Start Date',
  engagementEndDate: 'Engagement End Date',
  lead: 'Engagement Lead',
  poc: 'Account Team POC',
};

export const orgColNames = {
  type: 'type',
  org: 'organization name',
  group: 'group/category',
  action: 'action',
};

export const ActiveEngagementByLead = {
  rowHeaders: ['Requested', 'In Review', 'Booked'],
  colHeaders: [
    { value: 'Engagement Status', type: 'text' },
    { value: 'Engagement Lead', type: 'text' },
    { value: 'End Date Not Set', type: 'number' },
    { value: 'Sep', type: 'number' },
    { value: 'Oct', type: 'number' },
    { value: 'Nov', type: 'number' },
    { value: 'Dec', type: 'number' },
    { value: 'Jan', type: 'number' },
    { value: 'Feb', type: 'number' },
    { value: 'Mar', type: 'number' },
    { value: 'Apr', type: 'number' },
    { value: 'May', type: 'number' },
    { value: 'Jun', type: 'number' },
    { value: 'Jul', type: 'number' },
    { value: 'Aug', type: 'number' },
    { value: 'YTD Total', type: 'number' },
  ],
};

export const MetricsEngagementStatusByMonth = [
  { value: 'Engagement Status', type: 'text' },
  { value: 'End Date Not Set', type: 'number' },
  { value: 'Sep', type: 'number' },
  { value: 'Oct', type: 'number' },
  { value: 'Nov', type: 'number' },
  { value: 'Dec', type: 'number' },
  { value: 'Jan', type: 'number' },
  { value: 'Feb', type: 'number' },
  { value: 'Mar', type: 'number' },
  { value: 'Apr', type: 'number' },
  { value: 'May', type: 'number' },
  { value: 'Jun', type: 'number' },
  { value: 'Jul', type: 'number' },
  { value: 'Aug', type: 'number' },
  { value: 'YTD Total', type: 'number' },
];

export const MetricsCompletesEngagementByMonth = [
  { value: 'Engagement Type', type: 'text' },
  { value: 'End Date Not Set', type: 'number' },
  { value: 'Sep', type: 'number' },
  { value: 'Oct', type: 'number' },
  { value: 'Nov', type: 'number' },
  { value: 'Dec', type: 'number' },
  { value: 'Jan', type: 'number' },
  { value: 'Feb', type: 'number' },
  { value: 'Mar', type: 'number' },
  { value: 'Apr', type: 'number' },
  { value: 'May', type: 'number' },
  { value: 'Jun', type: 'number' },
  { value: 'Jul', type: 'number' },
  { value: 'Aug', type: 'number' },
  { value: 'YTD Total', type: 'number' },
];

export const MetricsCompletedByPurpose = [
  { value: 'Engagement Purpose', type: 'text' },
  { value: 'Client', type: 'number' },
  { value: 'Internal', type: 'number' },
  { value: 'External', type: 'number' },
  { value: 'YTD Total', type: 'number' },
];

export const ChargebackByMonth = [
  { value: 'Chargeback Status', type: 'text' },
  { value: 'Chargeback Date Not Set', type: 'number' },
  { value: 'Sep', type: 'number' },
  { value: 'Oct', type: 'number' },
  { value: 'Nov', type: 'number' },
  { value: 'Dec', type: 'number' },
  { value: 'Jan', type: 'number' },
  { value: 'Feb', type: 'number' },
  { value: 'Mar', type: 'number' },
  { value: 'Apr', type: 'number' },
  { value: 'May', type: 'number' },
  { value: 'Jun', type: 'number' },
  { value: 'Jul', type: 'number' },
  { value: 'Aug', type: 'number' },
  { value: 'YTD Total', type: 'number' },
];
export const topClients = [
  { value: 'Organization Name', type: 'text' },
  { value: 'Number of Engagments', type: 'number' },
];

export const gridColNames = {
  listViewId: 'Eng ID',
  status: 'Eng Status',
  type: 'Eng Type',
  title: 'Eng Title',
  org: 'Org Name',
  organizationTypes: 'Org Type',
  engagementStartDate: 'Eng Start Date',
  engagementEndDate: 'Eng End Date',
  lead: 'Eng Lead',
  internalPurpose: 'Eng Purpose',
  poc: 'Account Team POC',
  // wbsCode: 'code',
};
