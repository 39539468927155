<div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.md="space-between stretch"
    fxLayoutAlign.sm="space-between stretch"
    fxLayoutAlign.xs="space-between stretch"
    fxLayoutGap="0.6rem"
    class="general__mt"
    [formGroup]="parentForm"
  >
    <ioh-wbs-input
      fxFlex="28%"
      name="wbs-code"
      inputId="wbs-code-{{index}}"
      id="wbs-code-{{index}}"
      name="wbs-code"
      placeholder="WBS Code"
      value="code"
      [label]="smallScreen ? 'WBS Code': ''"
      [formGroup]="parentForm"
      [success]="isValid"
      [wbsDetails]="wbsDetails"
      [wbs] = "wbsCode"
      [expired]="isExpired"
      [type]="wbsType"
      [response]="responseLoaded"
      [valid]="isValid"
      [loading$]="wbsResultsLoading$"
      [smScreen] = "smallScreen"
    ></ioh-wbs-input>
    <div *ngIf="wbsDetails" fxLayout="column" fxFlex>
      <label *ngIf="smallScreen" class="wbs__details__label" fxFlex="50%">WBS Code</label>
      <p *ngIf="parentForm.get('code').value" fxFlex="27%" class="wbs__details__text" id="wbsType-{{index}}">{{wbsType || '-'}}</p>
    </div>
    <div *ngIf="wbsDetails" fxLayout="column" fxFlex>
      <label *ngIf="smallScreen" class="wbs__details__label" fxFlex="50%">WBS Description</label>
      <p *ngIf="parentForm.get('code').value" fxFlex="46%" class="wbs__details__text" id="wbsDescription-{{index}}">{{wbsDescription || '-'}}</p>
    </div>
</div>
