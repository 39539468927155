import { createAction, props } from '@ngrx/store';

export const loadVisitDetails = createAction(
  '[VisitDetails] Load VisitDetails'
);

export const loadVisitDetailsSuccess = createAction(
  '[VisitDetails] Load VisitDetails Success',
  props<{ visitDetails }>()
);

export const loadVisitDetailsFailure = createAction(
  '[VisitDetails] Load VisitDetails Failure',
  props<{ error: any }>()
);

export const loadWBSLookupResults = createAction(
  '[VisitDetails] Load WBSLookupResults',
  props<{ wbs: string; index: number }>()
);

export const loadWBSLookupResultsSuccess = createAction(
  '[VisitDetails] Load WBSLookupResultsSuccess',
  props<{ wbsCodeResults: any }>()
);

export const loadWBSLookupResultsFailure = createAction(
  '[VisitDetails] Load WBSLookupFailure',
  props<{ error: string }>()
);

export const updateSelectedDuration = createAction(
  '[VisitDetails] Update SelectedDuration',
  props<{ duration: string }>()
);

export const updateSelectedVisitType = createAction(
  '[VisitDetails] Update SelectedVisitType',
  props<{ visitType: string }>()
);

export const updateSelectedWBSCodes = createAction(
  '[VisitDetails] Update SelectedWBSCodes',
  props<{ wbsCodes: any }>()
);

export const clearStateObject = createAction(
  '[VisitDetails] Clear State Object'
);
