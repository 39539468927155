<!-- Sub Title -->
<h3 class="attendee__title" tabindex="0">Number of Attendees</h3>
<div class="attendee__input__container">
  <small class="attendee__word" tabindex="0"
  >Please indicate the number of attendees you expect from each category below.</small>
</div>

<!-- Number of Attendees -->


<div class="attendee__input__container">
  <div
  fxLayout="row"
  fxLayout.md="column"
  fxLayout.sm="column"
  fxLayout.xs="column"
  fxLayoutAlign="start start"
  fxLayoutAlign.md="space-between stretch"
  fxLayoutAlign.sm="space-between stretch"
  fxLayoutAlign.xs="space-between stretch"
  fxLayoutGap="1rem"
  >
  <ng-container *ngFor="let input of inputConsultationfields">
    <ioh-input 
      [label]="input.label"
      inputId="{{input.id}}"
      name="{{input.name}}"
      placeholder="{{input.placeholder}}"
      [formGroup]="parentForm"
      value="{{input.value}}"
      regex="^[0-9]+$"
      [maxLength]="input.maxLength"
      [required]="undefined != input.required ? input.required : requiredAttendeeNumber"
      class="attendee__input"
      fxFlex = {{input.flex}}
      fxFlex.md="100%"
      fxFlex.sm="100%"
      fxFlex.xs="100%"
    ></ioh-input>
  </ng-container>
  </div>
  </div>

<!-- Error Message -->
  <ioh-error
    tabindex="0"
    class="input__error__attendee"
    *ngIf="parentForm.touched && !(parentForm.get('totalAttendees').value > 0) && requiredAttendeeNumber"
    errorMsg="Please provide an expected number for at least one category of attendee above"
  ></ioh-error>
