<main class="container">
    <section class="response__wrapper" tabindex="0">
        <ioh-response [response]="response" tabindex="0"></ioh-response>
    </section>
    <section tabindex="0">
        <button mat-button (click)="goBack()" tabindex="0"><span class="material-icons" tabindex="0">
            keyboard_backspace
            </span>Go back</button>
    </section>
</main>
