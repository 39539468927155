import { AdminFacade } from '@ioh/core-data';
import { HttpService } from '@ioh/core-data';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { delay, retry } from 'rxjs/operators';
import { environment } from '../../environments';
import { map } from 'rxjs/internal/operators/map';
import { encrypt,decrypt } from '../../environments/encry-decrypt';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  awsAccess: any;
  counter: number = 1;

  constructor(
    private readonly sharedService: SharedService,
    private readonly http: HttpService,
    private readonly adminFacade: AdminFacade
  ) {}

  checkAccess(): Observable<any> {
    const url = `${environment.endpoints.base}${environment.endpoints.user.access}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        this.awsAccess = {
          // awsKey:decrypt(`${environment.endpoints.user.awsKey}`),
          awskey:"",
          awsSecret: "",
          region: `${environment.endpoints.user.region}`,
      };
        //res.data.userInfo.awsAccess;
        this.sharedService.canAccess.next(res.data.userInfo.hasAccess);
        if (res.data.userInfo.program.length > 0) {
          this.adminFacade.saveProgramList(res.data.userInfo.program);
          this.adminFacade.saveAdminProgramList(
            res.data.userInfo.adminHubNameList
          );
          this.adminFacade.saveUserAccessList(res.data.userInfo.accessInfo);
        }
      }),
      retry(10),
      delay(this.counter++ * 1000)
    );
  }
}
