import { Utils } from './utils';

export class AdditionalTab extends Utils {
  BookingID: string;
  Country: string;
  LabsTopics: string;
  IndustryProgram: string;

  constructor(workshop: any) {
    super();
    const program = workshop.program;
    const general = workshop.general ? workshop.general : '';
    const engagement = general.engagement ? general.engagement : '';
    this.BookingID =
      workshop.id && program.code
        ? `${program.code} - ` + `${workshop.id}`
        : '';
    this.Country =
      engagement.eurCountry && engagement.eurCountry.value
        ? `${engagement.eurCountry.value}`
        : '';
    this.LabsTopics = engagement.labsRDTopics
      ? this.mapJoin(engagement.labsRDTopics, 'value')
      : '';
    this.IndustryProgram = engagement.industryRDTopics
      ? this.mapJoin(engagement.industryRDTopics, 'value')
      : '';
  }

  public static getColumns = [
    { header: 'Booking ID', key: 'BookingID' },
    { header: 'Country', key: 'Country' },
    { header: 'Labs R&D Topics', key: 'LabsTopics' },
    { header: 'Industry Program', key: 'IndustryProgram' },
  ];
}
